import CONSTANTS from "../../util/constant/CONSTANTS";

export const DataSetlerForAll = (API) => {
  API.sendRequest(CONSTANTS.API.employmenttype, (res) => {
    const EmployeMentTypeOption = res?.data?.rows?.map((el) => ({
      id: el.id,
      value: el.id,
      Label: el.type,
    }));

    CONSTANTS.FORM_FIELD.EMPLOYEE_NEW_MODAL[6].item[1].option =
      EmployeMentTypeOption;
    CONSTANTS.FORM_FIELD.EMPLOYEE_EDIT_MODAL[6].item[1].option =
      EmployeMentTypeOption;
  });

  API.sendRequest(CONSTANTS.API.shifts, (res) => {
    const ShiftTypeOption = res?.data?.rows?.map((el) => ({
      id: el.id,
      value: el.id,
      Label: el.shift,
    }));

    CONSTANTS.FORM_FIELD.EMPLOYEE_NEW_MODAL[3].item[0].option = ShiftTypeOption;
    CONSTANTS.FORM_FIELD.EMPLOYEE_EDIT_MODAL[3].item[0].option =
      ShiftTypeOption;
  });

  API.sendRequest(CONSTANTS.API.getAllManagers, (res) => {
    const allManagerOptions = res?.data?.rows?.map((el) => ({
      id: el.id,
      value: el.id,
      Label: el.name,
    }));

    CONSTANTS.FORM_FIELD.EMPLOYEE_EDIT_MODAL[3].item[1].option =
      allManagerOptions;
    CONSTANTS.FORM_FIELD.EMPLOYEE_NEW_MODAL[3].item[1].option =
      allManagerOptions;
  });

  API.sendRequest(CONSTANTS.API.getAlldepartment, (res) => {
    const allDepartmentOptions = res?.data?.rows?.map((el) => ({
      id: el.id,
      value: el.id,
      Label: el.name,
    }));

    CONSTANTS.FORM_FIELD.EMPLOYEE_EDIT_MODAL[2].item[0].option =
      allDepartmentOptions;
    CONSTANTS.FORM_FIELD.EMPLOYEE_NEW_MODAL[2].item[0].option =
      allDepartmentOptions;
  });

  API.sendRequest(CONSTANTS.API.getallvendors, (res) => {
    const allVendorOptions = res?.data?.rows?.map((el) => ({
      Label: el.name,
      value: el.id,
      id: el.id,
    }));

    CONSTANTS.FORM_FIELD.NEW_EQUIPMENT_FORM[1].item[1].option =
      allVendorOptions;
  });

  API.sendRequest(CONSTANTS.API.equipmentcategory, (res) => {
    const AllEquipmentCategoryOption = res?.data?.rows?.map((el) => ({
      id: el.id,
      value: el.id,
      Label: el.category,
    }));

    CONSTANTS.FORM_FIELD.NEW_EQUIPMENT_FORM[1].item[0].option =
      AllEquipmentCategoryOption;
  });

  API.sendRequest(CONSTANTS.API.getAllClients, (res) => {
    const allClientOptions = res?.data?.rows?.map((el) => ({
      id: el.name,
      value: el.name,
      Label: el.name,
    }));

    CONSTANTS.FORM_FIELD.NEW_EQUIPMENT_FORM[7].item[1].option =
      allClientOptions;
  });

  API.sendRequest(CONSTANTS.API.searchProducts, (res) => {
    const allSearchProducts = res?.data?.rows?.map((el) => ({
      id: el.id,
      value: el.name,
      Label: el.name,
    }));

    CONSTANTS.FORM_FIELD.SUPPLY_IN_INVENTORY[0].option = allSearchProducts;
    CONSTANTS.FORM_FIELD.PURCHASE_REQUEST_INNER[0].option = allSearchProducts;
  });

  API.sendRequest(CONSTANTS.API.getallMaintenanceRequests, (res) => {
    const filteredRequests = res?.data?.data
      .filter((el) => el.status !== "Completed" && el.status !== "Cancelled")
      .map((el) => ({
        id: el.id,
        value: el.id,
        Label: el.title,
      }));

    CONSTANTS.FORM_FIELD.PURCHASE_REQUEST_FORM_NEW[0].item[1].option =
      filteredRequests;
    CONSTANTS.FORM_FIELD.PURCHASE_REQUEST_FORM[1].item[1].option =
      filteredRequests;
    CONSTANTS.FORM_FIELD.SUPPLY_OUT_INVENTORY[1].item[0].option =
      filteredRequests;
  });

  API.sendRequest(CONSTANTS.API.employee, (res) => {
    CONSTANTS.FORM_FIELD.SUPPLY_OUT_INVENTORY[0].item[0].option =
      res?.data?.rows?.map((el) => ({
        id: el.name,
        value: el.name,
        Label: el.name,
      }));

    const allEmployeOption = res?.data?.rows?.map((el) => ({
      id: el.id,
      value: el.id,
      Label: el.name,
    }));

    CONSTANTS.FORM_FIELD.MAINTENANCE_REQUEST_FORM[1].item[0].option =
      allEmployeOption;
    CONSTANTS.FORM_FIELD.MAINTENANCE_REQUEST_FORM[1].item[1].option =
      allEmployeOption;
    CONSTANTS.FORM_FIELD.MAINTENANCE_REQUEST_FORM_NEW[1].item[0].option =
      allEmployeOption;
    CONSTANTS.FORM_FIELD.MAINTENANCE_REQUEST_FORM_NEW[1].item[1].option =
      allEmployeOption;
    CONSTANTS.FORM_FIELD.VEHICAL_EXPANSE_FORM[3].option = allEmployeOption;
    CONSTANTS.FORM_FIELD.EVENT_LOG_FORM[2].item[0].option = allEmployeOption;
    CONSTANTS.FORM_FIELD.CLEANING_FORM[0].item[1].option = allEmployeOption;
  });

  API.sendRequest(CONSTANTS.API.productCategory, (res) => {
    const allProductCategoryOption = res?.data?.rows?.map((el) => ({
      id: el.id,
      value: el.id,
      Label: el.category,
    }));

    CONSTANTS.FORM_FIELD.SUPPLY_IN_INVENTORY[2].item[1].option =
      allProductCategoryOption;
    CONSTANTS.FORM_FIELD.PURCHASE_REQUEST_INNER[1].option =
      allProductCategoryOption;
  });
  API.sendRequest(CONSTANTS.API.AllEquipment, (res) => {
    const AllEquipmentOptions = res?.data?.rows.map((el) => ({
      id: el.id,
      value: el.id,
      Label: el.name,
    }));

    CONSTANTS.FORM_FIELD.MAINTENANCE_REQUEST_FORM[2].item[0].option =
      AllEquipmentOptions;
    CONSTANTS.FORM_FIELD.MAINTENANCE_REQUEST_FORM_NEW[2].item[0].option =
      AllEquipmentOptions;
  });
};
