import React, { useEffect, useState } from "react";
import { Col, Row } from "antd";
import PageHeader from "../../../../component/common/page-Header";
import { DownloadOutlined } from "@ant-design/icons";
import CustomTable from "../../../../component/common/Custom-Table";
import CONSTANTS from "../../../../util/constant/CONSTANTS";
import useHttp from "../../../../hooks/use-http";
import ModalFormCreator from "../../../../component/common/ModalFormCreator";
import moment from "moment";
const LogBookABTMeter = () => {
  const [data, setData] = useState([]);
  const [Allfilter, setAllFilter] = useState(null);
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 20,
    total: 0,
  });

  const [onTransfer, setOnTransfer] = useState(false);
  const [openEditRow, setOpenEditRow] = useState(false);
  const [formIventoryData, setFormIventoryData] = useState({});
  const [isDisabled, setIsDisabled] = useState(true);
  const [refresh, setRefresh] = useState(true);

  const API = useHttp();
  const onRequestCreate = (value, clear) => {
    // console.log(value, "data");
    value.date = moment(value.date.$d).format("YYYY-MM-DD");
    API.sendRequest(
      CONSTANTS.API.abtCreate,
      () => {
        // console.log(res, "purchase");
        setOnTransfer(false);
        setRefresh((prev) => !prev);
        clear();
      },
      value,
      "abt log created"
    );
  };
  useEffect(() => {
    const MovePage = { ...CONSTANTS.API.movehistory };
    MovePage.endpoint = `${MovePage.endpoint}`;
    let QuaryParams = {
      limit: pagination.pageSize,
      page: pagination.current,
    };
    if (Allfilter?.sort) {
      QuaryParams = { ...QuaryParams, ...Allfilter?.sort };
    }
    if (Allfilter?.filter) {
      Allfilter?.filter?.map((el) => {
        QuaryParams = {
          ...QuaryParams,
          [`autogenerate-mul-array-$${el[0]}$`]: el[1],
        };
      });
    }
    API.sendRequest(
      CONSTANTS.API.abtAll,
      (res) => {
        setPagination((prevPagination) => ({
          ...prevPagination,
          total: res?.data?.count,
        }));
        setData(
          res?.data?.rows?.map((el, i) => ({
            ...el,
            no: (pagination.current - 1) * pagination.pageSize + i + 1,
            key: el.id,
          }))
        );
      },
      QuaryParams
    );
  }, [refresh, pagination.current, pagination.pageSize, Allfilter]);

  /***
   *
   * Row click
   */
  const onEditHandler = (value, clear) => {
    console.log(value, formIventoryData);
    value.date = moment(value.date.$d).format("YYYY-MM-DD");
    const updateInventoryAPI = { ...CONSTANTS.API.abtUpdate };
    updateInventoryAPI.endpoint = updateInventoryAPI.endpoint.replace(
      ":id",
      formIventoryData.id
    );
    // console.log(updateInventoryAPI);
    API.sendRequest(
      updateInventoryAPI,
      () => {
        setOpenEditRow(false);
        setRefresh((prev) => !prev);
        clear();
      },
      value,
      "abt log updated"
    );
  };

  const CSVData = [];
  CSVData[0] = CONSTANTS.TABLE.ABT_TABLE.map((el) => el.title);
  data.map((item, index) => {
    CSVData[index + 1] = CONSTANTS.TABLE.ABT_TABLE.map(
      (el) => item[el.dataIndex]
    );
    return 0;
  });
  return (
    <Row className="container-main">
      <ModalFormCreator
        loading={API.isLoading}
        open={onTransfer}
        onCreate={onRequestCreate}
        onCancel={() => {
          setOnTransfer(false);
        }}
        name="add ABT READING"
        menu="ABT_FORM"
      />
      <PageHeader
        data={{
          buttons: [
            !CONSTANTS.USER_ROLE && {
              id: 1,
              action: () => {
                console.log("button1");
                setOnTransfer(true);
              },
              name: "New",
            },
            {
              id: 2,
              action: () => {
                console.log("download", CSVData);
              },
              icon: <DownloadOutlined />,
              data: CSVData,
              type: "linkicon",
              name: "ABT-Meter-Log-" + moment().format("YYYY-MM-DD HH:mm"),
            },
          ],
          name: " ABT Meter Reading Form",
        }}
        pagination={pagination}
        setPagination={setPagination}
        SearchBar={{
          endpointAPI: "/abt?search=",
          dataResponseHandle: (res) => {
            return res?.data?.rows?.map((el) => ({
              ...el,
              key: el.id,
            }));
          },
          onValueIsSelected: (v) => {
            setFormIventoryData({ ...v });
            setOpenEditRow(true);
          },
          extraLabel: "abtNo",
          onBaseSearch: ["sku"],
          debounceTime: 500,
        }}
      />
      <Col
        span={24}
        style={{
          marginBlock: "15px",
        }}
        className="container-body"
      >
        <ModalFormCreator
          loading={API.isLoading}
          open={openEditRow}
          onCreate={onEditHandler}
          onCancel={() => {
            setOpenEditRow(false);
            setIsDisabled(true);
            // setFormIventoryData({});
          }}
          disabled={isDisabled}
          edit
          onEdit={() => {
            console.log("edit click");
            setIsDisabled(false);
          }}
          Delete
          onDelete={() => {
            console.log("Delete Click", formIventoryData);
            const DeleteInventoryAPI = {
              ...CONSTANTS.API.abtDelete,
            };
            DeleteInventoryAPI.endpoint = DeleteInventoryAPI.endpoint.replace(
              ":id",
              formIventoryData.id
            );
            API.sendRequest(
              DeleteInventoryAPI,
              (res) => {
                console.log(res);
                setRefresh((prev) => !prev);
                setOpenEditRow(false);
              },
              "Deleted Successfully"
            );
          }}
          name="edit ABT READING"
          menu="ABT_FORM"
          formData={formIventoryData}
        />
        <CustomTable
          isLoading={API.isLoading}
          dataSource={data}
          setChanges={(v) => {
            setAllFilter(v);
            setPagination((prev) => ({
              ...prev,
              current: 1,
            }));
          }}
          name="ABT_TABLE"
          extraclass="pointer"
          DeleteSelectedRow
          APIendpoint="abtDelete"
          onConfirm={() => {
            setRefresh((prev) => !prev);
          }}
          Other={{
            onRow: (row) => ({
              onClick: () => {
                setFormIventoryData({ ...row });
                setOpenEditRow(true);
              },
            }),
          }}
        />
      </Col>
    </Row>
  );
};

export default LogBookABTMeter;
