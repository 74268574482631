import Logo from "../../asset/image/KP-Logo.jpeg";
import moment from "moment";

import React from "react";
import "./style/style.css";
import CONSTANTS from "../../util/constant/CONSTANTS";
const ERP = (props) => {
  const { data } = props;
  const styles = `body,
div,
table,
thead,
tbody,
tfoot,
tr,
th,
td,
p {
  font-family: "Arial";
  font-size: small;
}

hr {
  border: none;
  /* border-top: 1px solid #000; */
  margin: 10px 0;
}

h1 {
  font-size: 24px;
  margin: 10px 0;
}

a {
  text-decoration: none;
  color: #000;
}

a:hover {
  color: red;
}

table {
  border-collapse: collapse;
  /* border: 1px solid #000; */
  width: 100%;
}

td,
th {
  padding: 5px;
  /* border: 1px solid #000; */
}

.logo {
  width: 90px;
  height: 90px;
  margin-left: 0px;
  margin-top: 5px;
}
.border-top {
  border-top: 1px solid #000;
}

.border-bottom {
  border-bottom: 1px solid #000;
}

.border-left {
  border-left: 1px solid #000;
}

.border-right {
  border-right: 1px solid #000;
}

.border-all {
  border: 1px solid #000;
}`;
  return (
    <>
      <style>{styles}</style>
      <table>
        <colgroup>
          <col width="94" />
          <col width="197" />
          <col width="108" />
          <col width="141" />
          <col span="2" width="124" />
        </colgroup>
        <tr>
          <td height="20"></td>
          <td></td>
          <td></td>
          <td></td>
          <td colspan="2"></td>
        </tr>
        <tr>
          <td colspan="2" rowspan="5" height="106">
            <img src={Logo} alt="Logo" class="logo" />
          </td>
          <td></td>
          <td></td>
          <td colspan="2" style={{ fontSize: "22px" }}>
            <b>Expense Voucher</b>
          </td>
        </tr>
        <tr>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
        </tr>
        <tr>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
        </tr>
        <tr>
          <td></td>
          <td></td>
          <td style={{ fontSize: "15px" }}>
            <b>Date</b>
          </td>
          <td style={{ fontSize: "15px" }}> {moment().format("DD/MM/YYYY")}</td>
        </tr>
        <tr>
          <td></td>
          <td></td>
          <td>
            <b style={{ fontSize: "15px" }}>Voucher ID</b>
          </td>
          <td style={{ fontSize: "15px" }}>{data?.sku}</td>
        </tr>
        <tr>
          <td height="20"></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
        </tr>
        <tr class="border-all">
          <td height="31" class="border-all">
            Party
          </td>
          <td colspan="5" class="border-all">
            {data?.partyName}
          </td>
        </tr>
        <tr class="border-all">
          <td height="30" class="border-all">
            Description
          </td>
          <td colspan="5" class="border-all">
            {data?.description}
          </td>
        </tr>
        <tr class="border-all">
          <td height="28" class="border-all">
            Amount
          </td>
          <td class="border-all">₹ {data?.amount}</td>
          <td colspan="2" class="border-all">
            Category
          </td>
          <td colspan="2" class="border-all">
            {data?.category}
          </td>
        </tr>
        <tr class="border-all">
          <td height="32" class="border-all">
            Tracking
          </td>
          <td class="border-all">{data?.tracking}</td>
          <td colspan="2" class="border-all">
            Bill Reference
          </td>
          <td colspan="2" class="border-all">
            {data?.billReference}
          </td>
        </tr>
        <tr class="border-all">
          <td height="31" class="border-all">
            Remarks
          </td>
          <td colspan="5" class="border-all">
            {data?.remark}
          </td>
        </tr>
        <tr>
          <td height="20" colspan="6"></td>
        </tr>
        <tr class="border-all">
          <td height="31" class="border-all">
            Prepared By
          </td>
          <td class="border-all">{CONSTANTS.GETMe?.name}</td>
          <td colspan="2" class="border-all">
            Checked By
          </td>
          <td colspan="2" class="border-all">
            Approved By
          </td>
        </tr>
        <tr class="border-all">
          <td colspan="2" height="59" class="border-all">
            Signature
          </td>
          <td colspan="2" class="border-all">
            Signature
          </td>
          <td colspan="2" class="border-all">
            Signature
          </td>
        </tr>
        <tr>
          <td height="21" colspan="6"></td>
        </tr>
        <tr class="border-top">
          <td colspan="6" height="17" align="center" valign="bottom">
            KPI Green OMS Private Limited (Formerly Known as M81 Technologies
            Pvt. Ltd.)
          </td>
        </tr>
        <tr>
          <td
            colspan="6"
            height="17"
            align="center"
            valign="top"
            bgcolor="#FFFFFF"
          >
            A-1/2, Firdous Tower, Near Fazal Shopping Centre, Near Rander Road,
            Adajan Patia, Adajan, Surat, Gujarat - 395009
          </td>
        </tr>
      </table>

      {/* <h1>
        Sheet 2: <em>Gatepass</em>
      </h1>

      <table cellSpacing={0} border={0}>
        <colgroup width={67} />
        <colgroup width={55} />
        <colgroup width={285} />
        <colgroup width={98} />
        <colgroup width={86} />
        <colgroup width={49} />
        <colgroup width={141} />
        <tbody>
          <tr>
            <td height={20} align="left" valign="bottom">
              <font face="Rubik" size={3} color="#000000">
                <br />
              </font>
            </td>
            <td align="left" valign="bottom">
              <font face="Rubik" size={3} color="#000000">
                <br />
              </font>
            </td>
            <td align="left" valign="bottom">
              <font face="Rubik" size={3} color="#000000">
                <br />
              </font>
            </td>
            <td align="left" valign="bottom">
              <font face="Rubik" size={3} color="#000000">
                <br />
              </font>
            </td>
            <td align="left" valign="bottom">
              <font face="Rubik" size={3} color="#000000">
                <br />
              </font>
            </td>
            <td align="left" valign="bottom">
              <font face="Rubik" size={3} color="#000000">
                <br />
              </font>
            </td>
            <td align="left" valign="bottom">
              <font face="Rubik" size={3} color="#000000">
                <br />
              </font>
            </td>
          </tr>
          <tr>
            <td
              colSpan={2}
              rowSpan={5}
              height={106}
              align="left"
              valign="bottom"
            >
              <font face="Rubik" size={3} color="#000000">
                <br />
                <img
                  src="ERP%20Document%20Format_html_967ae0fb5defaf49.png"
                  width={20}
                  height={21}
                  hspace={23}
                  vspace={2}
                />
              </font>
            </td>
            <td align="left" valign="bottom">
              <font face="Rubik" size={3} color="#000000">
                Samoj 66KV Solar Plant
              </font>
            </td>
            <td colSpan={4} align="left" valign="bottom" bgcolor="#FFFFFF">
              <b>
                <font face="Rubik" size={4} color="#000000">
                  Returnable Gate Pass
                </font>
              </b>
            </td>
          </tr>
          <tr>
            <td align="left" valign="bottom">
              <font face="Rubik" size={3} color="#000000">
                Email: solar.samoj@kpgroup.co
              </font>
            </td>
            <td align="left" valign="bottom">
              <font face="Rubik" size={3} color="#000000">
                <br />
              </font>
            </td>
            <td align="left" valign="bottom">
              <font color="#000000">
                <br />
              </font>
            </td>
            <td align="left" valign="bottom">
              <font color="#000000">
                <br />
              </font>
            </td>
            <td align="left" valign="bottom">
              <font color="#000000">
                <br />
              </font>
            </td>
          </tr>
          <tr>
            <td align="left" valign="bottom">
              <font face="Rubik" size={3} color="#000000">
                Contact No.: 7984975311
              </font>
            </td>
            <td align="left" valign="bottom">
              <font color="#000000">
                <br />
              </font>
            </td>
            <td align="left" valign="bottom">
              <font color="#000000">
                <br />
              </font>
            </td>
            <td align="left" valign="bottom">
              <font color="#000000">
                <br />
              </font>
            </td>
            <td align="left" valign="bottom">
              <font color="#000000">
                <br />
              </font>
            </td>
          </tr>
          <tr>
            <td align="left" valign="bottom">
              <font face="Rubik" size={3} color="#000000">
                Address: Samoj, Dist. Bharuch
              </font>
            </td>
            <td colSpan={2} align="left" valign="bottom">
              <b>
                <font face="Rubik" size={3} color="#000000">
                  Gate Pass No.
                </font>
              </b>
            </td>
            <td colSpan={2} align="left" valign="bottom" bgcolor="#FFFFFF">
              <font face="Rubik" size={3} color="#1F1F1F">
                GPRN/2023/OCH/05
              </font>
            </td>
          </tr>
          <tr>
            <td align="left" valign="bottom">
              <font face="Rubik" size={3} color="#000000">
                Gujarat, 383001
              </font>
            </td>
            <td colSpan={2} align="left" valign="bottom">
              <b>
                <font face="Rubik" size={3} color="#000000">
                  Date
                </font>
              </b>
            </td>
            <td
              colSpan={2}
              align="left"
              valign="bottom"
              bgcolor="#FFFFFF"
              sdval={45113}
              sdnum="1033;0;MM/DD/YYYY"
            >
              <font face="Rubik" size={3} color="#1F1F1F">
                07/06/2023
              </font>
            </td>
          </tr>
          <tr>
            <td height={20} align="left" valign="bottom">
              <font face="Rubik" size={3} color="#000000">
                <br />
              </font>
            </td>
            <td align="left" valign="bottom">
              <font face="Rubik" size={3} color="#000000">
                <br />
              </font>
            </td>
            <td align="left" valign="bottom">
              <font color="#000000">
                <br />
              </font>
            </td>
            <td align="left" valign="bottom">
              <font color="#000000">
                <br />
              </font>
            </td>
            <td align="left" valign="bottom">
              <font color="#000000">
                <br />
              </font>
            </td>
            <td align="left" valign="bottom">
              <font color="#000000">
                <br />
              </font>
            </td>
            <td align="left" valign="bottom">
              <font color="#000000">
                <br />
              </font>
            </td>
          </tr>
          <tr>
            <td
              style={{ borderBottom: "2px solid #434343" }}
              colSpan={7}
              height={26}
              align="center"
              valign="middle"
            >
              <b>
                <font face="Rubik" size={3} color="#000000">
                  Gate Pass Out
                </font>
              </b>
            </td>
          </tr>
          <tr>
            <td
              style={{
                borderBottom: "1px solid #000000",
                borderLeft: "1px solid #000000",
                borderRight: "1px solid #000000",
              }}
              colSpan={7}
              height={20}
              align="left"
              valign="bottom"
            >
              <b>
                <font face="Rubik" size={3} color="#000000">
                  To,
                </font>
              </b>
            </td>
          </tr>
          <tr>
            <td
              className="border-all"
              colSpan={2}
              height={20}
              align="left"
              valign="bottom"
            >
              <font face="Rubik" size={3} color="#000000">
                Name
              </font>
            </td>
            <td className="border-all" align="left" valign="bottom">
              <font face="Rubik" size={3} color="#000000">
                Kamal Singh
              </font>
            </td>
            <td
              style={{
                borderBottom: "1px solid #000000",
                borderLeft: "1px solid #000000",
              }}
              colSpan={2}
              align="left"
              valign="bottom"
            >
              <font face="Rubik" size={3} color="#000000">
                Challan No.
              </font>
            </td>
            <td
              style={{
                borderBottom: "1px solid #000000",
                borderLeft: "1px solid #000000",
                borderRight: "1px solid #000000",
              }}
              colSpan={2}
              align="left"
              valign="bottom"
              sdval={121}
              sdnum="1033;"
            >
              <font face="Rubik" size={3} color="#000000">
                121
              </font>
            </td>
          </tr>
          <tr>
            <td
              className="border-all"
              colSpan={2}
              height={20}
              align="left"
              valign="bottom"
            >
              <font face="Rubik" size={3} color="#000000">
                Designation
              </font>
            </td>
            <td className="border-all" align="left" valign="bottom">
              <font face="Rubik" size={3} color="#000000">
                Manager
              </font>
            </td>
            <td
              style={{
                borderTop: "1px solid #000000",
                borderBottom: "1px solid #000000",
                borderLeft: "1px solid #000000",
              }}
              colSpan={2}
              align="left"
              valign="bottom"
            >
              <font face="Rubik" size={3} color="#000000">
                Transporter
              </font>
            </td>
            <td className="border-all" colSpan={2} align="left" valign="bottom">
              <font face="Rubik" size={3} color="#000000">
                KP Group
              </font>
            </td>
          </tr>
          <tr>
            <td
              className="border-all"
              colSpan={2}
              height={20}
              align="left"
              valign="bottom"
            >
              <font face="Rubik" size={3} color="#000000">
                Email
              </font>
            </td>
            <td className="border-all" align="left" valign="bottom">
              <font face="Rubik" size={3} color="#000000">
                ochhan.solar@kpgroup.co
              </font>
            </td>
            <td
              style={{
                borderTop: "1px solid #000000",
                borderBottom: "1px solid #000000",
                borderLeft: "1px solid #000000",
              }}
              colSpan={2}
              align="left"
              valign="bottom"
            >
              <font face="Rubik" size={3} color="#000000">
                Driver Name
              </font>
            </td>
            <td className="border-all" colSpan={2} align="left" valign="bottom">
              <font face="Rubik" size={3} color="#000000">
                Manish
              </font>
            </td>
          </tr>
          <tr>
            <td
              className="border-all"
              colSpan={2}
              height={20}
              align="left"
              valign="bottom"
            >
              <font face="Rubik" size={3} color="#000000">
                Phone
              </font>
            </td>
            <td
              style={{
                borderTop: "1px solid #000000",
                borderLeft: "1px solid #000000",
                borderRight: "1px solid #000000",
              }}
              align="left"
              valign="bottom"
              sdval={7861259810}
              sdnum="1033;"
            >
              <font face="Rubik" size={3} color="#000000">
                7861259810
              </font>
            </td>
            <td
              style={{
                borderTop: "1px solid #000000",
                borderBottom: "1px solid #000000",
                borderLeft: "1px solid #000000",
              }}
              colSpan={2}
              align="left"
              valign="bottom"
            >
              <font face="Rubik" size={3} color="#000000">
                Vehical No.
              </font>
            </td>
            <td className="border-all" colSpan={2} align="left" valign="bottom">
              <font face="Rubik" size={3} color="#000000">
                GJ18BE2565
              </font>
            </td>
          </tr>
          <tr>
            <td
              className="border-all"
              colSpan={2}
              rowSpan={2}
              height={40}
              align="left"
              valign="middle"
            >
              <font face="Rubik" size={3} color="#000000">
                Address
              </font>
            </td>
            <td className="border-all" rowSpan={2} align="left" valign="middle">
              <font face="Rubik" size={3} color="#000000">
                Ochhan Solar Plant, Dist. Bharuch, Gujarat - 395602
              </font>
            </td>
            <td
              style={{
                borderTop: "1px solid #000000",
                borderBottom: "1px solid #000000",
                borderLeft: "1px solid #000000",
              }}
              colSpan={2}
              align="left"
              valign="bottom"
            >
              <font face="Rubik" size={3} color="#000000">
                Division Name
              </font>
            </td>
            <td className="border-all" colSpan={2} align="left" valign="bottom">
              <font face="Rubik" size={3} color="#000000">
                PLANT
              </font>
            </td>
          </tr>
          <tr>
            <td
              style={{
                borderTop: "1px solid #000000",
                borderBottom: "1px solid #000000",
              }}
              colSpan={2}
              align="left"
              valign="bottom"
            >
              <font face="Rubik" size={3} color="#000000">
                Dept. Name
              </font>
            </td>
            <td className="border-all" colSpan={2} align="left" valign="bottom">
              <font face="Rubik" size={3} color="#000000">
                O&amp;M
              </font>
            </td>
          </tr>
          <tr>
            <td height={20} align="left" valign="bottom">
              <font face="Rubik" size={3} color="#000000">
                <br />
              </font>
            </td>
            <td align="left" valign="bottom">
              <font face="Rubik" size={3} color="#000000">
                <br />
              </font>
            </td>
            <td align="left" valign="bottom">
              <font face="Rubik" size={3} color="#000000">
                <br />
              </font>
            </td>
            <td align="left" valign="bottom">
              <font face="Rubik" size={3} color="#000000">
                <br />
              </font>
            </td>
            <td align="left" valign="bottom">
              <font face="Rubik" size={3} color="#000000">
                <br />
              </font>
            </td>
            <td align="left" valign="bottom">
              <font face="Rubik" size={3} color="#000000">
                <br />
              </font>
            </td>
            <td align="left" valign="bottom">
              <font face="Rubik" size={3} color="#000000">
                <br />
              </font>
            </td>
          </tr>
          <tr>
            <td
              className="border-all"
              height={20}
              align="center"
              valign="middle"
            >
              <b>
                <font face="Rubik" size={3} color="#000000">
                  S/N.
                </font>
              </b>
            </td>
            <td
              className="border-all"
              colSpan={2}
              align="center"
              valign="middle"
            >
              <b>
                <font face="Rubik" size={3} color="#000000">
                  Item Description
                </font>
              </b>
            </td>
            <td className="border-all" align="center" valign="middle">
              <b>
                <font face="Rubik" size={3} color="#000000">
                  Qty
                </font>
              </b>
            </td>
            <td className="border-all" align="center" valign="middle">
              <b>
                <font face="Rubik" size={3} color="#000000">
                  UOM
                </font>
              </b>
            </td>
            <td
              className="border-all"
              colSpan={2}
              align="center"
              valign="middle"
            >
              <b>
                <font face="Rubik" size={3} color="#000000">
                  SKU
                </font>
              </b>
            </td>
          </tr>
          <tr>
            <td className="border-all" height={20} align="left" valign="bottom">
              <font face="Rubik" size={3} color="#000000">
                <br />
              </font>
            </td>
            <td className="border-all" colSpan={2} align="left" valign="bottom">
              <font face="Rubik" size={3} color="#000000">
                <br />
              </font>
            </td>
            <td className="border-all" align="left" valign="bottom">
              <font face="Rubik" size={3} color="#000000">
                <br />
              </font>
            </td>
            <td className="border-all" align="left" valign="bottom">
              <font face="Rubik" size={3} color="#000000">
                <br />
              </font>
            </td>
            <td className="border-all" colSpan={2} align="left" valign="bottom">
              <font face="Rubik" size={3} color="#000000">
                <br />
              </font>
            </td>
          </tr>
          <tr>
            <td
              className="border-all"
              colSpan={7}
              height={20}
              align="left"
              valign="bottom"
            >
              <font face="Rubik" size={3} color="#000000">
                <br />
              </font>
            </td>
          </tr>
          <tr>
            <td
              className="border-all"
              colSpan={7}
              height={20}
              align="left"
              valign="bottom"
            >
              <font face="Rubik" size={3} color="#000000">
                Remarks :
              </font>
            </td>
          </tr>
          <tr>
            <td
              style={{
                borderTop: "1px solid #000000",
                borderBottom: "1px solid #000000",
                borderLeft: "1px solid #000000",
              }}
              height={20}
              align="left"
              valign="bottom"
            >
              <font face="Rubik" size={3} color="#000000">
                <br />
              </font>
            </td>
            <td
              style={{
                borderTop: "1px solid #000000",
                borderBottom: "1px solid #000000",
              }}
              align="left"
              valign="bottom"
            >
              <font face="Rubik" size={3} color="#000000">
                <br />
              </font>
            </td>
            <td
              style={{
                borderTop: "1px solid #000000",
                borderBottom: "1px solid #000000",
              }}
              align="left"
              valign="bottom"
            >
              <font face="Rubik" size={3} color="#000000">
                <br />
              </font>
            </td>
            <td
              style={{
                borderTop: "1px solid #000000",
                borderBottom: "1px solid #000000",
              }}
              align="left"
              valign="bottom"
            >
              <font face="Rubik" size={3} color="#000000">
                <br />
              </font>
            </td>
            <td
              style={{
                borderTop: "1px solid #000000",
                borderBottom: "1px solid #000000",
              }}
              align="left"
              valign="bottom"
            >
              <font face="Rubik" size={3} color="#000000">
                <br />
              </font>
            </td>
            <td
              style={{
                borderTop: "1px solid #000000",
                borderBottom: "1px solid #000000",
              }}
              align="left"
              valign="bottom"
            >
              <font face="Rubik" size={3} color="#000000">
                <br />
              </font>
            </td>
            <td
              style={{
                borderTop: "1px solid #000000",
                borderBottom: "1px solid #000000",
                borderRight: "1px solid #000000",
              }}
              align="left"
              valign="bottom"
            >
              <font face="Rubik" size={3} color="#000000">
                <br />
              </font>
            </td>
          </tr>
          <tr>
            <td
              className="border-all"
              colSpan={3}
              rowSpan={3}
              height={60}
              align="left"
              valign="bottom"
            >
              <font face="Rubik" size={3} color="#000000">
                <br />
              </font>
            </td>
            <td
              className="border-all"
              colSpan={4}
              rowSpan={3}
              align="left"
              valign="bottom"
            >
              <font face="Rubik" size={3} color="#000000">
                <br />
              </font>
            </td>
          </tr>
          <tr />
          <tr />
          <tr>
            <td
              className="border-all"
              colSpan={3}
              height={20}
              align="center"
              valign="bottom"
            >
              <font face="Rubik" size={3} color="#000000">
                Sender Name &amp; Signature
              </font>
            </td>
            <td
              className="border-all"
              colSpan={4}
              align="center"
              valign="bottom"
            >
              <font face="Rubik" size={3} color="#000000">
                Reciever Name &amp; Signature
              </font>
            </td>
          </tr>
          <tr>
            <td height={20} align="center" valign="bottom">
              <b>
                <font face="Rubik" size={3} color="#000000">
                  <br />
                </font>
              </b>
            </td>
            <td align="center" valign="bottom">
              <b>
                <font face="Rubik" size={3} color="#000000">
                  <br />
                </font>
              </b>
            </td>
            <td align="center" valign="bottom">
              <b>
                <font face="Rubik" size={3} color="#000000">
                  <br />
                </font>
              </b>
            </td>
            <td align="center" valign="bottom">
              <b>
                <font face="Rubik" size={3} color="#000000">
                  <br />
                </font>
              </b>
            </td>
            <td align="center" valign="bottom">
              <b>
                <font face="Rubik" size={3} color="#000000">
                  <br />
                </font>
              </b>
            </td>
            <td align="center" valign="bottom">
              <b>
                <font face="Rubik" size={3} color="#000000">
                  <br />
                </font>
              </b>
            </td>
            <td align="center" valign="bottom">
              <b>
                <font face="Rubik" size={3} color="#000000">
                  <br />
                </font>
              </b>
            </td>
          </tr>
          <tr>
            <td
              style={{ borderBottom: "2px solid #434343" }}
              colSpan={7}
              height={20}
              align="center"
              valign="bottom"
            >
              <b>
                <font face="Rubik" size={3} color="#000000">
                  Gate Pass In (Return)
                </font>
              </b>
            </td>
          </tr>
          <tr>
            <td
              style={{
                borderBottom: "1px solid #000000",
                borderLeft: "1px solid #000000",
                borderRight: "1px solid #000000",
              }}
              colSpan={7}
              height={20}
              align="left"
              valign="bottom"
            >
              <b>
                <font face="Rubik" size={3} color="#000000">
                  From,
                </font>
              </b>
            </td>
          </tr>
          <tr>
            <td
              className="border-all"
              colSpan={2}
              height={20}
              align="left"
              valign="bottom"
            >
              <font face="Rubik" size={3} color="#000000">
                Name
              </font>
            </td>
            <td className="border-all" align="left" valign="bottom">
              <font face="Rubik" size={3} color="#000000">
                Kamal Singh
              </font>
            </td>
            <td
              style={{
                borderBottom: "1px solid #000000",
                borderLeft: "1px solid #000000",
              }}
              colSpan={2}
              align="left"
              valign="bottom"
            >
              <font face="Rubik" size={3} color="#000000">
                Challan No.
              </font>
            </td>
            <td
              style={{
                borderBottom: "1px solid #000000",
                borderLeft: "1px solid #000000",
                borderRight: "1px solid #000000",
              }}
              colSpan={2}
              align="left"
              valign="bottom"
              sdval={121}
              sdnum="1033;"
            >
              <font face="Rubik" size={3} color="#000000">
                121
              </font>
            </td>
          </tr>
          <tr>
            <td
              className="border-all"
              colSpan={2}
              height={20}
              align="left"
              valign="bottom"
            >
              <font face="Rubik" size={3} color="#000000">
                Designation
              </font>
            </td>
            <td className="border-all" align="left" valign="bottom">
              <font face="Rubik" size={3} color="#000000">
                Manager
              </font>
            </td>
            <td
              style={{
                borderTop: "1px solid #000000",
                borderBottom: "1px solid #000000",
                borderLeft: "1px solid #000000",
              }}
              colSpan={2}
              align="left"
              valign="bottom"
            >
              <font face="Rubik" size={3} color="#000000">
                Transporter
              </font>
            </td>
            <td className="border-all" colSpan={2} align="left" valign="bottom">
              <font face="Rubik" size={3} color="#000000">
                KP Group
              </font>
            </td>
          </tr>
          <tr>
            <td
              className="border-all"
              colSpan={2}
              height={20}
              align="left"
              valign="bottom"
            >
              <font face="Rubik" size={3} color="#000000">
                Email
              </font>
            </td>
            <td className="border-all" align="left" valign="bottom">
              <font face="Rubik" size={3} color="#000000">
                ochhan.solar@kpgroup.co
              </font>
            </td>
            <td
              style={{
                borderTop: "1px solid #000000",
                borderBottom: "1px solid #000000",
                borderLeft: "1px solid #000000",
              }}
              colSpan={2}
              align="left"
              valign="bottom"
            >
              <font face="Rubik" size={3} color="#000000">
                Driver Name
              </font>
            </td>
            <td className="border-all" colSpan={2} align="left" valign="bottom">
              <font face="Rubik" size={3} color="#000000">
                Manish
              </font>
            </td>
          </tr>
          <tr>
            <td
              className="border-all"
              colSpan={2}
              height={20}
              align="left"
              valign="bottom"
            >
              <font face="Rubik" size={3} color="#000000">
                Phone
              </font>
            </td>
            <td
              style={{
                borderTop: "1px solid #000000",
                borderLeft: "1px solid #000000",
                borderRight: "1px solid #000000",
              }}
              align="left"
              valign="bottom"
              sdval={7861259810}
              sdnum="1033;"
            >
              <font face="Rubik" size={3} color="#000000">
                7861259810
              </font>
            </td>
            <td
              style={{
                borderTop: "1px solid #000000",
                borderBottom: "1px solid #000000",
                borderLeft: "1px solid #000000",
              }}
              colSpan={2}
              align="left"
              valign="bottom"
            >
              <font face="Rubik" size={3} color="#000000">
                Vehical No.
              </font>
            </td>
            <td className="border-all" colSpan={2} align="left" valign="bottom">
              <font face="Rubik" size={3} color="#000000">
                GJ18BE2565
              </font>
            </td>
          </tr>
          <tr>
            <td
              className="border-all"
              colSpan={2}
              rowSpan={2}
              height={40}
              align="left"
              valign="middle"
            >
              <font face="Rubik" size={3} color="#000000">
                Address
              </font>
            </td>
            <td className="border-all" rowSpan={2} align="left" valign="middle">
              <font face="Rubik" size={3} color="#000000">
                Ochhan Solar Plant, Dist. Bharuch, Gujarat - 395602
              </font>
            </td>
            <td
              style={{
                borderTop: "1px solid #000000",
                borderBottom: "1px solid #000000",
                borderLeft: "1px solid #000000",
              }}
              colSpan={2}
              align="left"
              valign="bottom"
            >
              <font face="Rubik" size={3} color="#000000">
                Division Name
              </font>
            </td>
            <td className="border-all" colSpan={2} align="left" valign="bottom">
              <font face="Rubik" size={3} color="#000000">
                PLANT
              </font>
            </td>
          </tr>
          <tr>
            <td
              style={{
                borderTop: "1px solid #000000",
                borderBottom: "1px solid #000000",
              }}
              colSpan={2}
              align="left"
              valign="bottom"
            >
              <font face="Rubik" size={3} color="#000000">
                Dept. Name
              </font>
            </td>
            <td className="border-all" colSpan={2} align="left" valign="bottom">
              <font face="Rubik" size={3} color="#000000">
                O&amp;M
              </font>
            </td>
          </tr>
          <tr>
            <td
              className="border-all"
              colSpan={7}
              height={20}
              align="left"
              valign="bottom"
            >
              <font face="Rubik" size={3} color="#000000">
                <br />
              </font>
            </td>
          </tr>
          <tr>
            <td
              className="border-all"
              colSpan={7}
              height={20}
              align="left"
              valign="bottom"
            >
              <font face="Rubik" size={3} color="#000000">
                Remarks :
              </font>
            </td>
          </tr>
          <tr>
            <td
              style={{
                borderTop: "1px solid #000000",
                borderBottom: "1px solid #000000",
                borderLeft: "1px solid #000000",
              }}
              height={20}
              align="left"
              valign="bottom"
            >
              <font face="Rubik" size={3} color="#000000">
                <br />
              </font>
            </td>
            <td
              style={{
                borderTop: "1px solid #000000",
                borderBottom: "1px solid #000000",
              }}
              align="left"
              valign="bottom"
            >
              <font face="Rubik" size={3} color="#000000">
                <br />
              </font>
            </td>
            <td
              style={{
                borderTop: "1px solid #000000",
                borderBottom: "1px solid #000000",
              }}
              align="left"
              valign="bottom"
            >
              <font face="Rubik" size={3} color="#000000">
                <br />
              </font>
            </td>
            <td
              style={{
                borderTop: "1px solid #000000",
                borderBottom: "1px solid #000000",
              }}
              align="left"
              valign="bottom"
            >
              <font face="Rubik" size={3} color="#000000">
                <br />
              </font>
            </td>
            <td
              style={{
                borderTop: "1px solid #000000",
                borderBottom: "1px solid #000000",
              }}
              align="left"
              valign="bottom"
            >
              <font face="Rubik" size={3} color="#000000">
                <br />
              </font>
            </td>
            <td
              style={{
                borderTop: "1px solid #000000",
                borderBottom: "1px solid #000000",
              }}
              align="left"
              valign="bottom"
            >
              <font face="Rubik" size={3} color="#000000">
                <br />
              </font>
            </td>
            <td
              style={{
                borderTop: "1px solid #000000",
                borderBottom: "1px solid #000000",
                borderRight: "1px solid #000000",
              }}
              align="left"
              valign="bottom"
            >
              <font face="Rubik" size={3} color="#000000">
                <br />
              </font>
            </td>
          </tr>
          <tr>
            <td
              className="border-all"
              colSpan={3}
              rowSpan={3}
              height={60}
              align="left"
              valign="bottom"
            >
              <font face="Rubik" size={3} color="#000000">
                <br />
              </font>
            </td>
            <td
              className="border-all"
              colSpan={4}
              rowSpan={3}
              align="left"
              valign="bottom"
            >
              <font face="Rubik" size={3} color="#000000">
                <br />
              </font>
            </td>
          </tr>
          <tr />
          <tr />
          <tr>
            <td
              className="border-all"
              colSpan={3}
              height={20}
              align="center"
              valign="bottom"
            >
              <font face="Rubik" size={3} color="#000000">
                Sender Name &amp; Signature
              </font>
            </td>
            <td
              className="border-all"
              colSpan={4}
              align="center"
              valign="bottom"
            >
              <font face="Rubik" size={3} color="#000000">
                Reciever Name &amp; Signature
              </font>
            </td>
          </tr>
          <tr>
            <td height={20} align="left" valign="bottom">
              <font face="Rubik" size={3} color="#000000">
                <br />
              </font>
            </td>
            <td align="left" valign="bottom">
              <font face="Rubik" size={3} color="#000000">
                <br />
              </font>
            </td>
            <td align="left" valign="bottom">
              <font face="Rubik" size={3} color="#000000">
                <br />
              </font>
            </td>
            <td align="left" valign="bottom">
              <font face="Rubik" size={3} color="#000000">
                <br />
              </font>
            </td>
            <td align="left" valign="bottom">
              <font face="Rubik" size={3} color="#000000">
                <br />
              </font>
            </td>
            <td align="left" valign="bottom">
              <font face="Rubik" size={3} color="#000000">
                <br />
              </font>
            </td>
            <td align="left" valign="bottom">
              <font face="Rubik" size={3} color="#000000">
                <br />
              </font>
            </td>
          </tr>
          <tr>
            <td
              style={{ borderTop: "2px solid #434343" }}
              colSpan={7}
              height={24}
              align="center"
              valign="bottom"
            >
              <font face="Rubik" color="#434343">
                KPI Green OMS Private Limited (Formerly Known as M81
                Technologies Pvt. Ltd.)
              </font>
            </td>
          </tr>
          <tr>
            <td
              colSpan={7}
              height={24}
              align="center"
              valign="top"
              bgcolor="#FFFFFF"
            >
              <font face="Rubik" color="#434343">
                A-1/2, Firdous Tower, Near Fazal Shopping Centre, Near Rander
                Road, Adajan Patia, Adajan, Surat, Gujarat - 395009
              </font>
            </td>
          </tr>
        </tbody>
      </table>
      <hr />
      <h1>
        Sheet 3: <em>Single Gatepass</em>
      </h1> */}
      {/* Add content for Sheet 3 (Single Gatepass) here */}
    </>
  );
};

export default ERP;
