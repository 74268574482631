import React, { useEffect, useState } from "react";
import { AutoComplete } from "antd";
import Search from "antd/es/input/Search";

import useHttp from "../../hooks/use-http";
import ModalFormCreator from "./ModalFormCreator";
import { ActualDuration } from "../../util/functions";
import moment from "moment";
import CustomTable from "./Custom-Table";
import dayjs from "dayjs";
const FormateModalObject = {
  Employee: {
    ResultHandler: (v) => ({ ...v.data, UniqueSKU: v.data?.sku }),
    ViewName: "View Employee Details",
    ModalName: "EMPLOYEE_EDIT_MODAL",
  },
  Equipment: {
    ResultHandler: (v) => ({
      ...v.data,
      ...v.data,
      doi: moment(v.data?.doi),
      doc: moment(v.data?.doc),
      warrantyExp: moment(v.data?.warrantyExp),
      maintananceDuration: dayjs(v.data?.maintananceDuration, "HH:mm"),
      UniqueSKU: v.data?.sku,
    }),
    ViewName: "View Equipment Details",
    ModalName: "NEW_EQUIPMENT_FORM",
  },
  Vendor: {
    ResultHandler: (v) => ({ ...v.data, UniqueSKU: v.data?.mobile }),
    ViewName: "View Vendor Details",
    ModalName: "NEW_VENDOR_REGISTRATION",
  },
  Inward: {
    ResultHandler: (v) => ({
      ...v.data?.product,
      ...v.data?.product?.productCategory,
      ...v.data,
      key: v.data?.id,
      UniqueSKU: v.data?.sku,
    }),
    ViewName: "View Product Details",
    ModalName: "SUPPLY_IN_INVENTORY",
  },
  Maintanance: {
    ResultHandler: (v) => ({
      ...v?.data,
      key: v?.data.id,
      actualduration:
        v?.data?.status === "Completed"
          ? ActualDuration(
              v?.data?.date,
              v?.data?.maintenanceResolutions
                ?.map((el) => el?.date)
                .sort((a, b) => moment(a).isAfter(moment(b)))[0]
            )
          : "-",
      creatorName: v?.data?.creator?.name,
      assignedName: v?.data?.assigned?.name,
      equipmentName: v?.data?.equipment?.name,
      equipmentModal: v?.data?.equipment?.model,
      equipmentSrNumber: v?.data?.equipment?.srNumber,
      // date: moment(v?.data?.date).format("YYYY-MM-DD HH:mm"),
      resolutionDate: v?.data?.maintenanceResolutions
        ?.map((el) => el?.date)
        .sort((a, b) => moment(a).isAfter(moment(b)))[0],

      maintenanceResolutions: [
        ...new Set(v?.data?.maintenanceResolutions?.map((el) => el?.action)),
      ]
        ?.map((ele) =>
          v?.data?.maintenanceResolutions?.filter((el) => el?.action === ele)
        )
        ?.map((el) => ({
          action: el[0]?.action,
          createdAt: el[0]?.createdAt,
          date: el[0]?.date,
          products: el?.map((p) => ({
            ...p,
            category: p?.product?.productCategory?.category,
            ...p?.product,
            quantity: p?.quantity,
            sku: p?.product?.inventoryInwards?.[0]?.sku,
            srNumber: p?.product?.inventoryInwards?.[0]?.trackingId,
          })),
        })),
      UniqueSKU: v.data?.sku,
    }),
    ViewName: "View Maintanance Details",
    ModalName: "MAINTENANCE_REQUEST_FORM",
  },
  Purchase: {
    ResultHandler: (v) => ({
      ...v.data.product,
      ...v.data,
      UniqueSKU: v.data?.prf,
    }),
    ViewName: "View PURCHASE REQUEST Details",
    ModalName: "PURCHASE_REQUEST_FORM",
  },
  Expanse: {
    ResultHandler: (v) => ({
      // ...v.data.product,
      ...v.data,
      UniqueSKU: v.data?.sku,
    }),
    ViewName: "View Expense Details",
    ModalName: "EXPANSE_FORM",
  },
  "Vehicle Expanse": {
    ResultHandler: (v) => ({
      ...v?.data?.employee,
      ...v?.data,
      key: v?.data?.id,
      UniqueSKU: v.data?.sku,
    }),
    ViewName: "View vehicle Expense Details",
    ModalName: "VEHICAL_EXPANSE_FORM",
  },
  "Event Log": {
    ResultHandler: (v) => ({
      ...v?.data?.employee,
      ...v?.data,
      key: v?.data?.id,
      UniqueSKU: v.data?.sku,
    }),
    ViewName: "View Event log Details",
    ModalName: "EVENT_LOG_FORM",
  },
  "Clean Log": {
    ResultHandler: (v) => ({
      ...v?.data?.employee,
      ...v?.data,
      key: v?.data?.id,
      UniqueSKU: v.data?.sku,
    }),
    ViewName: "View CLeaning Log Details",
    ModalName: "CLEANING_FORM",
  },
  "ABT Log": {
    ResultHandler: (v) => ({
      ...v?.data,
      key: v?.data?.id,
      UniqueSKU: v.data?.sku,
    }),
    ViewName: "View ABT READING Details",
    ModalName: "ABT_FORM",
  },
};

const CustomGlobalSearchBar = () => {
  const [selectedValue, setSelectedValue] = useState(null);
  const [formIventoryData, setFormIventoryData] = useState(null);
  const API = useHttp();

  useEffect(() => {
    if (selectedValue?.endpoint && FormateModalObject?.[selectedValue?.table]) {
      API.sendRequest(
        { type: "GET", endpoint: selectedValue?.endpoint },
        (res) => {
          // console.log(res, "Data Selected B");
          setFormIventoryData(
            FormateModalObject?.[selectedValue?.table]?.ResultHandler(res)
          );
        }
      );
    }
  }, [selectedValue]);

  return (
    <>
      {FormateModalObject?.[selectedValue?.table] && (
        <ModalFormCreator
          loading={API.isLoading}
          open={formIventoryData !== null}
          // onCreate={onCreate}
          disabled={true}
          onCancel={() => {
            setSelectedValue(null);
            setFormIventoryData(null);
          }}
          Submit={false}
          name={
            `    ${FormateModalObject?.[selectedValue?.table]?.ViewName}
          (${formIventoryData?.UniqueSKU})` || "View Search Details"
          }
          menu={FormateModalObject?.[selectedValue?.table]?.ModalName}
          formData={formIventoryData || {}}
        >
          {selectedValue?.table === "Maintanance" &&
            formIventoryData?.maintenanceResolutions?.map((el, i) => (
              <div className="mb15" key={el.products.id}>
                <div
                  className="form-modal-head an-20 mb5"
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <p className="form-modal-head an-20 mb5">{`Resolution - ${
                    i + 1
                  }`}</p>
                  <p className="form-modal-head an-15 mb5">
                    {/* {moment(el?.createdAt).format("YYYY-MM-DD HH:mm")} */}
                    {moment(el?.date).format("YYYY-MM-DD HH:mm")}
                    {/* {dayjs(moment(el?.createdAt).format("YYYY-MM-DD HH:mm")).from(
                  dayjs(formInventoryData?.date)
                )} */}
                  </p>
                </div>
                <p className="an-15 mb5">{el?.action}</p>
                {el?.products?.[0]?.product && (
                  <CustomTable
                    name="MAINTENANCE_RESOLUTION_TABLE"
                    dataSource={el.products}
                    scrollAutoOff={true}
                    Other={{ rowSelection: null }}
                  />
                )}
              </div>
            ))}
        </ModalFormCreator>
      )}
      <CustomSearchBarAuto
        endpointAPI="/search"
        dataResponseHandle={(res) => {
          return res?.data?.map((el) => ({
            ...el,
            key: el?.id,
          }));
        }}
        onValueIsSelected={(row) => {
          setSelectedValue(row);
          // console.log(row, "Data Selected A");
        }}
        setSelectedValue={setSelectedValue}
        // extraLabel="sku"
        // onBaseSearch={["name"]}
        extraLabel="name"
        onBaseSearch={["sku"]}
        debounceTime={500}
        isSearch={true}
      />
    </>
  );
};

export default CustomGlobalSearchBar;

const CustomSearchBarAuto = ({
  endpointAPI = "/search",
  dataResponseHandle = (res) => res?.data?.rows,
  onValueIsSelected = (v) => {
    console.log(v);
  },
  onChange = () => {},
  onBaseSearch = [],
  debounceTime = 1000,
  isSearch = false,
  extraLabel = "",
  setSelectedValue,
}) => {
  const API = useHttp();
  const [searchKeyword, setSearchKeyword] = useState("");
  const [suggestions, setSuggestions] = useState([]);
  const [fetchTimeout, setFetchTimeout] = useState(null);

  const fetchSuggestions = async (keyword) => {
    try {
      API.sendRequest(
        { type: "POST", endpoint: endpointAPI },
        (res) => {
          setSuggestions(dataResponseHandle(res));
          onChange(dataResponseHandle(res));
        },
        {
          search: keyword,
        }
      );
    } catch (error) {
      console.error("Error fetching suggestions:", error);
    }
  };

  const handleSearchChange = (value) => {
    clearTimeout(fetchTimeout);
    setSearchKeyword(value);
    setSelectedValue(null);
    const timeout = setTimeout(() => {
      if (!value.trim()) {
        dataResponseHandle(null, true);
      }
      if (isSearch && value?.trim()) {
        fetchSuggestions(value?.trim());
      }
    }, debounceTime);
    setFetchTimeout(timeout);
  };

  const handleSelect = (value) => {
    const selectedItem = suggestions.find((item) => {
      let nestedValue = item;
      for (let i = 0; i < onBaseSearch.length; i++) {
        nestedValue = nestedValue[onBaseSearch[i]];
        if (!nestedValue) {
          return false;
        }
      }
      return nestedValue === value;
    });

    onValueIsSelected(selectedItem);
  };

  useEffect(() => {
    return () => {
      clearTimeout(fetchTimeout);
    };
  }, [fetchTimeout]);

  const getNestedValue = (obj, path) => {
    let value = obj;
    for (let i = 0; i < path.length; i++) {
      value = value[path[i]];
      if (!value) {
        return undefined;
      }
    }
    return value;
  };

  const renderOptionLabel = (item) => {
    const baseValue = getNestedValue(item, onBaseSearch);
    const extraValue = getNestedValue(item, extraLabel.split("."));
    return `${baseValue}${extraValue ? ` (${extraValue})` : ""}`;
  };
  // selectedValue && console.log(selectedValue, "ABC");
  return (
    <>
      <AutoComplete
        options={
          !!extraLabel && onBaseSearch.length
            ? suggestions?.map((item) => ({
                value: getNestedValue(item, onBaseSearch),
                label: renderOptionLabel(item),
              }))
            : []
        }
        onSelect={handleSelect}
        onChange={handleSearchChange}
        //   onClick={() => {
        //     console.log("object");
        //   }}
        value={searchKeyword}
        style={{ width: "100%" }}
      >
        <Search
          className="dashboardSearch"
          allowClear
          // onSearch={console.log}
        />
      </AutoComplete>
    </>
  );
};
