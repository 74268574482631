import { Col } from "antd";
import React from "react";

const PlantOverviewCard = ({ name, value }) => {
  return (
    <Col className="gutter-row cursor-pointer" span={24} md={8} lg={8} xl={8}>
      <div className="gutter-box">
        <div
          className="pt15 pb5 my15 flex center px20"
          style={{
            justifyContent: "space-between",
            marginLeft: "-25px",
          }}
        >
          <div className="text-title">{name}</div>
          <div className="text-subtitle an-25">{value?.toLocaleString()}</div>
        </div>
      </div>
    </Col>
  );
};

export default PlantOverviewCard;
