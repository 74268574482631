import { Pie } from "@ant-design/charts";
import React from "react";

const DonutChart = React.memo(({ GraphData }) => {
  const data = GraphData || [
    {
      type: "1",
      value: 270,
    },
    {
      type: "2",
      value: 25,
    },
    {
      type: "3",
      value: 18,
    },
    {
      type: "4",
      value: 15,
    },
    {
      type: "5",
      value: 10,
    },
    {
      type: "6",
      value: 5,
    },
  ];
  const config = {
    appendPadding: 10,
    data,
    angleField: "value",
    colorField: "type",
    radius: 0.8,
    innerRadius: 0.64,
    meta: {
      value: {
        formatter: (v) => `${v} ¥`,
      },
    },
    label: {
      type: "inner",
      offset: "-50%",
      style: {
        textAlign: "center",
      },
      autoRotate: false,
      content: "{value}",
    },
    color: ["#2E5BFF", "#8C54FF", "#F7C137", "#2CC2A5", "#E84A50", "gray"],
    statistic: {
      title: {
        offsetY: -4,
        customHtml: (container, view, datum) => {
          return (
            <p className="an-10 text-title gray--text ">
              {datum && datum.type}
            </p>
          );
        },
      },
      content: {
        offsetY: 4,
        style: {
          fontSize: "32px",
        },
        customHtml: (container, view, datum, data) => {
          return (
            <p className="an-10 text-title gray--text  ">
              {datum &&
                `${(
                  (datum.value / data.reduce((r, d) => r + d.value, 0)) *
                  100
                ).toFixed(2)} %`}
            </p>
          );
        },
      },
    },
    interactions: [
      {
        type: "element-selected",
      },
      {
        type: "element-active",
      },
      {
        type: "pie-statistic-active",
      },
    ],
  };
  return <Pie {...config} />;
});

export default DonutChart;
