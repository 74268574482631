import React, { useEffect, useState } from "react";
import EmployeeCard from "../../../component/card/EmployeeCard";
import { Col, Empty, Row, Spin } from "antd";
import { DownloadOutlined } from "@ant-design/icons";
import ModalFormCreator from "../../../component/common/ModalFormCreator";
import PageHeader from "../../../component/common/page-Header";
import useHttp from "../../../hooks/use-http";
import CONSTANTS from "../../../util/constant/CONSTANTS";
const Employee = () => {
  const [open, setOpen] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [employeeDataArr, setEmployeeDataArr] = useState([]);
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 20,
    total: 0,
  });
  const API = useHttp();
  const onCreate = (values, clear) => {
    // console.log("Received values of form: ", values);
    values.mobile = values?.mobile?.toString();
    values.aadhar = values?.aadhar?.toString();
    API.sendRequest(
      CONSTANTS.API.addEmployee,
      () => {
        // console.log(res, "add");
        setRefresh((prev) => !prev);
        clear();
      },
      values,
      "employee added"
    );
    setOpen(false);
  };

  useEffect(() => {
    API.sendRequest(CONSTANTS.API.employee, (res) => {
      setPagination((prevPagination) => ({
        ...prevPagination,
        total: res?.data?.rows?.length,
      }));

      setEmployeeDataArr(
        res?.data?.rows.map((el) => (
          <Col key={el.eId}>
            <EmployeeCard {...el} />
          </Col>
        ))
      );
    });
  }, [refresh]);

  useEffect(() => {
    API.sendRequest(CONSTANTS.API.employmenttype, (res) => {
      CONSTANTS.FORM_FIELD.EMPLOYEE_NEW_MODAL[6].item[1].option =
        res?.data?.rows?.map((el) => ({
          id: el.id,
          value: el.id,
          Label: el.type,
        }));
      CONSTANTS.FORM_FIELD.EMPLOYEE_EDIT_MODAL[6].item[1].option =
        res?.data?.rows?.map((el) => ({
          id: el.id,
          value: el.id,
          Label: el.type,
        }));
    });
    API.sendRequest(CONSTANTS.API.shifts, (res) => {
      CONSTANTS.FORM_FIELD.EMPLOYEE_NEW_MODAL[3].item[0].option =
        res?.data?.rows?.map((el) => ({
          id: el.id,
          value: el.id,
          Label: el.shift,
        }));
      CONSTANTS.FORM_FIELD.EMPLOYEE_EDIT_MODAL[3].item[0].option =
        res?.data?.rows?.map((el) => ({
          id: el.id,
          value: el.id,
          Label: el.shift,
        }));
    });
    API.sendRequest(CONSTANTS.API.getAllManagers, (res) => {
      CONSTANTS.FORM_FIELD.EMPLOYEE_EDIT_MODAL[3].item[1].option =
        res?.data?.rows?.map((el) => ({
          id: el.id,
          value: el.id,
          Label: el.name,
        }));
      CONSTANTS.FORM_FIELD.EMPLOYEE_NEW_MODAL[3].item[1].option =
        res?.data?.rows?.map((el) => ({
          id: el.id,
          value: el.id,
          Label: el.name,
        }));
    });
    API.sendRequest(CONSTANTS.API.getAlldepartment, (res) => {
      CONSTANTS.FORM_FIELD.EMPLOYEE_EDIT_MODAL[2].item[0].option =
        res?.data?.rows?.map((el) => ({
          id: el.id,
          value: el.id,
          Label: el.name,
        }));
      CONSTANTS.FORM_FIELD.EMPLOYEE_NEW_MODAL[2].item[0].option =
        res?.data?.rows?.map((el) => ({
          id: el.id,
          value: el.id,
          Label: el.name,
        }));
    });
  }, []);
  return (
    <Row className="container-main">
      <PageHeader
        data={{
          buttons: [
            !CONSTANTS.USER_ROLE && {
              id: 1,
              action: () => {
                setOpen(true);
              },
              name: "NEW",
              type: "normal",
            },
            {
              id: 2,
              action: () => {
                // console.log("button3");
              },
              icon: <DownloadOutlined />,
              type: "icon",
            },
          ],
        }}
        pagination={pagination}
        setPagination={setPagination}
      />
      <ModalFormCreator
        loading={API.isLoading}
        open={open}
        onCreate={onCreate}
        onCancel={() => {
          setOpen(false);
        }}
        name="NEW EMPLOYEE FORM"
        menu="EMPLOYEE_NEW_MODAL"
      />
      <Col span={24} className="container-body">
        {!API.isLoading ? (
          <Row
            justify="center"
            gutter={[36, 32]}
            style={{ marginBlock: "15px", justifyContent: "start" }}
          >
            {employeeDataArr.slice(
              (pagination?.current - 1) * pagination?.pageSize,
              pagination?.current * pagination?.pageSize + pagination?.pageSize
            )}
          </Row>
        ) : (
          <Spin className="flex-x center" size="large" />
        )}
        {!employeeDataArr.length && (
          <Empty description={"No Employee Available"} />
        )}
      </Col>
    </Row>
  );
};

export default Employee;
