import React from "react";
import {
  createBrowserRouter,
  Navigate,
  RouterProvider,
} from "react-router-dom";
import "./asset/scss/app.scss";
import Error from "./page/error";
import UnAuthorize from "./page/unAuthorize";
import LogIn from "./page/user/login";
import Registration from "./page/user/Registration";
import SidePage from "../src/component/common/SidePage";
import AppLayout from "./component/layout/AppLayout";
import Dashboard from "./page/app/dashboard";
import Employee from "./page/app/employees";
import { ROUTES, appRoot } from "./util/constant/CONSTANTS";
import Inventory from "./page/app/inventory";
import Maintenance from "./page/app/maintenance";
import LogBook from "./page/app/logBook";
import Expenses from "./page/app/expenses";
import ForgetPassword from "./page/user/Registration/forgetPass";
import EmployeeDetail from "./page/app/employees/employeeDetail";
import Transfer from "./page/app/inventory/Transfer";
import MoveHistory from "./page/app/inventory/Move-History";
import Equipment from "./page/app/inventory/Equipment";
import EquipmentDetailPage from "./page/app/inventory/Equipment/equipmentDetail";
import PlantOMChecklist from "./page/app/plantDetails/Plant-OMChecklist";
import PlantSetting from "./page/app/plantDetails/Plant-Setting";
import PlantDetailsPage from "./page/app/plantDetails/Plant-Details";
import LogBookABTMeter from "./page/app/logBook/LogBook-ABTMeter";
import LogBookCleanLog from "./page/app/logBook/LogBook-CleanLog";
import LogBookLog from "./page/app/logBook/LogBook-Log";
import PurchaseRequest from "./page/app/inventory/Purchase";
import ExpanseDetails from "./page/app/expenses/Expenses";
import VehicalExpanse from "./page/app/expenses/Vehical-Expense";
import KanbanBoardMaintanance from "./page/app/maintenance/kanban";
import MaintananceCalendar from "./page/app/maintenance/calendar";
import MaintenanceDetails from "./page/app/maintenance/details";
import ResetPassword from "./page/user/Registration/ResetPassword";
function App() {
  const router = createBrowserRouter([
    {
      path: "/",
      element: <SidePage />,
      errorElement: <Error />,
      children: [
        {
          index: true,
          element: <LogIn />,
        },
        {
          path: "registration",
          element: <Registration />,
        },
      ],
    },
    {
      path: ROUTES.FORGOT_PASSWORD,
      element: <ForgetPassword />,
      errorElement: <Error />,
    },
    {
      path: ROUTES.RESET_PASSWORD,
      element: <ResetPassword />,
      errorElement: <Error />,
    },
    {
      path: `${appRoot}`,
      element: <AppLayout />,
      children: [
        {
          index: true,
          element: <Navigate to={`${appRoot}${ROUTES.DASHBOARD}`} />,
        },
        {
          path: `${appRoot}${ROUTES.DASHBOARD}`,
          element: <Dashboard />,
        },
        {
          path: `${appRoot}${ROUTES.INVENTORY}`,
          element: (
            <Navigate
              to={`${appRoot}${ROUTES.INVENTORY}${ROUTES.INVENTORY_DETAILS}`}
            />
          ),
        },
        {
          path: `${appRoot}${ROUTES.INVENTORY}${ROUTES.INVENTORY_DETAILS}`,
          element: <Inventory />,
        },
        {
          path: `${appRoot}${ROUTES.INVENTORY}${ROUTES.INVENTORY_MOVEHISTORY}`,
          element: <MoveHistory />,
        },
        {
          path: `${appRoot}${ROUTES.INVENTORY}${ROUTES.INVENTORY_EQUIPMENT}`,
          element: <Equipment />,
        },
        {
          path: `${appRoot}${ROUTES.INVENTORY}${ROUTES.INVENTORY_EQUIPMENT_DETAILS}`,
          element: <EquipmentDetailPage />,
        },
        {
          path: `${appRoot}${ROUTES.INVENTORY}${ROUTES.INVENTORY_TRANSFER}`,
          element: <Transfer />,
        },
        {
          path: `${appRoot}${ROUTES.INVENTORY}${ROUTES.INVENTORY_PURCHASE}`,
          element: <PurchaseRequest />,
        },
        {
          path: `${appRoot}${ROUTES.EXPENSES}`,
          element: <Expenses />,
        },
        {
          path: `${appRoot}${ROUTES.EXPENSES}`,
          element: (
            <Navigate to={appRoot + ROUTES.EXPENSES + ROUTES.EXPENSES_DETAIL} />
          ),
        },
        {
          path: `${appRoot}${ROUTES.EXPENSES}${ROUTES.EXPENSES_DETAIL}`,
          element: <ExpanseDetails />,
        },
        {
          path: `${appRoot}${ROUTES.EXPENSES}${ROUTES.EXPENSES_VEHICAL}`,
          element: <VehicalExpanse />,
        },
        {
          path: `${appRoot}${ROUTES.MAINTENANCE}`,
          element: (
            <Navigate
              to={appRoot + ROUTES.MAINTENANCE + ROUTES.MAINTENANCE_KANBAN}
            />
          ),
        },
        {
          path: `${appRoot}${ROUTES.MAINTENANCE}${ROUTES.MAINTENANCE_KANBAN}`,
          element: <KanbanBoardMaintanance />,
        },
        {
          path: `${appRoot}${ROUTES.MAINTENANCE}${ROUTES.MAINTENANCE_CALENDAR}`,
          element: <MaintananceCalendar />,
        },
        {
          path: `${appRoot}${ROUTES.MAINTENANCE}${ROUTES.MAINTENANCE_DETAILS}`,
          element: <MaintenanceDetails />,
        },
        {
          path: `${appRoot}${ROUTES.EMPLOYEE}`,
          element: <Employee />,
        },
        {
          path: `${appRoot}${ROUTES.EMPLOYEE_DETAILS}`,
          element: <EmployeeDetail />,
        },
        {
          path: `${appRoot}${ROUTES.LOGBOOK}`,
          element: (
            <Navigate to={appRoot + ROUTES.LOGBOOK + ROUTES.LOGBOOK_LOG} />
          ),
        },
        {
          path: `${appRoot}${ROUTES.LOGBOOK}${ROUTES.LOGBOOK_LOG}`,
          element: <LogBookLog />,
        },
        {
          path: `${appRoot}${ROUTES.LOGBOOK}${ROUTES.LOGBOOK_ABTMETER}`,
          element: <LogBookABTMeter />,
        },
        {
          path: `${appRoot}${ROUTES.LOGBOOK}${ROUTES.LOGBOOK_CLEANLOG}`,
          element: <LogBookCleanLog />,
        },
        {
          path: `${appRoot}${ROUTES.PLANTDETAILS}`,
          element: (
            <Navigate
              to={appRoot + ROUTES.PLANTDETAILS + ROUTES.PLANTDETAILS_DETAILS}
            />
          ),
        },
        {
          path: `${appRoot}${ROUTES.PLANTDETAILS}${ROUTES.PLANTDETAILS_DETAILS}`,
          element: <PlantDetailsPage />,
        },
        {
          path: `${appRoot}${ROUTES.PLANTDETAILS}${ROUTES.PLANTDETAILS_OMChecklist}`,
          element: <PlantOMChecklist />,
        },
        {
          path: `${appRoot}${ROUTES.PLANTDETAILS}${ROUTES.PLANTDETAILS_SETTING}`,
          element: <PlantSetting />,
        },
      ],
    },
    {
      path: "/error",
      element: <Error />,
    },
    {
      path: "/unAuthorize",
      element: <UnAuthorize />,
    },
  ]);
  return <RouterProvider router={router} />;
}

export default App;
