import React, { useEffect, useState } from "react";
import { Col, Form, Row, notification } from "antd";
import PageHeader from "../../../../component/common/page-Header";
import { DownloadOutlined } from "@ant-design/icons";
import CONSTANTS from "../../../../util/constant/CONSTANTS";
import useHttp from "../../../../hooks/use-http";
import ModalFormCreator from "../../../../component/common/ModalFormCreator";
import moment from "moment";
import CustomTable from "../../../../component/common/Custom-Table";
import FormWithButton from "../../../../component/common/Form-with-Button";
import FormFields from "../../../../component/common/FormFields";
import { ActualDuration } from "../../../../util/functions";

const MaintenanceDetails = React.memo(() => {
  const [data, setData] = useState([]);
  const [Allfilter, setAllFilter] = useState(null);
  const [onMaintenanceRequest, setOnMaintenanceRequest] = useState(false);
  // const [changedFields, setChangedFields] = useState({});
  const changedFields = {};
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 20,
    total: 0,
  });
  const [refresh, setRefresh] = useState(true);
  const [openEditRow, setOpenEditRow] = useState(false);
  const [formInventoryData, setFormInventoryData] = useState({});
  const [isDisabled, setIsDisabled] = useState(true);

  const [resolutionModal, setResolutionModal] = useState(false);
  const [resolutionProducts, setResolutionProducts] = useState([]);
  const [options, setOptions] = useState([]);
  const [products, setProducts] = useState(true);
  const [optionData, setOptionData] = useState([]);
  const [afterResolveData, setAfterResolveData] = useState({});

  const [SubmitValue, setSubmitValue] = useState({});
  const [formResolution] = Form.useForm();

  const API = useHttp();
  const handleDelete = (key) => {
    setResolutionProducts((prev) => prev.filter((item) => item.key !== key));
    setOptions((prev) => {
      const item = optionData.find((el) => el.id === key);
      return [
        {
          ...item.option,
        },
        ...prev,
      ];
    });
  };
  useEffect(() => {
    API.sendRequest(CONSTANTS.API.inventory, (res) => {
      // console.log(res, "inventory");
      const OptionDataCreation = res?.data?.rows
        ?.map((el) => ({
          ...el,
          ...el.product,
          // key: el?.id,
          option: {
            ...el,
            ...el.product,
            // id: el.id,
            value: el.product.id + "-" + el?.sku,
            Label: (
              <div style={{ display: "flex", alignItems: "center" }}>
                <p
                  style={{
                    flex: "1",
                    width: "78%",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    whiteSpace: "nowrap",
                  }}
                >
                  ({el?.sku}) &nbsp;&nbsp; {el?.product?.name}
                </p>
                <p
                  style={{
                    flex: "0 0 20%",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    whiteSpace: "nowrap",
                  }}
                >
                  {el?.quantity} - {el?.product?.unit}
                </p>
              </div>
            ),
          },
        }))
        ?.filter((el) => el?.quantity);
      setOptionData(OptionDataCreation);
      setOptions(OptionDataCreation?.map((el) => ({ ...el?.option })));
      // CONSTANTS.FORM_FIELD.RESOLUTION_FORM_INNER[0].option =
      //   OptionDataCreation?.map((el) => ({ ...el?.option }));
    });
  }, [products]);
  // }, []);
  CONSTANTS.FORM_FIELD.RESOLUTION_FORM_INNER[0].option = options;
  const onMaintananceResolution = (value) => {
    formResolution
      .validateFields()
      .then((value) => {
        setSubmitValue(value);
        // console.log(resolutionProducts);
        if (openEditRow) {
          value.products = resolutionProducts.map((el) => ({
            productId: el.id,
            quantity: el.quantity,
            usageType: el.usageType,
          }));
          value.maintenanceRequestId = formInventoryData?.id;
          API.sendRequest(
            CONSTANTS.API.createmaintenanceResolution,
            () => {
              // afterResolveData.duration = afterResolveData.duration.format("HH:mm");
              if (changedFields?.status === "Completed") {
                setResolutionModal(true);
                return;
              }
              const updateMaintenance = {
                ...CONSTANTS.API.updatemaintenanceRequests,
              };
              updateMaintenance.endpoint = updateMaintenance.endpoint.replace(
                ":id",
                formInventoryData.id
              );
              API.sendRequest(
                updateMaintenance,
                (res) => {
                  setOpenEditRow(false);
                  setFormInventoryData({});
                  setSubmitValue({});
                  setProducts((prev) => !prev);
                  setResolutionProducts([]);
                  setResolutionModal(false);
                  setRefresh((prev) => !prev);
                },
                afterResolveData,
                "maintenance request updated"
              );
            },
            value,
            "Maintanance resolved successfully"
          );
          return;
        }
      })
      .catch((e) => {
        console.log(e);
        return;
      });
    // value.products = resolutionProducts.map((el) => ({
    //   productId: el.id,
    //   quantity: el.quantity,
    //   usageType: el.usageType,
    // }));
    // value.maintenanceRequestId = cardData?._card?.id;
    // console.log(value);
    // API.sendRequest(
    //   CONSTANTS.API.createmaintenanceResolution,
    //   () => {

    //     setProducts((prev) => !prev);
    //     setResolutionProducts([]);
    //   },
    //   value,
    //   "Maintanance resolved successfully"
    // );
    // setResolutionModal(false);
  };
  useEffect(() => {
    let QuaryParams = {
      limit: pagination.pageSize,
      page: pagination.current,
    };
    if (Allfilter?.sort) {
      QuaryParams = { ...QuaryParams, ...Allfilter?.sort };
    }
    if (Allfilter?.filter) {
      Allfilter?.filter?.map((el) => {
        QuaryParams = {
          ...QuaryParams,
          [`autogenerate-mul-array-$${el[0]}$`]: el[1],
        };
      });
    }
    API.sendRequest(
      CONSTANTS.API.getallMaintenanceRequests,
      (res) => {
        const sortedData = res?.data?.data?.sort((a, b) => {
          const priorityOrder = { Critical: 1, Major: 2, Minor: 3 };
          return priorityOrder[a?.prioriti] - priorityOrder[b?.prioriti];
        });

        setPagination((prevPagination) => ({
          ...prevPagination,
          total: res?.data?.Total,
        }));
        setData(
          sortedData?.map((el, i) => ({
            ...el,
            key: el.id,
            no: (pagination.current - 1) * pagination.pageSize + i + 1,
            actualduration:
              el?.status === "Completed"
                ? ActualDuration(
                    el?.date,
                    el?.maintenanceResolutions
                      ?.map((el) => el?.date)
                      .sort((a, b) => moment(a).isAfter(moment(b)))[0]
                  )
                : "-",
            creatorName: el?.creator?.name,
            assignedName: el?.assigned?.name,
            equipmentName: el?.equipment?.name,
            equipmentModal: el?.equipment?.model,
            equipmentSrNumber: el?.equipment?.srNumber,
            // date: moment(el?.date).format("YYYY-MM-DD HH:mm"),
            resolutionDate: el?.maintenanceResolutions
              ?.map((el) => el?.date)
              .sort((a, b) => moment(a).isAfter(moment(b)))[0],

            maintenanceResolutions: [
              ...new Set(el?.maintenanceResolutions?.map((el) => el?.action)),
            ]
              ?.map((ele) =>
                el?.maintenanceResolutions?.filter((el) => el?.action === ele)
              )
              ?.map((el) => ({
                action: el[0]?.action,
                createdAt: el[0]?.createdAt,
                date: el[0]?.date,
                products: el?.map((p) => ({
                  ...p,
                  category: p?.product?.productCategory?.category,
                  ...p?.product,
                  quantity: p?.quantity,
                  sku: p?.product?.inventoryInwards?.[0]?.sku,
                  srNumber: p?.product?.inventoryInwards?.[0]?.trackingId,
                })),
              })),
          }))
        );
      },
      QuaryParams
    );
  }, [refresh, pagination.current, pagination.pageSize, Allfilter]);

  useEffect(() => {
    API.sendRequest(CONSTANTS.API.employee, (res) => {
      const options = res?.data?.rows?.map((el) => ({
        id: el.id,
        value: el.id,
        Label: el.name,
      }));
      CONSTANTS.FORM_FIELD.MAINTENANCE_REQUEST_FORM[1].item[0].option = options;
      CONSTANTS.FORM_FIELD.MAINTENANCE_REQUEST_FORM[1].item[1].option = options;
      CONSTANTS.FORM_FIELD.MAINTENANCE_REQUEST_FORM_NEW[1].item[0].option =
        options;
      CONSTANTS.FORM_FIELD.MAINTENANCE_REQUEST_FORM_NEW[1].item[1].option =
        options;
    });
    API.sendRequest(CONSTANTS.API.AllEquipment, (res) => {
      const options = res?.data?.rows.map((el) => ({
        id: el.id,
        value: el.id,
        Label: el.name,
      }));
      CONSTANTS.FORM_FIELD.MAINTENANCE_REQUEST_FORM[2].item[0].option = options;
      CONSTANTS.FORM_FIELD.MAINTENANCE_REQUEST_FORM_NEW[2].item[0].option =
        options;
    });
  }, []);

  const onEditHandler = (value, clear) => {
    // value.duration = value.duration.format("HH:mm");
    setAfterResolveData({ ...value });
    if (changedFields?.status === "Completed") {
      setResolutionModal(true);
      clear();
      return;
    }
    const updateMaintenance = { ...CONSTANTS.API.updatemaintenanceRequests };
    updateMaintenance.endpoint = updateMaintenance.endpoint.replace(
      ":id",
      formInventoryData.id
    );
    API.sendRequest(
      updateMaintenance,
      (res) => {
        setOpenEditRow(false);
        setFormInventoryData({});
        setRefresh((prev) => !prev);
        clear();
      },
      value,
      "maintenance request updated"
    );
  };

  const CSVData = [
    CONSTANTS.TABLE.MAINTENANCE_REQUEST_TABLE.map((el) => el.title),
  ];

  data?.forEach((item) => {
    CSVData.push(
      CONSTANTS.TABLE.MAINTENANCE_REQUEST_TABLE.map((el) => item[el.dataIndex])
    );
  });
  console.log(formInventoryData, "data");
  return (
    <Row className="container-main">
      <ModalFormCreator
        loading={API.isLoading}
        open={onMaintenanceRequest}
        onCreate={(value) => {
          // value.duration = value.duration.format("HH:mm");
          API.sendRequest(
            CONSTANTS.API.createmaintenanceRequests,
            (res) => {
              setRefresh((prev) => !prev);
            },
            value,
            "maintenance requested successfully"
          );
          setOnMaintenanceRequest(false);
        }}
        onCancel={() => {
          setOnMaintenanceRequest(false);
        }}
        name="Maintenance request form ( ptw )"
        menu="MAINTENANCE_REQUEST_FORM_NEW"
      />
      <ModalFormCreator
        loading={API.isLoading}
        open={resolutionModal}
        onCreate={onMaintananceResolution}
        onCancel={() => {
          setResolutionModal(false);
          setResolutionProducts([]);
          setProducts((prev) => !prev);
          setResolutionProducts([]);
        }}
        name="MAINTENANCE RESOLUTION"
        menu="MAINTENANCE_RESOLUTION_FORM"
        inbuilt={false}
      >
        <FormFields
          formData={SubmitValue}
          menu={"MAINTENANCE_RESOLUTION_FORM"}
          form={formResolution}
        />
        <CustomTable
          dataSource={resolutionProducts}
          name="RESOLUTION_TABLE_INNER"
          // onChange={(selectedRowKeys, selectedRows) => {
          //   setProducts(selectedRows);
          // }}
        />
        <FormWithButton
          menu={"RESOLUTION_FORM_INNER"}
          name="Add"
          buttonwidth={2}
          onCreate={(element) => {
            // console.log(element);
            // console.log(optionData);
            const el = optionData.find(
              (e) => e.sku === element.productName.split("-")[1]
            );
            // console.log(el);
            if (element.quantity > el?.quantity || !element?.quantity) {
              notification.error({
                message: `enter ${
                  !element.quantity ? "valid " : "less"
                }  quantity `,
              });
              return;
            }
            // console.log(el);

            setOptions((prev) =>
              prev.filter(
                (ele) => ele.sku !== element.productName.split("-")[1]
              )
            );
            setResolutionProducts((prev) => {
              if (prev?.findIndex((elFind) => elFind?.sku === el?.sku) !== -1) {
                notification.error({ message: "Product is already added" });
                return prev;
              }
              return [
                ...prev,
                {
                  ...element,
                  key: el?.id,
                  ...el,
                  productName: el?.product?.name,
                  quantity: element?.quantity,
                  delete: {
                    id: el?.id,
                    key: el?.id,
                    onClick: handleDelete,
                  },
                },
              ];
            });
          }}
        />
      </ModalFormCreator>
      <PageHeader
        data={{
          buttons: [
            !CONSTANTS.USER_ROLE && {
              id: 1,
              action: () => {
                setOnMaintenanceRequest(true);
              },
              name: "New",
            },
            {
              id: 2,
              action: () => {
                console.log("download", CSVData);
              },
              icon: <DownloadOutlined />,
              data: CSVData,
              type: "linkicon",
              name: "Maintanance-" + moment().format("YYYY-MM-DD HH:mm"),
            },
          ],
          name: " Details",
        }}
        pagination={pagination}
        setPagination={setPagination}
        SearchBar={{
          endpointAPI: "/maintenancerequest?search=",
          dataResponseHandle: (res) => {
            return res?.data?.data.map((el) => ({
              ...el,
              key: el.id,
              actualduration:
                el?.status === "Completed"
                  ? ActualDuration(
                      el?.date,
                      el?.maintenanceResolutions
                        ?.map((el) => el?.date)
                        .sort((a, b) => moment(a).isAfter(moment(b)))[0]
                    )
                  : "-",
              creatorName: el?.creator?.name,
              assignedName: el?.assigned?.name,
              equipmentName: el?.equipment?.name,
              equipmentModal: el?.equipment?.model,
              equipmentSrNumber: el?.equipment?.srNumber,
              // date: moment(el?.date).format("YYYY-MM-DD HH:mm"),
              // equipmentlastUpdate: el?.maintenanceResolutions
              //   ?.map((el) => el?.date)
              //   .sort((a, b) => moment(a).isBefore(moment(b)))[0],
              maintenanceResolutions: [
                ...new Set(el?.maintenanceResolutions?.map((el) => el?.action)),
              ]
                ?.map((ele) =>
                  el?.maintenanceResolutions?.filter((el) => el?.action === ele)
                )
                ?.map((el) => ({
                  action: el[0]?.action,
                  products: el?.map((p) => ({
                    ...p,
                    category: p?.product?.productCategory?.category,
                    ...p?.product,
                    date: el[0]?.date,
                    quantity: p?.quantity,
                    sku: p?.product?.inventoryInwards?.[0]?.sku,
                    srNumber: p?.product?.inventoryInwards?.[0]?.trackingId,
                  })),
                })),
            }));
            //   );
            // });;
          },
          onValueIsSelected: (row) => {
            setFormInventoryData({
              ...row,
              // duration: dayjs(row.duration, "HH:mm"),
            });
            setOpenEditRow(true);
          },
          extraLabel: "title",
          onBaseSearch: ["sku"],
          debounceTime: 500,
        }}
      />
      <ModalFormCreator
        loading={API.isLoading}
        open={openEditRow}
        onCreate={onEditHandler}
        onCancel={() => {
          setOpenEditRow(false);
          setIsDisabled(true);
          setFormInventoryData({});
        }}
        disabled={isDisabled}
        edit
        onEdit={() => {
          setIsDisabled(false);
        }}
        Delete
        onDelete={() => {
          const DeleteMaintenanceRequest = {
            ...CONSTANTS.API.deletemaintenanceRequests,
          };
          DeleteMaintenanceRequest.endpoint =
            DeleteMaintenanceRequest.endpoint.replace(
              ":id",
              formInventoryData.id
            );
          API.sendRequest(
            DeleteMaintenanceRequest,
            (res) => {
              setRefresh((prev) => !prev);
              setOpenEditRow(false);
            },
            "Deleted Successfully"
          );
        }}
        name={`edit Maintenance Request (${formInventoryData?.sku || ""})`}
        DeleteTitle={`Maintenance Request (${formInventoryData?.sku || ""})`}
        menu="MAINTENANCE_REQUEST_FORM"
        formData={formInventoryData}
        changedFields={changedFields}
      >
        {formInventoryData?.maintenanceResolutions?.map((el, i) => (
          <div className="mb15" key={el.products.id}>
            <div
              className="form-modal-head an-20 mb5"
              style={{ display: "flex", justifyContent: "space-between" }}
            >
              <p className="form-modal-head an-20 mb5">{`Resolution - ${
                i + 1
              }`}</p>
              <p className="form-modal-head an-15 mb5">
                {/* {moment(el?.createdAt).format("YYYY-MM-DD HH:mm")} */}
                {moment(el?.date).format("YYYY-MM-DD HH:mm")}
                {/* {dayjs(moment(el?.createdAt).format("YYYY-MM-DD HH:mm")).from(
                  dayjs(formInventoryData?.date)
                )} */}
              </p>
            </div>
            <p className="an-15 mb5">{el?.action}</p>
            {el?.products?.[0]?.product && (
              <CustomTable
                name="MAINTENANCE_RESOLUTION_TABLE"
                dataSource={el.products}
                scrollAutoOff={true}
                Other={{ rowSelection: null }}
              />
            )}
          </div>
        ))}
      </ModalFormCreator>
      <Col
        span={24}
        style={{
          marginBlock: "15px",
        }}
        className="container-body"
      >
        <CustomTable
          // dataSource={data.slice(
          //   (pagination?.current - 1) * pagination?.pageSize,
          //   (pagination?.current - 1) * pagination?.pageSize +
          //     pagination?.pageSize
          // )}
          setChanges={(v) => {
            setAllFilter(v);
            setPagination((prev) => ({
              ...prev,
              current: 1,
            }));
          }}
          dataSource={data}
          name="MAINTENANCE_REQUEST_TABLE"
          isLoading={API.isLoading}
          extraclass="pointer"
          Other={{
            onRow: (row) => ({
              onClick: () => {
                setFormInventoryData({
                  ...row,
                  // duration: dayjs(row.duration, "HH:mm"),
                });
                setOpenEditRow(true);
              },
            }),
          }}
          DeleteSelectedRow
          APIendpoint="deletemaintenanceRequests"
          onConfirm={() => {
            setRefresh((prev) => !prev);
          }}
        />
      </Col>
    </Row>
  );
});

export default MaintenanceDetails;
