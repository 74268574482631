import { AiOutlineDashboard } from "react-icons/ai";
import { BsBoxSeam, BsKanban, BsTruck } from "react-icons/bs";
import { BiWallet, BiTransfer, BiDetail } from "react-icons/bi";
import { HiOutlineWrench } from "react-icons/hi2";
import { MdPeopleOutline } from "react-icons/md";
import { GoBook } from "react-icons/go";
import { AiOutlineSetting, AiOutlineHistory } from "react-icons/ai";
import { SlSettings, SlWrench, SlWallet } from "react-icons/sl";
import { CgFileDocument } from "react-icons/cg";
import { TbFileStack } from "react-icons/tb";
import { VscChecklist, VscRequestChanges } from "react-icons/vsc";
import { RxActivityLog, RxCalendar } from "react-icons/rx";

const data = [
  {
    id: "dashboard",
    icon: <AiOutlineDashboard />,
    label: "Dashboard",
  },
  {
    id: "inventory",
    icon: <BsBoxSeam />,
    label: "Inventory",
    subMenu: [
      {
        id: "details",
        icon: <BsBoxSeam />,
        label: "Stock",
      },
      {
        id: "movehistory",
        icon: <AiOutlineHistory />,
        label: "Move History",
      },
      {
        id: "equipment",
        icon: <SlWrench />,
        label: "Equipment",
      },
      {
        id: "transfer",
        icon: <BiTransfer />,
        label: "Transfer",
      },
      {
        id: "purchase",
        icon: <VscRequestChanges />,
        label: "Purchase",
      },
    ],
  },
  {
    id: "expenses",
    icon: <BiWallet />,
    label: "Expenses",
    subMenu: [
      {
        id: "detail",
        icon: <SlWallet />,
        label: "Details",
      },
      {
        id: "Vehical",
        icon: <BsTruck />,
        label: "Vehicle",
      },
    ],
  },
  {
    id: "maintenance",
    icon: <HiOutlineWrench />,
    label: "Maintenance",
    subMenu: [
      {
        id: "details",
        icon: <BiDetail />,
        label: "Details",
      },
      {
        id: "kanban",
        icon: <BsKanban />,
        label: "Kanban",
      },
      {
        id: "calendar",
        icon: <RxCalendar />,
        label: "Calendar",
      },
    ],
  },
  {
    id: "employee",
    icon: <MdPeopleOutline />,
    label: "Employee",
  },
  {
    id: "logBook",
    icon: <GoBook />,
    label: "Log Book",
    subMenu: [
      {
        id: "log",
        icon: <RxActivityLog />,
        label: "Log",
      },
      {
        id: "ABTMeter",
        icon: <CgFileDocument />,
        label: "ABT Meter",
      },
      {
        id: "cleaningLog",
        icon: <TbFileStack />,
        label: "Cleaning Log",
      },
    ],
  },
  {
    id: "plantDetails",
    icon: <AiOutlineSetting />,
    label: "Plant Details",
    subMenu: [
      {
        id: "details",
        icon: <SlSettings />,
        label: "Details",
      },
      {
        id: "OMChecklist",
        icon: <VscChecklist />,
        label: "O&M Checklist",
      },
      {
        id: "setting",
        icon: <AiOutlineSetting />,
        label: "Setting",
      },
    ],
  },
];
export default data;
