import React, { useEffect } from "react";
import {
  AutoComplete,
  Button,
  Checkbox,
  Col,
  DatePicker,
  Form,
  Input,
  InputNumber,
  Radio,
  Row,
  Select,
  Space,
  TimePicker,
  Upload,
  message,
} from "antd";
import Label from "./Label";
import { UploadOutlined, InboxOutlined } from "@ant-design/icons";
import CONSTANTS from "../../util/constant/CONSTANTS";
import dayjs from "dayjs";
import moment from "moment";
const cleanObject = (obj) => {
  const cleanedObj = {};
  for (const key in obj) {
    const value = obj[key];
    if (value !== null && value !== undefined && value !== "") {
      cleanedObj[key] = value;
    }
  }
  return cleanedObj;
};

const FormFields = React.memo(
  ({
    changedFields = {},
    formData = {},
    menu,
    formFields = [],
    form,
    disabled = false,
    normal = false,
    threeField = false,
  }) => {
    formData = cleanObject(formData);
    const AllFieldsData =
      formFields && Array.isArray(formFields) && formFields.length > 0
        ? formFields
        : CONSTANTS.FORM_FIELD[menu];
    const getInputFormate = (data) => {
      const normFile = (e) => {
        console.log("Upload event:", e);
        if (Array.isArray(e) && e.file.size / 1024 / 1024 < 3) {
          return e;
        }
        return e?.fileList.filter((el) => el.size / 1024 / 1024 < 3);
      };
      switch (data.type) {
        case "date":
          return (
            <Form.Item
              name={data.name}
              id={data.id}
              className="form"
              initialValue={
                data?.defaultValue
                  ? dayjs(data?.defaultValue)
                  : dayjs(new Date())
              }
              rules={[
                {
                  type: "object",
                  required: data?.required,
                  message: "Please select date!",
                },
                data.rule && data.rule,
              ]}
            >
              <DatePicker
                showTime={data?.showTime}
                disabled={data?.disabled && formData[data?.name]}
                disabledDate={data?.disabledDate}
                placeholder={data.placeholder ? data.placeholder : ""}
                style={{
                  width: "100%",
                }}
                format={data?.showTime ? "YYYY-MM-DD HH:mm" : "YYYY-MM-DD"}
              />
            </Form.Item>
          );
        case "time":
          return (
            <Form.Item
              name={data.name}
              id={data.id}
              className="form"
              initialValue={
                data?.defaultValue
                  ? dayjs(moment(data?.defaultValue))
                  : dayjs(moment())
              }
              rules={[
                {
                  type: "object",
                  required: data?.required,
                  message: "Please select date!",
                },
                data.rule && data.rule,
              ]}
            >
              <TimePicker
                showTime={data?.showTime}
                disabled={data?.disabled && formData[data?.name]}
                minuteStep={data?.minuteStep ? data?.minuteStep : 1}
                hourStep={data?.hourStep ? data?.hourStep : 1}
                // placeholder={data.placeholder ? data.placeholder : ""}
                style={{
                  width: "100%",
                }}
                format={data?.format ? data?.format : "HH:mm:ss"}
              />
            </Form.Item>
          );
        case "autocomplete":
          return (
            <Form.Item
              name={data?.name}
              id={data?.id}
              className="form"
              rules={[
                {
                  required: data?.required,
                  message: "Please Enter Valid " + data.Label,
                },
                {
                  // pattern: data?.pattern || /^[a-zA-Z0-9\s]+$/,
                  pattern: data?.pattern || /^[a-zA-Z0-9\s.,/()%\-]+$/,
                  message:
                    "Only alphanumeric characters and spaces are allowed",
                },
              ]}
            >
              <AutoComplete
                // disabled={data?.disabled && formData[data?.name]}
                options={data?.option}
                filterOption={(inputValue, option) =>
                  option?.value
                    ?.toUpperCase()
                    ?.indexOf(inputValue?.toUpperCase()) !== -1
                }
                onSelect={data?.onSelect}
              />
            </Form.Item>
          );
        case "select":
          return (
            <>
              <Form.Item
                name={data.name}
                id={data.id}
                className="form"
                initialValue={data?.defaultValue}
                hasFeedback
                rules={[
                  {
                    required: data?.required,
                    message: "Please select Valid " + data.Label,
                  },
                ]}
              >
                <Select
                  onSelect={data?.onSelect}
                  // disabled={data?.disabled && formData[data?.name]}
                  showSearch
                  placeholder={data?.placeholder ? data?.placeholder : ""}
                  filterOption={(inputValue, option) => {
                    const value = option?.children?.props?.children[0]?.props
                      ?.children
                      ? `${option?.children?.props?.children[0]?.props?.children}`
                      : `${option?.children}`;

                    return value
                      ?.toLowerCase()
                      ?.includes(inputValue?.toLowerCase());
                  }}
                  allowClear // Allow clearing the selected value
                >
                  {data.option &&
                    data.option.length > 0 &&
                    data.option.map((item) => (
                      <Select.Option key={`role_${item.id}`} value={item.value}>
                        {item.Label ? item.Label : item.value}
                      </Select.Option>
                    ))}
                </Select>
              </Form.Item>
            </>
          );

        case "dragupload":
          return (
            <Form.Item className="form">
              <Form.Item
                name={data.id}
                className="form"
                valuePropName="fileList"
                getValueFromEvent={normFile}
                noStyle
                rules={[
                  {
                    required: data?.required,
                    message: "Please Enter valid " + data.Label,
                  },
                ]}
              >
                <Upload.Dragger
                  name={data.name}
                  id={data.id}
                  customRequest={async ({ file, onSuccess }) => {
                    setTimeout(() => {
                      onSuccess("ok");
                    }, 0);
                  }}
                  accept=".jpg,.png,.jpeg"
                  beforeUpload={(file) => {
                    const isLt3M = file.size / 1024 / 1024 < 3;
                    if (!isLt3M) {
                      message.error("Image must smaller than 3MB!");
                    }
                    return false;
                  }}
                >
                  <p className="ant-upload-drag-icon">
                    <InboxOutlined />
                  </p>
                  <p className="ant-upload-text">
                    Click or drag file to this area to upload
                  </p>
                  <p className="ant-upload-hint">
                    Support for a single or bulk upload.
                  </p>
                </Upload.Dragger>
              </Form.Item>
            </Form.Item>
          );
        case "file":
          return (
            <Form.Item
              name={data.name}
              className="form"
              id={data.id}
              valuePropName="fileList"
              getValueFromEvent={normFile}
              rules={[
                {
                  required: data?.required,
                  message: "Please Enter valid " + data.Label,
                },
              ]}
            >
              <Upload
                name="logo"
                disabled={data?.disabled && formData[data?.name]}
                listType="picture"
                maxCount={1}
                customRequest={async ({ file, onSuccess }) => {
                  setTimeout(() => {
                    onSuccess("ok");
                  }, 0);
                }}
                accept=".jpg,.png,.jpeg"
                beforeUpload={(file) => {
                  // const isJPG = file.type === "image/jpeg";
                  // if (!isJPG) {
                  //   message.error("You can only upload JPG file!");
                  // }
                  const isLt3M = file.size / 1024 / 1024 < 3;
                  if (!isLt3M) {
                    message.error("Image must smaller than 3MB!");
                  }
                  return isLt3M;
                  // return isJPG && isLt2M;
                }}
              >
                <Button icon={<UploadOutlined />}>Click to upload</Button>
              </Upload>
            </Form.Item>
          );
        case "number":
          const NumberRules = [
            {
              required: data?.required,
              message: "Please Enter valid " + data.Label,
            },
            {
              type: data.type,
              message: "Please Enter valid Number",
            },
          ];
          data?.rule && NumberRules.push(data?.rule);
          return (
            <Form.Item name={data.name} className="form" rules={NumberRules}>
              <InputNumber
                disabled={data?.disabled && formData[data?.name]}
                placeholder={data.placeholder ? data.placeholder : ""}
                controls={false}
                style={{
                  width: "100%",
                }}
                min={0}
                // step="0.01"
                value={formData && formData[data.id]}
              />
            </Form.Item>
          );
        case "mobile":
          return (
            <Form.Item
              name={data.name}
              className="form"
              rules={[
                // {
                //   required: data?.required,
                //   message: "Please Enter valid mobile number",
                // },
                {
                  type: data.type,
                  message: "Please Enter valid Number",
                },
                () => ({
                  validator(_, value) {
                    console.log(value);
                    if (value && value && /^\d{10}$/.exec(value)) {
                      return Promise.resolve();
                    }
                    return Promise.reject(
                      new Error("Please Enter valid mobile number")
                    );
                  },
                }),
              ]}
            >
              <InputNumber
                disabled={data?.disabled && formData[data?.name]}
                placeholder={data.placeholder ? data.placeholder : ""}
                controls={false}
                style={{
                  width: "100%",
                }}
                value={formData && formData[data.id]}
              />
            </Form.Item>
          );
        case "checkbox":
          return (
            <Form.Item
              name={data.name}
              className="form"
              id={data.id}
              required={data?.required}
              valuePropName="checked"
              wrapperCol={{
                offset: 8,
                span: 16,
              }}
            >
              <Checkbox disabled={data?.disabled && formData[data?.name]}>
                {data.Label}
              </Checkbox>
            </Form.Item>
          );
        case "textarea":
          return (
            <Form.Item
              name={data.name}
              className="form"
              rules={[
                {
                  required: data?.required,
                  message: "Enter Valid " + data.Label,
                },
                data.rule && data.rule,
              ]}
            >
              <Input.TextArea
                disabled={data?.disabled && formData[data?.name]}
                placeholder={data.placeholder ? data.placeholder : ""}
                initialvalues={
                  formData && data.type !== "file" ? formData[data.name] : ""
                }
              />
            </Form.Item>
          );
        case "password":
          const rulePass = [
            {
              required: data?.required,
              message: "Enter Valid " + data.Label,
            },
            () => ({
              validator(_, value) {
                if (value && /^.{8,}$/.exec(value)) {
                  return Promise.resolve();
                }
                return Promise.reject(
                  new Error("Password must be minimum 8 character ")
                );
              },
            }),
          ];
          data.rule && rulePass.push(data.rule);
          return (
            <Form.Item
              name={data.name}
              className="form"
              rules={[
                {
                  required: data?.required,
                  message: "Enter Valid " + data.Label,
                },
                ...rulePass,
              ]}
            >
              <Input.Password />
            </Form.Item>
          );
        case "radio":
          return (
            <Form.Item
              name={data.name}
              id={data.id}
              rules={[
                {
                  required: data?.required,
                  message: "Please select an option!",
                },
              ]}
            >
              <Radio.Group disabled={data?.disabled && formData[data?.name]}>
                <Space direction="vertical">
                  {data.option.map((el) => (
                    <Radio
                      value={el.value}
                      id={el.id}
                      key={el.id}
                      className="form-modal-title-items"
                    >
                      {el.Label}
                    </Radio>
                  ))}
                </Space>
              </Radio.Group>
            </Form.Item>
          );
        case "text":
          const ruleTest = [
            {
              required: data?.required,
              message: "Please Enter Valid " + data.Label,
            },
            {
              // pattern: data?.pattern || /^[a-zA-Z0-9\s]+$/,
              pattern: data?.pattern || /^[a-zA-Z0-9\s.,/()%\-]+$/,
              message: "Only alphanumeric characters and spaces are allowed",
            },
            ({ getFieldValue }) => ({
              validator(_, value) {
                if (data?.required && (!value || value.trim() === "")) {
                  return Promise.reject(new Error("This field is required"));
                }
                return Promise.resolve();
              },
            }),
          ];
          data?.rule && ruleTest.push(data.rule);

          return (
            <Form.Item
              name={data.name}
              className="form"
              id={data.id}
              required={data?.required}
              rules={ruleTest}
            >
              <Input
                required={data?.required}
                disabled={data?.disabled && formData[data?.name]}
                placeholder={data.placeholder ? data.placeholder : ""}
              />
            </Form.Item>
          );

        default:
          const rule = [
            {
              required: data?.required,
              message: "Please Enter Valid " + data.Label,
            },
            // data?.onlyText && {
            //   pattern: /^[a-zA-Z0-9\s]+$/,
            //   message: "Only alphanumeric characters and spaces are allowed",
            // },
            data?.rule && data.rule,
          ];
          return (
            <Form.Item
              name={data.name}
              className="form"
              id={data.id}
              required={data?.required}
              rules={rule}
              // initialValue={}
            >
              <Input
                disabled={data?.disabled && formData[data?.name]}
                placeholder={data.placeholder ? data.placeholder : ""}
              />
            </Form.Item>
          );
      }
    };

    useEffect(() => {
      form.resetFields();

      if (Object.keys(formData).length) {
        const Fields = [];
        AllFieldsData.forEach((el) => {
          if (el.item) {
            Fields.push(el.item[0]);
            Fields.push(el.item[1]);
          } else {
            Fields.push(el);
          }
        });

        Fields.filter((el) => el?.type === "number").forEach((el) => {
          if (formData[el.name]) {
            formData[el.name] = parseFloat(formData[el.name], 10);
          }
        });
        Fields.filter((el) => el?.type === "date").forEach((el) => {
          // console.log(
          //   formData[el.name],
          //   "qwertyui",
          //   moment("2023-06-16T07:09:00.000Z", "YYYY-MM-DDTHH:mm:ss.000Z")
          // );
          formData[el.name] = dayjs(moment.utc(formData[el.name]));
          // .subtract(5, "hours")
          // .subtract(30, "minutes");
          // console.log(formData[el.name], "cou");
        });
        Fields.filter((el) => el?.type === "time").forEach((el) => {
          formData[el.name] = dayjs(formData[el.name], "HH:mm:ss");
        });
        Fields.filter(
          (el) => el?.type === "file" || el?.type === "dragupload"
        ).forEach((el) => {
          delete formData[el.name];
        });
        form.setFieldsValue(formData);
      }
    }, [form, formData, AllFieldsData]);
    if (threeField) {
      return (
        <Form
          form={form}
          disabled={disabled}
          name={"form_in_modal" + menu}
          scrollToFirstError
          onFieldsChange={(value) => {
            changedFields[value[0].name] = value[0].value;
          }}
        >
          {AllFieldsData.map((data) => {
            if (!data.item) {
              return (
                <Row align={"middle"} key={data.id}>
                  <Label required={data.required}>{data.Label}</Label>
                  <Col span={4}></Col>
                  <Col span={24}>{getInputFormate(data)}</Col>
                </Row>
              );
            } else {
              return (
                <Row key={data.id}>
                  <Col span={8}>
                    <Row align={"middle"}>
                      <Label required={data.item[0].required}>
                        {data.item[0].Label}
                      </Label>
                      <Col span={4}></Col>
                      <Col span={20}>{getInputFormate(data.item[0])}</Col>
                      <Col span={4}></Col>
                    </Row>
                  </Col>
                  <Col span={8}>
                    {data.item[1] && (
                      <Row align={"middle"}>
                        <Label required={data.item[1].required}>
                          {data.item[1].Label}
                        </Label>
                        <Col span={4}></Col>
                        <Col span={20}>{getInputFormate(data.item[1])}</Col>
                      </Row>
                    )}
                  </Col>
                  <Col span={8}>
                    {data.item[2] && (
                      <Row align={"middle"}>
                        <Label required={data.item[2].required}>
                          {data.item[2].Label}
                        </Label>
                        <Col span={4}></Col>
                        <Col span={20}>{getInputFormate(data.item[2])}</Col>
                      </Row>
                    )}
                  </Col>
                </Row>
              );
            }
          })}
        </Form>
      );
    }
    if (normal) {
      return (
        <Form
          form={form}
          disabled={disabled}
          name={"form_in_modal" + menu}
          scrollToFirstError
          style={{ width: "100%", paddingInline: "20px" }}
          onFieldsChange={(value) => {
            changedFields[value[0].name] = value[0].value;
          }}
        >
          <Row justify="space-between">
            {AllFieldsData.map((data) => {
              if (!data.item) {
                return (
                  <Col key={data.id} span={data.width ? data.width : 8}>
                    <Label required={data.required}>{data.Label}</Label>
                    <Row align={"middle"} key={data.id}>
                      <Col span={24}>{getInputFormate(data)}</Col>
                    </Row>
                  </Col>
                );
              } else {
                return null;
              }
            })}
          </Row>
        </Form>
      );
    }

    return (
      <Form
        form={form}
        disabled={disabled}
        name={"form_in_modal" + menu}
        scrollToFirstError
        onFieldsChange={(value) => {
          if (value[0]?.name) {
            changedFields[value[0]?.name] = value[0]?.value;
          }
        }}
      >
        {AllFieldsData.map((data) => {
          if (!data.item) {
            return (
              <Row align={"middle"} key={data.id}>
                <Col span={4}>
                  <Label required={data.required}>{data.Label}</Label>
                </Col>
                <Col span={20}>{getInputFormate(data)}</Col>
              </Row>
            );
          } else {
            return (
              <Row key={data.id}>
                <Col span={12}>
                  <Row align={"middle"}>
                    <Col span={8}>
                      <Label required={data.item[0].required}>
                        {data.item[0].Label}
                      </Label>
                    </Col>
                    <Col span={12}>{getInputFormate(data.item[0])}</Col>
                    <Col span={4}></Col>
                  </Row>
                </Col>
                <Col span={12}>
                  {data.item[1] && (
                    <Row align={"middle"}>
                      <Col span={4}></Col>
                      <Col span={8}>
                        <Label required={data.item[1].required}>
                          {data.item[1].Label}
                        </Label>
                      </Col>
                      <Col span={12}>{getInputFormate(data.item[1])}</Col>
                    </Row>
                  )}
                </Col>
              </Row>
            );
          }
        })}
      </Form>
    );
  }
);

export default FormFields;
