import React from "react";
import CientImage from "../../asset/image/client.png";
import { Col } from "antd";

const ClientOverviewCard = ({ image, name, value }) => {
  return (
    <Col className="gutter-row cursor-pointer" span={24} md={12} lg={12}>
      <div className="gutter-box">
        <div
          className="pt10 pb10 "
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            marginLeft: "-25px",
          }}
        >
          <div style={{ display: "flex", alignItems: "center" }}>
            <div style={{ marginLeft: "15px", marginRight: "15px" }}>
              <img
                src={image || CientImage}
                style={{
                  borderRadius: "100%",
                  width: "50px",
                  height: "50px",
                }}
                alt="trueIcon"
              />
            </div>
            <div className="text-title">{name}</div>
          </div>
          <div
            className="text-subtitle mr15 an-25"
            // style={{ justifySelf: "flex-end", display: "flex" }}
          >
            {value?.toLocaleString()} MW
          </div>
        </div>
      </div>
    </Col>
  );
};

export default ClientOverviewCard;
