import React, { useEffect, useRef, useState } from "react";
import { Col, Row } from "antd";
import PageHeader from "../../../../component/common/page-Header";
import { DownloadOutlined } from "@ant-design/icons";
import CustomTable from "../../../../component/common/Custom-Table";
import CONSTANTS from "../../../../util/constant/CONSTANTS";
import useHttp from "../../../../hooks/use-http";
import ModalFormCreator from "../../../../component/common/ModalFormCreator";
import moment from "moment";
import FullGatePass from "../../../BillForm/FullGatePass";
import jsPDF from "jspdf";

const VehicalExpanse = () => {
  const [data, setData] = useState([]);
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 20,
    total: 0,
  });
  const [onTransfer, setOnTransfer] = useState(false);
  const [openEditRow, setOpenEditRow] = useState(false);
  const [formIventoryData, setFormIventoryData] = useState({});
  const [isDisabled, setIsDisabled] = useState(true);
  const [refresh, setRefresh] = useState(true);
  const [downloadData, setDownloadData] = useState({});

  const reportTemplateRef = useRef(null);
  const API = useHttp();
  const onRequestCreate = (value, clear) => {
    // console.log(value, "data");
    value.date = moment(value.date.$d).format("YYYY-MM-DD");
    const payload = new FormData();
    if (value.receipt) {
      payload.append("receipt", value.receipt[0].originFileObj);
      delete value.receipt;
    } else {
      delete value.receipt;
    }
    Object.keys(value).forEach((el) => {
      payload.append(el, value[el]);
    });
    setOnTransfer(false);
    API.sendRequest(
      CONSTANTS.API.vehicleexpanseCreate,
      () => {
        // console.log(res, "purchase");
        setRefresh((prev) => !prev);
        clear();
      },
      payload,
      "vehical exapanse created"
    );
  };

  const handleGeneratePdf = () => {
    console.log("object");
    const doc = new jsPDF({
      orientation: "1",
      format: [2480, 3508],
      unit: "px",
    });
    // Adding the fonts.
    doc.setFont("Inter-Regular", "normal");
    doc.html(reportTemplateRef.current, {
      async callback(doc) {
        await doc.save("document");
      },
    });
  };
  useEffect(() => {
    const vehicleExpansePage = { ...CONSTANTS.API.vehicleexpanseAll };
    vehicleExpansePage.endpoint = `${vehicleExpansePage.endpoint}?limit=${pagination.pageSize}&page=${pagination.current}`;

    API.sendRequest(vehicleExpansePage, (res) => {
      setPagination((prevPagination) => ({
        ...prevPagination,
        total: res?.data?.count,
      }));
      setData(
        res?.data?.rows?.map((el, i) => ({
          ...el.employee,
          ...el,
          key: el.id,
          no: (pagination.current - 1) * pagination.pageSize + i + 1,
          dowload: {
            onClick: () => {
              setDownloadData({ ...el.employee, ...el });
              handleGeneratePdf();
            },
          },
          status: {
            id: el.id,
            value: el.status,
            onChange: (res) => {
              // console.log(res, "click happend", el.id);
              const updatePurchaseAPI = {
                ...CONSTANTS.API.vehicleexpanseUpdate,
              };
              updatePurchaseAPI.endpoint = updatePurchaseAPI.endpoint.replace(
                ":id",
                el.id
              );
              API.sendRequest(
                updatePurchaseAPI,
                () => {
                  // console.log(res);
                },
                { status: res },
                "status updated"
              );
            },
          },
        }))
      );
    });
  }, [refresh, pagination.current, pagination.pageSize]);

  useEffect(() => {
    API.sendRequest(CONSTANTS.API.employee, (res) => {
      CONSTANTS.FORM_FIELD.VEHICAL_EXPANSE_FORM[3].option =
        res?.data?.rows?.map((el) => ({
          id: el.id,
          value: el.id,
          Label: el.name,
        }));
    });
  }, []);
  /***
   *
   * Row click
   */
  const onEditHandler = (value, clear) => {
    // console.log(value, formIventoryData);
    value.date = moment(value.date.$d).format("YYYY-MM-DD");
    const payload = new FormData();
    if (value.receipt) {
      payload.append("receipt", value.receipt[0].originFileObj);
      delete value.receipt;
    } else {
      delete value.receipt;
    }
    Object.keys(value).forEach((el) => {
      payload.append(el, value[el]);
    });
    const updateInventoryAPI = { ...CONSTANTS.API.vehicleexpanseUpdate };
    updateInventoryAPI.endpoint = updateInventoryAPI.endpoint.replace(
      ":id",
      formIventoryData.id
    );
    // console.log(updateInventoryAPI);
    API.sendRequest(
      updateInventoryAPI,
      () => {
        // console.log(res);
        clear();
        setOpenEditRow(false);
        setRefresh((prev) => !prev);
      },
      payload,
      "vehical expanse Updated"
    );
  };

  const CSVData = [];
  CSVData[0] = CONSTANTS.TABLE.VEHICAL_EXPANSE_TABLE.map((el) => el.title);
  // data.map((item, index) => {
  //   CSVData[index + 1] = CONSTANTS.TABLE.VEHICAL_EXPANSE_TABLE.map(
  //     (el) => item[el.dataIndex]
  //   );
  //   return 0;
  // });
  data.forEach((item, index) => {
    CSVData[index + 1] = CONSTANTS.TABLE.VEHICAL_EXPANSE_TABLE.map((el) => {
      if (el.dataIndex === "status") {
        return item[el.dataIndex]?.value;
      }
      if (el.dataIndex === "dowload") {
        return null;
      }
      return item[el.dataIndex];
    });
  });
  return (
    <>
      <Row className="container-main">
        <ModalFormCreator
          loading={API.isLoading}
          open={onTransfer}
          onCreate={onRequestCreate}
          onCancel={() => {
            setOnTransfer(false);
          }}
          name="add vehicle expanse"
          menu="VEHICAL_EXPANSE_FORM"
        />
        <PageHeader
          data={{
            buttons: [
              !CONSTANTS.USER_ROLE && {
                id: 1,
                action: () => {
                  // console.log("button1");
                  setOnTransfer(true);
                },
                name: "New",
              },
              {
                id: 2,
                action: () => {
                  // console.log("download", CSVData);
                },
                icon: <DownloadOutlined />,
                data: CSVData,
                type: "linkicon",
                name: "Vehical-Expanses-" + moment().format("YYYY-MM-DD HH:mm"),
              },
            ],
            name: " vehicle expense",
          }}
          search={{
            pagination: pagination,
            setPagination: setPagination,
          }}
          SearchBar={{
            endpointAPI: "/vehicleexpanse?search=",
            dataResponseHandle: (res) => {
              return res?.data?.rows?.map((el) => ({
                ...el.employee,
                ...el,
                key: el.id,
              }));
            },
            onValueIsSelected: (row) => {
              setFormIventoryData({ ...row });
              setOpenEditRow(true);
            },
            extraLabel: "vehicleNumber",
            onBaseSearch: ["sku"],
            debounceTime: 500,
          }}
        />
        <Col
          span={24}
          style={{
            marginBlock: "15px",
          }}
          className="container-body"
        >
          <ModalFormCreator
            loading={API.isLoading}
            open={openEditRow}
            onCreate={onEditHandler}
            onCancel={() => {
              setOpenEditRow(false);
              setIsDisabled(true);
              // setFormIventoryData({});
            }}
            disabled={isDisabled}
            edit
            onEdit={() => {
              // console.log("edit click");
              setIsDisabled(false);
            }}
            Delete
            onDelete={() => {
              // console.log("Delete Click", formIventoryData);
              const DeleteInventoryAPI = {
                ...CONSTANTS.API.vehicleexpanseDelete,
              };
              DeleteInventoryAPI.endpoint = DeleteInventoryAPI.endpoint.replace(
                ":id",
                formIventoryData.id
              );
              API.sendRequest(
                DeleteInventoryAPI,
                () => {
                  // console.log(res);
                  setRefresh((prev) => !prev);
                  setOpenEditRow(false);
                },
                "Deleted Successfully"
              );
            }}
            name="edit vehicle expanse"
            menu="VEHICAL_EXPANSE_FORM"
            formData={formIventoryData}
          />
          <CustomTable
            dataSource={data}
            name="VEHICAL_EXPANSE_TABLE"
            extraclass="pointer"
            isLoading={API.isLoading}
            Other={{
              onRow: (row) => ({
                onClick: () => {
                  setFormIventoryData({ ...row });
                  setOpenEditRow(true);
                },
              }),
            }}
            DeleteSelectedRow
            APIendpoint="vehicleexpanseDelete"
            onConfirm={() => {
              setRefresh((prev) => !prev);
            }}
          />
        </Col>
      </Row>
      <div className="d-none" style={{ display: "none" }}>
        <div className="" ref={reportTemplateRef}>
          <FullGatePass data={downloadData} />
        </div>
      </div>
    </>
  );
};

export default VehicalExpanse;
