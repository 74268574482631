// import { Button, Col, Form, Image, Input, Row } from "antd";
// import React, { useState } from "react";
// import { Typography } from "antd";
// import Logo from "../../../asset/image/M81-Logo-1.png";
// import Label from "../../../component/common/Label";
// import { loginRoot } from "../../../util/constant/CONSTANTS";
// import { Link } from "react-router-dom";
// const { Text } = Typography;
// const ForgetPassword = () => {
//   const formRef = React.useRef(null);
//   const onClickButton = (values) => {
//     setTimeout(() => {
//       // console.log(values);
//       setLoadings([]);
//     }, 1000);
//   };
//   const [loadings, setLoadings] = useState([]);
//   const enterLoading = (index) => {
//     setLoadings((prevLoadings) => {
//       const newLoadings = [...prevLoadings];
//       newLoadings[index] = true;
//       return newLoadings;
//     });
//     setTimeout(() => {
//       setLoadings((prevLoadings) => {
//         const newLoadings = [...prevLoadings];
//         newLoadings[index] = false;
//         return newLoadings;
//       });
//     }, 6000);
//   };
//   return (
//     <Row className="forget-screen">
//       <Col span={24} className="image-forget">
//         <Image preview={false} src={Logo} />
//       </Col>
//       <Col span={6}></Col>
//       <Col span={12}>
//         <Row className="forget-modal">
//           <Col span={24} className="forget-form">
//             <Row
//               style={{
//                 flexDirection: "column",
//                 alignItems: "center",
//               }}
//             >
//               <p className="heading2">Forgot Password?</p>
//               <Text className="sub-heading2" type="secondary" strong={true}>
//                 We get it, stuff happens. Just enter your email address below
//                 and we’ll send you a link to reset your password!
//               </Text>
//             </Row>
//             <Row justify="center" style={{ marginTop: "25px" }}>
//               <Form
//                 size="medium"
//                 style={{
//                   width: "80%",
//                 }}
//                 ref={formRef}
//                 layout="vertical"
//                 name="control-ref"
//                 onFinish={onClickButton}
//               >
//                 <Form.Item
//                   name="email"
//                   className="form"
//                   rules={[
//                     {
//                       required: true,
//                       message: "Please Enter Valid Email",
//                     },
//                     {
//                       type: "email",
//                       message: "Sorry, we dont recognise this email address",
//                     },
//                   ]}
//                 >
//                   <Input
//                     placeholder="Enter Email Address or Username"
//                     className="alpha"
//                   />
//                 </Form.Item>
//                 <Form.Item>
//                   <Button
//                     loading={loadings[2]}
//                     onClick={() => {
//                       enterLoading(2);
//                     }}
//                     size="large"
//                     type="primary"
//                     htmlType="submit"
//                     block
//                   >
//                     Reset Password
//                   </Button>
//                 </Form.Item>
//               </Form>

//               <Col
//                 span={24}
//                 style={{
//                   display: "flex",
//                   width: "150px",
//                   justifyContent: "space-evenly",
//                 }}
//               >
//                 <Label>Already have an account?</Label>
//                 <Link to={loginRoot}>Sign in</Link>
//               </Col>
//             </Row>
//           </Col>
//         </Row>
//       </Col>
//     </Row>
//   );
// };

// export default ForgetPassword;

import React, { useState } from "react";
import { Form, Input, Button } from "antd";
import { useNavigate } from "react-router-dom";
// import { useMutation } from "react-query";
// import { useAuth } from "../../services/AuthService";
import m81Logo from "../../../asset/image/M81-Logo-1.png";
import useHttp from "../../../hooks/use-http";
import CONSTANTS from "../../../util/constant/CONSTANTS";

const styles = `
 .forgot-password .login-container {
    background-repeat: no-repeat;
    background-size: cover;S
    min-height: 100vh;
    background-color: #e6e4e2;
    // background-color: #4e73df;
    // background-image: linear-gradient(180deg, #4e73df 10%, #224abe);
  }
 .forgot-password .form-container {
    background: white;
    width: 485px;
    height: auto;
    border: 1px solid lightgray;
    padding: 35px 50px;
    margin: auto 30px;
  }
 .forgot-password .app-input {
    padding: 12px 15px;
    // border-radius: 50px;
  }
 .forgot-password .ant-form label {
    padding-left: 20px;
  }
 .forgot-password .form-detail {
    max-width: 350px;
    margin: 0 auto;
  }
 .forgot-password .login-form-button {
    float: right;
    width: 100%;
    height: 45px;
    // border-radius: 50px;
    background-color: #4e73df;
    border-color: #4e73df;
    color: white;
  }
 .forgot-password .hr {
    margin-top: 1rem;
    border: 0;
    border-top: 1px solid rgba(0, 0, 0, 0.1);
  }
 .forgot-password .ant-form-item {
    margin-bottom: 15px;
  }
 .forgot-password .footer {
    position: relative;
    margin-top: 1rem;
  }
`;

const ForgotPassword = () => {
  const [isLoading, setLoadings] = useState(false);

  const navigate = useNavigate();
  const API = useHttp();
  const handleForm = (values) => {
    setLoadings(true);
    API.sendRequest(
      CONSTANTS.API.forgetPassword,
      (res) => {
        console.log(res);
        setLoadings(false);
      },
      values,
      "link sent to your resgistered mail"
    );
  };

  return (
    <>
      <style>{styles}</style>
      <div className="forgot-password">
        <div
          className="login-container flex align-center"
          style={{ height: "100vh" }}
        >
          <div className="login-container">
            <div className="mb40 pt50 flex-x center">
              <img
                src={m81Logo}
                alt="m81Logo"
                height="70"
                width="70"
                onClick={() => navigate("/")}
              />
            </div>
            <div className="flex-x center">
              <div className="form-container">
                <div className="an-22 regular-text text-dark text-center">
                  Forgot Password ?
                </div>
                <div
                  className="an-15 regular-text text-center mt10"
                  style={{ color: "#858796" }}
                >
                  We get it, stuff happens. Just enter your email address below
                  and we'll send you a link to reset your password!
                </div>
                <div className="form-detail">
                  {/* {isSuccess && (
                <Alert
                className="mt10"
                message={data}
                type="success"
                showIcon
                />
              )} */}
                  {/* {isError && (
                <Alert
                  className="mt10"
                  message={error.message}
                  type="error"
                  showIcon
                  />
                )} */}
                  <Form
                    name="normal_login"
                    initialValues={{ remember: true }}
                    onFinish={handleForm}
                  >
                    <Form.Item
                      name="email"
                      rules={[
                        {
                          required: true,
                          message: "Please enter your  Email!",
                        },
                      ]}
                    >
                      <Input
                        placeholder="Email Address"
                        className="app-input mt20"
                      />
                    </Form.Item>
                    <Form.Item className="mb0">
                      <Button
                        htmlType="submit"
                        className="login-form-button an-15 regular-text"
                        loading={isLoading}
                      >
                        Reset Password
                      </Button>
                    </Form.Item>
                    <hr className="hr" />
                    <Form.Item>
                      <div className="text-center">
                        Already have an account?{" "}
                        <span
                          className="cursor-pointer"
                          style={{ color: "#4e73df" }}
                          onClick={() => navigate("/")}
                        >
                          Login
                        </span>
                      </div>
                    </Form.Item>
                  </Form>
                </div>
              </div>
            </div>
            <div className="footer center flex-x">
              <div className="an-12 normal-text">
                © 2023 - All Rights Reserved. M81 ERP DASHBOARD
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ForgotPassword;
