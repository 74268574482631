import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import "../src/asset/css/base.css";
import { ConfigProvider } from "antd";
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <ConfigProvider
    theme={{
      token: {
        colorPrimary: "#2E5BFF",
      },
    }}
  >
    {/* <React.StrictMode> */}
    <App />
    {/* </React.StrictMode> */}
  </ConfigProvider>
);
// import {
//   EditableProTable,
//   ProCard,
//   ProFormField,
// } from "@ant-design/pro-components";
// import { Button } from "antd";
// import React, { useState } from "react";

// const defaultData = new Array(2).fill(1).map((_, index) => {
//   return {
//     id: (Date.now() + index).toString(),
//     title: `title${index}`,
//     decs: "des",
//     state: "open",
//     created_at: "1590486176000",
//   };
// });

// export default () => {
//   const [editableKeys, setEditableRowKeys] = useState(() =>
//     defaultData.map((item) => item.id)
//   );
//   const [dataSource, setDataSource] = useState(() => defaultData);

//   const columns = [
//     {
//       title: "title",
//       dataIndex: "title",
//       width: "30%",
//       formItemProps: {
//         rules: [
//           {
//             required: true,
//             whitespace: true,
//             message: "reuire",
//           },
//           {
//             message: "number please",
//             pattern: /[0-9]/,
//           },
//           {
//             max: 16,
//             whitespace: true,
//             message: "max 16",
//           },
//           {
//             min: 6,
//             whitespace: true,
//             message: "min 6",
//           },
//         ],
//       },
//     },
//     {
//       title: "State",
//       key: "state",
//       dataIndex: "state",
//       valueType: "select",
//       valueEnum: {
//         all: { text: "default", status: "Default" },
//         open: {
//           text: "Error",
//           status: "Error",
//         },
//         closed: {
//           text: "Success",
//           status: "Success",
//         },
//       },
//     },
//     {
//       title: "decs",
//       dataIndex: "decs",
//     },
//     {
//       title: "option",
//       valueType: "option",
//       width: 250,
//       render: () => {
//         return null;
//       },
//     },
//   ];

//   return (
//     <>
//       <EditableProTable
//         headerTitle="Editable table"
//         columns={columns}
//         rowKey="id"
//         scroll={{
//           x: 960,
//         }}
//         value={dataSource}
//         onChange={setDataSource}
//         recordCreatorProps={{
//           newRecordType: "dataSource",
//           record: () => ({
//             id: Date.now(),
//           }),
//         }}
//         toolBarRender={() => {
//           return [
//             <Button
//               type="primary"
//               key="save"
//               onClick={() => {
//                 // dataSource 就是当前数据，可以调用 api 将其保存
//                 console.log(dataSource);
//               }}
//             >
//               save
//             </Button>,
//           ];
//         }}
//         editable={{
//           type: "multiple",
//           editableKeys,
//           actionRender: (row, config, defaultDoms) => {
//             return [defaultDoms.delete];
//           },
//           onValuesChange: (record, recordList) => {
//             setDataSource(recordList);
//           },
//           onChange: setEditableRowKeys,
//         }}
//       />
//       <ProCard title="data" headerBordered collapsible defaultCollapsed>
//         <ProFormField
//           ignoreFormItem
//           fieldProps={{
//             style: {
//               width: "100%",
//             },
//           }}
//           mode="read"
//           valueType="jsonCode"
//           text={JSON.stringify(dataSource)}
//         />
//       </ProCard>
//     </>
//   );
// };
