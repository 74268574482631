import { Avatar, Col, Popover } from "antd";
import Profile from "../../asset/image/dummy-avatar.jpg";
import React from "react";
import moment from "moment";
const Color = {
  Minor: "#9DA6BA",
  Critical: "#E84A50",
  Major: "#F7C137",
  Back: {
    Minor: "rgba(157, 166, 186, 0.15)",
    Critical: "rgba(232, 74, 80, 0.15)",
    Major: "rgba(247, 193, 55, 0.15)",
  },
  Type: {
    New: "#2E5BFF",
    Planned: "#8C54FF",
    Inprogress: "#F7C137",
    Completed: "#2CC2A5",
    Cancelled: "#E84A50",
  },
};
const formatColor = (due, date) => {
  const today = date ? moment(date).startOf("day") : moment().startOf("day");
  const inputDate = moment(due).startOf("day");

  const diffInDays = inputDate.diff(today, "days");

  if (date) {
    if (diffInDays > 0) {
      return "Critical";
    } else {
      return "Minor";
    }
  }
  if (diffInDays < 0) {
    return "Critical";
  } else if (diffInDays < 5) {
    return "Major";
  } else {
    return "Minor";
  }
};
const formatDate = (due, date) => {
  const today = date ? moment(date).startOf("day") : moment().startOf("day");
  const inputDate = moment(due).startOf("day");

  const diffInDays = inputDate.diff(today, "days");

  if (date) {
    if (diffInDays > 1) {
      return ` Due ${Math.abs(diffInDays)} days`;
    } else if (diffInDays === 1) {
      return ` Due ${Math.abs(diffInDays)} day`;
    } else if (diffInDays === 0) {
      return `on Time`;
    } else if (diffInDays < 0) {
      return `On Time`;
    }
  }
  if (diffInDays === 0) {
    return "Due Today";
  } else if (diffInDays === 1) {
    return "Due Tomorrow";
  } else if (diffInDays > 1) {
    return `Due in ${diffInDays} Days`;
  } else if (diffInDays === -1) {
    return "Past Due Yesterday";
  } else {
    return `Past Due ${Math.abs(diffInDays)} Days`;
  }
};
const MaintananceCard = React.memo(({ data, onCardClick = () => {} }) => {
  return (
    <Col className="gutter-row cursor-pointer" span={24} onClick={onCardClick}>
      <div
        className="gutter-box px15 py5"
        style={{
          borderLeft: `2px solid ${Color.Type[data?.status]}`,
          borderRadius: "5pt",
          background: "#FFFFFF",
        }}
      >
        <p className="an-14 text-title gray--text lh-18 mt10">
          <b>{data?.title}</b>
        </p>
        <p className="an-14 text-title gray--text lh-18 ">{data?.category}</p>
        <p className="an-14 text-title gray--text lh-18 ">
          {data?.equipment?.name}
        </p>
        <div className="">
          <div className="flex-x space-between" style={{ width: "100%" }}>
            <div
              className="flex center"
              style={{ height: "40px", marginTop: "13px" }}
            >
              <p
                style={{
                  color: Color[data?.prioriti],
                  lineHeight: "40px",
                  height: "40px",

                  background: Color.Back[data?.prioriti],
                  borderRadius: "5pt",
                  paddingInline: "7px",
                }}
                className="mr15 my15 flex"
              >
                {data?.prioriti}
              </p>
              <p
                style={{
                  color:
                    Color[
                      data?.due && data?.status !== "Completed"
                        ? formatColor(data?.due)
                        : formatColor(
                            data?.maintenanceResolutions
                              ?.map((el) => el?.date)
                              .sort((a, b) => moment(a).isAfter(moment(b)))[0],
                            data?.due
                          )
                    ],
                  // lineHeight: "40px",
                  height: "40px",
                  textAlign: "center",
                  display: "flex",
                  alignItems: "center",

                  background:
                    Color.Back[
                      data?.due && data?.status !== "Completed"
                        ? formatColor(data?.due)
                        : formatColor(
                            data?.maintenanceResolutions
                              ?.map((el) => el?.date)
                              .sort((a, b) => moment(a).isAfter(moment(b)))[0],
                            data?.due
                          )
                    ],
                  borderRadius: "5pt",
                  paddingInline: "7px",
                }}
                className="mr15 my15 flex"
              >
                {data?.due && data?.status !== "Completed"
                  ? formatDate(data?.due)
                  : formatDate(
                      data?.maintenanceResolutions
                        ?.map((el) => el?.date)
                        .sort((a, b) => moment(a).isAfter(moment(b)))[0],
                      data?.due
                    )}
              </p>
            </div>
            <div
              className="flex center"
              style={{ height: "40px", margin: "13px" }}
            >
              <Popover
                content={
                  <div className="flex-x space-between item-center">
                    <p> {data?.assigned?.name}</p>
                    <Avatar
                      src={data?.assigned?.image || Profile}
                      size={35}
                      style={{
                        border: "1px solid black",
                        cursor: "pointer",
                      }}
                    />
                  </div>
                }
                title="Assigne Details"
              >
                <Avatar
                  src={data?.assigned?.image || Profile}
                  size={35}
                  style={{
                    border: "1px solid black",
                    cursor: "pointer",
                  }}
                />
              </Popover>
            </div>
          </div>
        </div>
      </div>
    </Col>
  );
});

export default MaintananceCard;
