import React from "react";
import { Col } from "antd";
import { AttendanceDashboardIcon } from "../../util/constant/ICON";

const StatCard = React.memo((props) => {
  const { icon, Text, desc, onClick = () => {}, progress } = props;

  return (
    <Col
      className="gutter-row cursor-pointer"
      span={24}
      md={12}
      lg={12}
      xl={6}
      onClick={onClick}
    >
      <div
        className="gutter-box flex-y space-between"
        style={{ height: "170px" }}
      >
        <div className="pt15 pb5">
          <img src={icon || AttendanceDashboardIcon} alt="trueIcon" />
        </div>
        <div className="an-34 text-title mt10">{Text}</div>
        <div className="an-14 text-title gray--text lh-32 pb10 mt10">
          {desc}
        </div>
      </div>
      {progress ? (
        <hr
          style={{
            width: `${progress}%`,
            marginTop: "-1px",
            height: "3px",
            background: "#2E5BFF",
            border: "#2E5BFF",
            borderRadius: "5pt",
          }}
        ></hr>
      ) : (
        ""
      )}
    </Col>
  );
});

export default StatCard;
