import React, { useEffect, useState } from "react";
import FormFields from "../../../../component/common/FormFields";
import { Avatar, Button, Col, Popconfirm, Row, Upload, message } from "antd";
import { MdOutlineModeEditOutline } from "react-icons/md";
import { useForm } from "antd/es/form/Form";
import useHttp from "../../../../hooks/use-http";
import CONSTANTS from "../../../../util/constant/CONSTANTS";
import {
  ArrowLeftOutlined,
  PlusOutlined,
  QuestionCircleOutlined,
} from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import { cleanObject } from "../../../../util/functions";
// import { setAuthDetails } from "../../../../util/API/authStorage";

const EmployeeDetails = React.memo(({ EmployeeData, refresher }) => {
  const [form] = useForm();
  const [data, setData] = useState({});
  const [fileList, setFileList] = useState([]);
  const [edit, setEdit] = useState(false);
  const API = useHttp();
  // const param = useParams();
  console.log(EmployeeData?.email, CONSTANTS?.GETMe);
  useEffect(() => {
    setData(EmployeeData);
  }, [EmployeeData]);
  const navigate = useNavigate();

  const onChange = ({ fileList: newFileList }) => {
    // console.log("Preview", newFileList);
    if (
      newFileList
        .map((el) => el.size / 1024 / 1024 < 3)
        .findIndex((v) => v === false) === -1
    ) {
      setFileList(newFileList);
    }
  };
  // console.log(fileList, "file2");

  return (
    <Col span={24} className="dashboard-main-frame">
      <Row className="dashboard-body">
        <Col span={18}>
          <Row className="mb5 mt5">
            <p className="dashboard-body-title flex">
              {EmployeeData?.name}
              <span style={{ fontSize: "22px" }}>
                &nbsp; {`  -  (${EmployeeData?.sku})`}
              </span>
            </p>
          </Row>
          <Row style={{ marginTop: "10px" }}>
            <p className="dashboard-body-sub mb5">{EmployeeData?.position}</p>
          </Row>
          <Col span={24} style={{ marginTop: "10px" }}>
            <FormFields
              formData={data}
              form={form}
              // disabled
              menu="EMPLOYEE_EDIT_MODAL"
              formFields={[]}
            />
            {(EmployeeData?.email === CONSTANTS?.GETMe?.email ||
              !CONSTANTS.USER_ROLE) && (
              <Row justify="end">
                <Col span={4} className="mx10">
                  <Popconfirm
                    title="Delete the Equipment"
                    onConfirm={() => {
                      const DeleteEmployee = {
                        ...CONSTANTS.API.deleteEmployee,
                      };
                      DeleteEmployee.endpoint = DeleteEmployee.endpoint.replace(
                        ":id",
                        EmployeeData?.id
                      );
                      // console.log(DeleteEmployee);
                      API.sendRequest(
                        DeleteEmployee,
                        () => {
                          navigate(-1);
                        },
                        "",
                        "Employee Deleted Successfully"
                      );
                    }}
                    description="Are you sure to delete Employee"
                    icon={
                      <QuestionCircleOutlined
                        style={{
                          color: "red",
                        }}
                      />
                    }
                  >
                    <Button danger block>
                      Delete
                    </Button>
                  </Popconfirm>
                </Col>
                <Col span={6}>
                  <Button
                    onClick={() => {
                      form
                        .validateFields()
                        .then((values) => {
                          const payload = new FormData();
                          values = cleanObject(values);
                          Object.keys(values).forEach((el) => {
                            payload.append(el, values[el]);
                          });

                          if (fileList.length) {
                            payload.append("image", fileList[0].originFileObj);
                          }
                          const APIpoint = {
                            ...CONSTANTS.API.updatedEmployee,
                          };
                          APIpoint.endpoint = APIpoint.endpoint.replace(
                            ":id",
                            data.id
                          );
                          API.sendRequest(
                            APIpoint,
                            (res) => {
                              // console.log(res, "updated");
                              // setAuthDetails(res.token);
                              refresher();
                            },
                            payload,
                            "Update Successfully"
                          );
                        })
                        .catch((info) => {
                          console.log("Validate Failed:", info);
                        });
                    }}
                    type="primary"
                    // ghost
                    block
                    loading={API.isLoading}
                  >
                    Update
                  </Button>
                </Col>
              </Row>
            )}
          </Col>
        </Col>
        <Col
          span={6}
          style={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          {edit || !data?.image ? (
            <>
              <Row className="avtar-upload-parent">
                {/* <Col span={4}></Col>
                <Col span={12}> */}
                <Upload
                  className="avtarUpload-uploader"
                  name="avtarUpload"
                  listType="picture-circle"
                  fileList={fileList}
                  accept=".jpg,.png,.jpeg"
                  onChange={onChange}
                  // onPreview={onPreview}
                  beforeUpload={(file) => {
                    const isLt3M = file.size / 1024 / 1024 < 3;
                    if (!isLt3M) {
                      message.error("Image must smaller than 3MB!");
                      setFileList([]);
                    }
                    return isLt3M;
                  }}
                >
                  {fileList.length < 1 && (
                    <div>
                      <PlusOutlined className="flex" />
                      <div
                        style={{
                          marginTop: 8,
                        }}
                      >
                        Upload
                      </div>
                    </div>
                  )}
                </Upload>
                {/* </Col> */}
              </Row>
              <Row>
                <Col span={6}>
                  {fileList.length < 1 && data?.image && (
                    <ArrowLeftOutlined
                      onClick={() => {
                        setEdit(false);
                      }}
                    />
                  )}
                </Col>
              </Row>
            </>
          ) : (
            <Row className="profile-pic-dashboard">
              <Row>
                <Avatar src={data?.image} size={140} />
              </Row>
              <Row className="edit-dashboard">
                <Row
                  justify="center"
                  align="middle"
                  style={{
                    borderRadius: "100%",
                    height: "140px",
                    width: "140px",
                  }}
                >
                  <Col span={8}></Col>
                  <Col span={12}>
                    <MdOutlineModeEditOutline
                      fontSize="25px"
                      color="white"
                      className="pointer"
                      onClick={() => {
                        // console.log("got it");
                        setEdit(true);
                      }}
                    />
                  </Col>
                  {/* <Col span={8}>
                    <AiOutlineDelete
                      fontSize="25px"
                      className="pointer"
                      color="white"
                    />
                  </Col> */}
                </Row>
              </Row>
            </Row>
          )}
        </Col>
      </Row>
    </Col>
  );
});

export default EmployeeDetails;
