import React from "react";

const Expenses = () => {
  return (
    <div>
      <h1>Expenses</h1>
    </div>
  );
};

export default Expenses;
