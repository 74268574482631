import moment from "moment";

export const cleanObject = (obj) => {
  const cleanedObj = {};
  for (const key in obj) {
    const value = obj[key];
    if (value !== null && value !== undefined && value !== "") {
      cleanedObj[key] = value;
    }
  }
  obj = { ...cleanedObj };
  return cleanedObj;
};

export function ActualDuration(createdAt, updatedAt) {
  const durationMinutes = moment(updatedAt).diff(createdAt, "minutes");

  if (durationMinutes < 60) {
    return `${durationMinutes} minutes`;
  } else if (durationMinutes < 1440) {
    const hours = Math.floor(durationMinutes / 60);
    const minutes = durationMinutes % 60;
    return `${hours}H ${minutes}M`;
  } else if (durationMinutes < 525600) {
    const days = Math.floor(durationMinutes / 1440);
    const hours = Math.floor((durationMinutes % 1440) / 60);
    const minutes = (durationMinutes % 1440) % 60;
    if (days >= 30) {
      const months = Math.floor(days / 30);
      const remainingDays = days % 30;
      return `${months}M ${remainingDays}D ${hours}H ${minutes}M`;
    }
    return `${days}D ${hours}H ${minutes}M`;
  } else {
    const years = Math.floor(durationMinutes / 525600);
    const remainingMinutes = durationMinutes % 525600;
    const months = Math.floor(remainingMinutes / 43200);
    const remainingMinutes2 = remainingMinutes % 43200;
    const days = Math.floor(remainingMinutes2 / 1440);
    const hours = Math.floor((remainingMinutes2 % 1440) / 60);
    const minutes = (remainingMinutes2 % 1440) % 60;
    if (years >= 1) {
      return `${years}Y ${months}M ${days}D ${hours}H:${minutes
        .toString()
        .padStart(2, "0")}M`;
    } else if (months >= 1) {
      return `${months}M ${days}D ${hours}H:${minutes
        .toString()
        .padStart(2, "0")}M`;
    } else if (days >= 1) {
      return `${days}D ${hours}H:${minutes.toString().padStart(2, "0")}M`;
    }
    return `${hours}H:${minutes.toString().padStart(2, "0")}M`;
  }
}
export const buildQueryString = (params) => {
  const queryParts = [];

  for (const key in params) {
    if (params.hasOwnProperty(key)) {
      const value = params[key];

      if (key.startsWith("autogenerate-mul-array-") && Array.isArray(value)) {
        // Handle autogenerate-mul-array- with array values
        console.log(key, "key");
        const arrayKey = key.slice("autogenerate-mul-array-".length);
        value.forEach((item) => {
          queryParts.push(
            `${encodeURIComponent(arrayKey)}=${encodeURIComponent(item)}`
          );
        });
      } else {
        // Handle other cases
        queryParts.push(
          `${encodeURIComponent(key)}=${encodeURIComponent(value)}`
        );
      }
    }
  }

  return queryParts.length > 0 ? `?${queryParts.join("&")}` : "";
};
