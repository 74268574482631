import React, { useEffect, useState } from "react";
import { Col, Empty, Row, Spin } from "antd";
import PageHeader from "../../../../component/common/page-Header";
import { DownloadOutlined } from "@ant-design/icons";
import InventoryCard from "../../../../component/card/InventoryCard";
import ModalFormCreator from "../../../../component/common/ModalFormCreator";
import useHttp from "../../../../hooks/use-http";
import CONSTANTS from "../../../../util/constant/CONSTANTS";
import { useNavigate } from "react-router-dom";
import moment from "moment";

const Equipment = () => {
  const [open, setOpen] = useState(false);
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 20,
    total: 0,
  });
  const [refresh, setRefresh] = useState(false);
  const [SubmitValue, setSubmitValue] = useState({});
  const [inventoryDataArr, setInventoryDataArr] = useState([]);

  const API = useHttp();
  const navigate = useNavigate();
  const onCreate = (values, clear) => {
    setSubmitValue({ ...values });

    values.maintananceDuration = moment(values.maintananceDuration).format(
      "HH:mm"
    );
    API.sendRequest(
      CONSTANTS.API.equipment,
      (res) => {
        setRefresh((prev) => !prev);
        setOpen(false);
        clear();
      },
      values,
      "equipment added"
    );
  };

  useEffect(() => {
    const EquipmentPage = { ...CONSTANTS.API.AllEquipment };
    EquipmentPage.endpoint = `${EquipmentPage.endpoint}?limit=${pagination.pageSize}&page=${pagination.current}`;
    API.sendRequest(EquipmentPage, (res) => {
      setPagination((prevPagination) => ({
        ...prevPagination,
        total: res?.data?.count,
      }));
      setInventoryDataArr(
        res?.data?.rows.map((el) => (
          <Col key={el.id}>
            <InventoryCard {...el} />
          </Col>
        ))
      );
    });
  }, [refresh, pagination.current, pagination.pageSize]);

  useEffect(() => {
    API.sendRequest(CONSTANTS.API.getallvendors, (res) => {
      CONSTANTS.FORM_FIELD.NEW_EQUIPMENT_FORM[1].item[1].option =
        res?.data?.rows?.map((el) => ({
          Label: el.name,
          value: el.id,
          id: el.id,
        }));
    });
    API.sendRequest(CONSTANTS.API.equipmentcategory, (res) => {
      CONSTANTS.FORM_FIELD.NEW_EQUIPMENT_FORM[1].item[0].option =
        res?.data?.rows?.map((el) => ({
          id: el.id,
          value: el.id,
          Label: el.category,
        }));
    });
    API.sendRequest(CONSTANTS.API.getAllClients, (res) => {
      CONSTANTS.FORM_FIELD.NEW_EQUIPMENT_FORM[7].item[1].option =
        res?.data?.rows?.map((el) => ({
          id: el.name,
          value: el.name,
          Label: el.name,
        }));
    });
  }, []);
  return (
    <Row className="container-main">
      <PageHeader
        data={{
          buttons: [
            !CONSTANTS.USER_ROLE && {
              id: 1,
              action: () => {
                console.log("button1");
                setOpen(true);
              },
              name: "New",
            },
            {
              id: 2,
              action: () => {
                console.log("button3");
              },
              icon: <DownloadOutlined />,
              type: "icon",
            },
          ],
          name: "Equipment",
        }}
        pagination={pagination}
        setPagination={setPagination}
        SearchBar={{
          endpointAPI: "/equipment?search=",
          dataResponseHandle: (res) => {
            return res?.data?.rows?.map((el) => ({
              ...el,
              key: el?.id,
            }));
          },
          onValueIsSelected: (row) => {
            navigate(`/app/inventory/equipment/${row?.model}/${row?.id}`);
          },
          extraLabel: "model",
          onBaseSearch: ["name"],
          debounceTime: 500,
        }}
      />
      <ModalFormCreator
        loading={API.isLoading}
        open={open}
        onCreate={onCreate}
        onCancel={() => {
          setOpen(false);
        }}
        formData={SubmitValue}
        name="New EQUIPMENT FORM"
        menu="NEW_EQUIPMENT_FORM"
      />
      <Col span={24} className="container-body">
        {!API.isLoading ? (
          <Row
            justify="center"
            gutter={[36, 32]}
            style={{ marginBlock: "15px", justifyContent: "start" }}
          >
            {inventoryDataArr}
          </Row>
        ) : (
          <Spin className="flex-x center" size="large" />
        )}
        {!inventoryDataArr.length && (
          <Empty description={"No Equipment Available"} />
        )}
      </Col>
    </Row>
  );
};

export default Equipment;
