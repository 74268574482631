import React from "react";
import Logo from "../../asset/image/KP-Logo.jpeg";
import "./style/custom.css";
const FullGatePass = (props) => {
  const { data } = props;
  return (
    <div
      className="ritz grid-container"
      dir="ltr"
      style={{ padding: "35px", width: "2390px", height: "3300px" }}
    >
      <table
        className="waffleGate no-grid"
        cellSpacing={0}
        cellPadding={0}
        style={{
          padding: "35px",
          width: "1500px",
          height: "1600px",
          marginBottom: "45px",
        }}
      >
        <tbody style={{ display: "table", width: "100%", height: "100%" }}>
          <tr style={{ height: "20px" }}>
            <td className="s0" />
            <td className="s0" />
            <td className="s0" />
            <td className="s0" />
            <td className="s0" />
            <td className="s0" />
            <td className="s0" />
          </tr>
          <tr style={{ height: "20px" }}>
            <td className="s1" colSpan={2} rowSpan={5}>
              <div style={{ width: "130px", height: "104px" }}>
                <img
                  src={Logo}
                  style={{
                    width: "100%",
                    height: "100%",
                    objectFit: "contain",
                    objectPosition: "left bottom",
                  }}
                />
              </div>
            </td>
            <td className="s0" dir="ltr">
              Samoj 66KV Solar Plant
            </td>
            <td className="s2" dir="ltr" colSpan={4}>
              Returnable Gate Pass
            </td>
          </tr>
          <tr style={{ height: "20px" }}>
            <td className="s0" dir="ltr" colSpan={7}>
              Email: solar.samoj@kpgroup.co
            </td>
          </tr>
          <tr style={{ height: "20px" }}>
            <td className="s0" dir="ltr" colSpan={7}>
              Contact No.: 7984975311
            </td>
          </tr>
          <tr style={{ height: "20px" }}>
            <td className="s0" dir="ltr">
              Address: Samoj, Dist. Bharuch
            </td>
            <td className="s3" dir="ltr" colSpan={2}>
              Gate Pass No.
            </td>
            <td className="s4" dir="ltr" colSpan={2}>
              GPRN/2023/OCH/05
            </td>
          </tr>
          <tr style={{ height: "20px" }}>
            <td className="s0" dir="ltr">
              Gujarat, 383001
            </td>
            <td className="s3" dir="ltr" colSpan={2}>
              Date
            </td>
            <td className="s4" dir="ltr" colSpan={2}>
              07/06/2023
            </td>
          </tr>
          <tr style={{ height: "25px" }}>
            <td className="s6" dir="ltr" colSpan={7}>
              Gate Pass Out
            </td>
          </tr>
          <tr style={{ height: "20px" }}>
            <td className="s8 bl" dir="ltr" colSpan={7}>
              To,
            </td>
          </tr>
          <tr style={{ height: "20px" }}>
            <td className="s10 bl" dir="ltr" colSpan={2}>
              Name
            </td>
            <td className="s10" dir="ltr">
              Kamal Singh
            </td>
            <td className="s10" dir="ltr" colSpan={2}>
              Challan No.
            </td>
            <td className="s10" dir="ltr" colSpan={2}>
              121
            </td>
          </tr>
          <tr style={{ height: "20px" }}>
            <td className="s10 bl" dir="ltr" colSpan={2}>
              Designation
            </td>
            <td className="s10" dir="ltr">
              Manager
            </td>
            <td className="s10" dir="ltr" colSpan={2}>
              Transporter
            </td>
            <td className="s10" dir="ltr" colSpan={2}>
              KP Group
            </td>
          </tr>
          <tr style={{ height: "20px" }}>
            <td className="s10 bl" dir="ltr" colSpan={2}>
              Email
            </td>
            <td className="s10" dir="ltr">
              ochhan.solar@kpgroup.co
            </td>
            <td className="s10" dir="ltr" colSpan={2}>
              Driver Name
            </td>
            <td className="s10" dir="ltr" colSpan={2}>
              Manish
            </td>
          </tr>
          <tr style={{ height: "20px" }}>
            <td className="s10 bl" dir="ltr" colSpan={2}>
              Phone
            </td>
            <td className="s10" dir="ltr">
              7861259810
            </td>
            <td className="s10" dir="ltr" colSpan={2}>
              Vehical No.
            </td>
            <td className="s10" dir="ltr" colSpan={2}>
              GJ18BE2565
            </td>
          </tr>
          <tr style={{ height: "20px" }}>
            <td className="s12 bl" dir="ltr" colSpan={2} rowSpan={2}>
              Address
            </td>
            <td className="s13" dir="ltr" rowSpan={2}>
              Ochhan Solar Plant, Dist. Bharuch, Gujarat - 395602
            </td>
            <td className="s10" dir="ltr" colSpan={2}>
              Division Name
            </td>
            <td className="s10" dir="ltr" colSpan={2}>
              PLANT
            </td>
          </tr>
          <tr style={{ height: "20px" }}>
            <td className="s10 " dir="ltr" colSpan={2}>
              Dept. Name
            </td>
            <td className="s10" dir="ltr" colSpan={2}>
              O&amp;M
            </td>
          </tr>
          <tr style={{ height: "20px" }}>
            <td className="s14" />
            <td className="s14" />
            <td className="s14" />
            <td className="s14" />
            <td className="s14" />
            <td className="s14" />
            <td className="s14" />
          </tr>
          <tr style={{ height: "20px" }}>
            <td className="s16 bl" dir="ltr">
              S/N.
            </td>
            <td className="s16" dir="ltr" colSpan={2}>
              Item Description
            </td>
            <td className="s16" dir="ltr">
              Qty
            </td>
            <td className="s16" dir="ltr">
              UOM
            </td>
            <td className="s16" dir="ltr" colSpan={2}>
              SKU
            </td>
          </tr>
          <tr style={{ height: "20px" }}>
            <td className="s10 bl" dir="ltr" />
            <td className="s10" colSpan={2} />
            <td className="s10" />
            <td className="s10" />
            <td className="s10" colSpan={2} />
          </tr>
          <tr style={{ height: "20px" }}>
            <td className="s10 bl" dir="ltr" />
            <td className="s10" colSpan={2} />
            <td className="s10" />
            <td className="s10" />
            <td className="s10" colSpan={2} />
          </tr>
          <tr style={{ height: "20px" }}>
            <td className="s10" colSpan={7} />
          </tr>
          <tr style={{ height: "20px" }}>
            <td className="s10 bl" dir="ltr" colSpan={7}>
              Remarks :
            </td>
          </tr>
          <tr style={{ height: "20px" }}>
            <td className="s14" dir="ltr" />
            <td className="s14" dir="ltr" />
            <td className="s14" dir="ltr" />
            <td className="s14" dir="ltr" />
            <td className="s14" dir="ltr" />
            <td className="s14" dir="ltr" />
            <td className="s10" dir="ltr" />
          </tr>
          <tr style={{ height: "20px" }}>
            <td className="s10 bl" colSpan={3} rowSpan={3} />
            <td className="s10" colSpan={4} rowSpan={3} />
          </tr>
          <tr style={{ height: "20px" }} />
          <tr style={{ height: "20px" }} />
          <tr style={{ height: "20px" }}>
            <td className="s18 bl" dir="ltr" colSpan={3}>
              Sender Name &amp; Signature
            </td>
            <td className="s18" dir="ltr" colSpan={4}>
              Reciever Name &amp; Signature
            </td>
          </tr>
          <tr style={{ height: "20px" }}>
            <td className="s19" dir="ltr" />
            <td className="s19" dir="ltr" />
            <td className="s19" dir="ltr" />
            <td className="s19" dir="ltr" />
            <td className="s19" dir="ltr" />
            <td className="s19" dir="ltr" />
            <td className="s19" dir="ltr" />
          </tr>
          <tr style={{ height: "20px" }}>
            <td className="s20" dir="ltr" colSpan={7}>
              Gate Pass In (Return)
            </td>
          </tr>
          <tr style={{ height: "20px" }}>
            <td className="s8 bl" dir="ltr" colSpan={7}>
              From,
            </td>
          </tr>
          <tr style={{ height: "20px" }}>
            <td className="s10 bl" dir="ltr" colSpan={2}>
              Name
            </td>
            <td className="s10" dir="ltr">
              Kamal Singh
            </td>
            <td className="s10" dir="ltr" colSpan={2}>
              Challan No.
            </td>
            <td className="s10" dir="ltr" colSpan={2}>
              121
            </td>
          </tr>
          <tr style={{ height: "20px" }}>
            <td className="s10 bl" dir="ltr" colSpan={2}>
              Designation
            </td>
            <td className="s10" dir="ltr">
              Manager
            </td>
            <td className="s10" dir="ltr" colSpan={2}>
              Transporter
            </td>
            <td className="s10" dir="ltr" colSpan={2}>
              KP Group
            </td>
          </tr>
          <tr style={{ height: "20px" }}>
            <td className="s10 bl" dir="ltr" colSpan={2}>
              Email
            </td>
            <td className="s10" dir="ltr">
              ochhan.solar@kpgroup.co
            </td>
            <td className="s10" dir="ltr" colSpan={2}>
              Driver Name
            </td>
            <td className="s10" dir="ltr" colSpan={2}>
              Manish
            </td>
          </tr>
          <tr style={{ height: "20px" }}>
            <td className="s10 bl" dir="ltr" colSpan={2}>
              Phone
            </td>
            <td className="s10" dir="ltr">
              7861259810
            </td>
            <td className="s10" dir="ltr" colSpan={2}>
              Vehical No.
            </td>
            <td className="s10" dir="ltr" colSpan={2}>
              GJ18BE2565
            </td>
          </tr>
          <tr style={{ height: "20px" }}>
            <td className="s12 bl" dir="ltr" colSpan={2} rowSpan={2}>
              Address
            </td>
            <td className="s13" dir="ltr" rowSpan={2}>
              Ochhan Solar Plant, Dist. Bharuch, Gujarat - 395602
            </td>
            <td className="s10" dir="ltr" colSpan={2}>
              Division Name
            </td>
            <td className="s10" dir="ltr" colSpan={2}>
              PLANT
            </td>
          </tr>
          <tr style={{ height: "20px" }}>
            <td className="s10 " dir="ltr" colSpan={2}>
              Dept. Name
            </td>
            <td className="s10" dir="ltr" colSpan={2}>
              O&amp;M
            </td>
          </tr>
          <tr style={{ height: "20px" }}>
            <td className="s10" colSpan={7} />
          </tr>
          <tr style={{ height: "20px" }}>
            <td className="s10 bl" dir="ltr" colSpan={7}>
              Remarks :
            </td>
          </tr>
          <tr style={{ height: "20px" }}>
            <td className="s14" dir="ltr" />
            <td className="s14" dir="ltr" />
            <td className="s14" dir="ltr" />
            <td className="s14" dir="ltr" />
            <td className="s14" dir="ltr" />
            <td className="s14" dir="ltr" />
            <td className="s10" dir="ltr" />
          </tr>
          <tr style={{ height: "20px" }}>
            <td className="s10 bl" colSpan={3} rowSpan={3} />
            <td className="s10" colSpan={4} rowSpan={3} />
          </tr>
          <tr style={{ height: "20px" }} />
          <tr style={{ height: "20px" }} />
          <tr style={{ height: "20px" }}>
            <td className="s18 bl" dir="ltr" colSpan={3}>
              Sender Name &amp; Signature
            </td>
            <td className="s18" dir="ltr" colSpan={4}>
              Reciever Name &amp; Signature
            </td>
          </tr>
          <tr style={{ height: "20px" }}>
            <td className="s21" />
            <td className="s21" />
            <td className="s21" />
            <td className="s21" />
            <td className="s21" />
            <td className="s21" />
            <td className="s21" />
          </tr>
          <tr style={{ height: "23px" }}>
            <td className="s22 " dir="ltr" colSpan={7}>
              KPI Green OMS Private Limited
            </td>
          </tr>
          <tr style={{ height: "23px" }}>
            <td className="s23" dir="ltr" colSpan={7}>
              A-1/2, Firdous Tower, Near Fazal Shopping Centre, Near Rander
              Road, Adajan Patia, Adajan, Surat, Gujarat - 395009
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default FullGatePass;
