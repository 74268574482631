import React, { useEffect, useState } from "react";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import { Col, Row } from "antd";
import useHttp from "../../../../hooks/use-http";
import CONSTANTS from "../../../../util/constant/CONSTANTS";
import moment from "moment";
import ModalFormCreator from "../../../../component/common/ModalFormCreator";
import CustomTable from "../../../../component/common/Custom-Table";

const MaintenanceCalendar = () => {
  const [events, setEvents] = useState([]);
  const [formInventoryData, setFormInventoryData] = useState({});
  const [openEditRow, setOpenEditRow] = useState(false);
  const API = useHttp();

  useEffect(() => {
    API.sendRequest(CONSTANTS.API.getallMaintenanceRequests, (res) => {
      const transformedEvents = res?.data?.data?.map((el, i) => {
        // console.log(el);
        let title;
        let start;
        let end;

        if (el.status === "Inprogress") {
          title = `[${moment(el.createdAt).format("hh:mm A")}] ${el.title}`;
          start = moment(el.createdAt).format("YYYY-MM-DD");
          end = el.updatedAt
            ? moment(el.end).add(1, "day").format("YYYY-MM-DD")
            : null;
        } else if (el.status === "Completed") {
          title = `[${moment(el.createdAt).format("hh:mm A")} - ${moment(
            el.updatedAt
          ).format("hh:mm A")}] ${el.title}`;
          start = moment(el.date).format("YYYY-MM-DD");
          end = moment(el.updatedAt).add(1, "day").format("YYYY-MM-DD");
        } else {
          title = `[${moment(el.createdAt).format("hh:mm A")}] ${el.title}`;
          start = moment(el.createdAt).format("YYYY-MM-DD");
          end = moment().add(1, "day").format("YYYY-MM-DD");
        }

        return {
          ...el,
          key: el.id,
          titleDef: el?.title,
          // actualduration:
          //   el?.status === "Completed"
          //     ? ActualDuration(el?.createdAt, el?.updatedAt)
          //     : "-",
          creatorName: el?.creator?.name,
          assignedName: el?.assigned?.name,
          equipmentName: el?.equipment?.name,
          // date: moment(el?.date).format("YYYY-MM-DD HH:mm"),
          maintenanceResolutions: [
            ...new Set(el?.maintenanceResolutions?.map((el) => el?.action)),
          ]
            ?.map((ele) =>
              el?.maintenanceResolutions?.filter((el) => el?.action === ele)
            )
            ?.map((el) => ({
              action: el[0]?.action,
              createdAt: el[0]?.createdAt,
              date: el[0]?.date,
              products: el?.map((p) => ({
                ...p,
                category: p?.product?.productCategory?.category,
                ...p?.product,
                quantity: p?.quantity,
                sku: p?.product?.inventoryInwards?.[0]?.sku,
                srNumber: p?.product?.inventoryInwards?.[0]?.trackingId,
              })),
            })),
          title,
          start,
          end,
          id: i,
          textColor: el?.prioriti in Color ? Color[el.prioriti] : "#808080",
          backgroundColor:
            el?.prioriti in Color.Back ? Color.Back[el.prioriti] : "#000000",
        };
      });
      // console.log(transformedEvents);
      setEvents(transformedEvents);
    });
    API.sendRequest(CONSTANTS.API.employee, (res) => {
      const options = res?.data?.rows?.map((el) => ({
        id: el.id,
        value: el.id,
        Label: el.name,
      }));
      CONSTANTS.FORM_FIELD.MAINTENANCE_REQUEST_FORM[1].item[0].option = options;
      CONSTANTS.FORM_FIELD.MAINTENANCE_REQUEST_FORM[1].item[1].option = options;
      CONSTANTS.FORM_FIELD.MAINTENANCE_REQUEST_FORM_NEW[1].item[0].option =
        options;
      CONSTANTS.FORM_FIELD.MAINTENANCE_REQUEST_FORM_NEW[1].item[1].option =
        options;
    });
    API.sendRequest(CONSTANTS.API.AllEquipment, (res) => {
      const options = res?.data?.rows.map((el) => ({
        id: el.id,
        value: el.id,
        Label: el.name,
      }));
      CONSTANTS.FORM_FIELD.MAINTENANCE_REQUEST_FORM[2].item[0].option = options;
      CONSTANTS.FORM_FIELD.MAINTENANCE_REQUEST_FORM_NEW[2].item[0].option =
        options;
    });
  }, []);

  const Color = {
    Minor: "#9DA6BA",
    Critical: "#E84A50",
    Major: "#F7C137",
    Back: {
      Minor: "rgba(157, 166, 186, 0.15)",
      Critical: "rgba(232, 74, 80, 0.15)",
      Major: "rgba(247, 193, 55, 0.15)",
    },
  };

  return (
    <Row className="container-main">
      <ModalFormCreator
        loading={API.isLoading}
        open={openEditRow}
        onCancel={() => {
          setOpenEditRow(false);
          setFormInventoryData({});
        }}
        disabled={true}
        name="view Maintenance Request"
        menu="MAINTENANCE_REQUEST_FORM"
        formData={formInventoryData}
        Submit={false}
      >
        {formInventoryData?.maintenanceResolutions?.map((el, i) => (
          <div className="mb15" key={el.products.id}>
            <div
              className="form-modal-head an-20 mb5"
              style={{ display: "flex", justifyContent: "space-between" }}
            >
              <p className="form-modal-head an-20 mb5">{`Resolution - ${
                i + 1
              }`}</p>
              <p className="form-modal-head an-15 mb5">
                {/* {moment(el?.createdAt).format("YYYY-MM-DD HH:mm")} */}
                {moment(el?.date).format("YYYY-MM-DD HH:mm")}
                {/* {dayjs(moment(el?.createdAt).format("YYYY-MM-DD HH:mm")).from(
                  dayjs(formInventoryData?.date)
                )} */}
              </p>
            </div>
            <p className="an-15 mb5">{el?.action}</p>
            {el?.products?.[0]?.product && (
              <CustomTable
                name="MAINTENANCE_RESOLUTION_TABLE"
                dataSource={el.products}
                scrollAutoOff={true}
                Other={{ rowSelection: null }}
              />
            )}
          </div>
        ))}
      </ModalFormCreator>
      <Col span={24} className="px45 py15 calendar">
        <FullCalendar
          plugins={[dayGridPlugin]}
          headerToolbar={{
            left: "prev,today,next",
            center: "title",
            right: "dayGridMonth,dayGridWeek,dayGridDay",
          }}
          eventClick={(res) => {
            console.log("click", res?.event?._def?.extendedProps);
            setOpenEditRow(true);
            setFormInventoryData({
              ...res?.event?._def?.extendedProps,
              title: res?.event?._def?.extendedProps?.titleDef,
            });
          }}
          // initialView="dayGridMonth"
          events={events}
          eventDisplay="block"
          eventTimeFormat={{
            hour: "numeric",
            minute: "2-digit",
            meridiem: "short",
          }}
          editable={false}
          droppable={false}
          selectable={false}
        />
      </Col>
    </Row>
  );
};

export default MaintenanceCalendar;
