import React, { useEffect, useState } from "react";
import { Col, Row } from "antd";
import PageHeader from "../../../../component/common/page-Header";
import { DownloadOutlined } from "@ant-design/icons";
import CustomTable from "../../../../component/common/Custom-Table";
import CONSTANTS from "../../../../util/constant/CONSTANTS";
import useHttp from "../../../../hooks/use-http";

const MoveHistory = () => {
  const [data, setData] = useState([]);
  const [Allfilter, setAllFilter] = useState(null);
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 20,
    total: 0,
  });
  const API = useHttp();
  useEffect(() => {
    const MovePage = { ...CONSTANTS.API.movehistory };
    MovePage.endpoint = `${MovePage.endpoint}`;
    let QuaryParams = {
      limit: pagination.pageSize,
      page: pagination.current,
    };
    if (Allfilter?.sort) {
      QuaryParams = { ...QuaryParams, ...Allfilter?.sort };
    }
    if (Allfilter?.filter) {
      Allfilter?.filter?.map((el) => {
        QuaryParams = {
          ...QuaryParams,
          [`autogenerate-mul-array-$product.${el[0]}$`]: el[1],
        };
      });
    }
    API.sendRequest(
      MovePage,
      (res) => {
        setPagination((prevPagination) => ({
          ...prevPagination,
          total: res?.data?.count,
        }));

        setData(
          res?.data?.rows?.map((el, i) => ({
            ...el?.product?.productCategory,
            ...el?.product,
            Ptype: el?.product?.type,
            ...el,
            no: (pagination.current - 1) * pagination.pageSize + i + 1,
            key: el?.id,
          }))
        );
      },
      QuaryParams
    );
  }, [pagination.current, pagination.pageSize, Allfilter]);
  const CSVData = [];
  CSVData[0] = CONSTANTS.TABLE.MOVE_HISTORY.map((el) => el.title);
  data.map((item, index) => {
    CSVData[index + 1] = CONSTANTS.TABLE.MOVE_HISTORY.map(
      (el) => item[el.dataIndex]
    );
    return 0;
  });
  return (
    <Row className="container-main">
      <PageHeader
        data={{
          buttons: [
            {
              id: 1,
              action: () => {
                // console.log("button3");
                // console.log("download", CSVData);
              },
              icon: <DownloadOutlined />,
              data: CSVData,
              type: "linkicon",
              name: "Move-History",
            },
          ],
          name: "Move History",
        }}
        pagination={pagination}
        setPagination={setPagination}
        SearchBar={{
          endpointAPI: `/movement?limit=${pagination.pageSize}&page=${pagination.current}&search=`,
          dataResponseHandle: (res, noWord) => {
            if (noWord && res === null) {
              setPagination((prev) => ({
                ...prev,
                current: prev.current !== 1 ? 1 : 2,
              }));
            }
            return res?.data?.rows?.map((el) => ({
              ...el?.product?.productCategory,
              ...el?.product,
              Ptype: el?.product?.type,
              ...el,
              key: el?.id,
            }));
          },
          // onValueIsSelected: (row) => {
          //   // setFormInventoryData({
          //   //   ...row,
          //   //   duration: dayjs(row.duration, "HH:mm"),
          //   // });
          //   // setOpenEditRow(true);
          //   console.log(row);
          // },
          onChange: (res) => {
            setData(res);
          },

          debounceTime: 500,
        }}
      />
      <Col
        span={24}
        style={{
          marginBlock: "15px",
        }}
        className="container-body"
      >
        <CustomTable
          isLoading={API.isLoading}
          dataSource={data}
          name="MOVE_HISTORY"
          setChanges={(v) => {
            setAllFilter(v);
            setPagination((prev) => ({
              ...prev,
              current: 1,
            }));
          }}
        />
      </Col>
    </Row>
  );
};

export default MoveHistory;
