import React, { useEffect, useState } from "react";
import StatCard from "./StatCard";
import {
  AttendanceDashboardIcon,
  ChecklistDashboardIcon,
  CleaningLogDashboardIcon,
  ExpensesDashboardIcon,
  InventoryDashboardIcon,
  RefreshDashboardIcon,
  maintananceDashboardIcon,
} from "../../util/constant/ICON";
import {
  Button,
  Col,
  DatePicker,
  Modal,
  Row,
  Select,
  Table,
  notification,
} from "antd";
import StatGraphCard from "./Stat-Graph-Card";
import Label from "../common/Label";
import CONSTANTS from "../../util/constant/CONSTANTS";
import useHttp from "../../hooks/use-http";
import dayjs from "dayjs";
import { TbAlertCircle } from "react-icons/tb";
import { ImWarning } from "react-icons/im";

import moment from "moment";

let attendanceemployeetoday = 0;
let onGoingCheckupsToday = [];
const HeadDashboard = React.memo(({ maintanance }) => {
  delete maintanance.Total;
  delete maintanance.data;
  const [attendance, setAttendance] = useState(false);
  const [attendancedate, setAttendancedate] = useState(
    dayjs().format("YYYY-MM-DD")
  );
  const [attendentEmp, setAttendentEmp] = useState([]);
  const [selectedRowKeysAttendence, setSelectedRowKeysAttendence] = useState(
    []
  );

  const [inventory, setInventory] = useState({});
  const [cleaningLog, setCleaningLog] = useState({});
  const [checklist, setChecklist] = useState(false);
  const [EmpList, setEmpList] = useState([]);
  const [OngoingCheckup, setOngoingCheckup] = useState([]);
  const [selectedRowKeyscheckup, setSelectedRowKeyscheckup] = useState([]);
  const [selctedEmployee, setSelctedEmployee] = useState(null);
  const [totalExpanse, setTotalExpense] = useState(0);
  const [checkupdate, setCheckupdate] = useState(dayjs().format("YYYY-MM-DD"));

  const API = useHttp();
  const rowSelectionAttendence = {
    selectedRowKeys: selectedRowKeysAttendence,
    onChange: (newSelectedRowKeys) => {
      setSelectedRowKeysAttendence(newSelectedRowKeys);
    },
  };
  const rowSelectionChecklist = {
    selectedRowKeys: selectedRowKeyscheckup,
    onChange: (newSelectedRowKeys) => {
      setSelectedRowKeyscheckup(newSelectedRowKeys);
    },
  };
  useEffect(() => {
    const AttendenceAPI = { ...CONSTANTS.API.getemployeeattendance };
    AttendenceAPI.endpoint = AttendenceAPI.endpoint + attendancedate;
    API.sendRequest(AttendenceAPI, (res) => {
      setSelectedRowKeysAttendence(res?.data?.map((el) => el.employeeId));
    });
  }, [attendancedate]);
  useEffect(() => {
    setSelectedRowKeyscheckup([]);
    const CheckupOngoingAPI = { ...CONSTANTS.API.getongoingcheckup };
    CheckupOngoingAPI.endpoint = CheckupOngoingAPI.endpoint + checkupdate;
    API.sendRequest(CheckupOngoingAPI, (res) => {
      if (checkupdate === dayjs().format("YYYY-MM-DD")) {
        onGoingCheckupsToday[0] = res?.assigned;
        onGoingCheckupsToday[1] = res?.total;
      }
      setOngoingCheckup(
        res?.data?.map((el, i) => ({
          key: el.id,
          no: i + 1,
          ...el.omRoutineCheckup,
          ...el,
        }))
      );
    });
  }, [checkupdate]);
  useEffect(() => {
    API.sendRequest(CONSTANTS.API.employee, (res) => {
      setAttendentEmp(
        res?.data?.rows.map((el, i) => ({
          ...el.employmentType,
          ...el,
          no: i + 1,
          key: el.id,
        }))
      );
      setEmpList(
        res?.data?.rows?.map((el) => ({
          id: el.id,
          value: el.id,
          Label: el.name,
        }))
      );
    });

    API.sendRequest(CONSTANTS.API.totalExpense, (res) => {
      setTotalExpense(res?.expanse);
    });
    API.sendRequest(CONSTANTS.API.InventoryDashboard, (res) => {
      setInventory(res?.data);
    });
    API.sendRequest(CONSTANTS.API.CleaningDashboard, (res) => {
      setCleaningLog(res);
    });
  }, []);
  if (attendancedate === dayjs().format("YYYY-MM-DD")) {
    attendanceemployeetoday = selectedRowKeysAttendence.length;
  }
  return (
    <Row gutter={[16, 16]}>
      <StatCard
        icon={AttendanceDashboardIcon}
        Text={
          <>
            {attendanceemployeetoday}
            <span className="text-span"> / {attendentEmp.length}</span>
          </>
        }
        desc={<>Attendance</>}
        onClick={() => {
          setAttendance(true);
        }}
      />
      {/* <ModalFormCreator
        open={attendance}
        onCreate={(value) => {
          console.log(value);
        }}
        onCancel={() => {
          setAttendance(false);
        }}
        name="Attendance"
        menu="ATTENDANCE_FORM"
      /> */}
      <Modal
        open={attendance}
        title={<p className="form-modal-head mb25">Attendance</p>}
        okText="Submit"
        width={1000}
        cancelButtonProps={{ style: { display: "none" } }}
        className="form-modal-title"
        onCancel={() => {
          setAttendancedate(dayjs().format("YYYY-MM-DD"));
          setAttendance(false);
        }}
        footer={[
          <Button
            key="submit"
            disabled={CONSTANTS.USER_ROLE}
            style={{
              borderRadius: "4px",
              height: "40px",
              width: "200px",
            }}
            type="primary"
            onClick={() => {
              setAttendance(false);
              const payload = {};
              payload.date = attendancedate;
              payload.ids = selectedRowKeysAttendence;
              API.sendRequest(
                CONSTANTS.API.updateemployeeattendance,
                () => {
                  setAttendancedate(dayjs().format("YYYY-MM-DD"));
                },
                payload,
                "Attendance updated"
              );
            }}
          >
            Submit
          </Button>,
        ]}
      >
        <Row>
          <Col span={12}>
            <Row align={"middle"}>
              <Col span={8}>
                <Label required={true}>Date</Label>
              </Col>
              <Col span={12} className="form">
                <DatePicker
                  style={{
                    width: "100%",
                  }}
                  disabledDate={(current) => current.isAfter(moment())}
                  value={dayjs(attendancedate)}
                  onChange={(value) => {
                    // console.log(value.format("YYYY-MM-DD"));
                    setAttendancedate(value.format("YYYY-MM-DD"));
                  }}
                />
              </Col>
              <Col span={4}></Col>
            </Row>
          </Col>
        </Row>
        <Table
          pagination={false}
          rowSelection={rowSelectionAttendence}
          columns={CONSTANTS.TABLE.ATTENDANCE_TABLE}
          dataSource={attendentEmp}
          scroll={{
            x: 900,
            y: 450,
          }}
        />
      </Modal>
      <StatCard
        icon={InventoryDashboardIcon}
        Text={
          <>
            <img src={RefreshDashboardIcon} alt="trueIcon" />
            &nbsp;{inventory?.total}
          </>
        }
        desc={
          <div
            className="an-14 text-title gray--text flex mb5"
            style={{ justifyContent: "space-between" }}
          >
            <p className="an-14 text-title gray--text "> Inventory</p>
            <p className="an-14 text-title gray--text pr15 flex center">
              <ImWarning style={{ color: "yellow", fontSize: "18px" }} />
              &nbsp;{inventory?.lessQuantity} &nbsp;&nbsp;
              <TbAlertCircle style={{ color: "red", fontSize: "20px" }} />
              &nbsp;{inventory?.zeroQuantity}
            </p>
          </div>
        }
        onClick={() => {
          window.location.assign("/app/inventory/details");
        }}
      />
      <StatCard
        icon={ExpensesDashboardIcon}
        // Text={`${0}`}
        Text={<>&#8377; {totalExpanse.toLocaleString()}</>}
        desc={<>Expenses</>}
        onClick={() => {
          window.location.assign("/app/expenses/detail");
        }}
      />
      <StatCard
        icon={CleaningLogDashboardIcon}
        onClick={() => {
          window.location.assign("/app/logBook/cleaningLog");
        }}
        Text={
          // <Button
          //   style={{ background: "#FAD050", backgroundBlendMode: "normal" }}
          // >
          //   Pending
          // </Button>
          <div style={{ display: "flex" }}>
            <p
              style={{
                color: `${!cleaningLog?.count ? "#FAD050" : "#2CC2A5"}`,
                display: "flex",
                lineHeight: "40px",
                height: "40px",
                paddingInline: "5px",
                marginTop: "-5px",
                marginBottom: "-5px",
                background: `${
                  !cleaningLog?.count ? "#FAD0502A" : "#2CC2A52A"
                }`,
                borderRadius: "5pt",
              }}
              className="mr15 my15 flex text-title an12"
            >
              {!cleaningLog?.count ? "Pending" : "Completed"}
            </p>
          </div>
        }
        desc="Cleaning log"
      />
      <StatCard
        icon={ChecklistDashboardIcon}
        Text={
          <>
            {onGoingCheckupsToday[0]}
            <span className="text-span"> / {onGoingCheckupsToday[1]}</span>
          </>
        }
        progress={+(onGoingCheckupsToday[0] * 100) / +onGoingCheckupsToday[1]}
        desc={
          <div
            className="an-14 text-title gray--text flex "
            style={{ justifyContent: "space-between" }}
          >
            <p className="an-14 text-title gray--text "> Checklist</p>
            <p className="an-14 text-title gray--text pr15 flex center">
              {(
                +(onGoingCheckupsToday[0] * 100) / +onGoingCheckupsToday[1]
              ).toFixed(0)}
              &nbsp; %
            </p>
          </div>
        }
        onClick={() => {
          setCheckupdate(dayjs().format("YYYY-MM-DD"));
          setSelectedRowKeyscheckup([]);
          setChecklist(true);
        }}
      />
      <Modal
        open={checklist}
        title={<p className="form-modal-head mb25">Checklist</p>}
        okText="Submit"
        width={1000}
        cancelButtonProps={{ style: { display: "none" } }}
        className="form-modal-title"
        onCancel={() => {
          setChecklist(false);
        }}
        footer={[
          <Button
            key="submit"
            style={{
              borderRadius: "4px",
              height: "40px",
              width: "200px",
            }}
            disabled={CONSTANTS.USER_ROLE}
            type="primary"
            onClick={() => {
              setChecklist(false);
              const payload = {};

              if (!selectedRowKeyscheckup.length) {
                return;
              }
              if (!selctedEmployee) {
                return notification.error({
                  message: "please select employee ",
                });
              }
              payload.employeeId = selctedEmployee;
              payload.ids = selectedRowKeyscheckup;
              payload.status = "Done";
              API.sendRequest(
                CONSTANTS.API.updategongoingcheckup,
                () => {
                  setOngoingCheckup((prev) =>
                    prev.filter(
                      (el) => !selectedRowKeyscheckup.includes(el.key)
                    )
                  );
                  onGoingCheckupsToday[0] += selectedRowKeyscheckup.length;
                  setCheckupdate(dayjs().format("YYYY-MM-DD"));
                  // setSelctedEmployee(null);
                  setSelectedRowKeyscheckup([]);
                },
                payload,
                "checkup updated"
              );
            }}
          >
            Submit
          </Button>,
        ]}
      >
        <Row>
          <Col span={12}>
            <Row align={"middle"}>
              <Col span={8}>
                <Label required={true}>Attended By</Label>
              </Col>
              <Col span={12} className="form">
                <Select
                  style={{
                    width: "100%",
                  }}
                  onSelect={(e) => {
                    // console.log(e, "employee");
                    setSelctedEmployee(e);
                  }}
                >
                  {EmpList.map((item) => (
                    <Select.Option key={`role_${item.id}`} value={item.value}>
                      {item.Label ? item.Label : item.value}
                    </Select.Option>
                  ))}
                </Select>
              </Col>
              <Col span={4}></Col>
            </Row>
          </Col>
          <Col span={12}>
            <Row align={"middle"}>
              <Col span={4}></Col>
              <Col span={8}>
                <Label required={true}>Date</Label>
              </Col>
              <Col span={12} className="form">
                <DatePicker
                  style={{
                    width: "100%",
                  }}
                  value={dayjs(checkupdate)}
                  disabledDate={(current) => current.isAfter(moment())}
                  onChange={(value) => {
                    // console.log(value.format("YYYY-MM-DD"));
                    setCheckupdate(value.format("YYYY-MM-DD"));
                  }}
                />
              </Col>
            </Row>
          </Col>
          <Col span={24}>
            <Table
              pagination={false}
              rowSelection={rowSelectionChecklist}
              columns={CONSTANTS.TABLE.ONGOING_CHECKUP_TABLE}
              dataSource={OngoingCheckup}
              scroll={{
                x: 1500,
                y: 450,
              }}
            />
          </Col>
        </Row>
      </Modal>
      <StatGraphCard
        icon={maintananceDashboardIcon}
        name={
          <>
            <ul className="an-14 text-title maintanance mb15 px15">
              <li>New </li>
              <li>Planned </li>
              <li>Complete </li>
              <li>In-Progress </li>
            </ul>
          </>
        }
        value={"Maintanance"}
        // graph={[
        //   {
        // type: "New",
        // value: 172,
        //   },
        //   {
        //     type: "Planned",
        //     value: 200,
        //   },
        //   {
        //     type: "In-Progress",
        //     value: 590,
        //   },
        //   {
        //     type: "Completed",
        //     value: 38,
        //   },
        // ]}
        graph={Object.keys(maintanance)
          // .filter((el) => maintanance[el])
          .map((el) => ({
            type: el,
            value: maintanance[el],
          }))}
      />
    </Row>
  );
});

export default HeadDashboard;
