import { Avatar, Button, Col, Popconfirm, Row, Upload, message } from "antd";
import React, { useEffect, useState } from "react";
import FormFields from "../../../../../component/common/FormFields";
import { useForm } from "antd/es/form/Form";
import moment from "moment";
import {
  ArrowLeftOutlined,
  PlusOutlined,
  QuestionCircleOutlined,
} from "@ant-design/icons";
import { MdOutlineModeEditOutline } from "react-icons/md";
import CONSTANTS from "../../../../../util/constant/CONSTANTS";
import useHttp from "../../../../../hooks/use-http";
import { useNavigate } from "react-router-dom";
import dayjs from "dayjs";

const EquipmentDetailTab = React.memo(({ InventoryData = {}, refresher }) => {
  // console.log("object3");
  const [form] = useForm();
  const [data, setData] = useState({});
  const [fileList, setFileList] = useState([]);
  const [edit, setEdit] = useState(false);
  const API = useHttp();
  const navigate = useNavigate();

  useEffect(() => {
    setData({
      ...InventoryData,
      doi: moment(InventoryData?.doi),
      doc: moment(InventoryData?.doc),
      warrantyExp: moment(InventoryData?.warrantyExp),
      maintananceDuration: dayjs(InventoryData?.maintananceDuration, "HH:mm"),
    });
  }, [InventoryData]);
  const onChange = ({ fileList: newFileList }) => {
    //  console.log("Preview", newFileList);
    if (
      newFileList
        .map((el) => el.size / 1024 / 1024 < 3)
        .findIndex((v) => v === false) === -1
    ) {
      setFileList(newFileList);
    }
  };
  // console.log(fileList, "file2");
  const changed = {};
  return (
    <Row className="dashboard-body">
      <Col span={20} className="dashboard-main-frame">
        <Row className="mb5 mt5">
          <p className="dashboard-body-title flex">{`  ${data?.sku || ""}`}</p>
        </Row>
        <Row className="dashboard-body">
          <Col span={24} className="mt10">
            <FormFields
              changedFields={changed}
              formData={data}
              form={form}
              menu="NEW_EQUIPMENT_FORM"
              formFields={[]}
            />
            <Row justify="end">
              <Col span={4} className="mx10">
                <Popconfirm
                  title="Delete the Equipment"
                  onConfirm={() => {
                    const DeleteEquipment = {
                      ...CONSTANTS.API.deleteEquipment,
                    };
                    DeleteEquipment.endpoint = DeleteEquipment.endpoint.replace(
                      ":id",
                      InventoryData.id
                    );
                    API.sendRequest(
                      DeleteEquipment,
                      () => {
                        navigate(-1);
                      },
                      "",
                      "Equipment Deleted Successfully"
                    );
                  }}
                  description="Are you sure to delete Equipment"
                  icon={
                    <QuestionCircleOutlined
                      style={{
                        color: "red",
                      }}
                    />
                  }
                >
                  <Button danger block>
                    Delete
                  </Button>
                </Popconfirm>
              </Col>
              <Col span={6} className="ml10">
                <Button
                  onClick={() => {
                    form
                      .validateFields()
                      .then((values) => {
                        console.log(data?.id);
                        const payload = new FormData();
                        // console.log(changed, "values");
                        // console.log(values, "form2");
                        values.doc = moment(values.doc.$d).format("YYYY-MM-DD");
                        values.doi = moment(values.doi.$d).format("YYYY-MM-DD");
                        values.warrantyExp = moment(
                          values.warrantyExp.$d
                        ).format("YYYY-MM-DD");
                        values.maintananceDuration =
                          values.maintananceDuration.format("HH:mm");

                        Object.keys(values).forEach((el) => {
                          payload.append(el, values[el]);
                        });

                        if (fileList.length) {
                          payload.append("image", fileList[0].originFileObj);
                        }
                        const APIpoint = { ...CONSTANTS.API.updateequipment };
                        APIpoint.endpoint = APIpoint.endpoint.replace(
                          ":id",
                          data.id
                        );
                        API.sendRequest(
                          APIpoint,
                          (res) => {
                            console.log(res, "updated");
                            refresher();
                          },
                          payload,
                          "Update Successfully"
                        );
                      })
                      .catch((info) => {
                        console.log("Validate Failed:", info);
                      });
                  }}
                  type="primary"
                  block
                  loading={API.isLoading}
                >
                  Submit
                </Button>
              </Col>
            </Row>
          </Col>
        </Row>
      </Col>
      <Col
        span={4}
        style={{
          display: "flex",
          justifyContent: "center",
        }}
      >
        {edit || !data?.image ? (
          <>
            <Row className="avtar-upload-parent">
              {/* <Col span={4}></Col>
                <Col span={12}> */}

              <Upload
                className="avtarUpload-uploader"
                name="avtarUpload"
                listType="picture-circle"
                fileList={fileList}
                accept=".jpg,.png,.jpeg"
                onChange={onChange}
                // onPreview={onPreview}
                beforeUpload={(file) => {
                  const isLt3M = file.size / 1024 / 1024 < 3;
                  if (!isLt3M) {
                    message.error("Image must smaller than 3MB!");
                    setFileList([]);
                  }
                  return isLt3M;
                }}
              >
                {fileList.length < 1 && (
                  <div>
                    <PlusOutlined className="flex" />
                    <div
                      style={{
                        marginTop: 8,
                      }}
                    >
                      Upload
                    </div>
                  </div>
                )}
              </Upload>
              {/* </Col> */}
            </Row>
            <Row>
              <Col span={6}>
                {fileList.length < 1 && data?.image && (
                  <ArrowLeftOutlined
                    onClick={() => {
                      setEdit(false);
                    }}
                  />
                )}
              </Col>
            </Row>
          </>
        ) : (
          <Row className="profile-pic-dashboard">
            <Row>
              <Avatar src={data?.image} size={140} />
            </Row>
            <Row className="edit-dashboard">
              <Row
                justify="center"
                align="middle"
                style={{
                  borderRadius: "100%",
                  height: "140px",
                  width: "140px",
                }}
              >
                <Col span={8}></Col>
                <Col span={12}>
                  <MdOutlineModeEditOutline
                    fontSize="25px"
                    color="white"
                    className="pointer"
                    onClick={() => {
                      console.log("got it");
                      setEdit(true);
                    }}
                  />
                </Col>
                {/* <Col span={8}>
                    <AiOutlineDelete
                      fontSize="25px"
                      className="pointer"
                      color="white"
                    />
                  </Col> */}
              </Row>
            </Row>
          </Row>
        )}
      </Col>
    </Row>
  );
});

export default EquipmentDetailTab;
