import { Col, Form, Row, notification } from "antd";
import React, { useEffect, useState } from "react";
import PageHeader from "../../../component/common/page-Header";
import ModalFormCreator from "../../../component/common/ModalFormCreator";
import useHttp from "../../../hooks/use-http";
import CONSTANTS from "../../../util/constant/CONSTANTS";
import moment from "moment";
import CustomTable from "../../../component/common/Custom-Table";
import FormWithButton from "../../../component/common/Form-with-Button";
import FormFields from "../../../component/common/FormFields";
import { DownloadOutlined } from "@ant-design/icons";

const Inventory = () => {
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 20,
    total: 0,
  });
  const [Allfilter, setAllFilter] = useState(null);
  const [dataInventory, setDataInventory] = useState([]);

  const [openSupplyIn, setOpenSupplyIn] = useState(false);
  const [openSupplyOut, setOpenSupplyOut] = useState(false);
  const [openEditRow, setOpenEditRow] = useState(false);
  const [dataOut, setDataOut] = useState([]);
  const [data, setData] = useState([]);
  const [options, setOptions] = useState([]);
  const [isDisabled, setIsDisabled] = useState(true);
  const [refresh, setRefresh] = useState(true);
  const [formIventoryData, setFormIventoryData] = useState({});

  const [SubmitValue, setSubmitValue] = useState({});

  const [formOutWord] = Form.useForm();
  const API = useHttp();
  const handleDelete = (key) => {
    // console.log(key);
    setDataOut((prev) => prev.filter((item) => item.key !== key));
    setOptions((prev) => {
      const item = data.find((el) => el.id === key);
      return [
        ...prev,
        {
          ...item,
          ...item.productCategory,
          id: item.id,
          value: item.id,
          Label: item.productName,
        },
      ];
    });
  };
  // const handleDeletePurchase = (key) => {
  //   // console.log(key);
  //   setPurchaseDataOut((prev) => prev.filter((item) => item.key !== key));
  // };
  const onCreate = (value) => {
    // console.log(value, dataOut);
    // console.log(value, "srydfdut");
    formOutWord
      .validateFields()
      .then((value) => {
        setSubmitValue(value);
        value.date = moment(value.date.$d).format("YYYY-MM-DD");
        value.products = dataOut.map((el) => ({
          id: el.id,
          quantity: el.quantity,
        }));
        if (dataOut.length) {
          API.sendRequest(
            CONSTANTS.API.createInventoryoutword,
            () => {
              // console.log(res);
              setRefresh((prev) => !prev);
              setSubmitValue({});
              setOpenSupplyOut(false);
              formOutWord.resetFields();
            },
            value,
            "Invetory outworded successfully"
          );
        } else {
          notification.error({ message: "Please select products" });
        }
      })
      .catch((info) => {
        console.log("Validate Failed:", info);
        return;
      });
  };

  const onInventoryInward = (value, clear) => {
    // console.log(value, "inward");
    setFormIventoryData({ ...value });
    value.date = moment(value.date.$d).format("YYYY-MM-DD");
    // console.log(value, "inward3");
    API.sendRequest(
      CONSTANTS.API.inventoryCreate,
      (res) => {
        // console.log(res);
        setData((prev) => [
          ...prev,
          { ...res?.data?.productCategory, ...res?.data, key: res?.data?.id },
        ]);
        setRefresh((prev) => !prev);
        setOpenSupplyIn(false);
        setFormIventoryData({});
        clear();
      },
      value,
      "inventory added"
    );
  };
  const onEditHandler = (value, clear) => {
    // console.log(value, formIventoryData);
    value.date = moment(value.date.$d).format("YYYY-MM-DD");
    delete value.name;
    delete value.type;
    delete value.unit;
    delete value.productCategoryId;
    const updateInventoryAPI = { ...CONSTANTS.API.inventoryUpdate };
    updateInventoryAPI.endpoint = updateInventoryAPI.endpoint.replace(
      ":id",
      formIventoryData.id
    );
    // console.log(updateInventoryAPI);
    API.sendRequest(
      updateInventoryAPI,
      () => {
        // console.log(res);
        clear();
        setOpenEditRow(false);
        setRefresh((prev) => !prev);
      },
      value,
      "inventory updated"
    );
  };

  useEffect(() => {
    API.sendRequest(CONSTANTS.API.searchProducts, (res) => {
      CONSTANTS.FORM_FIELD.SUPPLY_IN_INVENTORY[0].option = res?.data?.rows?.map(
        (el) => ({
          id: el.id,
          value: el.name,
          Label: el.name,
        })
      );
      CONSTANTS.FORM_FIELD.SUPPLY_IN_INVENTORY[0].onSelect = (val) => {
        const product = res?.data?.rows.find((el) => val === el?.name);
        console.log(product);
        setFormIventoryData(product);
      };
      CONSTANTS.FORM_FIELD.PURCHASE_REQUEST_INNER[0].option =
        res?.data?.rows?.map((el) => ({
          id: el.id,
          value: el.name,
          Label: el.name,
        }));
      CONSTANTS.FORM_FIELD.PURCHASE_REQUEST_INNER[0].onSelect = (val) => {
        const product = res?.data?.rows.find((el) => val === el?.name);
        setFormIventoryData({ ...product, product: product.name });
      };
    });
    API.sendRequest(CONSTANTS.API.inventory, (res) => {
      // console.log(res, "inventory");
      setPagination((prevPagination) => ({
        ...prevPagination,
        total: res?.data?.count,
      }));
      setData(
        res?.data?.rows?.map((el, i) => ({
          no: i + 1,
          ...el?.product,
          ...el?.product?.productCategory,
          productCategoryId: el?.product?.productCategory?.category,
          ...el,
          key: el?.id,
        }))
      );
      setOptions(
        res?.data?.rows?.map((el) => ({
          ...el,
          ...el?.productCategory,
          id: el?.id,
          value: el?.id,
          Label: (
            // <div className="flex-x space-between" style={{ width: "100%" }}>
            //   <p style={{ width: "78%", overflow: "hidden" }}>
            //     {el?.product?.name} &nbsp;&nbsp; ({el?.sku})
            //   </p>{" "}
            //   <p style={{ width: "20%" }}>
            //     {el?.quantity}- {el?.product?.unit}
            //   </p>
            // </div>
            <div style={{ display: "flex", alignItems: "center" }}>
              <p
                style={{
                  flex: "1",
                  width: "82%",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  whiteSpace: "nowrap",
                }}
              >
                ({el?.sku}) &nbsp;&nbsp; {el?.product?.name}
              </p>
              <p
                style={{
                  flex: "0 0 15%",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  whiteSpace: "nowrap",
                }}
              >
                {el?.quantity} - {el?.product?.unit}
              </p>
            </div>
          ),
        }))
      );

      CONSTANTS.FORM_FIELD.SUPPLY_OUT_INVENTORY_INNER[0].onSelect = (val) => {
        const product = res?.data?.rows.find((el) => val === el?.id);
        // console.log(product);
        setFormIventoryData({ ...product.product, productName: val });
      };
    });
  }, [refresh]);
  useEffect(() => {
    let QuaryParams = {
      limit: pagination.pageSize,
      page: pagination.current,
    };
    if (Allfilter?.sort) {
      QuaryParams = { ...QuaryParams, ...Allfilter?.sort };
    }
    if (Allfilter?.filter) {
      Allfilter?.filter?.map((el) => {
        QuaryParams = {
          ...QuaryParams,
          [`autogenerate-mul-array-$product.${el[0]}$`]: el[1],
        };
      });
    }
    API.sendRequest(
      CONSTANTS.API.inventory,
      (res) => {
        setPagination((prevPagination) => ({
          ...prevPagination,
          total: res?.data?.count,
        }));

        setDataInventory(
          res?.data?.rows?.map((el, i) => ({
            no: (pagination.current - 1) * pagination.pageSize + i + 1,
            ...el?.product,
            ...el?.product?.productCategory,
            productCategoryId: el?.product?.productCategory?.category,
            ...el,
            key: el?.id,
          }))
        );
      },
      QuaryParams
    );
  }, [pagination.current, pagination.pageSize, Allfilter, refresh]);

  useEffect(() => {
    API.sendRequest(CONSTANTS.API.getallMaintenanceRequests, (res) => {
      const filteredRequests = res?.data?.data
        .filter((el) => el.status !== "Completed" && el.status !== "Cancelled")
        .map((el) => ({
          id: el.id,
          value: el.id,
          Label: el.title,
        }));

      // purchase maintenance id
      CONSTANTS.FORM_FIELD.PURCHASE_REQUEST_FORM_NEW[0].item[1].option =
        filteredRequests;
      CONSTANTS.FORM_FIELD.PURCHASE_REQUEST_FORM[1].item[1].option =
        filteredRequests;

      // supply out maintenance id
      CONSTANTS.FORM_FIELD.SUPPLY_OUT_INVENTORY[1].item[0].option =
        filteredRequests;
    });

    API.sendRequest(CONSTANTS.API.employee, (res) => {
      CONSTANTS.FORM_FIELD.SUPPLY_OUT_INVENTORY[0].item[0].option =
        res?.data?.rows?.map((el) => ({
          id: el.name,
          value: el.name,
          Label: el.name,
        }));
    });

    API.sendRequest(CONSTANTS.API.productCategory, (res) => {
      CONSTANTS.FORM_FIELD.SUPPLY_IN_INVENTORY[2].item[1].option =
        res?.data?.rows?.map((el) => ({
          id: el.id,
          value: el.id,
          Label: el.category,
        }));
      CONSTANTS.TABLE.INVENTORY[5].filters = res?.data?.rows?.map((el) => ({
        value: el.id,
        text: el.category,
      }));
      CONSTANTS.FORM_FIELD.PURCHASE_REQUEST_INNER[1].option =
        res?.data?.rows?.map((el) => ({
          id: el.id,
          value: el.id,
          Label: el.category,
        }));
    });
  }, []);
  CONSTANTS.FORM_FIELD.SUPPLY_OUT_INVENTORY_INNER[0].option = options;
  const CSVData = [];
  CSVData[0] = CONSTANTS.TABLE.INVENTORY.map((el) => el.title);
  dataInventory.map((item, index) => {
    CSVData[index + 1] = CONSTANTS.TABLE.INVENTORY.map(
      (el) => item[el.dataIndex]
    );
    return 0;
  });
  return (
    <Row className="container-main">
      <PageHeader
        data={{
          buttons: [
            !CONSTANTS.USER_ROLE && {
              id: 1,
              action: () => {
                setOpenSupplyIn(true);
              },
              name: "Supply In",
              type: "normal",
            },
            !CONSTANTS.USER_ROLE && {
              id: 2,
              action: () => {
                // setOpen(true);
                setOpenSupplyOut(true);
              },
              name: "Supply Out",
              type: "normal",
            },
            // !CONSTANTS.USER_ROLE && {
            //   id: 3,
            //   action: () => {
            //     setOpenRequest(true);
            //   },
            //   name: "Request Form",
            //   type: "normal",
            // },
            {
              id: 4,
              action: () => {},
              icon: <DownloadOutlined />,
              data: CSVData,
              type: "linkicon",
              name:
                "Inventory" +
                ((pagination.current - 1) * pagination.pageSize + 1) +
                "-" +
                pagination.current * pagination.pageSize,
            },
          ],
          name: "",
        }}
        pagination={pagination}
        setPagination={setPagination}
        SearchBar={{
          endpointAPI: "/inventoryinward?search=",
          dataResponseHandle: (res) => {
            return res?.data?.rows?.map((el) => ({
              ...el.product,
              ...el.product.productCategory,
              ...el,
              key: el?.id,
            }));
          },
          onValueIsSelected: (row) => {
            setFormIventoryData({ ...row });
            setOpenEditRow(true);
          },
          extraLabel: "name",
          onBaseSearch: ["sku"],
          debounceTime: 500,
        }}
      />
      <ModalFormCreator
        open={openSupplyIn}
        onCreate={onInventoryInward}
        onCancel={() => {
          setOpenSupplyIn(false);
          setFormIventoryData({});
        }}
        formData={formIventoryData}
        name="INVENTORY FORM (INWARD)"
        menu="SUPPLY_IN_INVENTORY"
        loading={API.isLoading}
      />

      <ModalFormCreator
        open={openEditRow}
        onCreate={onEditHandler}
        onCancel={() => {
          setOpenEditRow(false);
          setIsDisabled(true);
          setFormIventoryData({});
        }}
        disabled={isDisabled}
        edit
        onEdit={() => {
          // console.log("edit click");
          setIsDisabled(false);
        }}
        Delete
        onDelete={() => {
          // console.log("Delete Click", formIventoryData);
          const DeleteInventoryAPI = { ...CONSTANTS.API.inventoryDelete };
          DeleteInventoryAPI.endpoint = DeleteInventoryAPI.endpoint.replace(
            ":id",
            formIventoryData.id
          );
          API.sendRequest(
            DeleteInventoryAPI,
            () => {
              // console.log(res);
              setRefresh((prev) => !prev);
              setOpenEditRow(false);
            },
            "Deleted Successfully"
          );
        }}
        name="INVENTORY DETAILS"
        menu="SUPPLY_IN_INVENTORY"
        formData={formIventoryData}
      />
      <ModalFormCreator
        open={openSupplyOut}
        onCreate={onCreate}
        onCancel={() => {
          setOpenSupplyOut(false);
          setDataOut([]);
        }}
        name="INVENTORY FORM (OUTWARD)"
        menu="SUPPLY_OUT_INVENTORY"
        inbuilt={false}
        loading={API.isLoading}
      >
        <FormFields
          formData={SubmitValue}
          menu={"SUPPLY_OUT_INVENTORY"}
          form={formOutWord}
        />

        <CustomTable
          dataSource={dataOut}
          name="SUPPLY_OUT_INVENTORY_TABLE_INNER"
          scrollAutoOff={true}
          Other={{ rowSelection: null }}
          scroll={{
            y: "20vh",
          }}
        />
        <FormWithButton
          menu={"SUPPLY_OUT_INVENTORY_INNER"}
          name="Add"
          buttonwidth={3}
          formData={formIventoryData}
          onCreate={(element) => {
            setFormIventoryData({});
            const el = data.find((e) => e.id === element.productName);
            if (element.quantity > el.quantity || !element.quantity) {
              notification.error({
                message: `enter ${
                  !element.quantity ? "valid " : "less"
                }  quantity `,
              });
              return;
            }
            // console.log(el, "data");
            setOptions((prev) => prev.filter((ele) => ele.id !== el.id));
            setDataOut((prev) => [
              ...prev,
              {
                productName: el.name,
                key: el.id,
                ...el,
                quantity: element.quantity,
                delete: {
                  id: el.id,
                  key: el.id,
                  onClick: handleDelete,
                },
              },
            ]);
          }}
        />
      </ModalFormCreator>
      <Col
        span={24}
        style={{
          marginBlock: "15px",
        }}
        className="container-body"
      >
        <CustomTable
          // dataSource={data.slice(
          //   (pagination?.current - 1) * pagination?.pageSize,
          //   (pagination?.current - 1) * pagination?.pageSize +
          //     pagination?.pageSize
          // )}
          dataSource={dataInventory}
          name="INVENTORY"
          DeleteSelectedRow
          isLoading={API.isLoading}
          APIendpoint="inventoryDelete"
          onConfirm={() => {
            setRefresh((prev) => !prev);
          }}
          Other={{
            onRow: (row) => ({
              onClick: () => {
                // console.log("Row", row);
                setFormIventoryData({ ...row });
                setOpenEditRow(true);
              },
            }),
          }}
          setChanges={(v) => {
            setAllFilter(v);
            setPagination((prev) => ({
              ...prev,
              current: 1,
            }));
          }}
        />
      </Col>
    </Row>
  );
};

export default Inventory;
{
  /* <ModalFormCreator
        open={openRequest}
        onCreate={onRequestCreate}
        changedareFormdata={true}
        onCancel={() => {
          setOpenRequest(false);
          setPurchaseDataOut([]);
          setFormIventoryData({});
          setSubmitValue({});
        }}
        loading={API.isLoading}
        inbuilt={false}
        name="PURCHASE requesition form"
        menu="PURCHASE_REQUEST_FORM_NEW"
      >
        <FormFields
          formData={SubmitValue}
          menu={"PURCHASE_REQUEST_FORM_NEW"}
          form={formPurchase}
        />
        <CustomTable
          dataSource={purchaseDataOut}
          name="PURCHASE_REQUEST_TABLE_INNER"
          Other={{ rowSelection: null }}
          // scroll={{
          //   y: "20vh",
          // }}
        />
        <FormWithButton
          menu={"PURCHASE_REQUEST_INNER"}
          name="Add"
          buttonwidth={2}
          formData={formIventoryData}
          onCreate={(element) => {
            // console.log(element, "item");
            setFormIventoryData({});
            element.productcategory =
              CONSTANTS.FORM_FIELD.PURCHASE_REQUEST_INNER[1].option.find(
                (el) => el.id === element.productCategoryId
              ).Label;
            setPurchaseDataOut((prev) => [
              ...prev,
              {
                key: prev.length ? prev[prev.length - 1].id + 1 : 1,
                id: prev.length ? prev[prev.length - 1].id + 1 : 1,
                ...element,
                delete: {
                  id: prev.length ? prev[prev.length - 1].id + 1 : 1,
                  key: prev.length ? prev[prev.length - 1].id + 1 : 1,
                  onClick: handleDeletePurchase,
                },
              },
            ]);
          }}
        />
      </ModalFormCreator> */
}

// const onRequestCreate = (value) => {
//   // console.log(formPurchase.getFieldsValue());
//   formPurchase
//     .validateFields()
//     .then((value) => {
//       // console.log(value, "data", purchaseDataOut);
//       setSubmitValue(value);
//       delete value.name;
//       value.products = purchaseDataOut.map((el) => ({
//         name: el.product,
//         quantity: el.quantity,
//         type: el.type,
//         unit: el.unit,
//         productCategoryId: el.productCategoryId,
//       }));
//       value.date = moment(value.date.$d).format("YYYY-MM-DD");
//       API.sendRequest(
//         CONSTANTS.API.purchaserequesition,
//         () => {
//           setPurchaseDataOut([]);
//           // console.log(res, "purchase");
//           formPurchase.resetFields();
//           setSubmitValue({});
//           setOpenRequest(false);
//         },
//         value,
//         "purchase requested"
//       );
//     })
//     .catch((info) => {
//       console.log("Validate Failed:", info);
//       return;
//     });
// };
