import React, { useEffect, useState } from "react";
import { Col, Form, Row, Spin, notification } from "antd";
import PageHeader from "../../../../component/common/page-Header";
import { DownloadOutlined } from "@ant-design/icons";
import CONSTANTS from "../../../../util/constant/CONSTANTS";
import useHttp from "../../../../hooks/use-http";
import ModalFormCreator from "../../../../component/common/ModalFormCreator";
import Board, { moveCard } from "@asseinfo/react-kanban";
import dayjs from "dayjs";
import MaintananceCard from "../../../../component/card/Maintanance-Card";
import CustomTable from "../../../../component/common/Custom-Table";
import FormWithButton from "../../../../component/common/Form-with-Button";
import KanbanLabel from "../../../../component/common/Kanban-Label";
import moment from "moment";
import FormFields from "../../../../component/common/FormFields";
const Category = ["New", "Planned", "Inprogress", "Completed", "Cancelled"];

const KanbanBoardMaintanance = () => {
  const [controlledBoard, setBoard] = useState({
    columns: [],
  });
  const changedFields = {};
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 30,
    total: 0,
  });
  const [cardData, setCardData] = useState({});
  const [data, setData] = useState([]);
  const [onMaintananceRequest, setOnMaintananceRequest] = useState(false);
  const [refresh, setRefresh] = useState(true);

  const [openEditRow, setOpenEditRow] = useState(false);
  const [formInventoryData, setFormInventoryData] = useState({});
  const [isDisabled, setIsDisabled] = useState(true);

  const [resolutionModal, setResolutionModal] = useState(false);
  const [resolutionProducts, setResolutionProducts] = useState([]);
  const [options, setOptions] = useState([]);
  const [products, setProducts] = useState(true);
  const [optionData, setOptionData] = useState([]);
  const [afterResolveData, setAfterResolveData] = useState({});

  const [SubmitValue, setSubmitValue] = useState({});
  const [formResolution] = Form.useForm();

  const API = useHttp();
  const handleDelete = (key) => {
    setResolutionProducts((prev) => prev.filter((item) => item.key !== key));
    setOptions((prev) => {
      const item = optionData.find((el) => el.id === key);
      return [
        {
          ...item.option,
        },
        ...prev,
      ];
    });
  };

  const onMaintananceResolution = (value) => {
    // console.log(resolutionProducts);
    formResolution
      .validateFields()
      .then((value) => {
        setSubmitValue(value);
        value.products = resolutionProducts.map((el) => ({
          productId: el.id,
          quantity: el.quantity,
          usageType: el.usageType,
        }));
        // console.log(formInventoryData, afterResolveData);
        // debugger;
        if (afterResolveData?.status === "Completed") {
          value.maintenanceRequestId = formInventoryData.id;
        } else {
          value.maintenanceRequestId = cardData?._card?.id;
        }
        // console.log(value);
        API.sendRequest(
          CONSTANTS.API.createmaintenanceResolution,
          () => {
            if (afterResolveData?.status === "Completed") {
              const updateMaintenance = {
                ...CONSTANTS.API.updatemaintenanceRequests,
              };
              updateMaintenance.endpoint = updateMaintenance.endpoint.replace(
                ":id",
                formInventoryData.id
              );
              API.sendRequest(
                updateMaintenance,
                (res) => {
                  setOpenEditRow(false);
                  setFormInventoryData({});
                  setRefresh((prev) => !prev);
                  setResolutionModal(false);
                  setProducts((prev) => !prev);
                  setResolutionProducts([]);
                  setSubmitValue({});
                },
                afterResolveData,
                "maintenance request updated"
              );
            } else {
              CardChangeEvent(
                cardData?._card,
                cardData?.source,
                cardData?.destination
              );
              setResolutionModal(false);
              setProducts((prev) => !prev);
              setResolutionProducts([]);
              setSubmitValue({});
            }
          },
          value,
          "Maintanance resolved successfully"
        );
      })
      .catch((e) => {
        console.log(e);
        return;
      });
  };

  useEffect(() => {
    let QuaryParams = {
      limit: pagination.pageSize,
      page: pagination.current,
    };
    API.sendRequest(
      CONSTANTS.API.getallMaintenanceRequests,
      (res) => {
        setPagination((prevPagination) => ({
          ...prevPagination,
          total: res?.data?.Total,
        }));
        const sortedData = res?.data?.data?.sort((a, b) => {
          const priorityOrder = { Critical: 1, Major: 2, Minor: 3 };
          return priorityOrder[a?.prioriti] - priorityOrder[b?.prioriti];
        });
        setBoard({
          columns: Category.map((el) => ({
            cards:
              sortedData
                ?.filter((element) => element?.status === el)
                .map((el) => ({
                  ...el,
                  maintenanceResolutions: [
                    ...new Set(
                      el?.maintenanceResolutions?.map((el) => el?.action)
                    ),
                  ]
                    ?.map((ele) =>
                      el?.maintenanceResolutions?.filter(
                        (el) => el?.action === ele
                      )
                    )
                    ?.map((el) => ({
                      action: el[0]?.action,
                      createdAt: el[0]?.createdAt,
                      date: el[0]?.date,
                      products: el?.map((p) => ({
                        ...p,
                        category: p?.product?.productCategory?.category,
                        ...p?.product,
                        quantity: p?.quantity,
                        sku: p?.product?.inventoryInwards?.[0]?.sku,
                        srNumber: p?.product?.inventoryInwards?.[0]?.trackingId,
                      })),
                    })),
                })) || [],
            id: el,
            title: (
              <KanbanLabel
                count={res?.data[el] || "0"}
                name={el}
                className="an-30 px5"
              />
            ),
          })),
        });
        setData(
          sortedData?.map((el) => ({
            ...el,
            key: el.id,
            creatorName: el?.creator?.name,
            assignedName: el?.assigned?.name,
            equipmentName: el?.equipment?.name,
            equipmentModal: el?.equipment?.model,
            equipmentSrNumber: el?.equipment?.srNumber,
            maintenanceResolutions: [
              ...new Set(el?.maintenanceResolutions?.map((el) => el?.action)),
            ]
              ?.map((ele) =>
                el?.maintenanceResolutions?.filter((el) => el?.action === ele)
              )
              ?.map((el) => ({
                action: el[0]?.action,
                createdAt: el[0]?.createdAt,
                products: el?.map((p) => ({
                  category: p?.product?.productCategory?.category,
                  ...p?.product,
                  quantity: p?.quantity,
                  sku: p?.product?.inventoryInwards?.[0]?.sku,
                  srNumber: p?.product?.inventoryInwards?.[0]?.trackingId,
                })),
              })),
          }))
        );
      },
      QuaryParams
    );
  }, [refresh, pagination.current, pagination.pageSize]);
  useEffect(() => {
    API.sendRequest(CONSTANTS.API.employee, (res) => {
      const options = res?.data?.rows?.map((el) => ({
        id: el.id,
        value: el.id,
        Label: el.name,
      }));
      CONSTANTS.FORM_FIELD.MAINTENANCE_REQUEST_FORM[1].item[0].option = options;
      CONSTANTS.FORM_FIELD.MAINTENANCE_REQUEST_FORM[1].item[1].option = options;
      CONSTANTS.FORM_FIELD.MAINTENANCE_REQUEST_FORM_NEW[1].item[0].option =
        options;
      CONSTANTS.FORM_FIELD.MAINTENANCE_REQUEST_FORM_NEW[1].item[1].option =
        options;
    });
    API.sendRequest(CONSTANTS.API.AllEquipment, (res) => {
      const options = res?.data?.rows.map((el) => ({
        id: el.id,
        value: el.id,
        Label: el.name,
      }));
      CONSTANTS.FORM_FIELD.MAINTENANCE_REQUEST_FORM[2].item[0].option = options;
      CONSTANTS.FORM_FIELD.MAINTENANCE_REQUEST_FORM_NEW[2].item[0].option =
        options;
    });
  }, []);
  useEffect(() => {
    API.sendRequest(CONSTANTS.API.inventory, (res) => {
      // console.log(res, "inventory");
      const OptionDataCreation = res?.data?.rows
        ?.map((el) => ({
          ...el,
          ...el.product,
          // key: el?.id,
          option: {
            ...el,
            ...el.product,
            // id: el.id,
            value: el.product.id + "-" + el?.sku,
            Label: (
              <div style={{ display: "flex", alignItems: "center" }}>
                <p
                  style={{
                    flex: "1",
                    width: "78%",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    whiteSpace: "nowrap",
                  }}
                >
                  ({el?.sku}) &nbsp;&nbsp; {el?.product?.name}
                </p>
                <p
                  style={{
                    flex: "0 0 20%",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    whiteSpace: "nowrap",
                  }}
                >
                  {el?.quantity} - {el?.product?.unit}
                </p>
              </div>
            ),
          },
        }))
        ?.filter((el) => el?.quantity);
      setOptionData(OptionDataCreation);
      console.log(OptionDataCreation, "hello operation");
      setOptions(OptionDataCreation?.map((el) => ({ ...el?.option })));
      // CONSTANTS.FORM_FIELD.RESOLUTION_FORM_INNER[0].option =
      //   OptionDataCreation?.map((el) => ({ ...el?.option }));
    });
  }, [products]);
  // }, []);
  CONSTANTS.FORM_FIELD.RESOLUTION_FORM_INNER[0].option = options;
  const onEditHandler = (value, clear) => {
    // value.duration = value.duration.format("HH:mm");
    setAfterResolveData({ ...value });
    if (changedFields?.status === "Completed") {
      setResolutionModal(true);
      clear();
      return;
    }
    const updateMaintenance = { ...CONSTANTS.API.updatemaintenanceRequests };
    updateMaintenance.endpoint = updateMaintenance.endpoint.replace(
      ":id",
      formInventoryData.id
    );
    API.sendRequest(
      updateMaintenance,
      (res) => {
        setOpenEditRow(false);
        setFormInventoryData({});
        setRefresh((prev) => !prev);
        clear();
      },
      value,
      "maintenance request updated"
    );
  };

  const CardChangeEvent = (_card, source, destination) => {
    if (CONSTANTS.USER_ROLE) {
      notification.error({ message: "You are not authorized !!" });
      return;
    }
    const APIUpdateRequest = { ...CONSTANTS.API.updatemaintenanceRequests };
    APIUpdateRequest.endpoint = APIUpdateRequest.endpoint.replace(
      ":id",
      _card?.id
    );
    API.sendRequest(
      APIUpdateRequest,
      () => {
        // const updatedBoard = moveCard(controlledBoard, source, destination);
        // const item = updatedBoard?.columns
        //   ?.find((el) => el.id === destination.toColumnId)
        //   .cards?.find((el) => el.id === _card?.id);
        // item.status = destination.toColumnId;
        // setBoard(updatedBoard);
        // setCardData({});
        setRefresh((prev) => !prev);
      },
      {
        status: destination.toColumnId,
      }
    );
  };

  const ControlledBoard = React.memo(() => {
    const handleCardMove = (_card, source, destination) => {
      // console.log(source, destination, _card);
      if (source.fromColumnId === destination.toColumnId) {
        const updatedBoard = moveCard(controlledBoard, source, destination);
        setBoard(updatedBoard);

        return;
      }
      // if (source.fromColumnId === "Completed") {
      //   return;
      // }
      if (destination.toColumnId === "Completed") {
        setResolutionModal(true);
        setCardData({
          _card,
          source,
          destination,
        });
        return;
      }
      CardChangeEvent(_card, source, destination);
    };

    return (
      <>
        <Board
          onCardDragEnd={handleCardMove}
          disableColumnDrag
          renderCard={(e) => (
            <div
              style={{
                width: "300px",
                padding: "5px",
              }}
            >
              <MaintananceCard
                data={e}
                onCardClick={() => {
                  setFormInventoryData({
                    ...e,
                    duration: dayjs(e.duration, "HH:mm"),
                  });
                  setOpenEditRow(true);
                }}
              />
            </div>
          )}
        >
          {controlledBoard}
        </Board>
      </>
    );
  });

  const CSVData = [];
  CSVData[0] = CONSTANTS.TABLE.MAINTENANCE_REQUEST_TABLE.map((el) => el.title);
  data?.map((item, index) => {
    CSVData[index + 1] = CONSTANTS.TABLE.MAINTENANCE_REQUEST_TABLE.map(
      (el) => item[el.dataIndex]
    );
    return 0;
  });
  return (
    <Row className="container-main">
      <ModalFormCreator
        loading={API.isLoading}
        open={onMaintananceRequest}
        onCreate={(value) => {
          // value.duration = value.duration.format("HH:mm");
          API.sendRequest(
            CONSTANTS.API.createmaintenanceRequests,
            (res) => {
              setRefresh((prev) => !prev);
              setOnMaintananceRequest(false);
            },
            value,
            "maintenance requested successfully"
          );
        }}
        onCancel={() => {
          setOnMaintananceRequest(false);
        }}
        name="Maintenance request form ( ptw )"
        menu="MAINTENANCE_REQUEST_FORM_NEW"
      />
      <PageHeader
        data={{
          buttons: [
            !CONSTANTS.USER_ROLE && {
              id: 1,
              action: () => {
                console.log("button1");
                setOnMaintananceRequest(true);
              },
              name: "New",
            },
            {
              id: 2,
              action: () => {
                console.log("button3");
                console.log("download", CSVData);
              },
              icon: <DownloadOutlined />,
              data: CSVData,
              type: "linkicon",
              name: "Maintanance-" + moment().format("YYYY-MM-DD HH:mm"),
            },
          ],
          name: "",
        }}
        // page={{
        //   action: {
        //     left: () => {
        //       // if (transferDisplay > 0) {
        //       //   setTransferDisplay((prev) => prev - 10);
        //       // }
        //     },
        //     right: () => {
        //       // if (transferDisplay + 10 < data.length) {
        //       //   setTransferDisplay((prev) => prev + 10);
        //       // }
        //     },
        //   },
        //   page: {
        //     // start: transferDisplay + 1,
        //     // end:
        //     //   transferDisplay + 10 < data.length
        //     //     ? transferDisplay + 10
        //     //     : data.length,
        //     // total: data?.length,
        //   },
        // }}
        pagination={pagination}
        setPagination={setPagination}
        SearchBar={{
          endpointAPI: "/maintenancerequest?search=",
          extraLabel: "title",
          dataResponseHandle: (res) => {
            return res?.data?.data.map((el) => ({
              ...el,
              key: el.id,
              creatorName: el?.creator?.name,
              assignedName: el?.assigned?.name,
              equipmentName: el?.equipment?.name,
              equipmentModal: el?.equipment?.model,
              equipmentSrNumber: el?.equipment?.srNumber,
              maintenanceResolutions: [
                ...new Set(el?.maintenanceResolutions?.map((el) => el?.action)),
              ]
                ?.map((ele) =>
                  el?.maintenanceResolutions?.filter((el) => el?.action === ele)
                )
                ?.map((el) => ({
                  action: el[0]?.action,
                  createdAt: el[0]?.createdAt,
                  products: el?.map((p) => ({
                    ...p,
                    category: p?.product?.productCategory?.category,
                    ...p?.product,
                    quantity: p?.quantity,
                    sku: p?.product?.inventoryInwards?.[0]?.sku,
                    srNumber: p?.product?.inventoryInwards?.[0]?.trackingId,
                  })),
                })),
            }));
          },
          onValueIsSelected: (row) => {
            setFormInventoryData({
              ...row,
              duration: dayjs(row.duration, "HH:mm"),
            });
            setOpenEditRow(true);
          },
          onBaseSearch: ["sku"],
          debounceTime: 500,
        }}
      />
      <ModalFormCreator
        open={openEditRow}
        loading={API.isLoading}
        onCreate={onEditHandler}
        onCancel={() => {
          setOpenEditRow(false);
          setIsDisabled(true);
        }}
        disabled={isDisabled}
        edit
        onEdit={() => {
          setIsDisabled(false);
        }}
        Delete
        onDelete={() => {
          const DeleteMaintenanceRequest = {
            ...CONSTANTS.API.deletemaintenanceRequests,
          };
          DeleteMaintenanceRequest.endpoint =
            DeleteMaintenanceRequest.endpoint.replace(
              ":id",
              formInventoryData.id
            );
          API.sendRequest(
            DeleteMaintenanceRequest,
            (res) => {
              setRefresh((prev) => !prev);
              setOpenEditRow(false);
            },
            "Deleted Successfully"
          );
        }}
        name={`edit Maintenance Request (${formInventoryData?.sku || ""})`}
        DeleteTitle={`Maintenance Request (${formInventoryData?.sku || ""})`}
        menu="MAINTENANCE_REQUEST_FORM"
        formData={formInventoryData}
        changedFields={changedFields}
      >
        {formInventoryData?.maintenanceResolutions?.map((el, i) => (
          <div className="mb15" key={el.products.id}>
            <div
              className="form-modal-head an-20 mb5"
              style={{ display: "flex", justifyContent: "space-between" }}
            >
              <p className="form-modal-head an-20 mb5">{`Resolution - ${
                i + 1
              }`}</p>
              <p className="form-modal-head an-15 mb5">
                {/* {moment(el?.createdAt).format("YYYY-MM-DD HH:mm")} */}
                {moment(el?.date).format("YYYY-MM-DD HH:mm")}
                {/* {dayjs(moment(el?.createdAt).format("YYYY-MM-DD HH:mm")).from(
                  dayjs(formInventoryData?.date)
                )} */}
              </p>
            </div>

            <p className="an-15 mb5">{el?.action}</p>
            {el?.products?.[0]?.product && (
              <CustomTable
                name="MAINTENANCE_RESOLUTION_TABLE"
                dataSource={el.products}
                scrollAutoOff={true}
                Other={{ rowSelection: null }}
              />
            )}
          </div>
        ))}
      </ModalFormCreator>
      <ModalFormCreator
        loading={API.isLoading}
        open={resolutionModal}
        onCreate={onMaintananceResolution}
        onCancel={() => {
          setResolutionModal(false);
          setResolutionProducts([]);
          setProducts((prev) => !prev);
          setResolutionProducts([]);
        }}
        name="MAINTENANCE RESOLUTION"
        menu="MAINTENANCE_RESOLUTION_FORM"
        inbuilt={false}
      >
        <FormFields
          formData={SubmitValue}
          menu={"MAINTENANCE_RESOLUTION_FORM"}
          form={formResolution}
        />
        <CustomTable
          dataSource={resolutionProducts}
          name="RESOLUTION_TABLE_INNER"
          // onChange={(selectedRowKeys, selectedRows) => {
          //   setProducts(selectedRows);
          // }}
        />
        <FormWithButton
          menu={"RESOLUTION_FORM_INNER"}
          name="Add"
          buttonwidth={2}
          onCreate={(element) => {
            // console.log(element);
            // console.log(optionData);
            const el = optionData.find(
              (e) => e.sku === element.productName.split("-")[1]
            );
            // console.log(el);
            if (element.quantity > el?.quantity || !element?.quantity) {
              notification.error({
                message: `enter ${
                  !element.quantity ? "valid " : "less"
                }  quantity `,
              });
              return;
            }
            // console.log(el);

            setOptions((prev) =>
              prev.filter(
                (ele) => ele.sku !== element.productName.split("-")[1]
              )
            );
            setResolutionProducts((prev) => {
              if (prev?.findIndex((elFind) => elFind?.sku === el?.sku) !== -1) {
                notification.error({ message: "Product is already added" });
                return prev;
              }
              return [
                ...prev,
                {
                  ...element,
                  key: el?.id,
                  ...el,
                  productName: el?.product?.name,
                  quantity: element?.quantity,
                  delete: {
                    id: el?.id,
                    key: el?.id,
                    onClick: handleDelete,
                  },
                },
              ];
            });
          }}
        />
      </ModalFormCreator>
      <Col
        span={24}
        style={{
          marginBlock: "15px",
        }}
        className="container-body"
      >
        {!API.isLoading ? (
          <ControlledBoard />
        ) : (
          <Spin className="flex-x center" size="large" />
        )}
      </Col>
    </Row>
  );
};

export default KanbanBoardMaintanance;
