import axios from "axios";
const Services = axios.create({
  baseURL:
    // process.env.REACT_APP_BASE_URL || "https://api-dev.kpigreenoms.com/api/v1", //staging
    process.env.REACT_APP_BASE_URL || "https://api.kpigreenoms.com/api/v1", //production
  // timeout: 10000,
  headers: {
    Accept: "application/json",
    Authorization: `Bearer ${
      document.cookie ? document.cookie.replace("SAID=", "") : null
    }`,
  },
});

export default Services;
