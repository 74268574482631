import React, { useEffect, useState } from "react";
import { Col, Form, Row, notification } from "antd";
import PageHeader from "../../../../component/common/page-Header";
import { DownloadOutlined } from "@ant-design/icons";
import CustomTable from "../../../../component/common/Custom-Table";
import CONSTANTS from "../../../../util/constant/CONSTANTS";
import useHttp from "../../../../hooks/use-http";
import ModalFormCreator from "../../../../component/common/ModalFormCreator";
import moment from "moment";
import FormWithButton from "../../../../component/common/Form-with-Button";
import FormFields from "../../../../component/common/FormFields";

const Transfer = () => {
  const [data, setData] = useState([]);
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 20,
    total: 0,
  });
  const [Allfilter, setAllFilter] = useState(null);
  const [onTransfer, setOnTransfer] = useState(false);
  const [dataInventory, setDataInventory] = useState([]);
  const [refresh, setRefresh] = useState(false);
  const [options, setOptions] = useState([]);
  const [optionData, setOptionData] = useState([]);
  const [openEditRow, setOpenEditRow] = useState(false);
  const [formIventoryData, setFormIventoryData] = useState({});
  const [isDisabled, setIsDisabled] = useState(true);
  const [SubmitValue, setSubmitValue] = useState({});
  const [formOutWord] = Form.useForm();
  const API = useHttp();
  const handleDelete = (key) => {
    setDataInventory((prev) => prev.filter((item) => item.key !== key));
    setOptions((prev) => {
      const item = optionData.find((el) => el.id === key);
      return [
        {
          ...item.option,
        },
        ...prev,
      ];
    });
  };
  const onInventoryTransfer = (value) => {
    // console.log(dataInventory);
    formOutWord
      .validateFields()
      .then((value) => {
        setSubmitValue(value);
        value.date = moment(value.date.$d).format("YYYY-MM-DD");
        value.products = dataInventory.map((el) => ({
          id: el.id,
          quantity: el.quantity,
        }));
        // console.log(value);
        if (dataInventory.length) {
          API.sendRequest(
            CONSTANTS.API.createInventoryoutword,
            (res) => {
              // console.log(res);
              setOnTransfer(false);
              setRefresh((prev) => !prev);
            },
            value,
            "Invetory Transfered successfully"
          );
        } else {
          notification.error({ message: "Please select products" });
        }
      })
      .catch((info) => {
        console.log("Validate Failed:", info);
        return;
      });
  };
  useEffect(() => {
    let QuaryParams = {
      limit: pagination.pageSize,
      page: pagination.current,
    };
    if (Allfilter?.sort) {
      QuaryParams = { ...QuaryParams, ...Allfilter?.sort };
    }
    if (Allfilter?.filter) {
      Allfilter?.filter?.map((el) => {
        QuaryParams = {
          ...QuaryParams,
          [`autogenerate-mul-array-${el[0]}`]: el[1],
        };
      });
    }
    const TransferPage = { ...CONSTANTS.API.TransferInventory };
    TransferPage.endpoint = `${TransferPage.endpoint}`;
    API.sendRequest(
      TransferPage,
      (res) => {
        setPagination((prevPagination) => ({
          ...prevPagination,
          total: res?.count,
        }));
        setData(
          res?.data?.map((el, i) => ({
            ...el,
            ...el.inventoryInward,
            no: (pagination.current - 1) * pagination.pageSize + i + 1,
            key: i,
          }))
        );
        setPagination((prevPagination) => ({
          ...prevPagination,
          total: res?.count,
        }));
      },
      QuaryParams
    );
  }, [pagination.current, pagination.pageSize, Allfilter, refresh]);

  useEffect(() => {
    API.sendRequest(CONSTANTS.API.inventory, (res) => {
      // console.log(res, "inventory");
      const OptionDataCreation = res?.data?.rows
        ?.map((el) => ({
          ...el.product,
          ...el,
          id: el?.id,
          value: el?.id,
          key: el?.id,
          option: {
            ...el.product,
            ...el,
            // id: el.id,
            value: el.id + "-" + el?.sku,
            Label: (
              <div style={{ display: "flex", alignItems: "center" }}>
                <p
                  style={{
                    flex: "1",
                    width: "78%",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    whiteSpace: "nowrap",
                  }}
                >
                  ({el?.sku}) &nbsp;&nbsp; {el?.product?.name}
                </p>
                <p
                  style={{
                    flex: "0 0 20%",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    whiteSpace: "nowrap",
                  }}
                >
                  {el?.quantity} - {el?.product?.unit}
                </p>
              </div>
            ),
          },
        }))
        ?.filter((el) => el?.quantity);
      setOptionData(OptionDataCreation);
      setOptions(OptionDataCreation?.map((el) => ({ ...el?.option })));
    });

    API.sendRequest(CONSTANTS.API.getallMaintenanceRequests, (res) => {
      const filteredRequests = res?.data?.data
        .filter((el) => el.status !== "Completed" && el.status !== "Cancelled")
        .map((el) => ({
          id: el.id,
          value: el.id,
          Label: el.title,
        }));

      // purchase maintenance id
      CONSTANTS.FORM_FIELD.PURCHASE_REQUEST_FORM_NEW[0].item[1].option =
        filteredRequests;
      CONSTANTS.FORM_FIELD.PURCHASE_REQUEST_FORM[1].item[1].option =
        filteredRequests;

      // supply out maintenance id
      CONSTANTS.FORM_FIELD.SUPPLY_OUT_INVENTORY[1].item[0].option =
        filteredRequests;
    });

    API.sendRequest(CONSTANTS.API.getAllClients, (res) => {
      CONSTANTS.FORM_FIELD.TRANSFER_FORM_TABLE[0].item[0].option =
        res?.data?.rows?.map((el) => ({
          id: el.name,
          value: el.name,
          Label: el.name,
        }));
    });
    API.sendRequest(CONSTANTS.API.employee, (res) => {
      CONSTANTS.FORM_FIELD.SUPPLY_OUT_INVENTORY[0].item[0].option =
        res?.data?.rows?.map((el) => ({
          id: el.name,
          value: el.name,
          Label: el.name,
        }));
    });
  }, []);
  CONSTANTS.FORM_FIELD.SUPPLY_OUT_INVENTORY_INNER[0].option = options;
  const CSVData = [];
  CSVData[0] = CONSTANTS.TABLE.TRANSFER.map((el) => el.title);
  data.map((item, index) => {
    CSVData[index + 1] = CONSTANTS.TABLE.TRANSFER.map(
      (el) => item[el.dataIndex]
    );
    return 0;
  });
  // console.log(options, "option");
  /***
   *
   * Row click
   */
  const onEditHandler = (value, clear) => {
    // console.log(value, formIventoryData);
    value.date = moment(value.date.$d).format("YYYY-MM-DD");
    const updateInventoryAPI = { ...CONSTANTS.API.TransferUpdate };
    updateInventoryAPI.endpoint = updateInventoryAPI.endpoint.replace(
      ":id",
      formIventoryData.id
    );
    // console.log(updateInventoryAPI);
    API.sendRequest(
      updateInventoryAPI,
      (res) => {
        // console.log(res);
        setOpenEditRow(false);
        setRefresh((prev) => !prev);
        clear();
      },
      value,
      "transfer Updated"
    );
  };

  return (
    <Row className="container-main">
      <ModalFormCreator
        open={onTransfer}
        loading={API.isLoading}
        onCreate={onInventoryTransfer}
        onCancel={() => {
          setOnTransfer(false);
          setDataInventory([]);
          setSubmitValue({});
          setFormIventoryData({});
        }}
        name="INVENTORY TRANSFER FORM"
        menu="SUPPLY_OUT_INVENTORY"
        inbuilt={false}
      >
        <FormFields
          formData={SubmitValue}
          menu={"SUPPLY_OUT_INVENTORY"}
          form={formOutWord}
        />
        <CustomTable
          dataSource={dataInventory}
          name="SUPPLY_OUT_INVENTORY_TABLE_INNER"
          // onChange={(selectedRowKeys, selectedRows) => {
          //   setProducts(selectedRows);
          // }}
          Other={{ rowSelection: null }}
          scroll={{
            y: "20vh",
          }}
        />
        <FormWithButton
          menu={"SUPPLY_OUT_INVENTORY_INNER"}
          name="Add"
          buttonwidth={3}
          // formData={formIventoryData}
          onCreate={(element) => {
            // setFormIventoryData({});

            const el = optionData.find(
              (e) => e.id === +element.productName.split("-")[0]
            );
            if (element.quantity > el.quantity || !element.quantity) {
              notification.error({
                message: `enter ${
                  !element.quantity ? "valid " : "less"
                }  quantity `,
              });
              return;
            }
            // console.log(el);

            setOptions((prev) =>
              prev.filter(
                (ele) => ele.id !== +element.productName.split("-")[0]
              )
            );
            setDataInventory((prev) => [
              ...prev,
              {
                key: el.id,
                ...el.product,
                ...el,
                productName: el.product.name,
                quantity: element.quantity,
                delete: {
                  id: el.id,
                  key: el.id,
                  onClick: handleDelete,
                },
              },
            ]);
          }}
        />
      </ModalFormCreator>
      <PageHeader
        data={{
          buttons: [
            !CONSTANTS.USER_ROLE && {
              id: 1,
              action: () => {
                // console.log("button1");
                setOnTransfer(true);
              },
              name: "New",
            },
            {
              id: 2,
              action: () => {
                // console.log("button3");
                // console.log("download", CSVData);
              },
              icon: <DownloadOutlined />,
              data: CSVData,
              type: "linkicon",
              name: "Transfer-" + moment().format("YYYY-MM-DD HH:mm"),
            },
          ],
          name: "Transfer",
        }}
        pagination={pagination}
        setPagination={setPagination}
        SearchBar={{
          endpointAPI: "/inventoryoutward/transfers?search=",
          dataResponseHandle: (res) => {
            return res?.data?.map((el, i) => ({
              ...el,
              ...el.inventoryInward,
              key: i,
            }));
          },
          onValueIsSelected: (row) => {
            setFormIventoryData({ ...row.employee, ...row });
            setOpenEditRow(true);
          },
          extraLabel: "srNumber",
          onBaseSearch: ["product"],
          debounceTime: 500,
        }}
      />
      <Col
        span={24}
        style={{
          marginBlock: "15px",
        }}
        className="container-body"
      >
        <ModalFormCreator
          loading={API.isLoading}
          open={openEditRow}
          onCreate={onEditHandler}
          onCancel={() => {
            setOpenEditRow(false);
            setIsDisabled(true);
            // setFormIventoryData({});
          }}
          disabled={isDisabled}
          edit
          onEdit={() => {
            // console.log("edit click");
            setIsDisabled(false);
          }}
          Delete
          onDelete={() => {
            // console.log("Delete Click", formIventoryData);
            const DeleteInventoryAPI = {
              ...CONSTANTS.API.TransferDelete,
            };
            DeleteInventoryAPI.endpoint = DeleteInventoryAPI.endpoint.replace(
              ":id",
              formIventoryData.id
            );
            API.sendRequest(
              DeleteInventoryAPI,
              (res) => {
                // console.log(res);
                setRefresh((prev) => !prev);
                setOpenEditRow(false);
              },
              "Deleted Successfully"
            );
          }}
          name="TRANSFER DETAILS"
          menu="TRANSFER_FORM_TABLE"
          formData={formIventoryData}
        />
        <CustomTable
          dataSource={data}
          name="TRANSFER"
          extraclass="pointer"
          Other={{
            onRow: (row) => ({
              onClick: () => {
                setFormIventoryData({ ...row.employee, ...row });
                setOpenEditRow(true);
              },
            }),
          }}
          isLoading={API.isLoading}
          DeleteSelectedRow
          APIendpoint="TransferDelete"
          onConfirm={() => {
            setRefresh((prev) => !prev);
          }}
          setChanges={(v) => {
            setAllFilter(v);
            setPagination((prev) => ({
              ...prev,
              current: 1,
            }));
          }}
        />
      </Col>
    </Row>
  );
};

export default Transfer;
