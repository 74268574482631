import React, { useEffect, useState } from "react";
import { Col, Row } from "antd";
import PageHeader from "../../../../component/common/page-Header";
import { DownloadOutlined, FilePdfFilled } from "@ant-design/icons";
import CustomTable from "../../../../component/common/Custom-Table";
import CONSTANTS from "../../../../util/constant/CONSTANTS";
import useHttp from "../../../../hooks/use-http";
import ModalFormCreator from "../../../../component/common/ModalFormCreator";
import moment from "moment";
import BillForm from "../../../BillForm/index";

const ExpanseDetails = () => {
  const [data, setData] = useState([]);
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 20,
    total: 0,
  });
  const [onTransfer, setOnTransfer] = useState(false);
  const [openEditRow, setOpenEditRow] = useState(false);
  const [formIventoryData, setFormIventoryData] = useState({});
  const [isDisabled, setIsDisabled] = useState(true);
  const [refresh, setRefresh] = useState(true);
  const [downloadData, setDownloadData] = useState({});

  const API = useHttp();
  const onRequestCreate = (value, clear) => {
    // console.log(value, "data");
    value.expanseDate = moment(value.expanseDate.$d).format("YYYY-MM-DD");
    const payload = new FormData();
    if (value.receipt) {
      payload.append("receipt", value.receipt[0].originFileObj);
      delete value.receipt;
    } else {
      delete value.receipt;
    }
    Object.keys(value).forEach((el) => {
      payload.append(el, value[el]);
    });
    setOnTransfer(false);
    API.sendRequest(
      CONSTANTS.API.expanseCreate,
      () => {
        // console.log(res, "purchase");
        setRefresh((prev) => !prev);
        clear();
      },
      payload,
      "expanse added"
    );
  };

  function codespeedy(elementId, fileName) {
    setTimeout(() => {
      var print_div = document.getElementById(elementId);
      var print_area = window.open(("", "_blank"));
      print_area.document.write(print_div.innerHTML);
      print_area.document.close();
      print_area.focus();
      print_area.print();
      print_area.close();
    }, 500);
  }
  // function codespeedy(elementId, fileName) {
  //   setTimeout(() => {
  //     var printDiv = document.getElementById(elementId);
  //     var printContent = printDiv.innerHTML;

  //     var link = document.createElement("a");
  //     link.href =
  //       "data:text/html;charset=utf-8," + encodeURIComponent(printContent);
  //     link.download = fileName;
  //     link.style.display = "none";
  //     document.body.appendChild(link);
  //     window.print();

  //     link.click();

  //     document.body.removeChild(link);
  //   }, 500);
  // }

  // const handleGeneratePdf = (elementId, fileName) => {
  //   setTimeout(() => {
  //     var printDiv = document.getElementById(elementId);
  //     var printContent = printDiv.innerHTML;

  //     // Create a new jsPDF instance
  //     var doc = new jsPDF({
  //       orientation: "portrait",
  //       unit: "pt",

  //       format: [600, 800],
  //     });

  //     // Convert the HTML content to PDF
  //     doc.html(printContent, {
  //       callback: function (doc) {
  //         // Save the PDF file
  //         doc.save(fileName);
  //       },
  //       x: 0,
  //       y: 0,
  //     });
  //   }, 500);
  // };

  // console.log(data);
  useEffect(() => {
    const ExpansePage = { ...CONSTANTS.API.expanseAll };
    ExpansePage.endpoint = `${ExpansePage.endpoint}?limit=${pagination.pageSize}&page=${pagination.current}`;

    API.sendRequest(ExpansePage, (res) => {
      // console.log(res);
      setPagination((prevPagination) => ({
        ...prevPagination,
        total: res?.data?.count,
      }));
      setData(
        res?.data?.rows?.map((el, i) => ({
          ...el,
          key: el.id,
          // download: {
          //   onClick: () => {
          //     // console.log(el);
          //     setDownloadData(el);
          //     // handleGeneratePdf("builty", "ExpenseVoucher");
          //     codespeedy("builty", "ExpenseVoucher");
          //   },
          // },
          no: (pagination.current - 1) * pagination.pageSize + i + 1,
          action: {
            Download: [
              {
                icon: <FilePdfFilled />,
                onClick: () => {
                  // console.log(el);
                  setDownloadData(el);
                  // handleGeneratePdf("builty", "ExpenseVoucher");
                  codespeedy("builty", "ExpenseVoucher");
                },
              },
              el?.receipt,
            ],
          },
          status: {
            id: el.id,
            value: el.status,
            onChange: (res) => {
              // console.log(res, "click happend", el.id);
              const updatePurchaseAPI = {
                ...CONSTANTS.API.expanseUpdate,
              };
              updatePurchaseAPI.endpoint = updatePurchaseAPI.endpoint.replace(
                ":id",
                el.id
              );
              API.sendRequest(
                updatePurchaseAPI,
                () => {
                  // console.log(res);
                },
                { status: res },
                "status updated"
              );
            },
          },
        }))
      );
    });
  }, [refresh, pagination.current, pagination.pageSize]);

  // useEffect(() => {
  //   API.sendRequest(CONSTANTS.API.employee, (res) => {
  //     CONSTANTS.FORM_FIELD.EXPANSE_FORM[0].item[1].option =
  // res?.data?.rows?.map((el) => ({
  //   id: el.id,
  //   value: el.id,
  //   Label: el.name,
  // }));
  //   });
  // }, []);
  /***
   *
   * Row click
   */
  const onEditHandler = (value, clear) => {
    // console.log(value, formIventoryData);
    value.expanseDate = moment(value.expanseDate.$d).format("YYYY-MM-DD");
    const payload = new FormData();
    if (value.receipt) {
      payload.append("receipt", value.receipt[0].originFileObj);
      delete value.receipt;
    } else {
      delete value.receipt;
    }
    Object.keys(value).forEach((el) => {
      payload.append(el, value[el]);
    });
    const updateInventoryAPI = { ...CONSTANTS.API.expanseUpdate };
    updateInventoryAPI.endpoint = updateInventoryAPI.endpoint.replace(
      ":id",
      formIventoryData.id
    );
    // console.log(updateInventoryAPI);
    API.sendRequest(
      updateInventoryAPI,
      () => {
        // console.log(res);
        setOpenEditRow(false);
        setRefresh((prev) => !prev);
        clear();
      },
      payload,
      "expanse Updated"
    );
  };

  const CSVData = [];
  CSVData[0] = CONSTANTS.TABLE.EXPANSE_TABLE.map((el) => el.title);

  data.forEach((item, index) => {
    CSVData[index + 1] = CONSTANTS.TABLE.EXPANSE_TABLE.map((el) => {
      if (el.dataIndex === "status") {
        return item[el.dataIndex]?.value;
      }
      if (el.dataIndex === "action") {
        return item[el.dataIndex]?.Download?.[1];
      }
      return item[el.dataIndex];
    });
  });
  return (
    <>
      <Row className="container-main">
        <ModalFormCreator
          loading={API.isLoading}
          open={onTransfer}
          onCreate={onRequestCreate}
          onCancel={() => {
            setOnTransfer(false);
          }}
          name="add Expense"
          menu="EXPANSE_FORM"
        />
        <PageHeader
          data={{
            buttons: [
              !CONSTANTS.USER_ROLE && {
                id: 1,
                action: () => {
                  // console.log("button1");
                  setOnTransfer(true);
                },
                name: "New",
              },
              {
                id: 2,
                action: () => {
                  // console.log("download", CSVData);
                },
                icon: <DownloadOutlined />,
                data: CSVData,
                type: "linkicon",
                name: "Expanses-" + moment().format("YYYY-MM-DD HH:mm"),
              },
            ],
            name: "",
          }}
          search={{
            pagination: pagination,
            setPagination: setPagination,
          }}
          SearchBar={{
            endpointAPI: "/expanse?search=",
            dataResponseHandle: (res) => {
              console.log(res);
              return res?.data?.rows?.map((el) => ({
                ...el,
                key: el.id,
              }));
            },
            onValueIsSelected: (row) => {
              setFormIventoryData({ ...row });
              setOpenEditRow(true);
            },
            extraLabel: "partyName",
            onBaseSearch: ["sku"],
            debounceTime: 500,
          }}
        />
        <Col
          span={24}
          style={{
            marginBlock: "15px",
          }}
          className="container-body"
        >
          <ModalFormCreator
            loading={API.isLoading}
            open={openEditRow}
            onCreate={onEditHandler}
            onCancel={() => {
              setOpenEditRow(false);
              setIsDisabled(true);
              // setFormIventoryData({});
            }}
            disabled={isDisabled}
            edit
            onEdit={() => {
              // console.log("edit click");
              setIsDisabled(false);
            }}
            Delete
            onDelete={() => {
              // console.log("Delete Click", formIventoryData);
              const DeleteInventoryAPI = {
                ...CONSTANTS.API.expanseDelete,
              };
              DeleteInventoryAPI.endpoint = DeleteInventoryAPI.endpoint.replace(
                ":id",
                formIventoryData.id
              );
              API.sendRequest(
                DeleteInventoryAPI,
                () => {
                  // console.log(res);
                  setRefresh((prev) => !prev);
                  setOpenEditRow(false);
                },
                "Deleted Successfully"
              );
            }}
            name="edit Expanse"
            menu="EXPANSE_FORM"
            formData={formIventoryData}
          />
          <CustomTable
            dataSource={data}
            name="EXPANSE_TABLE"
            extraclass="pointer"
            Other={{
              onRow: (row) => ({
                onClick: () => {
                  setFormIventoryData({ ...row });
                  setOpenEditRow(true);
                },
              }),
            }}
            isLoading={API.isLoading}
            DeleteSelectedRow
            APIendpoint="expanseDelete"
            onConfirm={() => {
              setRefresh((prev) => !prev);
            }}
          />
        </Col>
        <div className="d-none" style={{ display: "none" }}>
          <div id="builty">
            <BillForm data={downloadData} />
          </div>
        </div>
      </Row>
    </>
  );
};

export default ExpanseDetails;
