import React from "react";
import { maintananceDashboardIcon } from "../../util/constant/ICON";
import { Col } from "antd";
import DonutChart from "./DonutChart";

const StatGraphCard = React.memo(({ icon, value, graph }) => {
  return (
    <Col
      className="gutter-row cursor-pointer"
      span={8}
      md={24}
      xxl={8}
      xl={10}
      sm={24}
      xs={24}
    >
      <div
        className="gutter-box flex flex-y space-between"
        style={{ height: "170px" }}
      >
        <div className="pt15 pb5 flex-y" style={{ justifyContent: "stretch" }}>
          <div>
            <img src={icon || maintananceDashboardIcon} alt="trueIcon" />
          </div>
          <div className="an-32 text-title my15"> &nbsp;</div>
          <div className="an-14 text-title gray--text lh-32 pb10">{value}</div>
        </div>
        <div
          style={{
            position: "absolute",
            width: "280px",
            height: "200px !important",
            right: "40px",
          }}
        >
          <DonutChart GraphData={graph} />
        </div>
      </div>
    </Col>
  );
});

export default StatGraphCard;
