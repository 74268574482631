import { Col, Empty, Row } from "antd";
import React, { useEffect, useState } from "react";
import HeadDashboard from "../../../component/card/Head-Dashboard";

import PlantOverviewCard from "../../../component/card/Plant-Overview-Card";
import ClientOverviewCard from "../../../component/card/Client-Overview-Card";

import CONSTANTS from "../../../util/constant/CONSTANTS";
import useHttp from "../../../hooks/use-http";
import MaintananceCard from "../../../component/card/Maintanance-Card";
const Color = {
  Minor: "#9DA6BA",
  Critical: "#E84A50",
  Major: "#F7C137",
  Back: {
    Minor: "rgba(157, 166, 186, 0.15)",
    Critical: "rgba(232, 74, 80, 0.15)",
    Major: "rgba(247, 193, 55, 0.15)",
  },
  Type: {
    New: "#2E5BFF",
    Planned: "#8C54FF",
    Inprogress: "#F7C137",
    Completed: "#2CC2A5",
    Cancelled: "#E84A50",
  },
};
const Dashboard = () => {
  // const Me = CONSTANTS.GETMe;
  const [Me, SetMe] = useState({});
  const [clients, setClients] = useState([]);
  const [requests, setRequests] = useState([]);
  const [chartData, setChartData] = useState({});
  const API = useHttp();
  useEffect(() => {
    API.sendRequest(CONSTANTS.API.getAllClients, (res) => {
      // console.log(res?.rows);
      setClients(
        res?.data?.rows?.map((el) => ({
          ...el,
          value: el.plantCapacity,
          // name: "Anupam Rasayan",
          // value: 1.2,
          // image: Logo,
        }))
      );
    });
    API.sendRequest(CONSTANTS.API.getMe, (res) => {
      SetMe(res?.data);
    });
    API.sendRequest(CONSTANTS.API.dashboardRequestINProgress, (res) => {
      setRequests(res?.data?.data?.slice(0, 3));
      setChartData(res?.data);
      // <MaintananceCard
      //         data={e}
      //         onCardClick={() => {
      //           setFormIventoryData({
      //             ...e,
      //             duration: dayjs(e.duration, "HH:mm"),
      //           });
      //           setOpenEditRow(true);
      //         }}
      //       />
    });
  }, []);
  return (
    <div className="px50 py30">
      <Row>
        <p className="heading-dashboard">
          {Me?.siteName} - {Me?.plantCapacity?.toLocaleString()} MW
        </p>
      </Row>
      <hr className="mt25 mb25" style={{ border: "1px solid #E4E8F0" }}></hr>
      <div className="dashboard-main-dashboard">
        <HeadDashboard maintanance={chartData} />
        <hr className="mt25 mb25" style={{ border: "1px solid #E4E8F0" }}></hr>
        <Row gutter={[16, 16]}>
          <Col span={16} md={24} xxl={18} xl={16} sm={24} xs={24}>
            <Row className="gutter-box pr25 pb25 pt10 mb30">
              <Row>
                <div className="an-32 light-text">
                  <span>Plant Overview </span>
                </div>
              </Row>
              <Col span={24}>
                <hr
                  className="mt10 mb25"
                  style={{
                    border: "1px solid #E4E8F0",
                    width: "100% !important",
                  }}
                ></hr>
              </Col>
              <Col span={24}>
                <Row gutter={[16, 16]}>
                  {[
                    { name: "Total Rows", value: Me?.rows },
                    { name: "Total Inverter", value: Me?.inverter },
                    { name: "Total Zone", value: Me?.zone },
                    { name: "Total Area", value: Me?.area },
                  ].map((el, i) => (
                    <PlantOverviewCard {...el} key={i} />
                  ))}
                </Row>
              </Col>
            </Row>
            <Row className="gutter-box pr25 pb25 pt10">
              <Row>
                <div className="an-14 regular-text gray--text lh-32 pb10">
                  <span>Clients</span>
                </div>
              </Row>
              <Col span={24}>
                {clients.length ? (
                  <Row gutter={[16, 16]}>
                    {clients.map((el) => (
                      <ClientOverviewCard {...el} key={el.id} />
                    ))}
                  </Row>
                ) : (
                  <Empty description={"No Client Found"} />
                )}
              </Col>
            </Row>
          </Col>
          <Col span={8} md={24} xxl={6} xl={8} sm={24} xs={24}>
            <Row className="gutter-box pr25 pb25 pt10 mb30">
              <Col
                span={24}
                style={{ display: "flex", justifyContent: "space-between" }}
              >
                <div className="an-32 light-text">On Going Task</div>
                <span
                  className={`title-head-dahsboard an-22`}
                  style={{
                    color: `${Color.Type["Inprogress"]}`,
                    background: `${Color.Type["Inprogress"]}3F`,
                    borderRadius: "10pt",
                    // paddingBottom: "0",
                    display: "flex",
                    alignItems: "center",
                    //   background: "#FFFFFF",
                  }}
                >
                  {chartData?.Inprogress}
                </span>
              </Col>
              <Col span={24}>
                <hr
                  className="mt10 mb25"
                  style={{
                    border: "1px solid #E4E8F0",
                    width: "100% !important",
                  }}
                ></hr>
              </Col>
              {requests.length ? (
                <Row gutter={[16, 16]}>
                  {requests.map((el) => (
                    <MaintananceCard
                      data={el}
                      key={el?.id}
                      onCardClick={() => {
                        window.location.assign("/app/maintenance/kanban");
                      }}
                    />
                  ))}
                </Row>
              ) : (
                <Col span={24}>
                  <Empty description={"No Task In Progress"} />
                </Col>
              )}
            </Row>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default Dashboard;
