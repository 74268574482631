import React from "react";
import { Button } from "antd";
import { useNavigate } from "react-router-dom";

const ResetPasswordSuccess = () => {
  const navigate = useNavigate();

  const styles = `
    .reset-password-success-wrapper {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      max-width: 350px;
    }

    .reset-password-success-wrapper svg {
      width: 100px;
      display: block;
    }

    .reset-password-success-wrapper .path.circle {
      stroke-dasharray: 1000;
      stroke-dashoffset: 0;
    }

    .reset-password-success-wrapper .path.line {
      stroke-dashoffset: 1000;
    }

    .reset-password-success-wrapper .path.check {
      stroke-dashoffset: -100;
    }

    .reset-password-success-wrapper p {
      text-align: center;
      margin-top: 20px;
      font-size: 1.5em;
    }

    .reset-password-success-wrapper p.msg-success {
      color: #73af55;
    }

    .reset-password-success-wrapper p.msg-error {
      color: #d06079;
    }
  `;

  return (
    <>
      <style>{styles}</style>
      <div className="reset-password-success-wrapper">
        <svg
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 130.2 130.2"
        >
          <circle
            className="path circle"
            fill="none"
            stroke="#73AF55"
            strokeWidth="6"
            strokeMiterlimit="10"
            cx="65.1"
            cy="65.1"
            r="62.1"
          />
          <polyline
            className="path check"
            fill="none"
            stroke="#73AF55"
            strokeWidth="6"
            strokeLinecap="round"
            strokeMiterlimit="10"
            points="100.2,40.2 51.5,88.8 29.8,67.5"
          />
        </svg>
        <p className="msg-success mb0">Password changed successfully!</p>
        <p className="an-18 normal-text">
          You can now login with a new password.
        </p>
        <Button
          htmlType="submit"
          className="login-form-button an-15 medium-text mt10"
          onClick={() => {
            navigate("/");
          }}
        >
          Login
        </Button>
      </div>
    </>
  );
};

export default ResetPasswordSuccess;
