import React from "react";
import { Form, Input, Button, Alert } from "antd";

const ResetPasswordForm = ({ isError, error, handleForm, isLoading }) => {
  return (
    <div style={{ width: "100%" }}>
      <div className="an-22 regular-text text-dark text-center mb10">
        Reset Password
      </div>
      <div className="form-detail">
        {isError && (
          <Alert
            className="mt20 mb15"
            message={error.message}
            type="error"
            showIcon
          />
        )}
        <Form name="normal_login" onFinish={handleForm}>
          <Form.Item
            name="password"
            hasFeedback
            rules={[
              { required: true, message: "Please input new password" },
              {
                type: "string",
                min: 8,
                message: "Password must be at least 8 characters",
              },
            ]}
          >
            <Input
              type="password"
              placeholder="New Password"
              className="app-input mt5"
            />
          </Form.Item>
          <Form.Item
            name="confirmPassword"
            dependencies={["password"]}
            hasFeedback
            rules={[
              {
                required: true,
                message: "Please confirm your password!",
              },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (!value || getFieldValue("password") === value) {
                    return Promise.resolve();
                  }
                  return Promise.reject(
                    new Error(
                      "The two passwords that you entered do not match!"
                    )
                  );
                },
              }),
            ]}
          >
            <Input.Password
              className="app-input mt5"
              placeholder="Confirm Password"
            />
          </Form.Item>
          <Form.Item className="mb0">
            <Button
              loading={isLoading}
              htmlType="submit"
              className="login-form-button an-15 regular-text"
            >
              Submit
            </Button>
          </Form.Item>
        </Form>
      </div>
    </div>
  );
};

export default ResetPasswordForm;
