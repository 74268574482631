import React, { useEffect, useState } from "react";
import { AutoComplete } from "antd";
import useHttp from "../../hooks/use-http";

const CustomSearchBar = ({
  endpointAPI = "/inventoryinward?search=",
  dataResponseHandle = (res) => res?.data?.rows,
  onValueIsSelected = (v) => {
    console.log(v);
  },
  onChange = () => {},
  onBaseSearch = [],
  debounceTime = 500,
  isSearch = false,
  extraLabel = "",
}) => {
  const API = useHttp();
  const [searchKeyword, setSearchKeyword] = useState("");
  const [suggestions, setSuggestions] = useState([]);
  const [fetchTimeout, setFetchTimeout] = useState(null);

  const fetchSuggestions = async (keyword) => {
    try {
      API.sendRequest(
        { type: "GET", endpoint: endpointAPI + keyword },
        (res) => {
          setSuggestions(dataResponseHandle(res));
          onChange(dataResponseHandle(res));
        }
      );
    } catch (error) {
      console.error("Error fetching suggestions:", error);
    }
  };

  const handleSearchChange = (value) => {
    clearTimeout(fetchTimeout);
    setSearchKeyword(value);
    const timeout = setTimeout(() => {
      if (!value.trim()) {
        dataResponseHandle(null, true);
      }
      if (isSearch && value?.trim()) {
        fetchSuggestions(value);
      }
    }, debounceTime);
    setFetchTimeout(timeout);
  };

  const handleSelect = (value) => {
    const selectedItem = suggestions.find((item) => {
      let nestedValue = item;
      for (let i = 0; i < onBaseSearch.length; i++) {
        nestedValue = nestedValue[onBaseSearch[i]];
        if (!nestedValue) {
          return false;
        }
      }
      return nestedValue === value;
    });
    onValueIsSelected(selectedItem);
  };

  useEffect(() => {
    return () => {
      clearTimeout(fetchTimeout);
    };
  }, [fetchTimeout]);

  const getNestedValue = (obj, path) => {
    let value = obj;
    for (let i = 0; i < path.length; i++) {
      value = value[path[i]];
      if (!value) {
        return undefined;
      }
    }
    return value;
  };

  const renderOptionLabel = (item) => {
    const baseValue = getNestedValue(item, onBaseSearch);
    const extraValue = getNestedValue(item, extraLabel.split("."));
    return `${baseValue}${extraValue ? ` (${extraValue})` : ""}`;
  };

  return (
    <AutoComplete
      options={
        !!extraLabel && onBaseSearch.length
          ? suggestions?.map((item) => ({
              value: getNestedValue(item, onBaseSearch),
              label: renderOptionLabel(item),
            }))
          : []
      }
      onSelect={handleSelect}
      onChange={handleSearchChange}
      // onClick={() => {
      //   console.log("object");
      // }}
      value={searchKeyword}
      style={{ width: "100%" }}
    ></AutoComplete>
  );
};

export default CustomSearchBar;
