import React, { useEffect, useState } from "react";
import { Col, Row } from "antd";
import PageHeader from "../../../../component/common/page-Header";
import { DownloadOutlined } from "@ant-design/icons";
import CustomTable from "../../../../component/common/Custom-Table";
import CONSTANTS from "../../../../util/constant/CONSTANTS";
import useHttp from "../../../../hooks/use-http";
import ModalFormCreator from "../../../../component/common/ModalFormCreator";
import moment from "moment";
import dayjs from "dayjs";

const LogBookLog = () => {
  const [data, setData] = useState([]);
  const [Allfilter, setAllFilter] = useState(null);
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 20,
    total: 0,
  });
  const [onTransfer, setOnTransfer] = useState(false);
  const [openEditRow, setOpenEditRow] = useState(false);
  const [formIventoryData, setFormIventoryData] = useState({});
  const [isDisabled, setIsDisabled] = useState(true);
  const [refresh, setRefresh] = useState(true);

  const API = useHttp();

  const calculateDuration = (from, to) => {
    const duration = moment.duration(moment(to).diff(moment(from)));
    const days = duration.days();
    const hours = duration.hours();
    const minutes = duration.minutes();

    let durationString = "";
    if (days > 0) {
      durationString += `${days} Day${days !== 1 ? "s" : ""} `;
    }
    if (hours > 0) {
      durationString += `${hours} Hour${hours !== 1 ? "s" : ""} `;
    }
    if (minutes > 0) {
      durationString += `${minutes} Min${minutes !== 1 ? "s" : ""} `;
    }
    durationString = durationString.trim();

    return durationString;
  };

  useEffect(() => {
    let QuaryParams = {
      limit: pagination.pageSize,
      page: pagination.current,
    };
    if (Allfilter?.sort) {
      QuaryParams = { ...QuaryParams, ...Allfilter?.sort };
    }
    if (Allfilter?.filter) {
      Allfilter?.filter?.map((el) => {
        QuaryParams = {
          ...QuaryParams,
          [`autogenerate-mul-array-$${el[0]}$`]: el[1],
        };
      });
    }
    API.sendRequest(
      CONSTANTS.API.eventlogAll,
      (res) => {
        setPagination((prevPagination) => ({
          ...prevPagination,
          total: res?.data?.count,
        }));
        setData(
          res?.data?.rows?.map((el, i) => ({
            ...el?.employee,
            ...el,
            no: (pagination.current - 1) * pagination.pageSize + i + 1,
            duration: calculateDuration(el.from, el.to),
            status: {
              id: el.id,
              value: el.status,
              onChange: (res) => {
                console.log(res, "click happened", el.id);
                const updatePurchaseAPI = {
                  ...CONSTANTS.API.eventlogUpdate,
                };
                updatePurchaseAPI.endpoint = updatePurchaseAPI.endpoint.replace(
                  ":id",
                  el.id
                );
                API.sendRequest(
                  updatePurchaseAPI,
                  (res) => {
                    console.log(res);
                  },
                  { status: res },
                  "status updated"
                );
              },
            },
            key: el.id,
          }))
        );
      },
      QuaryParams
    );
  }, [refresh, pagination.current, pagination.pageSize, Allfilter]);

  useEffect(() => {
    API.sendRequest(CONSTANTS.API.employee, (res) => {
      CONSTANTS.FORM_FIELD.EVENT_LOG_FORM[2].item[0].option =
        res?.data?.rows?.map((el) => ({
          id: el.id,
          value: el.id,
          Label: el.name,
        }));
    });
  }, []);

  const onRequestCreate = (value, clear) => {
    // console.log(value, "data");
    // value.from = moment(value.from.$d).format("YYYY-MM-DD HH:mm");
    // value.to = moment(value.to.$d).format("YYYY-MM-DD HH:mm");
    API.sendRequest(
      CONSTANTS.API.eventlogCreate,
      () => {
        setRefresh((prev) => !prev);
        setOnTransfer(false);
        clear();
      },
      value,
      "event log created"
    );
  };

  const onEditHandler = (value, clear) => {
    // console.log(value, formIventoryData);
    // value.from = moment(value.from.$d).format("YYYY-MM-DD HH:mm");
    // value.to = moment(value.to.$d).format("YYYY-MM-DD HH:mm");
    const updateInventoryAPI = { ...CONSTANTS.API.eventlogUpdate };
    updateInventoryAPI.endpoint = updateInventoryAPI.endpoint.replace(
      ":id",
      formIventoryData.id
    );
    API.sendRequest(
      updateInventoryAPI,
      (res) => {
        console.log(res);
        setOpenEditRow(false);
        setRefresh((prev) => !prev);
        clear();
      },
      value,
      "event log updated"
    );
  };

  const CSVData = [];
  CSVData[0] = CONSTANTS.TABLE.EVENT_LOG_TABLE.map((el) => el.title);
  data.forEach((item, index) => {
    CSVData[index + 1] = CONSTANTS.TABLE.EVENT_LOG_TABLE.map((el) => {
      if (el.dataIndex === "status") {
        return item[el.dataIndex]?.value;
      }
      return item[el.dataIndex];
    });
  });

  return (
    <Row className="container-main">
      <ModalFormCreator
        loading={API.isLoading}
        open={onTransfer}
        onCreate={onRequestCreate}
        onCancel={() => {
          setOnTransfer(false);
        }}
        name="add Event log"
        menu="EVENT_LOG_FORM"
      />
      <PageHeader
        data={{
          buttons: [
            !CONSTANTS.USER_ROLE && {
              id: 1,
              action: () => {
                setOnTransfer(true);
              },
              name: "New",
            },
            {
              id: 2,
              action: () => {},
              icon: <DownloadOutlined />,
              data: CSVData,
              type: "linkicon",
              name: "Log-" + moment().format("YYYY-MM-DD HH:mm"),
            },
          ],
          name: "Event Log",
        }}
        SearchBar={{
          endpointAPI: "/eventlog?search=",
          dataResponseHandle: (res) => {
            return res?.data?.rows?.map((el) => ({
              ...el?.employee,
              ...el,
              duration: calculateDuration(el.from, el.to),
              key: el.id,
            }));
          },
          onValueIsSelected: (v) => {
            // console.log(v, "component");
            setFormIventoryData({ ...v, date: dayjs(v.date) });
            setOpenEditRow(true);
          },
          extraLabel: "event",
          onBaseSearch: ["sku"],
          debounceTime: 500,
        }}
        pagination={pagination}
        setPagination={setPagination}
      />
      <Col
        span={24}
        style={{
          marginBlock: "15px",
        }}
        className="container-body"
      >
        <ModalFormCreator
          loading={API.isLoading}
          open={openEditRow}
          onCreate={onEditHandler}
          onCancel={() => {
            setOpenEditRow(false);
            setIsDisabled(true);
          }}
          disabled={isDisabled}
          edit
          onEdit={() => {
            setIsDisabled(false);
          }}
          Delete
          onDelete={() => {
            const DeleteInventoryAPI = {
              ...CONSTANTS.API.eventlogDelete,
            };
            DeleteInventoryAPI.endpoint = DeleteInventoryAPI.endpoint.replace(
              ":id",
              formIventoryData.id
            );
            API.sendRequest(
              DeleteInventoryAPI,
              (res) => {
                console.log(res);
                setRefresh((prev) => !prev);
                setOpenEditRow(false);
              },
              "Deleted Successfully"
            );
          }}
          name="edit Event log"
          menu="EVENT_LOG_FORM"
          formData={formIventoryData}
        />
        <CustomTable
          isLoading={API.isLoading}
          dataSource={data}
          setChanges={(v) => {
            setAllFilter(v);
            setPagination((prev) => ({
              ...prev,
              current: 1,
            }));
          }}
          name="EVENT_LOG_TABLE"
          extraclass="pointer"
          Other={{
            onRow: (row) => ({
              onClick: () => {
                setFormIventoryData({ ...row, date: dayjs(row.date) });
                setOpenEditRow(true);
              },
            }),
          }}
          DeleteSelectedRow
          APIendpoint="eventlogDelete"
          onConfirm={() => {
            setRefresh((prev) => !prev);
          }}
        />
      </Col>
    </Row>
  );
};

export default LogBookLog;
