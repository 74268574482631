import React from "react";
const Color = {
  Minor: "#9DA6BA",
  Critical: "#E84A50",
  Major: "#F7C137",
  Back: {
    Minor: "rgba(157, 166, 186, 0.15)",
    Critical: "rgba(232, 74, 80, 0.15)",
    Major: "rgba(247, 193, 55, 0.15)",
  },
  Type: {
    New: "#2E5BFF",
    Planned: "#8C54FF",
    Inprogress: "#F7C137",
    Completed: "#2CC2A5",
    Cancelled: "#E84A50",
  },
};
const KanbanLabel = (props) => {
  const { name, count, className } = props;
  return (
    <div className={`title-head ${className ? className : ""}`}>
      <p
        className={`title-head ${className ? className : ""}`}
        style={{ color: "#2E384D" }}
      >
        {name}
      </p>
      <p
        className={`title-head ${className ? className : ""}`}
        style={{
          color: `${Color.Type[name]}`,
          background: `${Color.Type[name]}3F`,
          borderRadius: "5pt",
          //   background: "#FFFFFF",
        }}
      >
        {count}
      </p>
    </div>
  );
};

export default KanbanLabel;
