import { RightOutlined, LeftOutlined } from "@ant-design/icons";
import { Col, Row } from "antd";
import React from "react";
import CustomButton from "./Custom-Buttons";
import CustomSearchBar from "./Custom-SearchBar";

const PageHeader = ({
  page,
  searchHide = false,
  pagination,
  setPagination,
  data,
  SearchBar = {
    // endpointAPI: "/inventoryinward?search=",
    onChange: () => {},
    dataResponseHandle: (res) => res?.data?.rows,
    onValueIsSelected: (v) => {
      console.log(v, "pageHeader");
    },
    onBaseSearch: [],
    debounceTime: 500,
    extraLabel: "",
  },
}) => {
  if (data?.buttons) {
    data.buttons = data?.buttons?.filter((el) => el !== false);
  }
  if (pagination && setPagination) {
    page = {
      page: {
        start:
          pagination.current === 1
            ? pagination.current
            : (pagination.current - 1) * pagination.pageSize,
        end:
          pagination.current === 1
            ? pagination.current + pagination.pageSize < pagination?.total
              ? pagination.current + pagination.pageSize
              : pagination?.total
            : pagination.current * pagination.pageSize + pagination.pageSize <
              pagination?.total
            ? (pagination.current - 1) * pagination.pageSize +
              pagination.pageSize
            : pagination?.total,
        total: pagination?.total,
      },
    };
  }
  const params = window.location.pathname;
  return (
    <Col span={24}>
      <Row gutter={[16, 16]}>
        <Col
          span={page ? 12 : 24}
          sm={24}
          xs={24}
          md={24}
          lg={page ? 12 : 24}
          className="dashboard-headers"
        >
          <Row>
            <p
              className="dashboard-head capitlize"
              style={{ marginBottom: "7px" }}
            >
              {params.split("/").length < 4
                ? params.split("/")[2]
                : `${params.split("/")[2]} ${data?.name && "|" + data?.name}`}
            </p>
          </Row>
          <Row gutter={[8, 8]}>
            {data?.buttons.map((button) => (
              <Col key={button.id}>
                <CustomButton key={button.id} {...button} />
              </Col>
            ))}
          </Row>
        </Col>
        {page || (pagination && setPagination) ? (
          <Col
            span={12}
            sm={24}
            xs={24}
            md={24}
            lg={12}
            className="dashboard-headers"
          >
            {searchHide ? (
              ""
            ) : (
              <Row>
                <CustomSearchBar
                  {...SearchBar}
                  isSearch={SearchBar.endpointAPI}
                />
              </Row>
            )}
            {page?.page?.total || (pagination && setPagination) ? (
              <Row gutter={[16, 16]}>
                <Col span={12}></Col>
                <Col
                  span={12}
                  sm={24}
                  xs={24}
                  md={24}
                  lg={12}
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    flexDirection: "column",
                  }}
                >
                  <Row justify="end" align="middle">
                    <Col className="pages" span={8}>
                      {`${page?.page?.total ? page?.page?.start : 0}-${
                        page?.page?.total ? page?.page?.end : 0
                      } / ${page?.page?.total ? page?.page?.total : 0}`}
                    </Col>
                    <Col className="carasoul-btn pages" span={8}>
                      <Row align="middle">
                        <Col span={12}>
                          <LeftOutlined
                            onClick={
                              pagination && setPagination
                                ? () => {
                                    if (pagination.current > 1) {
                                      setPagination((prevPagination) => ({
                                        ...prevPagination,
                                        current: prevPagination.current - 1,
                                      }));
                                    }
                                  }
                                : page?.action?.left
                            }
                          />
                        </Col>
                        <Col span={12}>
                          <RightOutlined
                            onClick={
                              pagination && setPagination
                                ? () => {
                                    if (
                                      (pagination.current - 1) *
                                        pagination.pageSize +
                                        pagination.pageSize <
                                      pagination?.total
                                    ) {
                                      setPagination((prevPagination) => ({
                                        ...prevPagination,
                                        current: prevPagination.current + 1,
                                      }));
                                    }
                                  }
                                : page?.action?.right
                            }
                          />
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </Col>
              </Row>
            ) : (
              ""
            )}
          </Col>
        ) : (
          ""
        )}
      </Row>
    </Col>
  );
};

PageHeader.defaultProps = {
  page: null,
  data: {
    buttons: [],
    name: "",
  },
  details: "",
};

export default PageHeader;
