import React, { useEffect, useState } from "react";
import CustomTable from "../../../../../component/common/Custom-Table";
import dayjs from "dayjs";
import ModalFormCreator from "../../../../../component/common/ModalFormCreator";
import moment from "moment";

const MaintenanceHistoryTab = React.memo(({ HistoryData = [] }) => {
  // console.log("object1");
  const [dataSource, setDataSource] = useState([]);
  const [openEditRow, setOpenEditRow] = useState(false);
  const [formIventoryData, setFormIventoryData] = useState({});
  useEffect(() => {
    setDataSource(HistoryData);
  }, [HistoryData]);

  return (
    <>
      <ModalFormCreator
        open={openEditRow}
        Submit={false}
        onCancel={() => {
          setOpenEditRow(false);
          setFormIventoryData({});
        }}
        disabled={true}
        name={`View Maintenance Request (${formIventoryData?.sku || ""})`}
        menu="MAINTENANCE_REQUEST_FORM_NEW"
        formData={formIventoryData}
      >
        {formIventoryData?.maintenanceResolutions?.map((el, i) => (
          <div className="mb15" key={el.products.id}>
            <div
              className="form-modal-head an-20 mb5"
              style={{ display: "flex", justifyContent: "space-between" }}
            >
              <p className="form-modal-head an-20 mb5">{`Resolution - ${
                i + 1
              }`}</p>
              <p className="form-modal-head an-15 mb5">
                {/* {moment(el?.createdAt).format("YYYY-MM-DD HH:mm")} */}
                {moment(el?.date).format("YYYY-MM-DD HH:mm")}
                {/* {dayjs(moment(el?.createdAt).format("YYYY-MM-DD HH:mm")).from(
                  dayjs(formInventoryData?.date)
                )} */}
              </p>
            </div>
            <p className="an-15 mb5">{el?.action}</p>
            {el?.products?.[0]?.product && (
              <CustomTable
                name="MAINTENANCE_RESOLUTION_TABLE"
                dataSource={el.products}
                scrollAutoOff={true}
                Other={{ rowSelection: null }}
              />
            )}
          </div>
        ))}
      </ModalFormCreator>
      <CustomTable
        dataSource={dataSource}
        name="MAINTENANCE_HISTORY"
        extraclass="pointer"
        Other={{
          onRow: (row) => ({
            onClick: () => {
              setFormIventoryData({
                ...row,
                duration: dayjs(row.duration, "HH:mm"),
              });
              setOpenEditRow(true);
            },
          }),
        }}
      />
    </>
  );
});

export default MaintenanceHistoryTab;
