import React from "react";

const Label = (props) => {
  return props.required ? (
    <p
      className={`title-head reuired-field ${props?.class ? props?.class : ""}`}
    >
      {props.children}
    </p>
  ) : (
    <p className={`title-head ${props?.class ? props?.class : ""}`}>
      {props.children}
    </p>
  );
};

export default Label;
