import React, { useEffect, useState } from "react";
import { Col, Row } from "antd";
import moment from "moment";
import PageHeader from "../../../../component/common/page-Header";
import { DownloadOutlined } from "@ant-design/icons";
import CustomTable from "../../../../component/common/Custom-Table";
import CONSTANTS from "../../../../util/constant/CONSTANTS";
import useHttp from "../../../../hooks/use-http";
import ModalFormCreator from "../../../../component/common/ModalFormCreator";
const PlantOMChecklist = () => {
  const [data, setData] = useState([]);
  const [Allfilter, setAllFilter] = useState(null);
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 20,
    total: 0,
  });
  const [openRoutine, setOpenRoutine] = useState(false);
  const [formData, setFormData] = useState(null);
  const [refresh, setRefresh] = useState(false);
  const API = useHttp();
  const onAddRoutine = (value, clear) => {
    if (!formData) {
      // value.date = value.date.formate("YYYY-MM-DD");
      value.date = moment(value.date.$d).format("YYYY-MM-DD");
      API.sendRequest(
        CONSTANTS.API.checkupgetAdd,
        () => {
          setRefresh((prev) => !prev);
          setOpenRoutine(false);
          clear();
        },
        value,
        "OM checkup added"
      );
    } else {
      delete value.date;
      const UpdateClient = CONSTANTS.API.checkupUpdate;
      UpdateClient.endpoint = UpdateClient.endpoint.replace(":id", formData.id);
      API.sendRequest(
        UpdateClient,
        () => {
          setRefresh((prev) => !prev);
          setOpenRoutine(false);
          setFormData(null);
          clear();
        },
        value,
        "Checkup Updated"
      );
    }
  };
  useEffect(() => {
    let QuaryParams = {
      limit: pagination.pageSize,
      page: pagination.current,
    };
    if (Allfilter?.sort) {
      QuaryParams = { ...QuaryParams, ...Allfilter?.sort };
    }
    if (Allfilter?.filter) {
      Allfilter?.filter?.map((el) => {
        QuaryParams = {
          ...QuaryParams,
          [`autogenerate-mul-array-$${el[0]}$`]: el[1],
        };
      });
    }
    API.sendRequest(
      CONSTANTS.API.checkupgetAll,
      (res) => {
        setPagination((prevPagination) => ({
          ...prevPagination,
          total: res?.data?.count,
        }));
        setData(
          res?.data?.rows?.map((el, i) => ({
            ...el,
            key: el.id,
            no: (pagination.current - 1) * pagination.pageSize + i + 1,
            multButton: {
              Delete: {
                id: el.id,
                key: el.id,
                onClick: (key) => {
                  // console.log(res, "asb");
                  const deleteClient = CONSTANTS.API.checkupDelete;
                  deleteClient.endpoint = deleteClient.endpoint.replace(
                    ":id",
                    key
                  );
                  API.sendRequest(
                    deleteClient,
                    (res) => {
                      // console.log(res);
                      setData((prev) =>
                        prev.filter((item) => item.key !== key)
                      );
                    },
                    "",
                    "checkup Deleted"
                  );
                },
              },
              // Edit: {
              //   key: el.id,
              //   ...el,
              //   onClick: (res) => {
              //     // console.log(a, "edit");
              //     setFormData(res);
              //     // setId(res?.id);
              //     setOpenRoutine(true);
              //   },
              // },
            },
          }))
        );
      },
      QuaryParams
    );
  }, [refresh, pagination.current, pagination.pageSize, Allfilter]);
  const CSVData = [];
  CSVData[0] = CONSTANTS.TABLE.SETTING_ROUTINE_CHECKUP.map((el) => el.title);
  data.map((item, index) => {
    CSVData[index + 1] = CONSTANTS.TABLE.SETTING_ROUTINE_CHECKUP.map(
      (el) => item[el.dataIndex]
    );
    return 0;
  });
  return (
    <Row className="container-main">
      <PageHeader
        data={{
          buttons: [
            !CONSTANTS.USER_ROLE && {
              id: 1,
              action: () => {
                console.log("button1");
                setOpenRoutine(true);
              },
              name: "New",
            },
            {
              id: 2,
              action: () => {
                console.log("button3");
                console.log("download", CSVData);
              },
              icon: <DownloadOutlined />,
              data: CSVData,
              type: "linkicon",
              name: "O & M ",
            },
          ],
          name: "  O & M Routine Checkup",
        }}
        pagination={pagination}
        setPagination={setPagination}
        searchHide
      />
      <ModalFormCreator
        loading={API.isLoading}
        open={openRoutine}
        onCreate={onAddRoutine}
        onCancel={() => {
          setOpenRoutine(false);
          setFormData(null);
        }}
        menu="SETTING_ROUTINE_CHECKUP"
        formData={formData || {}}
        name={`${formData ? "Edit " : "Add New "} O&M Routine Checkup`}
        SubmitName={formData ? "Update " : "Submit"}
      />
      <Col
        span={24}
        style={{
          marginBlock: "15px",
        }}
        className="container-body"
      >
        <CustomTable
          dataSource={data}
          isLoading={API.isLoading}
          name="SETTING_ROUTINE_CHECKUP"
          DeleteSelectedRow
          APIendpoint="checkupDelete"
          onConfirm={() => {
            setRefresh((prev) => !prev);
          }}
          setChanges={(v) => {
            setAllFilter(v);
            setPagination((prev) => ({
              ...prev,
              current: 1,
            }));
          }}
        />
      </Col>
    </Row>
  );
};

export default PlantOMChecklist;
