import React, { useState } from "react";
// import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import m81Logo from "../../../asset/image/M81-Logo-1.png";
// import { useMutation } from "react-query";
// import { useAuth } from "../../services/AuthService";
import ResetPasswordSuccess from "./ResetPasswordSuccess";
import ResetPasswordForm from "./ResetPasswordForm";
import useHttp from "../../../hooks/use-http";
import CONSTANTS from "../../../util/constant/CONSTANTS";

const styles = `
  .reset-password-wrapper .login-container {
    background-repeat: no-repeat;
    background-size: cover;
    min-height: 100vh;
    background-color: #e6e4e2;
  }
  .reset-password-wrapper .form-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: white;
    width: 485px;
    height: auto;
    border: 1px solid lightgray;
    padding: 35px 10px;
    margin: auto 30px;
  }
  .reset-password-wrapper .app-input {
    padding: 12px 15px;
  }
  .reset-password-wrapper .ant-form label {
    padding-left: 20px;
  }
  .reset-password-wrapper .form-detail {
    max-width: 350px;
    margin: 0 auto;
  }
  .reset-password-wrapper .login-form-button {
    float: right;
    width: 100%;
    height: 45px;
    background-color: #4e73df;
    border-color: #4e73df;
    color: white;
  }
  .reset-password-wrapper .hr {
    margin-top: 1rem;
    border: 0;
    border-top: 1px solid rgba(0, 0, 0, 0.1);
  }
  .reset-password-wrapper .footer {
    position: relative;
    margin-top: 1rem;
  }
`;

const ResetPassword = () => {
  const navigate = useNavigate();
  const API = useHttp();
  const [isLoading, setLoadings] = useState(false);
  const [isSuccess, setisSuccess] = useState(false);

  const handleForm = (values) => {
    setLoadings(true);
    const data = window.location.pathname.split("/").pop();
    console.log(data, values);
    const ResetAPIEnd = { ...CONSTANTS.API.resetPassword };
    ResetAPIEnd.endpoint = ResetAPIEnd.endpoint + data;
    API.sendRequest(
      ResetAPIEnd,
      (res) => {
        setLoadings(false);
        console.log(res);
        setisSuccess(true);
      },
      { newPassword: values.password },
      "",
      () => {
        navigate("/forgot-password");
      }
    );
  };

  return (
    <>
      <style>{styles}</style>

      <div className="reset-password-wrapper">
        <div className="login-container">
          <div className="mb50 pt25 logo-text flex-x center">
            <img
              className=""
              src={m81Logo}
              alt="m81Logo"
              height="70"
              width="70"
              onClick={() => navigate("/")}
            />
            {/* <span
              style={{ cursor: "pointer" }}
              className="an-50 ml5 logo-text flex-x center"
              onClick={() => navigate("/")}
            >
              easy
            </span>
            <p className="an-20 ml5 slogan-text text-center">
              Accounting made simple!
            </p> */}
          </div>
          <div className="flex-x center">
            <div className="form-container">
              {isSuccess ? (
                <ResetPasswordSuccess />
              ) : (
                <ResetPasswordForm
                  // isError={isError}
                  // error={error}
                  isLoading={isLoading}
                  handleForm={handleForm}
                />
              )}
            </div>
          </div>
          <div className="footer center flex-x">
            <div className="an-12 normal-text">
              © 2022 - All Rights Reserved. Easy Accounting App
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ResetPassword;
