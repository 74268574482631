import React, { useEffect, useState } from "react";
import CustomTable from "../../../../component/common/Custom-Table";
import CustomButton from "../../../../component/common/Custom-Buttons";
import { Col, Row } from "antd";
import useHttp from "../../../../hooks/use-http";
import CONSTANTS from "../../../../util/constant/CONSTANTS";
import ModalFormCreator from "../../../../component/common/ModalFormCreator";
const PlantSetting = () => {
  const API = useHttp();
  const [equipmentCategory, setEquipmentCategory] = useState([]);
  const [inventoryCategory, setInventoryCategory] = useState([]);
  const [vendors, setVendors] = useState([]);
  const [departments, setDepartments] = useState([]);
  const [categoryAdd, setCategoryAdd] = useState(null);
  const [newVendor, setNewVendor] = useState(false);
  const [newDepartment, setNewDepartment] = useState(false);
  const [formData, setFormData] = useState(null);
  const [refresh, setRefresh] = useState(false);
  const onNewVendorHandler = (value, clear) => {
    // console.log(value, "Object");
    if (formData) {
      const updatevendorAPI = CONSTANTS.API.updatevendor;
      updatevendorAPI.endpoint = updatevendorAPI.endpoint.replace(
        ":id",
        formData.id
      );
      API.sendRequest(
        updatevendorAPI,
        () => {
          // console.log(res);
          setRefresh((prev) => !prev);
          setNewVendor(false);
          setFormData(null);
          clear();
        },
        value,
        "vendor updated"
      );
      return;
    }
    API.sendRequest(
      CONSTANTS.API.createvendor,
      () => {
        setRefresh((prev) => !prev);
        setNewVendor(false);
        clear();
      },
      value,
      "vendor created"
    );
  };
  const onNewDepartmentHandler = (value, clear) => {
    // console.log(value, "Object");
    if (formData) {
      const updateDepartment = CONSTANTS.API.updatedepartment;
      updateDepartment.endpoint = updateDepartment.endpoint.replace(
        ":id",
        formData.id
      );
      API.sendRequest(
        updateDepartment,
        () => {
          // console.log(res);
          setRefresh((prev) => !prev);
          setNewDepartment(false);
          setFormData(null);
          clear();
        },
        value,
        "department updated"
      );
      return;
    }
    API.sendRequest(
      CONSTANTS.API.createdepartment,
      () => {
        // console.log(res);
        setRefresh((prev) => !prev);
        setNewDepartment(false);
        clear();
      },
      value,
      "department created"
    );
  };

  const onCreate = (value, clear) => {
    if (categoryAdd?.inventory) {
      if (categoryAdd?.edit) {
        const UpdateAPIEqp = {
          ...CONSTANTS.API.updateinventoryProductCategory,
        };
        UpdateAPIEqp.endpoint = UpdateAPIEqp.endpoint.replace(
          ":id",
          formData.id
        );
        API.sendRequest(
          UpdateAPIEqp,
          () => {
            // console.log(res);
            setRefresh((prev) => !prev);
            setCategoryAdd(null);
            setFormData(null);
            clear();
          },
          value,
          "inventory category updated"
        );
        return;
      }
      API.sendRequest(
        CONSTANTS.API.addinventoryProductCategory,
        (res) => {
          setCategoryAdd(null);
          setFormData(null);
          setInventoryCategory((prev) => [
            ...prev,
            {
              ...res?.data,
              key: res?.data.id,
              no: prev.length ? prev[prev.length - 1].no + 1 : 1,
              multButton: {
                Delete: {
                  id: res?.data.id,
                  key: res?.data.id,
                  onClick: handleDeleteInventory,
                },
                Edit: {
                  ...res?.data,
                  key: res?.data.id,
                  onClick: () => {
                    // console.log(res, "edit");
                    setFormData(res?.data);
                    setCategoryAdd({
                      inventory: true,
                      edit: true,
                    });
                  },
                },
              },
            },
          ]);
          clear();
        },
        value,
        "inventory category added"
      );
    } else {
      if (categoryAdd?.edit) {
        const UpdateAPIInv = {
          ...CONSTANTS.API.updateequipmentProductCategory,
        };
        UpdateAPIInv.endpoint = UpdateAPIInv.endpoint.replace(
          ":id",
          formData.id
        );
        API.sendRequest(
          UpdateAPIInv,
          () => {
            // console.log(res);
            setRefresh((prev) => !prev);
            setCategoryAdd(null);
            setFormData(null);
            clear();
          },
          value,
          "equipment category updated"
        );
        return;
      }
      API.sendRequest(
        CONSTANTS.API.addequipmentProductCategory,
        (res) => {
          setCategoryAdd(null);
          setFormData(null);
          setEquipmentCategory((prev) => [
            ...prev,
            {
              ...res?.data,
              key: res?.data.id,
              no: prev.length ? prev[prev.length - 1].no + 1 : 1,
              multButton: {
                Delete: {
                  id: res?.data.id,
                  key: res?.data.id,
                  onClick: handleDeleteEquipment,
                },
                Edit: {
                  ...res?.data,
                  key: res?.data.id,
                  onClick: () => {
                    // console.log(res, "edit");
                    setFormData(res?.data);
                    setCategoryAdd({ inventory: false, edit: true });
                  },
                },
              },
            },
          ]);
          clear();
        },
        value,
        "equipment category added"
      );
    }
  };
  const handleDeleteInventory = (key) => {
    const DeletAPIInv = { ...CONSTANTS.API.deleteinventoryProductCategory };
    DeletAPIInv.endpoint = DeletAPIInv.endpoint.replace(":id", key);
    API.sendRequest(
      DeletAPIInv,
      () => {
        // console.log("object Delete", res);
        setInventoryCategory((prev) => prev.filter((item) => item.key !== key));
      },
      "",
      "Deleted Inventory category Successfully"
    );
  };
  const handleDeleteEquipment = (key) => {
    const DeletAPIEqp = {
      ...CONSTANTS.API.deleteequipmentProductCategory,
    };
    DeletAPIEqp.endpoint = DeletAPIEqp.endpoint.replace(":id", key);
    API.sendRequest(
      DeletAPIEqp,
      () => {
        // console.log("object Delete", res);
        setEquipmentCategory((prev) => prev.filter((item) => item.key !== key));
      },
      "",
      "Deleted Equipment category Successfully"
    );
  };
  useEffect(() => {
    API.sendRequest(CONSTANTS.API.equipmentProductCategory, (res) => {
      setEquipmentCategory(
        res?.data?.rows?.map((el, i) => ({
          ...el,
          key: el.id,
          no: i + 1,
          multButton: {
            Delete: {
              id: el.id,
              key: el.id,
              onClick: handleDeleteEquipment,
            },
            Edit: {
              ...el,
              key: el.id,
              onClick: (res) => {
                // console.log(res, "edit");
                setFormData(res);
                setCategoryAdd({ inventory: false, edit: true });
              },
            },
          },
        }))
      );
    });
    API.sendRequest(CONSTANTS.API.inventoryProductCategory, (res) => {
      setInventoryCategory(
        res?.data?.rows?.map((el, i) => ({
          ...el,
          key: el.id,
          no: i + 1,
          multButton: {
            Delete: {
              id: el.id,
              key: el.id,
              onClick: handleDeleteInventory,
            },
            Edit: {
              ...el,
              key: el.id,
              onClick: (res) => {
                // console.log(res, "edit");
                setFormData(res);
                setCategoryAdd({ inventory: true, edit: true });
              },
            },
          },
        }))
      );
    });
    API.sendRequest(CONSTANTS.API.getallvendors, (res) => {
      setVendors(
        res?.data?.rows?.map((el, i) => ({
          ...el,
          key: el.id,
          no: i + 1,
          multButton: {
            Delete: {
              id: el.id,
              key: el.id,
              onClick: (key) => {
                const DeletVendorAPI = { ...CONSTANTS.API.deletevendor };
                DeletVendorAPI.endpoint = DeletVendorAPI.endpoint.replace(
                  ":id",
                  key
                );
                API.sendRequest(
                  DeletVendorAPI,
                  (res) => {
                    // console.log("object Delete", res);
                    setVendors((prev) =>
                      prev.filter((item) => item.key !== key)
                    );
                  },
                  "",
                  "Deleted vendor Successfully"
                );
              },
            },
            Edit: {
              ...el,
              key: el.id,
              onClick: (res) => {
                // console.log(res, "edit");
                setFormData(res);
                // setCategoryAdd({ inventory: true, edit: true });
                setNewVendor(true);
              },
            },
          },
        }))
      );
    });
    API.sendRequest(CONSTANTS.API.getAlldepartment, (res) => {
      setDepartments(
        res?.data?.rows?.map((el, i) => ({
          ...el,
          key: el.id,
          no: i + 1,
          multButton: {
            Delete: {
              id: el.id,
              key: el.id,
              onClick: (key) => {
                const DeleteDepartment = { ...CONSTANTS.API.deletedepartment };
                DeleteDepartment.endpoint = DeleteDepartment.endpoint.replace(
                  ":id",
                  key
                );
                API.sendRequest(
                  DeleteDepartment,
                  () => {
                    setDepartments((prev) =>
                      prev.filter((item) => item.key !== key)
                    );
                  },
                  "",
                  "Deleted Department Successfully"
                );
              },
            },
            Edit: {
              ...el,
              key: el.id,
              onClick: (res) => {
                setFormData(res);
                setNewDepartment(true);
              },
            },
          },
        }))
      );
    });
  }, [refresh]);
  return (
    <>
      <div className="px45 py25 mt20">
        <Col span={24} className="pt15">
          <Row className="mb10">
            <Col span={24} className="dashboard-headers ">
              <Row className="pb20">
                <p className="dashboard-head capitlize">Setting</p>
              </Row>
            </Col>
          </Row>
        </Col>
        <ModalFormCreator
          loading={API.isLoading}
          open={categoryAdd !== null}
          onCreate={onCreate}
          onCancel={() => {
            // console.log("object");
            setCategoryAdd(null);
            setFormData(null);
          }}
          name={`${categoryAdd?.edit ? "Edit " : "Add New "} ${
            categoryAdd?.inventory ? "Inventory" : "Equipment"
          } Category`}
          formData={formData || {}}
          menu="SETTING_CATEGORY_FORM"
          SubmitName={categoryAdd?.edit ? "Update " : "Submit"}
        />
        <Row>
          <Col
            span={12}
            className="dashboard-main-frame px15 py15 pt15 "
            style={{ borderRight: "3px solid #f4f6fc" }}
          >
            <Row className="mb10">
              <Col span={24} className="setting-tb-header">
                <Row>
                  <p className="setting-tb-header pt10 pb15 capitlize">
                    INVENTORY PRODUCT CATEGORIES
                  </p>
                </Row>
              </Col>
            </Row>
            <Row>
              <Col span={24} className="mb10">
                <CustomTable
                  name="SETTING_CATEGORY_TABLE"
                  dataSource={inventoryCategory}
                  isLoading={API.isLoading}
                  scrollAutoOff={true}
                  Other={{ rowSelection: null }}
                  scroll={{
                    x: "80vw",
                    y: "40vh",
                  }}
                />
              </Col>
            </Row>
            <Row>
              <Col span={12}>
                {!CONSTANTS.USER_ROLE &&
                  [
                    {
                      id: 1,
                      action: () => {
                        setCategoryAdd({ inventory: true });
                      },
                      name: "Add New Inventory Category",
                      type: "normal",
                    },
                  ]?.map((button) => (
                    <CustomButton
                      key={button.id}
                      {...button}
                      ButtonDefault={{ block: true, ghost: true }}
                    />
                  ))}
              </Col>
            </Row>
          </Col>
          <Col
            span={12}
            className="dashboard-main-frame px15 py15 pt15  "
            style={{ borderLeft: "3px solid #f4f6fc" }}
          >
            <Row className="mb10">
              <Col span={24} className="setting-tb-header">
                <Row>
                  <p className="setting-tb-header pt10 pb15 capitlize">
                    EQUIPMENT CATEGORIES
                  </p>
                </Row>
              </Col>
            </Row>
            <Row>
              <Col span={24} className="mb10">
                <CustomTable
                  isLoading={API.isLoading}
                  name="SETTING_CATEGORY_TABLE"
                  scrollAutoOff={true}
                  scroll={{
                    x: "80vw",
                    y: "40vh",
                  }}
                  Other={{ rowSelection: null }}
                  dataSource={equipmentCategory}
                />
              </Col>
            </Row>
            <Row>
              <Col span={12}>
                {!CONSTANTS.USER_ROLE &&
                  [
                    {
                      id: 1,
                      action: () => {
                        // console.log("button1");
                        setCategoryAdd({ inventory: false });
                      },
                      name: "Add New Equipment Category",
                      type: "normal",
                    },
                  ]?.map((button) => (
                    <CustomButton
                      key={button.id}
                      {...button}
                      ButtonDefault={{ block: true, ghost: true }}
                    />
                  ))}
              </Col>
            </Row>
          </Col>
        </Row>
        <ModalFormCreator
          loading={API.isLoading}
          open={newVendor}
          onCreate={onNewVendorHandler}
          onCancel={() => {
            setNewVendor(false);
            setFormData(null);
          }}
          formData={formData || {}}
          name={`${formData ? "Edit" : "New"} vendor ${
            !formData ? " REGISTRATION form" : ""
          }`}
          SubmitName={formData ? "Update " : "Submit"}
          menu="NEW_VENDOR_REGISTRATION"
        />
        <Col span={24} className="dashboard-main-frame px15 py15 mt20">
          <Row className="mb30">
            <Col span={24} className="setting-tb-header">
              <Row>
                <p className="setting-tb-header pt10 pb15 capitlize">VENDORS</p>
              </Row>
              <Row>
                {!CONSTANTS.USER_ROLE &&
                  [
                    {
                      id: 1,
                      action: () => {
                        // console.log("button1");
                        setNewVendor(true);
                      },
                      name: "New Vendor",
                      type: "normal",
                    },
                  ]?.map((button) => (
                    <CustomButton key={button.id} {...button} />
                  ))}
              </Row>
            </Col>
          </Row>
          <CustomTable
            name="SETTING_VENDOR"
            dataSource={vendors}
            Other={{ rowSelection: null }}
          />
        </Col>
        <ModalFormCreator
          loading={API.isLoading}
          open={newDepartment}
          onCreate={onNewDepartmentHandler}
          onCancel={() => {
            setNewDepartment(false);
            setFormData(null);
          }}
          formData={formData || {}}
          name={`${formData ? "Edit" : "New"} Department ${
            !formData ? " REGISTRATION form" : ""
          }`}
          SubmitName={formData ? "Update " : "Submit"}
          menu="NEW_DEPARTMENT_REGISTRATION"
        />
        <Col span={24} className="dashboard-main-frame px15 py15 mt20">
          <Row className="mb30">
            <Col span={24} className="setting-tb-header">
              <Row>
                <p className="setting-tb-header pt10 pb15 capitlize">
                  DEPARTMENTS
                </p>
              </Row>
              <Row>
                {!CONSTANTS.USER_ROLE &&
                  [
                    {
                      id: 1,
                      action: () => {
                        // console.log("button1");
                        setNewDepartment(true);
                      },
                      name: "New Department",
                      type: "normal",
                    },
                  ]?.map((button) => (
                    <CustomButton key={button.id} {...button} />
                  ))}
              </Row>
            </Col>
          </Row>
          <CustomTable
            name="SETTING_DEPARTMENT_TABLE"
            dataSource={departments}
            isLoading={API.isLoading}
            Other={{ rowSelection: null }}
          />
        </Col>
      </div>
    </>
  );
};

export default PlantSetting;
