import Eye from "../../asset/image/eye.png";
import { Image, Popconfirm, Select } from "antd";
import {
  DeleteOutlined,
  DownloadOutlined,
  EditOutlined,
  EyeOutlined,
  FilePdfOutlined,
} from "@ant-design/icons";
import moment from "moment/moment";

const alphanumericSort = (a, b, value) => {
  if (isNaN(parseInt(a[value]))) {
    return a[value]?.localeCompare(b[value]);
  }
  return a[value] - b[value];
};

const Units = [
  {
    id: "Nos",
    value: "Nos",
    Label: "Nos",
    text: "Nos",
  },
  {
    id: "Cm",
    value: "Cm",
    Label: "Cm",
    text: "Cm",
  },
  {
    id: "Km",
    value: "Km",
    Label: "Km",
    text: "Km",
  },
  {
    id: "Meter",
    value: "Meter",
    Label: "Meter",
    text: "Meter",
  },
  {
    id: "Inches",
    value: "Inches",
    Label: "Inches",
    text: "Inches",
  },
  {
    id: "Kg",
    value: "Kg",
    Label: "Kg",
    text: "Kg",
  },
  {
    id: "Lot",
    value: "Lot",
    Label: "Lot",
    text: "Lot",
  },
  {
    id: "Gram",
    value: "Gram",
    Label: "Gram",
    text: "Gram",
  },
  {
    id: "Liter",
    value: "Liter",
    Label: "Liter",
    text: "Liter",
  },
];
const MaintanancePriority = [
  {
    id: "Critical",
    value: "Critical",
    Label: "Critical",
    text: "Critical",
  },
  {
    id: "Major",
    value: "Major",
    Label: "Major",
    text: "Major",
  },
  {
    id: "Minor",
    value: "Minor",
    Label: "Minor",
    text: "Minor",
  },
];

const maintenanceRequestStatus = [
  {
    id: "New",
    value: "New",
    Label: "New",
    text: "New",
  },
  {
    id: "Planned",
    value: "Planned",
    Label: "Planned",
    text: "Planned",
  },
  {
    id: "Inprogress",
    value: "Inprogress",
    Label: "Inprogress",
    text: "Inprogress",
  },
  {
    id: "Completed",
    value: "Completed",
    Label: "Completed",
    text: "Completed",
  },
  {
    id: "Cancelled",
    value: "Cancelled",
    Label: "Cancelled",
    text: "Cancelled",
  },
];
const Color = {
  Minor: "#9DA6BA",
  Critical: "#E84A50",
  Major: "#F7C137",
  Back: {
    Minor: "rgba(157, 166, 186, 0.15)",
    Critical: "rgba(232, 74, 80, 0.15)",
    Major: "rgba(247, 193, 55, 0.15)",
  },
  Type: {
    New: "#2E5BFF",
    Planned: "#8C54FF",
    Inprogress: "#F7C137",
    Completed: "#2CC2A5",
    Cancelled: "#E84A50",
  },
};

const DocumentType = [
  {
    id: "Warrant Document",
    text: "Warrant Document",
    value: "Warrant Document",
    Label: "Warranty Document",
  },
  {
    id: "Manual Book",
    text: "Manual Book",
    value: "Manual Book",
    Label: "Manual Book",
  },
  {
    id: "ID Card",
    text: "ID Card",
    value: "ID Card",
    Label: "ID Card",
  },
  {
    id: "Other",
    text: "Other",
    value: "Other",
    Label: "Other",
  },
];
const RenderActionButtons = (value) => {
  let ButtonsArray = [];
  // console.log(value, "mul", Object.entries(value));

  Object.entries(value)?.forEach((elp) => {
    const option = elp[0];

    switch (option) {
      case "Delete":
        ButtonsArray.push(RenderDeleteButton(elp[1]));
        break;
      case "Edit":
        ButtonsArray.push(RenderEditButton(elp[1]));

        break;
      case "View":
        ButtonsArray.push(RenderViewButton(elp[1]));

        break;
      case "Download":
        if (Array.isArray(elp[1])) {
          elp[1]?.forEach((el) => ButtonsArray.push(RenderDownloadButton(el)));
        } else {
          ButtonsArray.push(RenderDownloadButton([elp[1]]));
        }
        break;
      default:
        break;
    }
  });

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "space-around",
        // paddingRight: "5px",
      }}
      onClick={(e) => {
        e.stopPropagation();
      }}
    >
      {ButtonsArray?.map((el, i) => (
        <div key={i}>{el}</div>
      ))}
    </div>
  );
};

const RenderDeleteButton = (value) => {
  return (
    <Popconfirm
      title="Sure to delete?"
      onConfirm={(e) => {
        e.stopPropagation();
        value?.onClick(value.key);
      }}
    >
      {value?.name ? (
        value?.name
      ) : (
        <DeleteOutlined style={{ color: "black" }} />
      )}
    </Popconfirm>
  );
};
const RenderDeleteWithoutConfirmation = (value) => {
  return (
    <Popconfirm
      title="Sure to delete?"
      open={false}
      onOpenChange={() => value?.onClick(value.key)}
      onConfirm={() => value?.onClick(value.key)}
    >
      {value?.name ? (
        value?.name
      ) : (
        <DeleteOutlined style={{ color: "black" }} />
      )}
    </Popconfirm>
  );
};

const RenderDownloadButton = (value) => {
  return value ? (
    <div
      style={{ color: "black" }}
      onClick={(e) => {
        e.stopPropagation();
        if (value?.onClick) {
          value.onClick();
        } else if (value) {
          window.open(value, "Download");
        }
      }}
    >
      {value?.icon ? value?.icon : <DownloadOutlined />}
    </div>
  ) : null;
};

// const handleGeneratePdf = () => {
//   const doc = new jsPDF({
//     orientation: "1",
//     format: [2480, 3508],
//     unit: "px",
//   });
//   // Adding the fonts.
//   doc.setFont("Inter-Regular", "normal");
//   doc.html(reportTemplateRef.current, {
//     async callback(doc) {
//       await doc.save("document");
//     },
//   });
// };

const RenderEditButton = (props) => {
  const { icon, onClick } = props;

  return (
    <div
      style={{ color: "black" }}
      className="pointer"
      onClick={(e) => {
        e.stopPropagation();
        onClick && onClick(props);
      }}
    >
      {icon ? icon : <EditOutlined />}
    </div>
  );
};

const RenderViewButton = (value) => {
  if (value && value?.endsWith(".pdf")) {
    return (
      <a href={value} target="_blank" rel="noopener noreferrer">
        {value?.icon ? (
          <span className="custom-icon">{value?.icon}</span>
        ) : (
          <FilePdfOutlined style={{ color: "black" }} />
        )}
      </a>
    );
  }

  return (
    <div>
      {value?.icon ? (
        <span className="custom-icon">
          <EyeOutlined style={{ color: "black" }} />
        </span>
      ) : (
        <Image
          width={20}
          src={Eye}
          className="pointer"
          preview={{
            mask: false,
            src: value,
          }}
        />
      )}
      {value?.name && <span className="custom-name">{value?.name}</span>}
    </div>
  );
};

export const loginRoot = "/";
export const appRoot = "/app";
export const ROUTES = {
  FORGOT_PASSWORD: "/forgot-password",
  RESET_PASSWORD: "/reset/:id",
  DASHBOARD: "/dashboard",
  INVENTORY: "/inventory",
  INVENTORY_DETAILS: "/details",
  INVENTORY_MOVEHISTORY: "/movehistory",
  INVENTORY_EQUIPMENT: "/equipment",
  INVENTORY_EQUIPMENT_DETAILS: "/equipment/:eid/:id",
  INVENTORY_TRANSFER: "/transfer",
  INVENTORY_PURCHASE: "/purchase",
  EXPENSES: "/expenses",
  EXPENSES_DETAIL: "/detail",
  EXPENSES_VEHICAL: "/vehical",
  MAINTENANCE: "/maintenance",
  MAINTENANCE_KANBAN: "/kanban",
  MAINTENANCE_CALENDAR: "/calendar",
  MAINTENANCE_DETAILS: "/details",
  EMPLOYEE: "/employee",
  EMPLOYEE_DETAILS: "/employee/:eid/:id",
  LOGBOOK: "/logBook",
  LOGBOOK_LOG: "/log",
  LOGBOOK_ABTMETER: "/ABTMeter",
  LOGBOOK_CLEANLOG: "/cleaningLog",
  PLANTDETAILS: "/plantDetails",
  PLANTDETAILS_DETAILS: "/details",
  PLANTDETAILS_OMChecklist: "/OMChecklist",
  PLANTDETAILS_SETTING: "/setting",
};

const CONSTANTS = {
  BASE_URL: "http://20.244.39.211/api/v1",
  USER_ROLE: false,
  GETMe: null,
  FORM_FIELD: {
    // ATTENDANCE_FORM: [
    //   {
    //     no: 1,
    //     id: 1,
    //     item: [
    //       {
    //         no: 1,
    //         Label: "Date",
    //         name: "date",
    //         id: "date",
    //         type: "date",
    //         required: true,
    //       },
    //     ],
    //   },
    // ],
    // CHECKLIST_FORM: [
    //   {
    //     no: 1,
    //     id: 1,
    //     item: [
    //       {
    //         no: 1,
    //         Label: "Attended By",
    //         name: "aby",
    //         id: "aby",
    //         type: "select",
    //         required: true,
    //       },
    //       {
    //         no: 2,
    //         Label: "Date",
    //         name: "date",
    //         id: "date",
    //         type: "date",
    //         required: true,
    //       },
    //     ],
    //   },
    // ],
    EMPLOYEE_NEW_MODAL: [
      {
        no: 1,
        Label: "Employee name",
        name: "name",
        onlyText: true,
        id: "name",
        type: "text",
        placeholder: "Enter Employee name",
        required: true,
      },
      {
        no: 2,
        id: 2,
        item: [
          {
            no: 2,
            Label: "Employee ID",
            name: "eId",
            id: "eId",
            type: "text",
            required: true,
            pattern: /^[a-zA-Z0-9]+$/,
          },
          {
            no: 3,
            Label: "Position",
            name: "position",
            placeholder: "Enter position",
            id: "position",
            type: "text",
            required: true,
          },
        ],
      },
      {
        no: 3,
        id: 3,
        item: [
          {
            no: 4,
            Label: "Department",
            name: "departmentId",
            placeholder: "Select Department",
            id: "departmentId",
            type: "select",
            required: true,
            option: [
              // {
              //   id: 8,
              //   value: 8,
              //   Label: "Department of Plates",
              // },
              // {
              //   id: 7,
              //   value: 7,
              //   Label: "Department 6",
              // },
              // {
              //   id: 6,
              //   value: 6,
              //   Label: "Department 4",
              // },
              // {
              //   id: 5,
              //   value: 5,
              //   Label: "General Department",
              // },
            ],
          },
          {
            no: 5,
            Label: "Contact",
            name: "mobile",
            id: "mobile",
            type: "mobile",
            required: true,
          },
        ],
      },
      {
        no: 4,
        id: 4,
        item: [
          {
            no: 10,
            Label: "Working Shift",
            name: "workingShiftId",
            placeholder: "Select the shift",

            id: "workingShiftId",
            type: "select",
            required: true,
            option: [],
          },

          {
            no: 7,
            Label: "Manager",
            name: "managerId",
            placeholder: "Select the Manager",
            id: "managerId",
            type: "select",
            required: false,
            option: [],
          },
        ],
      },
      {
        no: 5,
        Label: "Address",
        name: "address",
        placeholder: "Enter Your Address",
        id: "address",
        type: "text",
        required: false,
      },
      {
        no: 6,
        id: 6,
        item: [
          {
            no: 6,
            Label: "Email",
            name: "email",
            placeholder: "Enter Email ID",
            id: "email",
            type: "email",
            required: true,
          },
          {
            no: 10,
            Label: "Password",
            name: "password",
            id: "password",
            type: "password",
            required: true,
          },
        ],
      },
      {
        no: 7,
        id: 7,
        item: [
          {
            no: 11,
            Label: "Aadhar Card",
            name: "aadhar",
            placeholder: "Enter Aadhar Number",
            id: "aadhar",
            type: "number",
            required: false,
          },
          {
            no: 12,
            Label: "Employment Type",
            name: "employmentTypeId",
            placeholder: "Select Employment ",

            id: "employmentTypeId",
            type: "select",
            required: true,
            option: [],
          },
        ],
      },
      {
        no: 8,
        id: 8,
        item: [
          {
            no: 13,
            Label: "Gender",
            name: "gender",

            id: "gender",
            option: [
              {
                id: "F",
                value: "F",
                Label: "Female",
              },
              {
                id: "M",
                value: "M",
                Label: "Male",
              },
            ],
            placeholder: "Select Gender",
            type: "select",
            required: true,
          },
          {
            no: 14,
            Label: "Access role",
            name: "role",

            placeholder: "Select Access Role ",
            id: "role",
            type: "select",
            required: true,
            option: [
              {
                id: "Admin",
                value: "Admin",
                Label: "Admin",
              },
              {
                id: "User",
                value: "User",
                Label: "User",
              },
              {
                id: "Employee",
                value: "Employee",
                Label: "Employee",
              },
            ],
          },
        ],
      },
    ],
    EMPLOYEE_EDIT_MODAL: [
      {
        no: 1,
        Label: "Employee name",
        name: "name",
        onlyText: true,
        id: "name",
        type: "text",
        placeholder: "Enter Employee name",
        required: true,
      },
      {
        no: 2,
        id: 2,
        item: [
          {
            no: 2,
            Label: "Employee ID",
            name: "eId",
            id: "eId",
            type: "text",
            required: true,
            pattern: /^[a-zA-Z0-9]+$/,
          },
          {
            no: 3,
            Label: "Position",
            name: "position",
            placeholder: "Enter position",
            id: "position",
            type: "text",
            required: true,
          },
        ],
      },
      {
        no: 3,
        id: 3,
        item: [
          {
            no: 4,
            Label: "Department",
            name: "departmentId",
            placeholder: "Select Department",
            id: "departmentId",
            type: "select",
            required: true,
            option: [],
          },
          {
            no: 5,
            Label: "Contact",
            name: "mobile",
            id: "mobile",
            type: "mobile",
            required: true,
          },
        ],
      },
      {
        no: 4,
        id: 4,
        item: [
          {
            no: 10,
            Label: "Working Shift",
            name: "workingShiftId",
            placeholder: "Select the shift",

            id: "workingShiftId",
            type: "select",
            required: true,
            option: [],
          },
          {
            no: 7,
            Label: "Manager",
            name: "managerId",
            placeholder: "Select the Manager",
            id: "managerId",
            type: "select",
            required: false,
            option: [],
          },
        ],
      },
      {
        no: 5,
        Label: "Address",
        name: "address",
        placeholder: "Enter Your Address",
        id: "address",
        type: "text",
        required: false,
      },
      {
        no: 6,
        id: 6,
        item: [
          {
            no: 6,
            Label: "Email",
            name: "email",
            placeholder: "Enter Email ID",
            id: "email",
            type: "email",
            required: true,
          },
          // {
          //   no: 10,
          //   Label: "Password",
          //   name: "password",
          //   id: "password",
          //   type: "password",
          //   required: false,
          // },
        ],
      },
      {
        no: 7,
        id: 7,
        item: [
          {
            no: 11,
            Label: "Aadhar Card",
            name: "aadhar",
            placeholder: "Enter Aadhar Number",
            id: "aadhar",
            type: "number",
            required: false,
          },
          {
            no: 12,
            Label: "Employment Type",
            name: "employmentTypeId",
            placeholder: "Select Employment ",

            id: "employmentTypeId",
            type: "select",
            required: true,
            option: [],
          },
        ],
      },
      {
        no: 8,
        id: 8,
        item: [
          {
            no: 13,
            Label: "Gender",
            name: "gender",
            id: "gender",
            option: [
              {
                id: "F",
                value: "F",
                Label: "Female",
              },
              {
                id: "M",
                value: "M",
                Label: "Male",
              },
            ],
            placeholder: "Select Gender",
            type: "select",
            required: true,
          },
          {
            no: 14,
            Label: "Access role",
            name: "role",

            placeholder: "Select Access Role ",
            id: "role",
            type: "select",
            required: true,
            option: [
              {
                id: "Admin",
                value: "Admin",
                Label: "Admin",
              },
              {
                id: "User",
                value: "User",
                Label: "User",
              },
              {
                id: "Employee",
                value: "Employee",
                Label: "Employee",
              },
            ],
          },
        ],
      },
    ],

    EMPLOYEE_LEAVE_REQUEST: [
      {
        no: 1,
        Label: "Employee name",
        name: "name",
        id: "name",
        type: "text",
        placeholder: "Enter Employee name",
        required: false,
      },
      {
        no: 2,
        id: 2,
        item: [
          {
            no: 2,
            Label: "Leave Type",
            name: "leaveTypeId",
            id: "leaveTypeId",
            type: "select",
            required: true,
            option: [],
          },
        ],
      },
      {
        no: 3,
        id: 3,
        item: [
          {
            no: 3,
            Label: "leave date from",
            name: "from",
            id: "from",
            type: "date",
            required: true,
          },
          {
            no: 4,
            Label: "Leave date to",
            name: "to",
            id: "to",
            type: "date",
            required: true,
          },
        ],
      },

      {
        no: 5,
        Label: "reason",
        name: "reason",
        id: "reason",
        type: "text",
        required: false,
      },
      {
        no: 6,
        id: 6,
        item: [
          {
            no: 6,
            Label: "Supporting docs",
            name: "supportingDocs",
            id: "supportingDocs",
            type: "file",
            required: false,
          },
        ],
      },
    ],
    DOCUMENT_UPLOAD: [
      {
        no: 1,
        Label: "Document Name",
        name: "name",
        id: "name",
        type: "text",
        required: false,
      },

      {
        no: 2,
        id: 2,
        item: [
          {
            no: 2,
            Label: "Doc Number",
            name: "number",
            id: "number",
            type: "text",
            required: true,
          },
          {
            no: 3,
            Label: "Document Type",
            name: "docType",
            id: "docType",
            type: "select",
            required: true,
            option: DocumentType,
          },
        ],
      },
      {
        no: 4,
        Label: "Upload Document",
        name: "documents",
        id: "documents",
        type: "dragupload",
        required: false,
      },
    ],
    MAINTENANCE_REQUEST_FORM: [
      {
        no: 1,
        Label: "Title",
        name: "title",
        id: "title",
        type: "text",
        required: true,
      },
      {
        no: 2,
        id: 2,
        item: [
          {
            no: 2,
            Label: "assignes",
            name: "assignedId",
            id: "assignedId",
            type: "select",
            required: true,
            option: [],
          },
          {
            no: 3,
            Label: "created by",
            name: "createdBy",
            id: "createdBy",
            type: "select",
            required: true,
            option: [],
          },
        ],
      },
      {
        no: 3,
        id: 3,
        item: [
          {
            no: 4,
            Label: "equipment",
            name: "equipmentId",
            id: "equipmentId",
            type: "select",
            required: true,
            option: [
              {
                id: "Other1",
                value: "Other1",
                Label: "Other1",
              },
              {
                id: "Other2",
                value: "Other2",
                Label: "Other2",
              },
              {
                id: "Other3",
                value: "Other3",
                Label: "Other3",
              },
            ],
          },
          {
            no: 5,
            Label: "maintanance category",
            name: "category",
            id: "category",
            type: "select",
            required: true,
            option: [
              {
                id: "Routine",
                value: "Routine",
                Label: "Routine",
              },
              {
                id: "Breakdown",
                value: "Breakdown",
                Label: "Breakdown",
              },
              {
                id: "Fault",
                value: "Fault",
                Label: "Fault",
              },
              {
                id: "Notice",
                value: "Notice",
                Label: "Notice",
              },
            ],
          },
        ],
      },
      {
        no: 4,
        Label: "description",
        name: "description",
        id: "description",
        type: "text",
        required: false,
      },
      {
        no: 5,
        id: 5,
        item: [
          {
            no: 7,
            Label: "date & time",
            name: "date",
            id: "date",
            showTime: true,
            type: "date",
            disabled: true,

            required: false,
          },
          {
            no: 8,
            Label: "priority",
            name: "prioriti",
            id: "prioriti",
            type: "select",
            required: true,
            option: MaintanancePriority,
          },
        ],
      },
      {
        no: 6,
        id: 6,
        item: [
          {
            no: 9,
            Label: "remarks",
            name: "remarks",
            id: "remarks",
            type: "text",
            required: false,
          },
          {
            no: 10,
            Label: "due Date",
            name: "due",
            id: "due",
            disabled: true,
            showTime: true,
            type: "date",
            required: true,
            rule: ({ getFieldValue }) => ({
              validator(_, value) {
                const dateNTime = getFieldValue("date");
                if (!dateNTime || !value) {
                  return Promise.resolve();
                }
                if (value.isAfter(dateNTime)) {
                  return Promise.resolve();
                }
                return Promise.reject(
                  "Due date cannot be less than or equal to the selected date and time!"
                );
              },
            }),
          },
        ],
      },
      {
        no: 7,
        id: 7,
        item: [
          {
            no: 11,
            Label: "How did you come to know?",
            name: "comeToKnow",
            id: "comeToKnow",
            type: "select",
            required: true,
            option: [
              {
                id: "By Visual Inspection",
                value: "By Visual Inspection",
                Label: "By Visual Inspection",
              },
              {
                id: "By SCADA Monitoring",
                value: "By SCADA Monitoring",
                Label: "By SCADA Monitoring",
              },
            ],
          },
          {
            no: 12,
            Label: "status",
            name: "status",
            id: "status",
            type: "select",
            disabled: "edit",
            required: true,
            option: maintenanceRequestStatus,
          },
        ],
      },
      {
        no: 8,
        id: 8,
        item: [
          {
            no: 13,
            Label: "Approval requested",
            name: "approvalRequired",
            id: "approvalRequired",
            type: "radio",
            required: true,
            option: [
              {
                id: true,
                value: true,
                Label: "Yes",
              },
              {
                id: false,
                value: false,
                Label: "No",
              },
            ],
          },
        ],
      },
    ],
    MAINTENANCE_REQUEST_FORM_NEW: [
      {
        no: 1,
        Label: "Title",
        name: "title",
        id: "title",
        type: "text",
        required: true,
      },
      {
        no: 2,
        id: 2,
        item: [
          {
            no: 2,
            Label: "assignes",
            name: "assignedId",
            id: "assignedId",
            type: "select",
            required: true,
            option: [],
          },
          {
            no: 3,
            Label: "created by",
            name: "createdBy",
            id: "createdBy",
            type: "select",
            required: true,
            option: [],
          },
        ],
      },
      {
        no: 3,
        id: 3,
        item: [
          {
            no: 4,
            Label: "equipment",
            name: "equipmentId",
            id: "equipmentId",
            type: "select",
            required: true,
            option: [
              {
                id: "Other1",
                value: "Other1",
                Label: "Other1",
              },
              {
                id: "Other2",
                value: "Other2",
                Label: "Other2",
              },
              {
                id: "Other3",
                value: "Other3",
                Label: "Other3",
              },
            ],
          },
          {
            no: 5,
            Label: "maintanance category",
            name: "category",
            id: "category",
            type: "select",
            required: true,
            option: [
              {
                id: "Routine",
                value: "Routine",
                Label: "Routine",
              },
              {
                id: "Breakdown",
                value: "Breakdown",
                Label: "Breakdown",
              },
              {
                id: "Fault",
                value: "Fault",
                Label: "Fault",
              },
              {
                id: "Notice",
                value: "Notice",
                Label: "Notice",
              },
            ],
          },
        ],
      },
      {
        no: 4,
        Label: "description",
        name: "description",
        id: "description",
        type: "text",
        required: false,
      },
      {
        no: 5,
        id: 5,
        item: [
          {
            no: 7,
            Label: "date & time",
            name: "date",
            id: "date",
            showTime: true,
            type: "date",
            required: false,
          },
          {
            no: 8,
            Label: "priority",
            name: "prioriti",
            id: "prioriti",
            type: "select",
            required: true,
            option: MaintanancePriority,
          },
        ],
      },
      {
        no: 6,
        id: 6,
        item: [
          {
            no: 9,
            Label: "remarks",
            name: "remarks",
            id: "remarks",
            type: "text",
            required: false,
          },
          {
            no: 10,
            Label: "due Date",
            name: "due",
            id: "due",
            showTime: true,
            type: "date",
            required: true,
            rule: ({ getFieldValue }) => ({
              validator(_, value) {
                const dateNTime = getFieldValue("date");
                if (!dateNTime || !value) {
                  return Promise.resolve();
                }
                if (value.isAfter(dateNTime)) {
                  return Promise.resolve();
                }
                return Promise.reject(
                  "Due date cannot be less than or equal to the selected date and time!"
                );
              },
            }),
          },
        ],
      },
      {
        no: 7,
        id: 7,
        item: [
          {
            no: 11,
            Label: "How did you come to know?",
            name: "comeToKnow",
            id: "comeToKnow",
            type: "select",
            required: true,
            option: [
              {
                id: "By Visual Inspection",
                value: "By Visual Inspection",
                Label: "By Visual Inspection",
              },
              {
                id: "By SCADA Monitoring",
                value: "By SCADA Monitoring",
                Label: "By SCADA Monitoring",
              },
            ],
          },
          {
            no: 12,
            Label: "status",
            name: "status",
            id: "status",
            type: "select",
            disabled: "edit",
            required: true,
            option: maintenanceRequestStatus.filter(
              (el) => el.id !== "Completed"
            ),
          },
        ],
      },
      {
        no: 8,
        id: 8,
        item: [
          {
            no: 13,
            Label: "Approval requested",
            name: "approvalRequired",
            id: "approvalRequired",
            type: "radio",
            required: true,
            option: [
              {
                id: true,
                value: true,
                Label: "Yes",
              },
              {
                id: false,
                value: false,
                Label: "No",
              },
            ],
          },
        ],
      },
    ],
    SUPPLY_IN_INVENTORY: [
      {
        no: 1,
        Label: "Product Name",
        name: "name",
        id: "name",
        type: "autocomplete",
        pattern: /^[a-zA-Z0-9\s.,/()%\-]+$/,
        option: [],
        onSelect: (res) => {
          console.log(res);
        },
        required: false,
        disabled: "edit",
      },
      {
        no: 2,
        id: 2,
        item: [
          {
            no: 2,
            Label: "Product Type",
            name: "type",
            id: "type",
            type: "select",
            disabled: "edit",
            option: [
              {
                id: "Consumable",
                value: "Consumable",
                Label: "Consumable",
              },
              {
                id: "Storable",
                value: "Storable",
                Label: "Storable",
              },
            ],
            required: true,
          },
          {
            no: 3,
            Label: "Unit of Measure",
            name: "unit",
            id: "unit",
            type: "select",
            disabled: "edit",
            option: Units,
            required: true,
          },
        ],
      },
      {
        no: 4,
        id: 4,
        item: [
          {
            no: 4,
            Label: "Quantity",
            name: "quantity",
            id: "quantity",
            type: "number",
            rule: ({ getFieldValue }) => ({
              validator(_, value) {
                if (
                  getFieldValue("tracking") === "Unique serial number" &&
                  value !== 1
                ) {
                  return Promise.reject(
                    new Error("Please enter valid Value 1")
                  );
                }
                return Promise.resolve();
              },
            }),
            required: true,
          },
          {
            no: 5,
            Label: "Product Category",
            name: "productCategoryId",
            id: "productCategoryId",
            disabled: "edit",
            option: [
              // {
              //   id: "setting",
              //   value: "setting",
              //   Label: "setting",
              // },
            ],
            type: "select",
            required: true,
          },
        ],
      },
      {
        no: 6,
        id: 6,
        item: [
          {
            no: 6,
            Label: "Cost",
            name: "cost",
            id: "cost",
            type: "number",
            required: true,
          },
          {
            no: 7,
            Label: "Tracking",
            name: "tracking",
            id: "tracking",
            type: "radio",
            required: true,
            defaultValue: 3,
            option: [
              {
                id: "Unique serial number",
                value: "Unique serial number",
                Label: "Unique serial number",
              },
              {
                id: "By lots",
                value: "By lots",
                Label: "By lots",
              },
              {
                id: "NA",
                value: "NA",
                Label: "NA",
              },
            ],
          },
        ],
      },
      {
        no: 8,
        id: 8,
        item: [
          {
            no: 8,
            Label: "Tracking Id",
            name: "trackingId",
            id: "trackingId",
            type: "text",
            required: false,
            rule: ({ getFieldValue }) => ({
              validator(_, value) {
                if (getFieldValue("tracking") === "NA" || value) {
                  return Promise.resolve();
                }
                return Promise.reject(
                  new Error("Please enter valid Tracking Id")
                );
              },
            }),
          },
          {
            no: 9,
            Label: "Date",
            name: "date",
            id: "date",
            type: "date",
            disabledDate: (current) => current.isAfter(moment()),

            required: false,
          },
        ],
      },
    ],
    MAINTENANCE_RESOLUTION_FORM: [
      {
        no: 1,
        Label: "Action Taken",
        name: "action",
        id: "action",
        type: "text",
        required: true,
      },
      {
        no: 2,
        id: 2,
        item: [
          {
            no: 2,
            Label: "Date",
            name: "date",
            id: "date",
            type: "date",
            showTime: true,
            required: true,
            // disabledDate: (current) => current.isBefore(moment()),
          },
        ],
      },
    ],
    SUPPLY_OUT_INVENTORY: [
      {
        no: 1,
        id: 1,
        item: [
          {
            Label: "RECEIVER'S NAME",
            name: "receiver",
            id: "receiver",
            type: "autocomplete",
            required: true,
            option: [],
          },
          {
            no: 2,
            Label: "Date",
            name: "date",
            id: "date",
            type: "date",
            // showTime: true,
            disabledDate: (current) => current.isAfter(moment()),
            required: false,
          },
        ],
      },
      {
        no: 3,
        id: 3,
        item: [
          {
            no: 3,

            Label: "Select Ticket",
            name: "maintenanceRequestId",
            id: "maintenanceRequestId",
            type: "select",
            required: false,
            option: [],
          },
          {
            no: 4,

            Label: "Usage Type",
            name: "usageType",
            id: "usageType",
            type: "select",
            required: false,
            defaultValue: "Transfer",
            option: [
              {
                id: "Transfer",
                value: "Transfer",
                Label: "Transfer",
              },
              {
                id: "Consumed",
                value: "Consumed",
                Label: "Consumed",
              },
              {
                id: "Replaced",
                value: "Replaced",
                Label: "Replaced",
              },
              {
                id: "Expired/Defective",
                value: "Expired/Defective",
                Label: "Expired/Defective",
              },
            ],
          },
        ],
      },
    ],
    TRANSFER_FORM_TABLE: [
      {
        no: 1,
        id: 1,
        item: [
          {
            Label: "RECEIVER'S NAME",
            name: "receiver",
            id: "receiver",
            type: "select",
            required: false,
            option: [],
          },
          {
            no: 2,
            Label: "Date",
            name: "date",
            id: "date",
            type: "date",
            required: false,
          },
        ],
      },
      {
        no: 3,
        id: 3,
        item: [
          {
            no: 3,
            Label: "Quantity",
            name: "quantity",
            id: "quantity",
            type: "number",
            required: true,
          },
          // {
          //   no: 7,
          //   Label: "Serial Number",
          //   name: "srNumber",
          //   id: "srNumber",
          //   type: "text",
          //   required: false,
          // },
        ],
      },
    ],
    SUPPLY_OUT_INVENTORY_INNER: [
      {
        no: 1,
        Label: "Product",
        name: "productName",
        id: "productName",
        type: "select",
        option: [],
        required: true,
        width: 18,
      },
      // {
      //   no: 3,
      //   Label: "Unit of Measure",
      //   name: "unit",
      //   id: "unit",
      //   type: "select",
      //   // disabled: "edit",
      //   option: Units,
      //   required: true,
      //   width: 5,
      // },
      {
        no: 2,
        Label: "Quantity",
        name: "quantity",
        id: "quantity",
        type: "number",
        required: true,
        width: 5,
      },
    ],
    RESOLUTION_FORM_INNER: [
      {
        no: 1,
        Label: "Product",
        name: "productName",
        id: "productName",
        type: "select",
        option: [],
        required: true,
        width: 14,
      },
      {
        no: 2,
        Label: "Quantity",
        name: "quantity",
        id: "quantity",
        type: "number",
        required: true,
        width: 3,
      },
      {
        no: 3,
        Label: "Usage Type",
        name: "usageType",
        id: "usageType",
        type: "select",
        required: true,
        defaultValue: "New",
        option: [
          {
            id: "New",
            value: "New",
            Label: "New",
          },
          {
            id: "Consumed",
            value: "Consumed",
            Label: "Consumed",
          },
          {
            id: "Replaced",
            value: "Replaced",
            Label: "Replaced",
          },
          {
            id: "Expired/Defective",
            value: "Expired/Defective",
            Label: "Expired/Defective",
          },
        ],
        width: 6,
      },
    ],
    PURCHASE_REQUEST_INNER: [
      {
        no: 1,
        Label: "Product",
        name: "product",
        id: "product",
        type: "autocomplete",
        option: [],
        onSelect: () => {},
        required: true,
        width: 6,
      },
      {
        no: 2,
        Label: "Product Category",
        name: "productCategoryId",
        id: "productCategoryId",
        disabled: "edit",
        option: [],
        type: "select",
        width: 6,
        required: true,
      },
      {
        no: 3,
        Label: "Quantity",
        name: "quantity",
        id: "quantity",
        type: "number",
        required: true,
        width: 3,
      },
      {
        no: 4,
        Label: "Product Type",
        name: "type",
        id: "type",
        type: "select",
        option: [
          {
            id: "Consumable",
            value: "Consumable",
            Label: "Consumable",
          },
          {
            id: "Storable",
            value: "Storable",
            Label: "Storable",
          },
        ],
        width: 4,
        required: true,
      },
      {
        no: 5,
        Label: "unit",
        name: "unit",
        id: "unit",
        type: "select",
        required: true,
        width: 3,
        option: Units,
      },
    ],
    NEW_EQUIPMENT_FORM: [
      {
        no: 1,
        Label: "name",
        name: "name",
        id: "name",
        type: "text",
        required: true,
      },
      {
        no: 2,
        id: 2,
        item: [
          {
            no: 2,
            Label: "Category",
            name: "equipmentCategoryId",
            id: "equipmentCategoryId",
            type: "select",
            option: [],
            required: true,
          },
          {
            no: 3,
            Label: "Vendor",
            name: "vendorId",
            id: "vendorId",
            type: "select",
            option: [],
            required: true,
          },
        ],
      },
      {
        no: 4,
        id: 4,
        item: [
          {
            no: 4,
            Label: "Used in location",
            name: "location",
            id: "location",
            type: "text",
            required: true,
          },
          {
            no: 5,
            Label: "Model",
            name: "model",
            id: "model",
            type: "text",
            required: true,
          },
        ],
      },
      {
        no: 6,
        Label: "Description",
        name: "description",
        id: "description",
        type: "text",
        required: true,
      },
      {
        no: 7,
        id: 7,
        item: [
          {
            no: 7,
            Label: "Serial Number",
            name: "srNumber",
            id: "srNumber",
            type: "text",
            required: false,
          },
          {
            no: 8,
            Label: "Cost",
            name: "cost",
            id: "cost",
            type: "number",
            required: true,
          },
        ],
      },
      {
        no: 9,
        id: 9,
        item: [
          {
            no: 9,
            Label: "WARRANTY EXPIRATION DATE",
            name: "warrantyExp",
            id: "warrantyExp",
            type: "date",
            required: true,
          },
          {
            no: 10,
            Label: " maintenance frequency",
            name: "pmFrequency",
            id: "pmFrequency",
            type: "select",
            option: [
              {
                id: "Daily",
                value: "Daily",
                Label: "Daily",
              },
              {
                id: "Weekly",
                value: "Weekly",
                Label: "Weekly",
              },
              {
                id: "Monthly",
                value: "Monthly",
                Label: "Monthly",
              },
              {
                id: "Quarterly",
                value: "Quarterly",
                Label: "Quarterly",
              },
              {
                id: "Yearly",
                value: "Yearly",
                Label: "Yearly",
              },
            ],
            required: true,
          },
        ],
      },
      {
        no: 11,
        id: 11,
        item: [
          {
            no: 11,
            Label: "maintenance duration",
            name: "maintananceDuration",
            id: "maintananceDuration",
            type: "time",
            minuteStep: 15,
            format: "HH:mm",
            required: true,
          },
          {
            no: 12,
            Label: "date of commisioning",
            name: "doc",
            id: "doc",
            type: "date",
            required: true,
          },
        ],
      },
      {
        no: 13,
        id: 13,
        item: [
          {
            no: 13,
            Label: "date of installation",
            name: "doi",
            id: "doi",
            type: "date",
            required: true,
          },
          {
            no: 14,
            Label: "OWNER NAME",
            name: "owner",
            id: "owner",
            type: "select",
            required: false,
            option: [],
          },
        ],
      },
    ],
    DOCUMENT_EQUIPMENT_UPLOAD: [
      {
        no: 1,
        Label: "Document Name",
        name: "name",
        id: "name",
        type: "text",
        required: true,
      },

      {
        no: 2,
        id: 2,
        item: [
          {
            no: 2,
            Label: "Doc Number",
            name: "number",
            id: "number",
            type: "text",
            required: true,
          },
          {
            no: 3,
            Label: "Document Type",
            name: "type",
            id: "type",
            type: "select",
            required: true,
            option: DocumentType,
          },
        ],
      },
      {
        no: 4,
        Label: "Upload Document",
        name: "documents",
        id: "documents",
        type: "dragupload",
        required: true,
      },
    ],
    PURCHASE_REQUEST_FORM: [
      {
        no: 1,
        Label: "Product Name",
        name: "name",
        id: "name",
        type: "text",
        required: false,
        disabled: "edit",
      },
      {
        no: 2,
        id: 2,
        item: [
          // {
          //   no: 2,
          //   Label: "quantity",
          //   name: "quantity",
          //   id: "quantity",
          //   type: "number",
          //   required: true,
          // },
          {
            no: 8,
            Label: "Date",
            name: "date",
            id: "date",
            type: "date",
            required: true,
          },
          {
            no: 3,
            Label: "ticket reference",
            name: "maintenanceRequestId",
            id: "maintenanceRequestId",
            type: "select",
            required: false,
            option: [],
          },
        ],
      },
      {
        no: 4,
        id: 4,
        item: [
          {
            no: 4,
            Label: "Purpose of use",
            name: "purpose",
            id: "purpose",
            type: "text",
            required: true,
          },
          {
            no: 5,
            Label: "quantity",
            name: "quantity",
            id: "quantity",
            type: "number",
            required: true,
          },
        ],
      },
      // {
      //   no: 6,
      //   id: 6,
      //   item: [
      //     {
      //       no: 6,
      //       Label: "Product type",
      //       name: "type",
      //       id: "type",
      //       type: "text",
      //       required: false,
      //     },
      //     {
      //       no: 7,
      //       Label: "unit of measure",
      //       name: "unit",
      //       id: "unit",
      //       type: "select",
      //       required: false,
      //       option: Units,
      //     },
      //   ],
      // },
      // {
      //   no: 8,
      //   id: 8,
      //   item: [
      //     {
      //       no: 8,
      //       Label: "Date",
      //       name: "date",
      //       id: "date",
      //       type: "date",
      //       required: true,
      //     },
      //   ],
      // },
    ],
    PURCHASE_REQUEST_FORM_NEW: [
      {
        no: 2,
        id: 2,
        item: [
          {
            no: 8,
            Label: "Date",
            name: "date",
            id: "date",
            type: "date",
            disabledDate: (current) => current.isAfter(moment()),
            required: true,
          },
          {
            no: 3,
            Label: "ticket reference",
            name: "maintenanceRequestId",
            id: "maintenanceRequestId",
            type: "select",
            required: false,
            option: [],
          },
        ],
      },
      {
        no: 4,
        id: 4,
        item: [
          {
            no: 4,
            Label: "Purpose of use",
            name: "purpose",
            id: "purpose",
            type: "text",
            required: true,
          },
        ],
      },
    ],
    NEW_DEPARTMENT_REGISTRATION: [
      {
        no: 1,
        Label: "Department Name",
        name: "name",
        id: "name",
        type: "text",
        required: true,
      },
    ],
    NEW_VENDOR_REGISTRATION: [
      {
        no: 1,
        Label: "Full Name",
        name: "name",
        id: "name",
        type: "text",
        required: true,
      },
      {
        no: 2,
        id: 2,
        item: [
          {
            no: 2,
            Label: "Contact name",
            name: "contactName",
            id: "contactName",
            type: "text",
            required: true,
          },
          {
            no: 3,
            Label: "Bussiness name",
            name: "businessName",
            id: "businessName",
            type: "text",
            required: true,
          },
        ],
      },
      {
        no: 4,
        id: 4,
        item: [
          {
            no: 4,
            Label: "phone number",
            name: "mobile",
            id: "mobile",
            type: "mobile",
            required: true,
          },
          {
            no: 5,
            Label: "email address",
            name: "email",
            id: "email",
            type: "email",
            required: true,
          },
        ],
      },
      {
        no: 6,
        Label: "Address",
        name: "address1",
        id: "address1",
        type: "text",
        required: false,
      },
      {
        no: 6,
        Label: "",
        name: "address2",
        id: "address2",
        type: "text",
        required: false,
      },
    ],
    SETTING_PLANT_PASSWORD: [
      {
        no: 1,
        id: 1,
        item: [
          {
            no: 1,
            Label: "current password",
            name: "currentPassword",
            id: "currentPassword",
            type: "password",
            required: true,
          },
        ],
      },
      {
        no: 2,
        id: 2,
        item: [
          {
            no: 2,
            Label: "new password",
            name: "newPassword",
            id: "newPassword",
            type: "password",
            required: true,
          },
          {
            no: 3,
            Label: "confirm password",
            name: "conpassword",
            id: "conpassword",
            type: "password",
            required: true,
            rule: ({ getFieldValue }) => ({
              validator(_, value) {
                if (!value || getFieldValue("newPassword") === value) {
                  return Promise.resolve();
                }
                return Promise.reject(
                  new Error("The two passwords that you entered do not match!")
                );
              },
            }),
          },
        ],
      },
    ],
    SETTING_PLANT_DETAIL: [
      {
        no: 1,
        id: 1,
        item: [
          {
            no: 1,
            Label: "Username",
            name: "email",
            id: "email",
            type: "email",
            required: true,
          },
        ],
      },
      {
        no: 3,
        id: 3,
        item: [
          {
            no: 1,
            Label: "SITE Name",
            name: "siteName",
            id: "siteName",
            type: "text",
            required: true,
          },
          {
            no: 2,
            Label: "site location",
            name: "siteLocation",
            id: "siteLocation",
            type: "text",
            required: true,
            option: [],
          },
          {
            no: 3,
            Label: "site land area (Acar / Yard)",
            name: "siteLandArea",
            id: "siteLandArea",
            type: "number",
            required: true,
          },
        ],
      },
      {
        no: 4,
        id: 4,
        item: [
          {
            no: 1,
            Label: "Plant Capacity (MWp)",
            name: "plantCapacity",
            id: "plantCapacity",
            type: "number",
            required: true,
          },
          {
            no: 2,
            Label: "Water avaibility",
            name: "waterAvailability",
            id: "waterAvailability",
            type: "text",
            required: true,
          },
          {
            no: 3,
            Label: "Water condition",
            name: "waterCondition",
            id: "waterCondition",
            type: "text",
            required: true,
          },
        ],
      },
      {
        no: 5,
        id: 5,
        item: [
          // {
          //   no: 1,
          //   Label: "Plant Capacity (MWp)",
          //   name: "capacity",
          //   id: "capacity",
          //   type: "number",
          //   required: true,
          // },
          {
            no: 2,
            Label: "Cleaning Frequency",
            name: "clearFrequency",
            id: "clearFrequency",
            type: "text",
            required: true,
          },
          {
            no: 3,
            Label: "Cleaning method",
            name: "clearMethod",
            id: "clearMethod",
            type: "text",
            required: true,
          },
          {
            no: 1,
            Label: "zone",
            name: "zone",
            id: "zone",
            type: "number",
            required: true,
          },
        ],
      },
      {
        no: 6,
        id: 6,
        item: [
          {
            no: 1,
            Label: "area",
            name: "area",
            id: "area",
            type: "number",
            required: true,
          },
          {
            no: 2,
            Label: "inverter",
            name: "inverter",
            id: "inverter",
            type: "number",
            required: true,
          },
          {
            no: 3,
            Label: "rows",
            name: "rows",
            id: "rows",
            type: "number",
            required: true,
          },
        ],
      },
    ],
    NEW_CLIENT: [
      {
        no: 1,
        Label: "Client Name",
        name: "name",
        id: "name",
        type: "text",
        required: true,
      },
      {
        no: 2,
        Label: "plant Capacity",
        name: "plantCapacity",
        id: "plantCapacity",
        type: "number",
        required: true,
      },
    ],
    SETTING_CATEGORY_FORM: [
      {
        no: 1,
        Label: "category",
        name: "category",
        id: "category",
        type: "text",
        required: true,
      },
    ],
    SETTING_ROUTINE_CHECKUP: [
      {
        no: 1,
        id: 1,
        item: [
          {
            no: 1,
            Label: "Category",
            name: "category",
            id: "category",
            type: "text",
            required: true,
          },
          {
            no: 2,
            Label: "Sub Category",
            name: "subCategory",
            id: "subCategory",
            type: "text",
            required: true,
          },
        ],
      },
      {
        no: 2,
        id: 2,
        item: [
          {
            no: 3,
            Label: "Activities",
            name: "activities",
            id: "activities",
            type: "text",
            required: true,
          },
          {
            no: 4,
            Label: "FREQUENCY",
            name: "frequency",
            id: "frequency",
            type: "select",
            option: [
              {
                id: "Daily",
                value: "Daily",
                Label: "Daily",
              },
              {
                id: "Weekly",
                value: "Weekly",
                Label: "Weekly",
              },
              {
                id: "Monthly",
                value: "Monthly",
                Label: "Monthly",
              },
              {
                id: "Yearly",
                value: "Yearly",
                Label: "Yearly",
              },
            ],
            required: true,
          },
        ],
      },
      {
        no: 3,
        id: 3,
        item: [
          {
            no: 5,
            Label: "Priority",
            name: "priority",
            id: "priority",
            type: "select",
            option: [
              {
                id: "Low",
                value: "Low",
                Label: "Low",
              },
              {
                id: "Medium",
                value: "Medium",
                Label: "Medium",
              },
              {
                id: "High",
                value: "High",
                Label: "High",
              },
            ],
            required: true,
          },
          {
            no: 6,
            Label: " Date",
            name: "date",
            id: "date",
            type: "date",
            // showTime: true,
            required: true,
            disabled: true,
          },
        ],
      },
    ],
    EVENT_LOG_FORM: [
      {
        no: 1,
        id: 1,
        item: [
          {
            no: 1,
            Label: "Event details",
            name: "event",
            id: "event",
            type: "text",
            required: true,
          },
          {
            no: 2,
            Label: "Event type",
            name: "type",
            id: "type",
            type: "text",
            required: true,
          },
        ],
      },
      {
        no: 2,
        id: 2,
        item: [
          {
            no: 3,
            Label: "From",
            name: "from",
            id: "from",
            type: "date",
            showTime: true,
            required: true,
          },
          {
            no: 4,
            Label: "to",
            name: "to",
            id: "to",
            type: "date",
            showTime: true,
            required: true,
          },
        ],
      },
      {
        no: 3,
        id: 3,
        item: [
          {
            no: 5,
            Label: "ATTENDED by",
            name: "employeeId",
            id: "employeeId",
            type: "select",
            required: true,
            option: [],
          },
          // {
          //   no: 2,
          //   Label: "Sub Category",
          //   name: "subCategory",
          //   id: "subCategory",
          //   type: "text",
          //   required: true,
          // },
        ],
      },
      {
        no: 6,
        Label: "Action taken",
        name: "actionTaken",
        id: "actionTaken",
        type: "text",
        required: false,
      },
      {
        no: 7,
        Label: "Remark",
        name: "remark",
        id: "remark",
        type: "text",
        required: false,
      },
    ],
    ABT_FORM: [
      {
        no: 1,
        id: 1,
        item: [
          {
            no: 1,
            Label: "ABT Number",
            name: "abtNo",
            id: "abtNo",
            type: "text",
            required: true,
          },
          {
            no: 2,
            Label: "date",
            name: "date",
            id: "date",
            type: "date",
            required: true,
          },
        ],
      },
      {
        no: 2,
        id: 2,
        item: [
          {
            no: 2,
            Label: "import",
            name: "import",
            id: "import",
            type: "number",
            required: true,
          },
          {
            no: 4,
            Label: "export",
            name: "export",
            id: "export",
            type: "number",
            required: true,
          },
        ],
      },
    ],
    CLEANING_FORM: [
      {
        no: 1,
        id: 1,
        item: [
          {
            no: 1,
            Label: "zone",
            name: "zone",
            id: "zone",
            type: "text",
            required: true,
          },
          {
            no: 2,
            Label: "supervisor",
            name: "employeeId",
            id: "employeeId",
            type: "select",
            option: [],
            required: true,
          },
        ],
      },
      {
        no: 2,
        id: 2,
        item: [
          {
            no: 3,
            Label: "from Row",
            name: "fromRow",
            id: "fromRow",
            type: "text",
            required: true,
          },
          {
            no: 4,
            Label: "to Row",
            name: "toRow",
            id: "toRow",
            type: "text",
            required: true,
          },
        ],
      },
      {
        no: 3,
        id: 3,
        item: [
          {
            no: 5,
            Label: "modules",
            name: "modules",
            id: "modules",
            type: "text",
            required: true,
          },
          {
            no: 6,
            Label: "rows",
            name: "rows",
            id: "rows",
            type: "text",
            required: true,
          },
        ],
      },
      {
        no: 4,
        id: 4,
        item: [
          {
            no: 7,
            Label: "total KWP",
            name: "totalKWP",
            id: "totalKWP",
            type: "number",
            required: true,
          },
          {
            no: 8,
            Label: "workers",
            name: "workers",
            id: "workers",
            type: "number",
            required: true,
          },
        ],
      },
      {
        no: 5,
        id: 5,
        item: [
          {
            no: 9,
            Label: "start Date",
            name: "startDate",
            id: "startDate",
            type: "date",
            showTime: true,
            required: true,
          },
          {
            no: 10,
            Label: "end Date",
            name: "endDate",
            id: "endDate",
            type: "date",
            showTime: true,
            required: true,
          },
        ],
      },
      // {
      //   no: 11,
      //   Label: "Remark",
      //   name: "remark",
      //   id: "remark",
      //   type: "text",
      //   required: false,
      // },
    ],
    EXPANSE_FORM: [
      {
        no: 1,
        Label: "party",
        name: "partyName",
        id: "partyName",
        type: "text",
        required: true,
      },
      {
        no: 2,
        id: 2,
        item: [
          {
            no: 2,
            Label: "Expense Date",
            name: "expanseDate",
            id: "expanseDate",
            type: "date",
            required: true,
          },
          {
            no: 3,
            Label: "category",
            name: "category",
            id: "category",
            type: "select",
            option: [
              {
                id: "Travel",
                value: "Travel",
                Label: "Travel",
              },
              {
                id: "Misc",
                value: "Misc",
                Label: "Misc",
              },
              {
                id: "Fuel",
                value: "Fuel",
                Label: "Fuel",
              },
              {
                id: "Food",
                value: "Food",
                Label: "Food",
              },
              {
                id: "Accomodation",
                value: "Accomodation",
                Label: "Accomodation",
              },
              {
                id: "Office",
                value: "Office",
                Label: "Office",
              },
              {
                id: "Courier",
                value: "Courier",
                Label: "Courier",
              },
            ],
            required: true,
          },
        ],
      },
      {
        no: 3,
        id: 3,
        item: [
          {
            no: 4,
            Label: "amount",
            name: "amount",
            id: "amount",
            type: "number",
            required: true,
          },
          {
            no: 6,
            Label: "payment Mode",
            name: "payment",
            id: "payment",
            type: "select",
            option: [
              // {
              //   id: "Offline",
              //   value: "Offline",
              //   Label: "Offline",
              // },
              // {
              //   id: "Online",
              //   value: "Online",
              //   Label: "Online",
              // },
              {
                id: "Cash",
                value: "Cash",
                Label: "Cash",
              },
              {
                id: "Cheque",
                value: "Cheque",
                Label: "Cheque",
              },
              {
                id: "UPI",
                value: "UPI",
                Label: "UPI",
              },
              {
                id: "Credit/Debit Card",
                value: "Credit/Debit Card",
                Label: "Credit/Debit Card",
              },
            ],
            required: true,
          },
        ],
      },
      {
        no: 7,
        Label: "Remark",
        name: "remark",
        id: "remark",
        type: "text",
        required: false,
      },
      {
        no: 8,
        Label: "description",
        name: "description",
        id: "description",
        type: "text",
        required: false,
      },
      {
        no: 6,
        id: 6,
        item: [
          {
            no: 9,
            Label: "tracking",
            name: "tracking",
            id: "tracking",
            type: "radio",
            option: [
              {
                id: "Employee (to reimburse)",
                value: "Employee",
                Label: "Employee",
              },
              {
                id: "Company",
                value: "Company",
                Label: "Company",
              },
            ],
            required: true,
          },
          {
            no: 10,
            Label: "bill Reference",
            name: "billReference",
            id: "billReference",
            type: "text",
            required: true,
          },
        ],
      },
      {
        no: 5,
        id: 5,
        item: [
          {
            no: 11,
            Label: "Upload recipt",
            name: "receipt",
            id: "receipt",
            type: "file",
            required: false,
          },
        ],
      },
      // {
      //   no: 11,
      //   Label: "Remark",
      //   name: "remark",
      //   id: "remark",
      //   type: "text",
      //   required: false,
      // },
    ],
    VEHICAL_EXPANSE_FORM: [
      {
        no: 1,
        id: 1,
        item: [
          {
            no: 1,
            Label: "vehicle Type",
            name: "vehicle",
            id: "vehicle",
            type: "select",
            option: [
              {
                id: "Car",
                value: "Car",
                Label: "Car",
              },
              {
                id: "Bike",
                value: "Bike",
                Label: "Bike",
              },
            ],
            required: true,
          },
          {
            no: 2,
            Label: "vehicle Number",
            name: "vehicleNumber",
            id: "vehicleNumber",
            type: "text",
            required: true,
          },
        ],
      },
      {
        no: 2,
        id: 2,
        item: [
          {
            no: 3,
            Label: "Expense date",
            name: "date",
            id: "date",
            type: "date",
            required: true,
          },
          {
            no: 4,
            Label: "category",
            name: "category",
            id: "category",
            type: "select",
            option: [
              {
                id: "Fuel",
                value: "Fuel",
                Label: "Fuel",
              },
              {
                id: "Service",
                value: "Service",
                Label: "Service",
              },
              {
                id: "Breakdown",
                value: "Breakdown",
                Label: "Breakdown",
              },
            ],
            required: true,
          },
        ],
      },
      {
        no: 3,
        id: 3,
        item: [
          {
            no: 5,
            Label: "cost",
            name: "coast",
            id: "coast",
            type: "number",
            required: true,
          },
          {
            no: 6,
            Label: "odometer reading",
            name: "odometerReading",
            id: "odometerReading",
            type: "number",
            required: true,
          },
        ],
      },
      {
        no: 7,
        Label: "driver",
        name: "employeeId",
        id: "employeeId",
        type: "select",
        option: [],
        required: false,
      },
      {
        no: 8,
        Label: "Remark",
        name: "remark",
        id: "remark",
        type: "text",
        required: false,
      },
      {
        no: 6,
        id: 6,
        item: [
          {
            no: 7,
            Label: "receipt",
            name: "receipt",
            id: "receipt",
            type: "file",
            required: false,
          },
        ],
      },
    ],
  },
  TABLE: {
    EMPLOYEE_DOCUMENT: [
      {
        title: "SR NO.",
        dataIndex: "no",
        width: 60,
        sorter: (a, b) => alphanumericSort(a, b, "no"),
      },
      {
        title: "dOCUMENT NAME",
        width: 200,
        dataIndex: "name",
        sorter: (a, b) => alphanumericSort(a, b, "name"),
      },
      {
        title: "DOCUMENT number",
        width: 200,
        dataIndex: "number",
        sorter: (a, b) => alphanumericSort(a, b, "number"),
      },
      {
        title: "DOCUMENT type",
        width: 200,
        dataIndex: "docType",
        sorter: (a, b) => alphanumericSort(a, b, "docType"),
        filters: DocumentType,
        onFilter: (value, record) => record.docType === value,
        filterSearch: true,
      },
      // {
      //   dataIndex: "preview",
      //   render: RenderViewButton,
      // },
      // {
      //   dataIndex: "delete",
      //   render: RenderDeleteButton,
      // },
      { dataIndex: "multButton", width: 150, render: RenderActionButtons },
    ],
    INVENTORY_DOCUMENT: [
      {
        title: "SR NO.",
        dataIndex: "no",
        sorter: (a, b) => alphanumericSort(a, b, "no"),
        width: 100,
      },
      {
        title: "dOCUMENT NAME",
        width: 200,
        dataIndex: "name",
        sorter: (a, b) => alphanumericSort(a, b, "name"),
      },
      {
        title: "DOCUMENT number",
        width: 200,
        dataIndex: "number",
        sorter: (a, b) => alphanumericSort(a, b, "number"),
      },
      {
        title: "DOCUMENT type",
        width: 200,
        dataIndex: "type",
        sorter: (a, b) => alphanumericSort(a, b, "type"),
        filters: DocumentType,
        onFilter: (value, record) => record.type === value,
        filterSearch: true,
      },
      { dataIndex: "multButton", render: RenderActionButtons, width: 150 },
    ],
    SUPPLY_OUT_INVENTORY_TABLE_INNER: [
      // {
      //   title: "SR NO.",
      //   dataIndex: "no",
      //   width: 100,
      //   sorter: (a, b) => alphanumericSort(a, b, "no"),
      // },
      {
        title: "Product",
        dataIndex: "productName",
        width: 30,
        sorter: (a, b) => alphanumericSort(a, b, "productName"),
      },
      {
        title: "Qty",
        width: 10,
        dataIndex: "quantity",

        sorter: (a, b) => alphanumericSort(a, b, "quantity"),
      },
      {
        title: "Unit of Measure",
        dataIndex: "unit",
        width: 10,
        sorter: (a, b) => alphanumericSort(a, b, "unit"),
        filters: Units,
        onFilter: (value, record) => record.unit === value,
        filterSearch: true,
      },
      {
        dataIndex: "delete",
        render: RenderDeleteWithoutConfirmation,
        width: 5,
      },
      // {
      //   render: (_, record) => randorMthor(_, record, handleDelete),
      // },
    ],
    RESOLUTION_TABLE_INNER: [
      // {
      //   title: "SR NO.",
      //   dataIndex: "no",
      //   width: 100,
      //   sorter: (a, b) => alphanumericSort(a, b, "no"),
      // },
      {
        title: "Product",
        dataIndex: "productName",
        width: 350,
        sorter: (a, b) => alphanumericSort(a, b, "productName"),
      },
      {
        title: "Qty",
        dataIndex: "quantity",
        width: 100,
        sorter: (a, b) => alphanumericSort(a, b, "quantity"),
      },
      {
        title: "Unit of Measure",
        dataIndex: "unit",
        width: 100,
        sorter: (a, b) => alphanumericSort(a, b, "unit"),
        filters: Units,
        onFilter: (value, record) => record.unit === value,
        filterSearch: true,
      },
      {
        title: "Usage Type",
        dataIndex: "usageType",
        width: 150,
        sorter: (a, b) => alphanumericSort(a, b, "usageType"),
      },
      {
        dataIndex: "delete",
        render: RenderDeleteWithoutConfirmation,
        width: 100,
      },
      // {
      //   render: (_, record) => randorMthor(_, record, handleDelete),
      // },
    ],
    MAINTENANCE_RESOLUTION_TABLE: [
      // {
      //   title: "SR NO.",
      //   dataIndex: "no",
      //   width: 100,
      //   sorter: (a, b) => alphanumericSort(a, b, "no"),
      // },
      {
        title: "Product",
        dataIndex: "name",
        width: 150,
        sorter: (a, b) => alphanumericSort(a, b, "name"),
      },
      {
        title: "sku",
        dataIndex: "sku",
        width: 100,
        sorter: (a, b) => alphanumericSort(a, b, "sku"),
      },
      {
        title: "srNumber",
        dataIndex: "srNumber",
        width: 100,
        sorter: (a, b) => alphanumericSort(a, b, "srNumber"),
      },
      {
        title: "Qty",
        dataIndex: "quantity",
        width: 50,
        sorter: (a, b) => alphanumericSort(a, b, "quantity"),
      },
      {
        title: "Unit of Measure",
        dataIndex: "unit",
        width: 50,
        sorter: (a, b) => alphanumericSort(a, b, "unit"),
        filters: Units,
        onFilter: (value, record) => record.unit === value,
        filterSearch: true,
      },
      {
        title: "category",
        dataIndex: "category",
        width: 100,
        sorter: (a, b) => alphanumericSort(a, b, "category"),
      },
      {
        title: "usage Type",
        dataIndex: "usageType",
        width: 100,
        sorter: (a, b) => alphanumericSort(a, b, "usageType"),
      },
      {
        title: "type",
        dataIndex: "type",
        width: 100,
        sorter: (a, b) => alphanumericSort(a, b, "type"),
      },
    ],
    PURCHASE_REQUEST_TABLE_INNER: [
      // {
      //   title: "SR NO.",
      //   dataIndex: "no",
      //   width: 100,
      //   sorter: (a, b) => alphanumericSort(a, b, "no"),
      // },
      {
        title: "Product",
        width: 150,

        dataIndex: "product",
        sorter: (a, b) => alphanumericSort(a, b, "product"),
      },
      {
        title: "Qty",

        width: 60,
        dataIndex: "quantity",
        sorter: (a, b) => alphanumericSort(a, b, "quantity"),
      },
      {
        title: "UOM",
        dataIndex: "unit",
        width: 70,
        sorter: (a, b) => alphanumericSort(a, b, "unit"),
        filters: Units,
        onFilter: (value, record) => record.unit === value,
        filterSearch: true,
      },
      {
        title: "PRoduct Type",
        dataIndex: "type",
        width: 100,
        sorter: (a, b) => alphanumericSort(a, b, "type"),
      },
      {
        title: "PRoduct Category",
        dataIndex: "productcategory",
        width: 100,
        sorter: (a, b) => alphanumericSort(a, b, "productcategory"),
      },
      {
        dataIndex: "delete",
        render: RenderDeleteWithoutConfirmation,
        width: 50,
      },
      // {
      //   render: (_, record) => randorMthor(_, record, handleDelete),
      // },
    ],
    EMPLOYEE_TIMEOFF: [
      {
        title: "SR NO.",
        dataIndex: "no",
        width: 50,

        sorter: (a, b) => alphanumericSort(a, b, "no"),
        // render: (text) => <a>{text}</a>,
      },
      {
        title: "LEAVE TYPE",
        dataIndex: "leavetype",
        width: 100,
        sorter: (a, b) => alphanumericSort(a, b, "leavetype"),
        // render: (typeID) => {
        //   if (typeID === 3) {
        //     return <>Periods Leave</>;
        //   }
        //   if (typeID === 2) {
        //     return <>Sick Leave</>;
        //   }
        //   return <>DONE</>;
        // },
      },
      {
        title: "LEAVE DATE",
        width: 100,
        dataIndex: "from",
        sorter: (a, b) => moment(a.from).subtract(moment(b.from)),
      },
      {
        title: "REPORTING DATE",
        width: 100,
        dataIndex: "to",
        sorter: (a, b) => moment(a.to).subtract(moment(b.to)),
      },
      {
        title: "DURATION",
        width: 100,
        dataIndex: "duration",
        sorter: (a, b) => alphanumericSort(a, b, "duration"),
      },
      {
        title: "Action",
        dataIndex: "multButton",
        render: RenderActionButtons,
        width: 100,
      },
      {
        title: "STATUS",
        width: 100,
        dataIndex: "status",
        sorter: (a, b) => alphanumericSort(a, b, "status"),
        render: (status) => {
          return (
            <Select
              style={{ width: "100%" }}
              defaultValue={status?.value}
              onChange={(e) => status?.onChange(e)}
              onClick={(e) => {
                e.stopPropagation();
              }}
            >
              <Select.Option key={"Approved"}>Approved </Select.Option>
              <Select.Option key={"Pending"}>Pending </Select.Option>
              <Select.Option key={"Rejected"}>Rejected</Select.Option>
            </Select>
          );
        },
      },
    ],
    INVENTORY: [
      {
        title: "SR NO.",
        dataIndex: "no",
        width: 60,
        sorter: (a, b) => alphanumericSort(a, b, "no"),
      },
      {
        title: "Product",
        dataIndex: "name",
        width: 300,
        // sorter: (a, b) => alphanumericSort(a, b, "name"),
      },
      {
        title: "sku",
        width: 150,
        dataIndex: "sku",
        sorter: (a, b) => alphanumericSort(a, b, "sku"),
      },
      {
        title: "Qty",
        width: 60,
        dataIndex: "quantity",
        sorter: (a, b) => alphanumericSort(a, b, "quantity"),
      },
      {
        title: "Unit",
        width: 90,
        dataIndex: "unit",
        // sorter: (a, b) => alphanumericSort(a, b, "unit"),
        filters: Units,
        onFilter: (value, record) => record.unit === value,
        filterSearch: true,
      },
      {
        title: "Category",
        width: 150,
        dataIndex: "productCategoryId",
        // sorter: (a, b) => alphanumericSort(a, b, "category"),
        filters: [],
        // onFilter: (value, record) => record.productCategoryId === value,
        filterSearch: true,
      },
      {
        title: "Cost",
        width: 100,
        dataIndex: "cost",
        sorter: (a, b) => alphanumericSort(a, b, "cost"),
      },
      {
        title: "type",
        width: 150,
        dataIndex: "type",
        // sorter: (a, b) => alphanumericSort(a, b, "type"),
        filters: [
          {
            value: "Consumable",
            text: "Consumable",
          },
          {
            value: "Storable",
            text: "Storable",
          },
        ],
        onFilter: (value, record) => record.type === value,
        filterSearch: true,
      },
      {
        title: "serial Number",
        width: 150,
        dataIndex: "trackingId",
        sorter: (a, b) => alphanumericSort(a, b, "trackingId"),
      },
    ],
    PURCHASE_REQUESTS: [
      {
        title: "SR NO.",
        dataIndex: "no",
        width: 60,
        sorter: (a, b) => alphanumericSort(a, b, "no"),
      },
      {
        title: "Product Name",
        dataIndex: "name",
        width: 250,
        // sorter: (a, b) => alphanumericSort(a, b, "name"),
      },
      {
        title: "PRF",
        dataIndex: "prf",
        width: 150,
        sorter: (a, b) => alphanumericSort(a, b, "prf"),
      },
      {
        title: "QTY",
        dataIndex: "quantity",
        width: 60,
        sorter: (a, b) => alphanumericSort(a, b, "quantity"),
      },
      {
        title: "UOM",
        dataIndex: "unit",
        width: 60,
        // sorter: (a, b) => alphanumericSort(a, b, "unit"),
        // filters: Units,
        // onFilter: (value, record) => record.unit === value,
        // filterSearch: true,
      },
      {
        title: "date",
        dataIndex: "date",
        width: 150,
        sorter: (a, b) => moment(a.date).subtract(moment(b.date)),
      },
      {
        title: "status",
        dataIndex: "status",
        width: 150,
        filters: [
          {
            text: "Pending",
            value: "Pending",
          },
          {
            text: "Delivered",
            value: "Delivered",
          },
          {
            text: "Declined",
            value: "Declined",
          },
          {
            text: "Approved",
            value: "Approved",
          },
        ],
        onFilter: (value, record) => record?.status?.value === value,
        filterSearch: true,
        // sorter: (a, b) => alphanumericSort(a, b, "status"),
        render: (status) => {
          return (
            <Select
              style={{ width: "100%" }}
              defaultValue={status.value}
              disabled={status.value === "Delivered"}
              onChange={(e) => status?.onChange(e)}
              onClick={(e) => {
                e.stopPropagation();
              }}
            >
              <Select.Option key={"Pending"}>PENDING</Select.Option>
              <Select.Option key={"Delivered"}>Delivered</Select.Option>
              <Select.Option key={"Declined"}>Declined</Select.Option>
              <Select.Option key={"Approved"}>Approved</Select.Option>
            </Select>
          );
        },
      },
    ],
    TRANSFER: [
      {
        title: "SR NO.",
        dataIndex: "no",
        width: 60,
        sorter: (a, b) => alphanumericSort(a, b, "no"),
      },
      {
        title: "Products",
        dataIndex: "product",
        width: 250,
        sorter: (a, b) => alphanumericSort(a, b, "product"),
      },
      {
        title: "Qty",
        dataIndex: "quantity",
        width: 60,
        sorter: (a, b) => alphanumericSort(a, b, "quantity"),
      },
      {
        title: "uom",
        dataIndex: "uom",
        width: 60,
        sorter: (a, b) => alphanumericSort(a, b, "uom"),
        filters: Units,
        onFilter: (value, record) => record.uom === value,
        filterSearch: true,
      },
      {
        title: "date",
        dataIndex: "date",
        width: 150,
        sorter: (a, b) => moment(a.date).subtract(moment(b.date)),
      },
      {
        title: "Serial No.",
        dataIndex: "srNumber",
        width: 150,
        sorter: (a, b) => alphanumericSort(a, b, "srNumber"),
      },
      // {
      //   title: "Usage Type",
      //   dataIndex: "type",
      //   sorter: (a, b) => alphanumericSort(a, b, "type"),
      // },
      {
        title: "receiever's name",
        dataIndex: "receiver",
        width: 150,
        sorter: (a, b) => alphanumericSort(a, b, "receiver"),
      },
    ],
    MOVE_HISTORY: [
      {
        title: "SR NO.",
        dataIndex: "no",
        width: 60,
        sorter: (a, b) => alphanumericSort(a, b, "no"),
      },
      {
        title: "Products",
        dataIndex: "name",
        width: 200,
        // sorter: (a, b) => alphanumericSort(a, b, "name"),
      },
      {
        title: "SKU",
        dataIndex: "sku",
        width: 200,
        sorter: (a, b) => alphanumericSort(a, b, "sku"),
      },
      {
        title: "Sr No.",
        dataIndex: "srNumber",
        width: 200,
        sorter: (a, b) => alphanumericSort(a, b, "srNumber"),
      },
      {
        title: "Qty",
        dataIndex: "quantity",
        width: 60,
        sorter: (a, b) => alphanumericSort(a, b, "quantity"),
      },
      {
        title: "unit",
        dataIndex: "unit",
        width: 90,
        // sorter: (a, b) => alphanumericSort(a, b, "unit"),
        filters: Units,
        onFilter: (value, record) => record.unit === value,
        filterSearch: true,
      },
      {
        title: "Category",
        dataIndex: "category",
        width: 150,
        // sorter: (a, b) => alphanumericSort(a, b, "category"),
      },
      {
        title: "Product Type",
        dataIndex: "Ptype",
        width: 150,
        // sorter: (a, b) => alphanumericSort(a, b, "Ptype"),
      },
      // {
      //   title: "date",
      //   dataIndex: "date",
      //   sorter: (a, b) => moment(a.date).subtract(moment(b.date)),
      // },
      {
        title: "status",
        dataIndex: "status",
        width: 150,
        sorter: (a, b) => alphanumericSort(a, b, "status"),
      },
      {
        title: "usageType",
        dataIndex: "usageType",
        width: 150,
        sorter: (a, b) => alphanumericSort(a, b, "usageType"),
        filters: [
          {
            value: "New",
            text: "New",
          },
          {
            value: "Replaced",
            text: "Replaced",
          },
          {
            value: "Transfer",
            text: "Transfer",
          },
          {
            value: "Consumed",
            text: "Consumed",
          },
          // {
          //   value: "Consumed",
          //   text: "Consumed",
          // },
        ],
        onFilter: (value, record) => record.usageType === value,
        filterSearch: true,
      },
      {
        title: "date",
        dataIndex: "createdAt",
        width: 150,
        sorter: (a, b) => alphanumericSort(a, b, "createdAt"),
        render: (v) => moment(v).format("YYYY-MM-DD"),
      },
      {
        title: "type",
        dataIndex: "type",
        width: 150,
        sorter: (a, b) => alphanumericSort(a, b, "type"),
        // filters: [
        //   {
        //     value: "OUTWARD",
        //     text: "OUTWARD",
        //   },
        //   {
        //     value: "INWARD",
        //     text: "INWARD",
        //   },
        // ],
        // onFilter: (value, record) => record.type === value,
        // filterSearch: true,
      },

      {
        title: "receiever's name",
        dataIndex: "receiver",
        width: 150,
        sorter: (a, b) => alphanumericSort(a, b, "receiver"),
      },
    ],
    MAINTENANCE_REQUEST_TABLE: [
      {
        title: "SR NO.",
        dataIndex: "no",
        width: 60,
        sorter: (a, b) => alphanumericSort(a, b, "no"),
      },
      {
        title: "title",
        dataIndex: "title",
        width: 300,
        sorter: (a, b) => alphanumericSort(a, b, "title"),
      },
      {
        title: "TICKET Id",
        width: 150,
        dataIndex: "sku",
        sorter: (a, b) => alphanumericSort(a, b, "sku"),
      },
      {
        title: "Category",
        dataIndex: "category",
        width: 150,
        sorter: (a, b) => alphanumericSort(a, b, "category"),
        filters: [
          {
            text: "Routine",
            value: "Routine",
          },
          {
            text: "Breakdown",
            value: "Breakdown",
          },
          {
            text: "Fault",
            value: "Fault",
          },
          {
            text: "Notice",
            value: "Notice",
          },
        ],
        onFilter: (value, record) => record.category === value,
        filterSearch: true,
      },
      {
        title: "Creation date",
        dataIndex: "date",
        sorter: (a, b) => moment(a.date).subtract(moment(b.date)),
        width: 200,
        render: (value) => moment(value).format("YYYY-MM-DD HH:mm"),
      },
      {
        title: "Due Date",
        dataIndex: "due",
        sorter: (a, b) => moment(a.due).subtract(moment(b.due)),
        width: 200,
        render: (value) => moment(value).format("YYYY-MM-DD HH:mm"),
      },
      {
        title: "Resolution Date",
        dataIndex: "resolutionDate",
        // sorter: (a, b) =>
        //   moment(a.resolutionDate).subtract(moment(b.resolutionDate)),
        width: 200,
        render: (value) => moment(value).format("YYYY-MM-DD HH:mm"),
      },
      {
        title: "Priority",
        dataIndex: "prioriti",
        width: 130,
        filters: MaintanancePriority,
        onFilter: (value, record) => record.prioriti === value,
        filterSearch: true,
        sorter: (a, b) => alphanumericSort(a, b, "prioriti"),
        render: (v) => (
          <p
            style={{
              color: Color[v],
              background: Color.Back[v],
              padding: "5px",
              textAlign: "center",
              borderRadius: "5pt",
            }}
          >
            {" "}
            {v}
          </p>
        ),
      },
      // {
      //   title: "duration",
      //   dataIndex: "duration",
      //   sorter: (a, b) => moment(a.duration).subtract(moment(b.duration)),
      // },
      {
        title: " duration",
        dataIndex: "actualduration",
        width: 120,
        // sorter: (a, b) =>
        //   moment(a.actualduration).subtract(moment(b.actualduration)),
      },
      // {
      //   title: "come To Know",
      //   dataIndex: "comeToKnow",
      //   width: 200,
      //   sorter: (a, b) => alphanumericSort(a, b, "comeToKnow"),
      //   filters: [
      //     {
      //       value: "By Visual Inspection",
      //       text: "By Visual Inspection",
      //     },
      //     {
      //       value: "By SCADA Monitoring",
      //       text: "By SCADA Monitoring",
      //     },
      //   ],
      //   onFilter: (value, record) => record.comeToKnow === value,
      //   filterSearch: true,
      // },
      {
        title: "status",
        dataIndex: "status",
        width: 150,
        filters: maintenanceRequestStatus,
        onFilter: (value, record) => record.status === value,
        filterSearch: true,
        sorter: (a, b) => alphanumericSort(a, b, "status"),
        render: (v) => (
          <p
            style={{
              color: Color.Type[v],
              padding: "5px",
              textAlign: "center",
              borderRadius: "5pt",
            }}
          >
            {" "}
            {v}
          </p>
        ),
      },
      {
        title: "Model No.",
        dataIndex: "equipmentModal",
        width: 200,
        // sorter: (a, b) => alphanumericSort(a, b, "equipmentModal"),
      },
      {
        title: "SR No.",
        dataIndex: "equipmentSrNumber",
        width: 200,
        // sorter: (a, b) => alphanumericSort(a, b, "equipmentSrNumber"),
      },
      {
        title: "equipment Name",
        dataIndex: "equipmentName",
        width: 200,
        // sorter: (a, b) => alphanumericSort(a, b, "equipmentName"),
      },
      {
        title: "ASSIGNED TO",
        dataIndex: "assignedName",
        width: 150,
        // sorter: (a, b) => alphanumericSort(a, b, "assignedName"),
      },
      {
        title: "created By",
        dataIndex: "creatorName",
        // sorter: (a, b) => alphanumericSort(a, b, "creatorName"),
        width: 150,
      },
    ],
    MAINTENANCE_HISTORY: [
      {
        title: "SR NO.",
        dataIndex: "no",
        width: 60,
        sorter: (a, b) => alphanumericSort(a, b, "no"),
      },
      {
        title: "title",
        dataIndex: "title",
        width: 300,
        sorter: (a, b) => alphanumericSort(a, b, "title"),
      },
      {
        title: "TICKET Id",
        width: 150,
        dataIndex: "sku",
        sorter: (a, b) => alphanumericSort(a, b, "sku"),
      },
      {
        title: "Category",
        dataIndex: "category",
        width: 150,
        sorter: (a, b) => alphanumericSort(a, b, "category"),
      },
      // {
      //   title: "Modal No.",
      //   dataIndex: "equipmentModal",
      //   width: 150,
      //   sorter: (a, b) => alphanumericSort(a, b, "equipmentModal"),
      // },
      {
        title: "Sr No.",
        dataIndex: "equipmentSrNumber",
        width: 150,
        sorter: (a, b) => alphanumericSort(a, b, "equipmentSrNumber"),
      },
      {
        title: "date",
        dataIndex: "date",
        sorter: (a, b) => moment(a.date).subtract(moment(b.date)),
        width: 200,
        render: (value) => moment(value).format("YYYY-MM-DD HH:mm"),
      },
      {
        title: "Due Date",
        dataIndex: "due",
        sorter: (a, b) => moment(a.due).subtract(moment(b.due)),
        width: 200,
        render: (value) => moment(value).format("YYYY-MM-DD HH:mm"),
      },
      {
        title: "resolution Date",
        dataIndex: "resolutionDate",
        sorter: (a, b) =>
          moment(a.resolutionDate).subtract(moment(b.resolutionDate)),
        width: 200,
        render: (value) => moment(value).format("YYYY-MM-DD HH:mm"),
      },
      {
        title: "Priority",
        dataIndex: "prioriti",
        width: 130,
        sorter: (a, b) => alphanumericSort(a, b, "prioriti"),
        filters: Units,
        onFilter: (value, record) => record.prioriti === value,
        filterSearch: true,
        render: (v) => (
          <p
            style={{
              color: Color[v],
              background: Color.Back[v],
              padding: "5px",
              textAlign: "center",
              borderRadius: "5pt",
            }}
          >
            {" "}
            {v}
          </p>
        ),
      },
      // {
      //   title: "duration",
      //   dataIndex: "duration",
      //   sorter: (a, b) => moment(a.duration).subtract(moment(b.duration)),
      // },
      {
        title: " duration",
        dataIndex: "actualduration",
        width: 110,
        sorter: (a, b) =>
          moment(a.actualduration).subtract(moment(b.actualduration)),
      },
      // {
      //   title: "come To Know",
      //   dataIndex: "comeToKnow",
      //   width: 200,
      //   sorter: (a, b) => alphanumericSort(a, b, "comeToKnow"),
      // },
      {
        title: "status",
        dataIndex: "status",
        width: 180,
        filters: maintenanceRequestStatus,
        onFilter: (value, record) => record.status === value,
        filterSearch: true,
        sorter: (a, b) => alphanumericSort(a, b, "status"),
        render: (v) => (
          <p
            style={{
              color: Color.Type[v],
              padding: "5px",
              textAlign: "center",
              borderRadius: "5pt",
            }}
          >
            {" "}
            {v}
          </p>
        ),
      },

      {
        title: "ASSIGNED TO",
        dataIndex: "assignedName",
        width: 150,
        sorter: (a, b) => alphanumericSort(a, b, "assignedName"),
      },
      {
        title: "created By",
        dataIndex: "creatorName",
        sorter: (a, b) => alphanumericSort(a, b, "creatorName"),
        width: 150,
      },
    ],
    PLANT_DOCUMENT: [
      {
        title: "Documents #",
        dataIndex: "no",
        width: 60,
        sorter: (a, b) => alphanumericSort(a, b, "no"),
      },
      {
        title: "DOCUMENT NAME",
        dataIndex: "name",
        width: 150,
        sorter: (a, b) => alphanumericSort(a, b, "name"),
      },
      {
        title: "DOCUMENT type",
        dataIndex: "type",
        width: 150,
        sorter: (a, b) => alphanumericSort(a, b, "type"),
        filters: DocumentType,
        onFilter: (value, record) => record.type === value,
        filterSearch: true,
      },
      { dataIndex: "multButton", render: RenderActionButtons, width: 150 },
    ],
    SETTING_NEW_CLIENT: [
      {
        title: "SN No.",
        dataIndex: "no",
        width: 60,
        sorter: (a, b) => alphanumericSort(a, b, "no"),
      },
      {
        title: "Client NAME",
        dataIndex: "name",
        width: 150,
        sorter: (a, b) => alphanumericSort(a, b, "name"),
      },
      {
        title: "Plant Capacity",
        dataIndex: "plantCapacity",
        width: 100,
        sorter: (a, b) => alphanumericSort(a, b, "plantCapacity"),
      },
      { dataIndex: "multButton", render: RenderActionButtons, width: 150 },
    ],
    SETTING_CATEGORY_TABLE: [
      {
        title: "SN No.",
        dataIndex: "no",
        width: 20,
        sorter: (a, b) => alphanumericSort(a, b, "no"),
      },
      {
        title: "Category NAME",
        dataIndex: "category",
        width: 200,
        sorter: (a, b) => alphanumericSort(a, b, "category"),
      },
      { dataIndex: "multButton", render: RenderActionButtons, width: 50 },
    ],
    SETTING_DEPARTMENT_TABLE: [
      {
        title: "SN No.",
        dataIndex: "no",
        width: 40,
        sorter: (a, b) => alphanumericSort(a, b, "no"),
      },
      {
        title: "Department NAME",
        dataIndex: "name",
        width: 250,
        sorter: (a, b) => alphanumericSort(a, b, "name"),
      },
      { dataIndex: "multButton", render: RenderActionButtons, width: 80 },
    ],
    SETTING_VENDOR: [
      {
        title: "SN No.",
        dataIndex: "no",
        width: 50,
        sorter: (a, b) => alphanumericSort(a, b, "no"),
      },
      {
        title: "Vendor name",
        dataIndex: "name",
        width: 200,
        sorter: (a, b) => alphanumericSort(a, b, "name"),
      },
      {
        title: "Bussiness Name",
        dataIndex: "businessName",
        width: 150,
        sorter: (a, b) => alphanumericSort(a, b, "businessName"),
      },
      {
        title: "Contact number",
        dataIndex: "mobile",
        width: 150,
        sorter: (a, b) => alphanumericSort(a, b, "mobile"),
      },
      {
        title: "Email Address",
        dataIndex: "email",
        width: 100,
        sorter: (a, b) => alphanumericSort(a, b, "email"),
      },
      { dataIndex: "multButton", render: RenderActionButtons, width: 90 },
    ],
    SETTING_ROUTINE_CHECKUP: [
      {
        title: "SR NO.",
        dataIndex: "no",
        width: 60,
        sorter: (a, b) => alphanumericSort(a, b, "no"),
      },
      {
        title: "CATEGORY",
        dataIndex: "category",
        width: 250,
        sorter: (a, b) => alphanumericSort(a, b, "category"),
      },
      {
        title: "sub CATEGORY",
        dataIndex: "subCategory",
        width: 120,
        sorter: (a, b) => alphanumericSort(a, b, "subCategory"),
      },
      {
        title: "activities",
        dataIndex: "activities",
        width: 350,
        sorter: (a, b) => alphanumericSort(a, b, "activities"),
      },
      {
        title: "frequency",
        dataIndex: "frequency",
        width: 140,
        sorter: (a, b) => alphanumericSort(a, b, "frequency"),
        onFilter: (value, record) => record.frequency === value,
        filterSearch: true,
        filters: [
          {
            text: "Daily",
            value: "Daily",
            Label: "Daily",
          },
          {
            text: "Weekly",
            value: "Weekly",
            Label: "Weekly",
          },
          {
            text: "Monthly",
            value: "Monthly",
            Label: "Monthly",
          },
          {
            text: "Yearly",
            value: "Yearly",
            Label: "Yearly",
          },
        ],
      },
      {
        title: "date",
        dataIndex: "date",
        width: 120,
        sorter: (a, b) => moment(a.date).subtract(moment(b.date)),
      },
      {
        title: "priority",
        dataIndex: "priority",
        width: 120,
        sorter: (a, b) => alphanumericSort(a, b, "priority"),
        onFilter: (value, record) => record.priority === value,
        filterSearch: true,
        filters: [
          {
            text: "Low",
            value: "Low",
            Label: "Low",
          },
          {
            text: "Medium",
            value: "Medium",
            Label: "Medium",
          },
          {
            text: "High",
            value: "High",
            Label: "High",
          },
        ],
      },
      { dataIndex: "multButton", render: RenderActionButtons, width: 50 },
    ],
    EVENT_LOG_TABLE: [
      {
        title: "SR NO.",
        dataIndex: "no",
        width: 60,
        sorter: (a, b) => alphanumericSort(a, b, "no"),
      },
      {
        title: "event Details",
        dataIndex: "event",
        width: 150,
        sorter: (a, b) => alphanumericSort(a, b, "event"),
      },
      {
        title: "Log Id",
        width: 150,
        dataIndex: "sku",
        sorter: (a, b) => alphanumericSort(a, b, "sku"),
      },
      {
        title: "event Type",
        dataIndex: "type",
        width: 100,
        sorter: (a, b) => alphanumericSort(a, b, "type"),
      },
      {
        title: "from",
        dataIndex: "from",
        sorter: (a, b) => moment(a.from).subtract(moment(b.from)),
        width: 100,
        render: (value) => moment(value).format("YYYY-MM-DD HH:mm"),
      },
      {
        title: "to",
        dataIndex: "to",
        sorter: (a, b) => moment(a.to).subtract(moment(b.to)),
        width: 100,
        render: (value) => moment(value).format("YYYY-MM-DD HH:mm"),
      },
      {
        title: "DURATION",
        dataIndex: "duration",
        width: 100,
        // sorter: (a, b) => alphanumericSort(a, b, "duration"),
      },
      {
        title: "attended By",
        dataIndex: "name",
        width: 100,
        // sorter: (a, b) => alphanumericSort(a, b, "name"),
      },
      {
        title: "status",
        dataIndex: "status",
        width: 150,
        onFilter: (value, record) => record.priority === value,
        filterSearch: true,
        filters: [
          {
            text: "Pending",
            value: "Pending",
            Label: "Pending",
          },
          {
            text: "Completed",
            value: "Completed",
            Label: "Completed",
          },
        ],
        // sorter: (a, b) => alphanumericSort(a, b, "status"),
        render: (status) => {
          return (
            <Select
              style={{ width: "100%" }}
              defaultValue={status.value}
              onChange={(e) => status?.onChange(e)}
              onClick={(e) => {
                e.stopPropagation();
              }}
              disabled={status.value === "Completed"}
            >
              <Select.Option key={"Pending"}>PENDING</Select.Option>
              <Select.Option key={"Completed"}>COMPLETED</Select.Option>
            </Select>
          );
        },
      },
    ],
    ABT_TABLE: [
      {
        title: "SR NO.",
        dataIndex: "no",
        width: 60,
        sorter: (a, b) => alphanumericSort(a, b, "no"),
      },
      {
        title: "ABT Number",
        dataIndex: "abtNo",
        width: 150,
        sorter: (a, b) => alphanumericSort(a, b, "abtNo"),
      },
      {
        title: "Log Id",
        width: 150,
        dataIndex: "sku",
        sorter: (a, b) => alphanumericSort(a, b, "sku"),
      },
      {
        title: "date",
        dataIndex: "date",
        width: 100,
        sorter: (a, b) => moment(a.date).subtract(moment(b.date)),
      },
      {
        title: "import",
        dataIndex: "import",
        width: 100,
        sorter: (a, b) => alphanumericSort(a, b, "import"),
      },
      {
        title: "export",
        dataIndex: "export",
        width: 100,
        sorter: (a, b) => alphanumericSort(a, b, "export"),
      },
    ],
    CLEANING_TABLE: [
      {
        title: "SR NO.",
        dataIndex: "no",
        width: 60,
        sorter: (a, b) => alphanumericSort(a, b, "no"),
      },
      {
        title: "zone",
        dataIndex: "zone",
        width: 150,
        sorter: (a, b) => alphanumericSort(a, b, "zone"),
      },
      {
        title: "Log Id",
        width: 150,
        dataIndex: "sku",
        sorter: (a, b) => alphanumericSort(a, b, "sku"),
      },
      {
        title: "from Row",
        dataIndex: "fromRow",
        width: 100,
        sorter: (a, b) => alphanumericSort(a, b, "fromRow"),
      },
      {
        title: "to Row",
        dataIndex: "toRow",
        width: 100,
        sorter: (a, b) => alphanumericSort(a, b, "toRow"),
      },
      {
        title: "no. of modules",
        dataIndex: "modules",
        width: 100,
        sorter: (a, b) => alphanumericSort(a, b, "modules"),
      },
      {
        title: "no. of rows",
        dataIndex: "rows",
        width: 100,
        sorter: (a, b) => alphanumericSort(a, b, "rows"),
      },
      {
        title: "cleaning supervisor",
        dataIndex: "name",
        width: 100,
        // sorter: (a, b) => alphanumericSort(a, b, "name"),
      },
      {
        title: "total KWP",
        dataIndex: "totalKWP",
        width: 100,
        sorter: (a, b) => alphanumericSort(a, b, "totalKWP"),
      },
      {
        title: "duration",
        dataIndex: "duration",
        width: 100,
        // sorter: (a, b) => alphanumericSort(a, b, "duration"),
      },
      {
        title: "start Date",
        dataIndex: "startDate",
        sorter: (a, b) => moment(a.startDate).subtract(moment(b.startDate)),
        width: 150,
        render: (value) => moment(value).format("YYYY-MM-DD HH:mm"),
      },
      {
        title: "end Date",
        dataIndex: "endDate",
        sorter: (a, b) => moment(a.endDate).subtract(moment(b.endDate)),
        width: 150,
        render: (value) => moment(value).format("YYYY-MM-DD HH:mm"),
      },
    ],
    EXPANSE_TABLE: [
      {
        title: "SR NO.",
        dataIndex: "no",
        width: 60,
        sorter: (a, b) => alphanumericSort(a, b, "no"),
      },
      {
        title: "party Name",
        dataIndex: "partyName",
        width: 150,
        sorter: (a, b) => alphanumericSort(a, b, "partyName"),
      },
      {
        title: "Expense ID",
        width: 180,
        dataIndex: "sku",
        sorter: (a, b) => alphanumericSort(a, b, "sku"),
      },
      {
        title: "Expense date",
        dataIndex: "expanseDate",
        width: 150,
        sorter: (a, b) => moment(a.expanseDate).subtract(moment(b.expanseDate)),
      },
      {
        title: "Category",
        dataIndex: "category",
        width: 150,
        sorter: (a, b) => alphanumericSort(a, b, "category"),
        onFilter: (value, record) => record.category === value,
        filterSearch: true,
        filters: [
          {
            text: "Travel",
            value: "Travel",
            Label: "Travel",
          },
          {
            text: "Misc",
            value: "Misc",
            Label: "Misc",
          },
          {
            text: "Fuel",
            value: "Fuel",
            Label: "Fuel",
          },
          {
            text: "Food",
            value: "Food",
            Label: "Food",
          },
          {
            text: "Accomodation",
            value: "Accomodation",
            Label: "Accomodation",
          },
          {
            text: "Office",
            value: "Office",
            Label: "Office",
          },
          {
            text: "Courier",
            value: "Courier",
            Label: "Courier",
          },
        ],
      },
      {
        title: "amount",
        dataIndex: "amount",
        width: 100,
        sorter: (a, b) => alphanumericSort(a, b, "amount"),
      },
      {
        title: "payment mode",
        dataIndex: "payment",
        width: 120,
        sorter: (a, b) => alphanumericSort(a, b, "payment"),
        onFilter: (value, record) => record.payment === value,
        filterSearch: true,
        filters: [
          {
            text: "Cash",
            value: "Cash",
            Label: "Cash",
          },
          {
            text: "Cheque",
            value: "Cheque",
            Label: "Cheque",
          },
          {
            text: "UPI",
            value: "UPI",
            Label: "UPI",
          },
          {
            text: "Credit/Debit Card",
            value: "Credit/Debit Card",
            Label: "Credit/Debit Card",
          },
        ],
      },
      {
        title: "paid by",
        dataIndex: "tracking",
        width: 100,
        sorter: (a, b) => alphanumericSort(a, b, "tracking"),
        onFilter: (value, record) => record.tracking === value,
        filterSearch: true,
        filters: [
          {
            text: "Employee (to reimburse)",
            value: "Employee",
            Label: "Employee",
          },
          {
            text: "Company",
            value: "Company",
            Label: "Company",
          },
        ],
      },
      {
        title: "Bill Reference",
        dataIndex: "billReference",
        width: 120,
        sorter: (a, b) => alphanumericSort(a, b, "billReference"),
      },
      {
        title: "action",
        dataIndex: "action",
        width: 120,
        render: RenderActionButtons,
      },
      // {
      //   title: "receipt",
      //   dataIndex: "receipt",
      //   width: 60,
      //   render: RenderDownloadButton,
      // },
      // {
      //   title: "Dowload",
      //   dataIndex: "download",
      //   width: 80,
      //   render: RenderDownloadButton,
      // },
      {
        title: "STATUS",
        width: 150,
        dataIndex: "status",
        sorter: (a, b) => alphanumericSort(a, b, "status"),
        filters: [
          {
            text: "Pending",
            value: "Pending",
          },
          {
            text: "Delivered",
            value: "Delivered",
          },
          {
            text: "Declined",
            value: "Declined",
          },
          {
            text: "Approved",
            value: "Approved",
          },
        ],
        onFilter: (value, record) => record?.status?.value === value,
        filterSearch: true,
        render: (status) => {
          return (
            <Select
              style={{ width: "100%" }}
              defaultValue={status?.value}
              onChange={(e) => status?.onChange(e)}
              onClick={(e) => {
                e.stopPropagation();
              }}
            >
              <Select.Option key={"Submit"}>Submit </Select.Option>
              <Select.Option key={"Approved"}>Approved </Select.Option>
              <Select.Option key={"Paid"}>Paid </Select.Option>
              <Select.Option key={"Rejected"}>Rejected</Select.Option>
            </Select>
          );
        },
      },
    ],
    ATTENDANCE_TABLE: [
      {
        title: "SR NO.",
        dataIndex: "no",
        width: 60,
        sorter: (a, b) => alphanumericSort(a, b, "no"),
      },
      {
        title: "employee name",
        dataIndex: "name",
        width: 200,
        sorter: (a, b) => alphanumericSort(a, b, "name"),
      },
      {
        title: "position",
        dataIndex: "position",
        width: 150,
        sorter: (a, b) => alphanumericSort(a, b, "position"),
      },
      {
        title: "Employment Type",
        dataIndex: "type",
        width: 60,
        sorter: (a, b) => alphanumericSort(a, b, "type"),
      },
      // {
      //   title: "remarks",
      //   dataIndex: "remarks",
      //   sorter: (a, b) => alphanumericSort(a, b, "remarks"),
      // },
    ],
    ONGOING_CHECKUP_TABLE: [
      {
        title: "SR NO.",
        dataIndex: "no",
        width: 40,
        sorter: (a, b) => alphanumericSort(a, b, "no"),
      },
      {
        title: "activities",
        dataIndex: "activities",
        width: 250,
        sorter: (a, b) => alphanumericSort(a, b, "activities"),
      },
      {
        title: "category",
        dataIndex: "category",
        width: 120,
        sorter: (a, b) => alphanumericSort(a, b, "category"),
      },

      {
        title: "subCategory",
        dataIndex: "subCategory",
        width: 100,
        sorter: (a, b) => alphanumericSort(a, b, "subCategory"),
      },
      {
        title: "frequency",
        dataIndex: "frequency",
        width: 80,
        sorter: (a, b) => alphanumericSort(a, b, "frequency"),
      },
      {
        title: "priority",
        dataIndex: "priority",
        width: 70,
        sorter: (a, b) => alphanumericSort(a, b, "priority"),
      },
    ],
    VEHICAL_EXPANSE_TABLE: [
      {
        title: "SR NO.",
        dataIndex: "no",
        width: 70,
        sorter: (a, b) => alphanumericSort(a, b, "no"),
      },
      {
        title: "vehicle type",
        dataIndex: "vehicle",
        width: 100,
        sorter: (a, b) => alphanumericSort(a, b, "vehicle"),
      },
      {
        title: "Expense ID",
        width: 200,
        dataIndex: "sku",
        sorter: (a, b) => alphanumericSort(a, b, "sku"),
      },
      {
        title: "vehicle number",
        dataIndex: "vehicleNumber",
        width: 120,
        sorter: (a, b) => alphanumericSort(a, b, "vehicleNumber"),
      },
      {
        title: "category",
        dataIndex: "category",
        width: 110,
        sorter: (a, b) => alphanumericSort(a, b, "category"),
      },
      {
        title: "cost",
        dataIndex: "coast",
        width: 70,
        sorter: (a, b) => alphanumericSort(a, b, "coast"),
      },
      {
        title: "odometer reading",
        dataIndex: "odometerReading",
        width: 120,
        sorter: (a, b) => alphanumericSort(a, b, "odometerReading"),
      },
      {
        title: "remarks",
        dataIndex: "remark",
        width: 110,
        sorter: (a, b) => alphanumericSort(a, b, "remark"),
      },
      // {
      //   title: "total KWP",
      //   dataIndex: "totalKWP",
      //   sorter: (a, b) => alphanumericSort(a, b, "totalKWP"),
      // },
      // {
      //   title: "startDate",
      //   dataIndex: "startDate",
      //   sorter: (a, b) => moment(a.date).subtract(moment(b.startDate)),
      // },
      {
        title: "receipt",
        dataIndex: "receipt",
        width: 70,
        render: RenderDownloadButton,
      },
      // {
      //   title: "dowload",
      //   dataIndex: "dowload",
      //   width: 80,
      //   render: RenderDownloadButton,
      // },
      {
        title: "STATUS",
        width: 150,
        dataIndex: "status",
        sorter: (a, b) => alphanumericSort(a, b, "status"),
        render: (status) => {
          return (
            <Select
              style={{ width: "100%" }}
              defaultValue={status?.value}
              onChange={(e) => status?.onChange(e)}
              onClick={(e) => {
                e.stopPropagation();
              }}
            >
              <Select.Option key={"Submit"}>Submit </Select.Option>
              <Select.Option key={"Approved"}>Approved </Select.Option>
              <Select.Option key={"Paid"}>Paid </Select.Option>
              <Select.Option key={"Rejected"}>Rejected</Select.Option>
            </Select>
          );
        },
      },
      {
        title: "date",
        dataIndex: "date",
        width: 120,
        sorter: (a, b) => moment(a.date).subtract(moment(b.date)),
      },
    ],
  },
  API: {
    dashboardRequestINProgress: {
      type: "GET",
      endpoint: "/maintenancerequest?status=Inprogress",
    },
    totalExpense: {
      type: "GET",
      endpoint: "/expanse/total",
    },
    getCalendarEvents: {
      type: "GET",
      endpoint: "/maintenancerequest/calendar",
    },
    getemployeeattendance: {
      type: "GET",
      endpoint: "/attendance?date=",
    },
    updateemployeeattendance: {
      type: "PATCH",
      endpoint: "/attendance",
    },
    getongoingcheckup: {
      type: "GET",
      endpoint: "/ongoingcheckup?due=",
    },
    updategongoingcheckup: {
      type: "PATCH",
      endpoint: "/ongoingcheckup/1",
    },
    createmaintenanceResolution: {
      type: "POST",
      endpoint: "/maintenanceresolution",
    },
    login: {
      type: "POST",
      endpoint: "/user/login",
    },
    signUp: { type: "POST", endpoint: "/user/signup" },
    getMe: {
      type: "GET",
      endpoint: "/user/getMe",
    },
    driver: {
      type: "GET",
      endpoint: "/driver",
    },
    employee: {
      type: "GET",
      endpoint: "/employee",
    },
    oneEmployee: {
      type: "GET",
      // endpoint: "/employee?eId=:id",
      endpoint: "/employee/:id",
    },
    updatedEmployee: {
      type: "PATCH",
      endpoint: "/employee/:id",
    },
    deleteEmployee: {
      type: "DELETE",
      endpoint: "/employee/:id",
    },
    oneEmployeeId: {
      type: "GET",
      endpoint: "/employee/:id",
    },
    addEmployee: {
      type: "POST",
      endpoint: "/employee",
    },
    documentEmployee: {
      type: "GET",
      // endpoint: "/employeedocument?eId=:id",
      endpoint: "/employeedocument?employeeId=:id",
    },
    uploadEmployeeDocument: {
      type: "POST",
      endpoint: "/employeedocument",
    },
    deleteEmployeeDocument: {
      type: "DELETE",
      endpoint: "/employeedocument/:id",
    },
    leaverequest: {
      type: "POST",
      endpoint: "/leaverequest",
    },
    leaverequestUpdate: {
      type: "PATCH",
      endpoint: "/leaverequest/:id",
    },
    leaverequestDelete: {
      type: "DELETE",
      endpoint: "/leaverequest/:id",
    },
    TimeoffEmployee: {
      type: "GET",
      // endpoint: "/leaverequest?eId=:id",
      endpoint: "/leaverequest?employeeId=:id",
    },
    inventory: {
      type: "GET",
      endpoint: "/inventoryinward",
    },
    inventoryUpdate: {
      type: "PATCH",
      endpoint: "/inventoryinward/:id",
    },
    inventoryDelete: {
      type: "DELETE",
      endpoint: "/inventoryinward/:id",
    },
    inventoryCreate: {
      type: "POST",
      endpoint: "/inventoryinward",
    },
    inventoryById: {
      type: "GET",
      endpoint: "/inventoryinward/:id",
    },
    AllEquipment: {
      type: "GET",
      endpoint: "/equipment",
    },
    equipmentById: {
      type: "GET",
      endpoint: "/equipment/:id",
    },
    deleteEquipment: {
      type: "DELETE",
      endpoint: "/equipment/:id",
    },
    updateequipment: {
      type: "PATCH",
      endpoint: "/equipment/:id",
    },
    equipment: {
      type: "POST",
      endpoint: "/equipment",
    },
    searchProducts: {
      type: "GET",
      endpoint: "/product",
    },
    documentEquipment: {
      type: "GET",
      endpoint: "/equipmentdocument?equipmentId=:id",
    },
    uploadEquipmentDocument: {
      type: "POST",
      endpoint: "/equipmentdocument",
    },
    deleteEquipmentDocument: {
      type: "DELETE",
      endpoint: "/equipmentdocument/:id",
    },
    purchaserequesition: {
      type: "POST",
      endpoint: "/purchaserequesition",
    },
    purchaserequesitionUpdate: {
      type: "PATCH",
      endpoint: "/purchaserequesition/:id",
    },
    purchaserequesitionDelete: {
      type: "DELETE",
      endpoint: "/purchaserequesition/:id",
    },
    purchaserequesitiongetAll: {
      type: "GET",
      endpoint: "/purchaserequesition",
    },
    eventlogCreate: {
      type: "POST",
      endpoint: "/eventlog",
    },
    eventlogUpdate: {
      type: "PATCH",
      endpoint: "/eventlog/:id",
    },
    eventlogDelete: {
      type: "DELETE",
      endpoint: "/eventlog/:id",
    },
    eventlogAll: {
      type: "GET",
      endpoint: "/eventlog",
    },
    cleaningCreate: {
      type: "POST",
      endpoint: "/cleaning",
    },
    cleaningUpdate: {
      type: "PATCH",
      endpoint: "/cleaning/:id",
    },
    cleaningDelete: {
      type: "DELETE",
      endpoint: "/cleaning/:id",
    },
    cleaningAll: {
      type: "GET",
      endpoint: "/cleaning",
    },
    expanseCreate: {
      type: "POST",
      endpoint: "/expanse",
    },
    expanseUpdate: {
      type: "PATCH",
      endpoint: "/expanse/:id",
    },
    expanseDelete: {
      type: "DELETE",
      endpoint: "/expanse/:id",
    },
    expanseAll: {
      type: "GET",
      endpoint: "/expanse",
    },
    vehicleexpanseCreate: {
      type: "POST",
      endpoint: "/vehicleexpanse",
    },
    vehicleexpanseUpdate: {
      type: "PATCH",
      endpoint: "/vehicleexpanse/:id",
    },
    vehicleexpanseDelete: {
      type: "DELETE",
      endpoint: "/vehicleexpanse/:id",
    },
    vehicleexpanseAll: {
      type: "GET",
      endpoint: "/vehicleexpanse",
    },
    abtCreate: {
      type: "POST",
      endpoint: "/abt",
    },
    abtUpdate: {
      type: "PATCH",
      endpoint: "/abt/:id",
    },
    abtDelete: {
      type: "DELETE",
      endpoint: "/abt/:id",
    },
    abtAll: {
      type: "GET",
      endpoint: "/abt",
    },
    getallMaintenanceRequests: {
      type: "GET",
      endpoint: "/maintenancerequest",
    },
    getallMaintenanceRequestsEquipmentId: {
      type: "GET",
      endpoint: "/maintenancerequest?equipmentId=:id",
    },
    createmaintenanceRequests: {
      type: "POST",
      endpoint: "/maintenancerequest",
    },
    updatemaintenanceRequests: {
      type: "PATCH",
      endpoint: "/maintenancerequest/:id",
    },
    deletemaintenanceRequests: {
      type: "DELETE",
      endpoint: "/maintenancerequest/:id",
    },
    equipmentcategory: {
      type: "GET",
      endpoint: "/equipmentcategory",
    },
    employmenttype: {
      type: "GET",
      endpoint: "/employmenttype",
    },
    shifts: {
      type: "GET",
      endpoint: "/shifts",
    },
    getAllManagers: {
      type: "GET",
      endpoint: "/employee",
    },
    getAlldepartment: {
      type: "GET",
      endpoint: "/department",
    },
    createdepartment: {
      type: "POST",
      endpoint: "/department",
    },
    deletedepartment: {
      type: "DELETE",
      endpoint: "/department/:id",
    },
    updatedepartment: {
      type: "PATCH",
      endpoint: "/department/:id",
    },
    plantdocument: {
      type: "GET",
      endpoint: "/document",
    },
    plantdocumentDelete: {
      type: "DELETE",
      endpoint: "/document/:id",
    },
    plantdocumentUpload: {
      type: "POST",
      endpoint: "/document",
    },
    getAllClients: {
      type: "GET",
      endpoint: "/client",
    },
    createClient: {
      type: "POST",
      endpoint: "/client",
    },
    updateClient: {
      type: "PATCH",
      endpoint: "/client/:id",
    },
    deleteClient: {
      type: "DELETE",
      endpoint: "/client/:id",
    },
    productCategory: {
      type: "GET",
      endpoint: "/productcategory",
    },
    getAllproduct: {
      type: "GET",
      endpoint: "/product",
    },
    createProduct: {
      type: "POST",
      endpoint: "/product",
    },
    createInventoryoutword: {
      type: "POST",
      endpoint: "/inventoryoutward",
    },
    TransferInventory: {
      type: "GET",
      endpoint: "/inventoryoutward/transfers",
    },
    TransferUpdate: {
      type: "PATCH",
      endpoint: "/inventoryoutward/:id",
    },
    TransferDelete: {
      type: "DELETE",
      endpoint: "/inventoryoutward/:id",
    },
    inventoryProductCategory: {
      type: "GET",
      endpoint: "/productcategory",
    },
    equipmentProductCategory: {
      type: "GET",
      endpoint: "/equipmentcategory",
    },
    addinventoryProductCategory: {
      type: "POST",
      endpoint: "/productcategory",
    },
    deleteinventoryProductCategory: {
      type: "DELETE",
      endpoint: "/productcategory/:id",
    },
    updateinventoryProductCategory: {
      type: "PATCH",
      endpoint: "/productcategory/:id",
    },
    addequipmentProductCategory: {
      type: "POST",
      endpoint: "/equipmentcategory",
    },
    deleteequipmentProductCategory: {
      type: "DELETE",
      endpoint: "/equipmentcategory/:id",
    },
    updateequipmentProductCategory: {
      type: "PATCH",
      endpoint: "/equipmentcategory/:id",
    },
    getallvendors: {
      type: "GET",
      endpoint: "/vendor",
    },
    createvendor: {
      type: "POST",
      endpoint: "/vendor",
    },
    deletevendor: {
      type: "DELETE",
      endpoint: "/vendor/:id",
    },
    updatevendor: {
      type: "PATCH",
      endpoint: "/vendor/:id",
    },
    checkupgetAll: {
      type: "GET",
      endpoint: "/checkup",
    },
    checkupgetAdd: {
      type: "POST",
      endpoint: "/checkup",
    },
    checkupDelete: {
      type: "DELETE",
      endpoint: "/checkup/:id",
    },
    checkupUpdate: {
      type: "PATCH",
      endpoint: "/checkup/:id",
    },
    updatePlant: {
      type: "PATCH",
      endpoint: "/user/updateplant",
    },
    updatePassword: {
      type: "PATCH",
      endpoint: "/user/password",
    },
    movehistory: {
      type: "GET",
      endpoint: "/movement",
    },
    InventoryDashboard: {
      type: "GET",
      endpoint: "/inventoryinward/stock",
    },
    CleaningDashboard: {
      type: "GET",
      endpoint: "/cleaning/status",
    },
    leaveType: {
      type: "GET",
      endpoint: "/leavetype",
    },
    forgetPassword: {
      type: "PATCH",
      endpoint: "/user/password/forgot",
    },
    resetPassword: {
      type: "PATCH",
      endpoint: "/user/password/reset/",
    },
  },
};
export default CONSTANTS;
