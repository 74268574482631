import React, { useEffect, useState } from "react";
import { Col, Row } from "antd";
import PageHeader from "../../../../component/common/page-Header";
import { DownloadOutlined } from "@ant-design/icons";
import CustomTable from "../../../../component/common/Custom-Table";
import CONSTANTS from "../../../../util/constant/CONSTANTS";
import useHttp from "../../../../hooks/use-http";
import ModalFormCreator from "../../../../component/common/ModalFormCreator";
import moment from "moment";
const calculateDuration = (from, to) => {
  const duration = moment.duration(moment(to).diff(moment(from)));
  const days = duration.days();
  const hours = duration.hours();
  const minutes = duration.minutes();

  let durationString = "";
  if (days > 0) {
    durationString += `${days} Day${days !== 1 ? "s" : ""} `;
  }
  if (hours > 0) {
    durationString += `${hours} Hour${hours !== 1 ? "s" : ""} `;
  }
  if (minutes > 0) {
    durationString += `${minutes} Min${minutes !== 1 ? "s" : ""} `;
  }
  durationString = durationString.trim();

  return durationString;
};
const LogBookCleanLog = () => {
  const [data, setData] = useState([]);
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 20,
    total: 0,
  });
  const [Allfilter, setAllFilter] = useState(null);
  const [onTransfer, setOnTransfer] = useState(false);
  const [openEditRow, setOpenEditRow] = useState(false);
  const [formIventoryData, setFormIventoryData] = useState({});
  const [isDisabled, setIsDisabled] = useState(true);
  const [refresh, setRefresh] = useState(true);

  const API = useHttp();
  const onRequestCreate = (value, clear) => {
    API.sendRequest(
      CONSTANTS.API.cleaningCreate,
      () => {
        setOnTransfer(false);
        // console.log(res, "purchase");
        setRefresh((prev) => !prev);
        clear();
      },
      value,
      "cleaning log created"
    );
  };
  useEffect(() => {
    let QuaryParams = {
      limit: pagination.pageSize,
      page: pagination.current,
    };
    if (Allfilter?.sort) {
      QuaryParams = { ...QuaryParams, ...Allfilter?.sort };
    }
    if (Allfilter?.filter) {
      Allfilter?.filter?.map((el) => {
        QuaryParams = {
          ...QuaryParams,
          [`autogenerate-mul-array-$${el[0]}$`]: el[1],
        };
      });
    }
    API.sendRequest(
      CONSTANTS.API.cleaningAll,
      (res) => {
        setPagination((prevPagination) => ({
          ...prevPagination,
          total: res?.data?.count,
        }));
        setData(
          res?.data?.rows?.map((el, i) => {
            return {
              ...el.employee,
              ...el,
              no: (pagination.current - 1) * pagination.pageSize + i + 1,
              duration: calculateDuration(el.startDate, el.endDate),
              key: el.id,
            };
          })
        );
      },
      QuaryParams
    );
  }, [refresh, pagination.current, pagination.pageSize, Allfilter]);

  useEffect(() => {
    API.sendRequest(CONSTANTS.API.employee, (res) => {
      CONSTANTS.FORM_FIELD.CLEANING_FORM[0].item[1].option =
        res?.data?.rows?.map((el) => ({
          id: el.id,
          value: el.id,
          Label: el.name,
        }));
    });
  }, []);
  /***
   *
   * Row click
   */
  const onEditHandler = (value, clear) => {
    const updateInventoryAPI = { ...CONSTANTS.API.cleaningUpdate };
    updateInventoryAPI.endpoint = updateInventoryAPI.endpoint.replace(
      ":id",
      formIventoryData.id
    );
    // console.log(updateInventoryAPI);
    API.sendRequest(
      updateInventoryAPI,
      (res) => {
        console.log(res);
        setOpenEditRow(false);
        setRefresh((prev) => !prev);
        clear();
      },
      value,
      "cleaning log updated"
    );
  };

  const CSVData = [];
  CSVData[0] = CONSTANTS.TABLE.CLEANING_TABLE.map((el) => el.title);
  data.map((item, index) => {
    CSVData[index + 1] = CONSTANTS.TABLE.CLEANING_TABLE.map(
      (el) => item[el.dataIndex]
    );
    return 0;
  });
  return (
    <Row className="container-main">
      <ModalFormCreator
        loading={API.isLoading}
        open={onTransfer}
        onCreate={onRequestCreate}
        onCancel={() => {
          setOnTransfer(false);
        }}
        name="add CLeaning Log"
        menu="CLEANING_FORM"
      />
      <PageHeader
        data={{
          buttons: [
            !CONSTANTS.USER_ROLE && {
              id: 1,
              action: () => {
                console.log("button1");
                setOnTransfer(true);
              },
              name: "New",
            },
            {
              id: 2,
              action: () => {
                console.log("download", CSVData);
              },
              icon: <DownloadOutlined />,
              data: CSVData,
              type: "linkicon",
              name: "Cleaning-Log-" + moment().format("YYYY-MM-DD HH:mm"),
            },
          ],
          name: " Modules Cleaning Log Form",
        }}
        pagination={pagination}
        setPagination={setPagination}
        SearchBar={{
          endpointAPI: "/cleaning?search=",
          dataResponseHandle: (res) => {
            return res?.data?.rows?.map((el) => ({
              ...el.employee,
              ...el,
              duration: calculateDuration(el.startDate, el.endDate),
              key: el.id,
            }));
          },
          onValueIsSelected: (v) => {
            setFormIventoryData({ ...v });
            setOpenEditRow(true);
          },
          extraLabel: "zone",

          onBaseSearch: ["sku"],
          debounceTime: 500,
        }}
      />
      <Col
        span={24}
        style={{
          marginBlock: "15px",
        }}
        className="container-body"
      >
        <ModalFormCreator
          loading={API.isLoading}
          open={openEditRow}
          onCreate={onEditHandler}
          onCancel={() => {
            setOpenEditRow(false);
            setIsDisabled(true);
            // setFormIventoryData({});
          }}
          disabled={isDisabled}
          edit
          onEdit={() => {
            console.log("edit click");
            setIsDisabled(false);
          }}
          Delete
          onDelete={() => {
            console.log("Delete Click", formIventoryData);
            const DeleteInventoryAPI = {
              ...CONSTANTS.API.cleaningDelete,
            };
            DeleteInventoryAPI.endpoint = DeleteInventoryAPI.endpoint.replace(
              ":id",
              formIventoryData.id
            );
            API.sendRequest(
              DeleteInventoryAPI,
              (res) => {
                console.log(res);
                setRefresh((prev) => !prev);
                setOpenEditRow(false);
              },
              "Deleted Successfully"
            );
          }}
          name="edit CLeaning Log"
          menu="CLEANING_FORM"
          formData={formIventoryData}
        />
        <CustomTable
          isLoading={API.isLoading}
          dataSource={data}
          setChanges={(v) => {
            setAllFilter(v);
            setPagination((prev) => ({
              ...prev,
              current: 1,
            }));
          }}
          name="CLEANING_TABLE"
          extraclass="pointer"
          Other={{
            onRow: (row) => ({
              onClick: () => {
                setFormIventoryData({ ...row });
                setOpenEditRow(true);
              },
            }),
          }}
          DeleteSelectedRow
          APIendpoint="cleaningDelete"
          onConfirm={() => {
            setRefresh((prev) => !prev);
          }}
        />
      </Col>
    </Row>
  );
};

export default LogBookCleanLog;
