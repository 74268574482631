import React, { useEffect, useState } from "react";
import { Col, Form, Row, notification } from "antd";
import PageHeader from "../../../../component/common/page-Header";
import { DownloadOutlined } from "@ant-design/icons";
import CustomTable from "../../../../component/common/Custom-Table";
import CONSTANTS from "../../../../util/constant/CONSTANTS";
import useHttp from "../../../../hooks/use-http";
import ModalFormCreator from "../../../../component/common/ModalFormCreator";
import moment from "moment";
import FormWithButton from "../../../../component/common/Form-with-Button";
import FormFields from "../../../../component/common/FormFields";
const PurchaseRequest = () => {
  const [data, setData] = useState([]);
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 20,
    total: 0,
  });
  const [Allfilter, setAllFilter] = useState(null);

  const [onTransfer, setOnTransfer] = useState(false);
  const [openEditRow, setOpenEditRow] = useState(false);
  const [formIventoryData, setFormIventoryData] = useState({});
  const [isDisabled, setIsDisabled] = useState(true);
  const [refresh, setRefresh] = useState(true);
  const [purchaseDataOut, setPurchaseDataOut] = useState([]);
  const [SubmitValue, setSubmitValue] = useState({});
  const [formPurchase] = Form.useForm();
  const handleDeletePurchase = (key) => {
    // console.log(key);
    setPurchaseDataOut((prev) => prev.filter((item) => item.key !== key));
  };
  const API = useHttp();
  const onRequestCreate = (value) => {
    formPurchase
      .validateFields()
      .then((value) => {
        setSubmitValue(value);
        value.products = purchaseDataOut.map((el) => ({
          name: el.product,
          quantity: el.quantity,
          type: el.type,
          unit: el.unit,
          productCategoryId: el.productCategoryId,
        }));
        value.date = moment(value.date.$d).format("YYYY-MM-DD");
        if (purchaseDataOut?.length) {
          API.sendRequest(
            CONSTANTS.API.purchaserequesition,
            (res) => {
              // console.log(res, "purchase");
              formPurchase.resetFields();
              setOnTransfer(false);
              setPurchaseDataOut([]);
              setRefresh((prev) => !prev);
            },
            value,
            "purchase requested"
          );
        } else {
          notification.error({
            message: "please select product for requestation",
          });
        }
      })
      .catch((info) => {
        console.log("Validate Failed:", info);
        return;
      });
  };
  useEffect(() => {
    let QuaryParams = {
      limit: pagination.pageSize,
      page: pagination.current,
    };
    if (Allfilter?.sort) {
      QuaryParams = { ...QuaryParams, ...Allfilter?.sort };
    }
    if (Allfilter?.filter) {
      Allfilter?.filter?.map((el) => {
        QuaryParams = {
          ...QuaryParams,
          [`autogenerate-mul-array-${el[0]}`]: el[1],
        };
      });
    }
    const PurChasePage = { ...CONSTANTS.API.purchaserequesitiongetAll };
    PurChasePage.endpoint = `${PurChasePage.endpoint}`;

    API.sendRequest(
      PurChasePage,
      (res) => {
        setPagination((prevPagination) => ({
          ...prevPagination,
          total: res?.data?.count,
        }));
        setData(
          res?.data?.rows?.map((el, i) => ({
            ...el.product,
            ...el,
            no: (pagination.current - 1) * pagination.pageSize + i + 1,
            status: {
              id: el.id,
              value: el.status,
              onChange: (res) => {
                // console.log(res, "click happend", el.id);
                const updatePurchaseAPI = {
                  ...CONSTANTS.API.purchaserequesitionUpdate,
                };
                updatePurchaseAPI.endpoint = updatePurchaseAPI.endpoint.replace(
                  ":id",
                  el.id
                );
                API.sendRequest(
                  updatePurchaseAPI,
                  (res) => {
                    // console.log(res);
                    setRefresh((prev) => !prev);
                  },
                  { status: res },
                  "status updated"
                );
              },
            },
            key: el.id,
          }))
        );
      },
      QuaryParams
    );
  }, [pagination.current, pagination.pageSize, Allfilter, refresh]);
  useEffect(() => {
    API.sendRequest(CONSTANTS.API.getallMaintenanceRequests, (res) => {
      const filteredRequests = res?.data?.data
        .filter((el) => el.status !== "Completed" && el.status !== "Cancelled")
        .map((el) => ({
          id: el.id,
          value: el.id,
          Label: el.title,
        }));

      // purchase maintenance id
      CONSTANTS.FORM_FIELD.PURCHASE_REQUEST_FORM_NEW[0].item[1].option =
        filteredRequests;
      CONSTANTS.FORM_FIELD.PURCHASE_REQUEST_FORM[1].item[1].option =
        filteredRequests;
      // supply out maintenance id
      CONSTANTS.FORM_FIELD.SUPPLY_OUT_INVENTORY[1].item[0].option =
        filteredRequests;
    });
    API.sendRequest(CONSTANTS.API.productCategory, (res) => {
      CONSTANTS.FORM_FIELD.PURCHASE_REQUEST_INNER[1].option =
        res?.data?.rows?.map((el) => ({
          id: el.id,
          value: el.id,
          Label: el.category,
        }));
    });

    API.sendRequest(CONSTANTS.API.searchProducts, (res) => {
      CONSTANTS.FORM_FIELD.PURCHASE_REQUEST_INNER[0].option =
        res?.data?.rows?.map((el) => ({
          id: el.id,
          value: el.name,
          Label: el.name,
        }));
      CONSTANTS.FORM_FIELD.PURCHASE_REQUEST_INNER[0].onSelect = (val) => {
        const product = res?.data?.rows.find((el) => val === el?.name);
        setFormIventoryData({ ...product, product: product.name });
      };
    });
  }, []);

  /***
   *
   * Row click
   */
  const onEditHandler = (value, clear) => {
    if (formIventoryData.status.value === "Delivered") {
      setOpenEditRow(false);
      return;
    }
    delete value.name;
    value.date = moment(value.date.$d).format("YYYY-MM-DD");
    const updateInventoryAPI = { ...CONSTANTS.API.purchaserequesitionUpdate };
    updateInventoryAPI.endpoint = updateInventoryAPI.endpoint.replace(
      ":id",
      formIventoryData.id
    );
    // console.log(updateInventoryAPI);
    API.sendRequest(
      updateInventoryAPI,
      (res) => {
        console.log(res);
        setOpenEditRow(false);
        setRefresh((prev) => !prev);
        clear();
      },
      value,
      "purchse request Updated"
    );
  };

  const CSVData = [];
  CSVData[0] = CONSTANTS.TABLE.PURCHASE_REQUESTS.map((el) => el.title);
  data.forEach((item, index) => {
    CSVData[index + 1] = CONSTANTS.TABLE.PURCHASE_REQUESTS.map((el) => {
      if (el.dataIndex === "status") {
        return item[el.dataIndex]?.value;
      }
      return item[el.dataIndex];
    });
  });
  // data.map((item, index) => {
  //   CSVData[index + 1] = CONSTANTS.TABLE.PURCHASE_REQUESTS.map(
  //     (el) => item[el.dataIndex]
  //   );
  //   return 0;
  // });
  return (
    <Row className="container-main">
      {/* <ModalFormCreator
        open={onTransfer}
        onCreate={onRequestCreate}
        onCancel={() => {
          setOnTransfer(false);
        }}
        name="PURCHASE requesition form"
        menu="PURCHASE_REQUEST_FORM"
      /> */}
      <ModalFormCreator
        loading={API.isLoading}
        open={onTransfer}
        onCreate={onRequestCreate}
        onCancel={() => {
          setOnTransfer(false);
          setPurchaseDataOut([]);
          setSubmitValue({});
          setFormIventoryData({});
        }}
        inbuilt={false}
        name="PURCHASE requesition form"
        menu="PURCHASE_REQUEST_FORM_NEW"
      >
        <FormFields
          formData={SubmitValue}
          menu={"PURCHASE_REQUEST_FORM_NEW"}
          form={formPurchase}
        />
        <CustomTable
          dataSource={purchaseDataOut}
          name="PURCHASE_REQUEST_TABLE_INNER"
        />
        <FormWithButton
          menu={"PURCHASE_REQUEST_INNER"}
          name="Add"
          buttonwidth={2}
          formData={formIventoryData}
          onCreate={(element) => {
            // console.log(element, "item");
            setFormIventoryData({});
            element.productcategory =
              CONSTANTS.FORM_FIELD.PURCHASE_REQUEST_INNER[1].option.find(
                (el) => el.id === element.productCategoryId
              ).Label;
            setPurchaseDataOut((prev) => [
              ...prev,
              {
                key: prev.length ? prev[prev.length - 1].id + 1 : 1,
                id: prev.length ? prev[prev.length - 1].id + 1 : 1,
                ...element,
                delete: {
                  id: prev.length ? prev[prev.length - 1].id + 1 : 1,
                  key: prev.length ? prev[prev.length - 1].id + 1 : 1,
                  onClick: handleDeletePurchase,
                },
              },
            ]);
          }}
        />
      </ModalFormCreator>
      <PageHeader
        data={{
          buttons: [
            !CONSTANTS.USER_ROLE && {
              id: 1,
              action: () => {
                console.log("button1");
                setOnTransfer(true);
              },
              name: "New",
            },
            {
              id: 2,
              action: () => {
                console.log("button3");
                console.log("download", CSVData);
              },
              icon: <DownloadOutlined />,
              data: CSVData,
              type: "linkicon",
              name: "PurChase-" + moment().format("YYYY-MM-DD HH:mm"),
            },
          ],
          name: " Purchase Request",
        }}
        pagination={pagination}
        setPagination={setPagination}
        SearchBar={{
          endpointAPI: "/purchaserequesition?search=",
          dataResponseHandle: (res) => {
            return res?.data?.rows?.map((el) => ({
              ...el.product,
              ...el,
              key: el?.id,
            }));
          },
          onValueIsSelected: (row) => {
            setFormIventoryData({ ...row });
            setOpenEditRow(true);
          },
          extraLabel: "name",
          onBaseSearch: ["prf"],
          debounceTime: 500,
        }}
      />
      <Col
        span={24}
        style={{
          marginBlock: "15px",
        }}
        className="container-body"
      >
        <ModalFormCreator
          loading={API.isLoading}
          open={openEditRow}
          onCreate={onEditHandler}
          onCancel={() => {
            setOpenEditRow(false);
            setIsDisabled(true);
            // setFormIventoryData({});
          }}
          disabled={isDisabled}
          edit
          onEdit={() => {
            // console.log("edit click");
            setIsDisabled(false);
          }}
          Delete
          onDelete={() => {
            // console.log("Delete Click", formIventoryData);
            if (formIventoryData.status.value === "Delivered") {
              setOpenEditRow(false);
              return;
            }
            const DeleteInventoryAPI = {
              ...CONSTANTS.API.purchaserequesitionDelete,
            };
            DeleteInventoryAPI.endpoint = DeleteInventoryAPI.endpoint.replace(
              ":id",
              formIventoryData.id
            );
            API.sendRequest(
              DeleteInventoryAPI,
              (res) => {
                console.log(res);
                setRefresh((prev) => !prev);
                setOpenEditRow(false);
              },
              "Deleted Successfully"
            );
          }}
          name="PURCHASE REQUEST DETAILS"
          menu="PURCHASE_REQUEST_FORM"
          formData={formIventoryData}
        />
        <CustomTable
          isLoading={API.isLoading}
          dataSource={data}
          name="PURCHASE_REQUESTS"
          extraclass="pointer"
          Other={{
            onRow: (row) => ({
              onClick: () => {
                setFormIventoryData({ ...row });
                setOpenEditRow(true);
              },
            }),
          }}
          DeleteSelectedRow
          APIendpoint="purchaserequesitionDelete"
          onConfirm={() => {
            setRefresh((prev) => !prev);
            // console.log(keys);
            // setIventoryDocumentData((prev) =>
            //   prev.filter((item) => !keys.includes(item.key))
            // );
          }}
          setChanges={(v) => {
            setAllFilter(v);
            setPagination((prev) => ({
              ...prev,
              current: 1,
            }));
          }}
        />
      </Col>
    </Row>
  );
};

export default PurchaseRequest;
